import React from 'react'

const Inspect = () => {
  return (
    <div><svg width="106" height="96" viewBox="0 0 106 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M95 0.75H11C5.225 0.75 0.5 5.475 0.5 11.25V84.75C0.5 90.525 5.225 95.25 11 95.25H95C100.775 95.25 105.5 90.525 105.5 84.75V11.25C105.5 5.475 100.775 0.75 95 0.75ZM95 84.75H11V11.25H95V84.75Z" fill="#35BBC5"/>
    </svg>
    </div>
  )
}

export default Inspect