import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Card,
  // Menu,
  // MenuItem,
  Typography,
  Hidden,
  // Grid,
  // Button,
  IconButton,
} from "@mui/material";
// import { Link as RouterLink } from "react-router-dom";
import ContentWrapper from "src/components/ContentWrapper";
import { styled as experimentalStyled } from "@mui/material/styles";
// import { IconContext } from "react-icons";
// import { RiArrowDownSLine } from 'react-icons/ri';
// import { CgFileDocument } from 'react-icons/cg';
// import { BiNotepad } from 'react-icons/bi';
// import { ImMobile } from 'react-icons/im';
// import { GiHamburgerMenu } from 'react-icons/gi';
// import { HiOutlineLightBulb } from 'react-icons/hi';
// import { RiQuestionnaireLine } from 'react-icons/ri';
// import { AiOutlineSearch } from 'react-icons/ai';
// import Logo from "src/components/Logo";
import Footer from "./Footer";
import useRefMounted from "src/hooks/useRefMounted";
import useAuth from "src/hooks/useAuth";
import Pricing from "src/content/pricing";
import "src/content/pricing/pricing.css";
import Home from "../home";
// import "./Landing.css";
// import { Duo, HeadsetMic } from "@mui/icons-material";
import AudioVideo from "../product/audioVedio";
import LiveStream from "../product/liveStream";
import { ButtonPrimary } from "src/components/CustomButtons";
// import GridImage from "../../assets/Svg/GridImage";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import HeaderMobile from "./HeaderMobile";
// import IngressifyLogo from "src/assets/Svg/IngressifyLogo";
import Logo from "src/components/Logo";

const HeaderWrapper = experimentalStyled(Card)(
  ({ theme }) => `
    width: 2560px;
    // height:100px;
    border-radius: 0;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 6;
    top: 0;
    background: linear-gradient(to bottom, #000000, #202020);
    height: ${theme.spacing(8.5)};
    box-shadow: none;
    @media (max-width: 720px) {
      width: 100%;
    };
    @media (max-width: 2560px) and (min-width: 720px) {
      width: 100%;
    };
`
);

const Heading = experimentalStyled(Typography)(
  () => `
font-size: 20px;
font-weight: 400;
font-family: Helvetica;
color: white;

@media (max-width: 768px) {
  font-size: 25px;
}

@media (max-width: 480px) {
  font-size: 15px;
}
`
);

const Price = experimentalStyled(Typography)(
  () => `
  font-size: 15px;
font-weight: 400;
font-family: Poppins;
color: white;
 
@media (max-width: 768px) {
  font-size: 15px;
}

@media (max-width: 480px) {
  font-size: 10px;
}
`
);

const OverviewWrapper = experimentalStyled(Box)(
  ({ theme }) => `
    overflow: auto;
    // flex: 1;
    overflow-x: visible;
    margin-top: ${theme.spacing(7.5)};
`
);

function Overview() {
  const { loginWithPopup } = useAuth() as any;
  const isMountedRef = useRefMounted();
  const [, setError] = useState<string | null>(null);
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const [openSidebar, setOpenDrawer] = useState(false);

  const handleSidebar = () => {
    setOpenDrawer(!openSidebar);
  };

  const closeSidebar = () => {
    setOpenDrawer(false);
  };

  const handleLogin = async (): Promise<void> => {
    try {
      await loginWithPopup();
    } catch (err) {
      if (isMountedRef.current) {
        setError(err.message);
      }
    }
  };

  // const [productAnchorEl, setProductAnchorEl] = useState(null);
  // const [developerAnchorEl, setDeveloperAnchorEl] = useState(null);
  // const productOpen = Boolean(productAnchorEl);
  // const developerOpen = Boolean(developerAnchorEl);

  // const handleProductClick = (event) => {
  //   setProductAnchorEl(event.currentTarget);
  // };

  // const handleDeveloperClick = (event) => {
  //   setDeveloperAnchorEl(event.currentTarget);
  // };

  // const handleProductClose = () => {
  //   setProductAnchorEl(null);
  // };

  // const changeAVProductHandler = () => {
  //   navigate("/audio-video-calling-api-sdk");
  // };

  // const changeLiveProductHandler = () => {
  //   navigate("/live-streaming-api-sdk");
  // };
  // const handleDeveloperClose = () => {
  //   setDeveloperAnchorEl(null);
  // };

  const getDynamicPage = () => {
    if (location.pathname === "/") {
      return (
        <div style={{ background: "white", color: "black" }}>
          <Home />
        </div>
      );
    } else if (location.pathname === "/pricing") {
      return (
        <div className="features_container">
          <Pricing />
        </div>
      );
    } else if (location.pathname === "/audio-video-calling-api-sdk") {
      return (
        <div className="features_container">
          <AudioVideo />
        </div>
      );
    } else if (location.pathname === "/live-streaming-api-sdk") {
      return (
        <div className="features_container">
          <LiveStream />
        </div>
      );
    }
  };

  return (
    <OverviewWrapper
      sx={{
        margin: "auto",
        maxWidth: "2560px",
        display: "block",
        minHeight: "100vh",
      }}
    >
      <ContentWrapper title="Ingressify">
        <HeaderWrapper>
          <Container maxWidth="xl">
            <Box
              display="flex"
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Box display="flex" alignItems="center" gap={"10px"}>
                {/* <Box sx={{  width: {
                  xl:'50px',
                  lg:'50px',

                }, height: "50px" }}>
                  <IngressifyLogo />
                </Box> */}
                <Logo />
                <Heading>Ingressify</Heading>
              </Box>

              <Hidden only={["sm", "xs"]}>
                <Box
                  sx={{
                    display: "flex",
                    gap: { xl: "20px", lg: "20px", md: "10px" },
                  }}
                >
                  {/* <Typography
                    fontSize="14px"
                    fontWeight="500"
                    fontFamily={"Poppins"}
                    color={"white"}
                  >
                    Product
                  </Typography> */}
                  <Typography
                    fontSize="14px"
                    fontWeight="500"
                    fontFamily={"Poppins"}
                    color={"white"}
                  >
                    Soulutions
                  </Typography>
                  {/* <Typography
                    fontSize="14px"
                    fontWeight="500"
                    fontFamily={"Poppins"}
                    color={"white"}
                  >
                    Customers
                  </Typography> */}
                  <Typography
                    fontSize="14px"
                    fontWeight="500"
                    fontFamily={"Poppins"}
                    color={"white"}
                  >
                    Doc
                  </Typography>
                  <Typography
                    fontSize="14px"
                    fontWeight="500"
                    fontFamily={"Poppins"}
                    color={"white"}
                  >
                    Pricing
                  </Typography>
                  <Typography
                    fontSize="14px"
                    fontWeight="500"
                    fontFamily={"Poppins"}
                    color={"white"}
                  >
                    Download
                  </Typography>
                </Box>
                <Box display="flex" gap={"10px"}>
                  <Box display="flex" flexDirection={"column"}>
                    <Price>From $5</Price>
                    <Typography
                      fontSize="10px"
                      fontWeight="400"
                      fontFamily={"Poppins"}
                      color={"#B7B7B7"}
                    >
                      Discounts available
                    </Typography>
                  </Box>
                  {isAuthenticated ? (
                    <>
                      <ButtonPrimary
                        id="dashboard"
                        responsive={false}
                        fixedHeight={"40px"}
                        onClick={() =>
                          navigate("/dashboard", { replace: false })
                        }
                      >
                        {"Dashboard"}
                      </ButtonPrimary>
                    </>
                  ) : (
                    <ButtonPrimary
                      id="login"
                      responsive={false}
                      fixedHeight={"40px"}
                      fixedWidth={"120px"}
                      onClick={handleLogin}
                    >
                      {"Login"}
                    </ButtonPrimary>
                  )}
                </Box>
              </Hidden>
              <Hidden mdUp>
                <Box display="flex" alignItems="center">
                  <IconButton
                    id="menu-mobile"
                    color="primary"
                    // className={Style.categoryMenuMobile}
                    onClick={handleSidebar}
                    disableFocusRipple
                    disableRipple
                  >
                    {!openSidebar ? (
                      <MenuTwoToneIcon
                        style={{
                          color: "white",
                        }}
                      />
                    ) : (
                      <CloseTwoToneIcon
                        style={{
                          color: "white",
                        }}
                      />
                    )}
                  </IconButton>
                </Box>
                <HeaderMobile open={openSidebar} close={closeSidebar} />
              </Hidden>
            </Box>
          </Container>
        </HeaderWrapper>
      </ContentWrapper>

      {getDynamicPage()}

      <Footer />

      {/* <Box sx={{
        bottom: 0,
        left: 0,
        width: '100%',
        height: '60px',
        backgroundColor: '#f5f5f5',
        textAlign: 'center',
        marginTop:'40px'
      }}
      >
        <Footer />

      </Box> */}
    </OverviewWrapper>
  );
}

export default Overview;
