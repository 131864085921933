
import { Box, Card, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';


export const FeatureGridContainerRow = styled(Grid)(({ theme }) => ({
  margin: 'auto', 
  padding: '20px', 
  marginBottom:'100px', 
  display:'flex', 
  flexDirection:'row',
  [theme.breakpoints.down('sm')]: {
    flexDirection:'column-reverse',
    marginBottom:'50px', 
  },
}));

export const FeatureGridContainerRowReverse = styled(Grid)(({ theme }) => ({
  margin: 'auto', 
  padding: '20px', 
  marginBottom:'100px', 
  display:'flex', 
  flexDirection:'row-reverse',
  [theme.breakpoints.down('sm')]: {
    flexDirection:'column-reverse',
    marginBottom:'50px', 
  },
}));

export const ExtraFeatureGridContainer = styled(Grid)(({ theme }) => ({
  margin: 'auto', 
  padding: '20px', 
  marginBottom:'100px', 
  display:'flex', 
  flexDirection:'row',
  justifyContent:'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection:'column-reverse',
    marginBottom:'50px', 
  },
}));

export const HeadingWrapper = styled(Box)(() => ({
  textAlign: 'left',
}));

export const FeatureWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',
}));

export const HeadingTitle = styled(Typography)(({ theme }) => ({
  fontSize: '36px',
  fontWeight: 600,
  [theme.breakpoints.down('sm')]: {
    fontSize: '22px',
    textAlign: 'center'
  },
}));

export const HeadingSubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 400,
  color: '#B2B2B2',
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    textAlign: 'center'
  },
}));

export const ButtonBox = styled(Box)(({theme}) => ({
  marginTop: '40px',
  marginBottom: '20px',
  display:'flex',
  flexDirection:'row',
  gap:'15px',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '5px',
    justifyContent:'center'
  },
}));

export const FeatureTitle = styled(Typography)(() => ({
  fontSize: '20px',
  fontWeight: 600,
  textAlign: 'center',
}));

export const CenterContentBox = styled(Box)(() => ({
  width: '100%',
  // border:'1px solid red',
  textAlign: 'center',
  marginTop: '50px',
  marginBottom: '50px',
}));

export const FeatureCard = styled(Card)(() => ({
  backgroundColor: '#F6F6F6',
  // backgroundImage: 'linear-gradient(to right, red , yellow)',
  color: 'black',
  boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 3px rgba(0,0,0,0.24)',
  padding: '10px 10px',
  width: '200px',
  height: '200px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const ExtraFeatureCard = styled(Card)(() => ({
  backgroundColor: '#F6F6F6',
  // backgroundImage: 'linear-gradient(to right, red , yellow)',
  color: 'black',
  boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 3px rgba(0,0,0,0.24)',
  padding: '10px 10px',
  width: '200px',
  height: '200px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const FeatureHeading = styled(Typography)(() => ({
  fontSize: '20px',
  fontWeight: 600,
  marginBottom: '15px'
}));

export const FeatureContent = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: 400,
  color: '#B2B2B2',
}));