import { Grid, Typography, Avatar } from "@mui/material";
import useAuth from "src/hooks/useAuth";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { format } from "date-fns";
import {getToken} from 'src/contexts/Auth0Context'
import { useState } from 'react';


function PageHeader() {
  const [token, setToken ] = useState("No Token")
  const { t }: { t: any } = useTranslation();
  const { user } = useAuth();
  const theme = useTheme();

  const getTokenHandler = async () =>{
    try{
      const t = await getToken();
      setToken(t);
      console.log(t)
    }catch(e){
      console.log(e)
    } 
  }  
    
  

  return (
    <Grid container alignItems="center">
      <Grid item>
        <Avatar
          sx={{
            mr: 2,
            width: theme.spacing(8),
            height: theme.spacing(8),
          }}
          variant="rounded"
          alt={user?.name}
          src={user?.avatar}
        />
      </Grid>
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          {t("Welcome")}, {user?.name}!
        </Typography>
        <Typography variant="subtitle2">
          {t("These are your analytics stats for today")},{" "}
          <b>{format(new Date(), "MMMM dd yyyy")}</b>
        </Typography>
        <Typography variant="h3">{token}</Typography>
        <button onClick={getTokenHandler}>
      Click
    </button>

      </Grid>
    </Grid>
  );
}

export default PageHeader;
