import React from 'react'

const DeveloperProductivity = () => {
  return (
    <div><svg width="126" height="126" viewBox="0 0 126 126" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_226_1156)">
    <path d="M49.35 87.15L25.2 63L49.35 38.85L42 31.5L10.5 63L42 94.5L49.35 87.15ZM76.65 87.15L100.8 63L76.65 38.85L84 31.5L115.5 63L84 94.5L76.65 87.15Z" fill="#35BBC5"/>
    </g>
    <defs>
    <clipPath id="clip0_226_1156">
    <rect width="126" height="126" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    </div>
  )
}

export default DeveloperProductivity