import React, { useState } from 'react'
import { Box, Typography,useTheme,useMediaQuery } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { styled } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';


import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';


export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        // padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        // padding: theme.spacing(1),
    },
}));

function CancelPayment({ openDailog }: any) {
    const [cancelSubscriptionDailog, setCancelSubscriptionDailog] = React.useState(true);

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.up('sm'));

    const handleClickOpenCancelSubscriptionDailog = () => {
        setCancelSubscriptionDailog(true);
    };
    const handleCloseCancelSubscriptionDailog = () => {
        setCancelSubscriptionDailog(false);
        openDailog(false)
    };


    const [check, setCheck] = useState(true)


    const handleCheckBox = () => {
        if (check === true) {
            setCheck(false);
        } else {
            setCheck(true);
        }
    }


    return (

        <>
            <BootstrapDialog
                onClose={handleClickOpenCancelSubscriptionDailog}
                aria-labelledby="customized-dialog-title"
                open={cancelSubscriptionDailog}
            >

                <Box display="flex" justifyContent="space-around" alignItems="center" sx={{mt:2,p:mobile?"":2}}>
                    <Typography variant="h3">
                        Are you sure want to cancel the subscription?
                    </Typography>

                    <Box sx={{mt:1}}>
                        <CloseIcon color="primary" cursor="pointer" onClick={handleCloseCancelSubscriptionDailog}/>
                    </Box>

                </Box>



                <DialogContent  sx={{p:mobile?"":2}}>
                    <Paper sx={{ backgroundColor: "#eceff1", p: 2,mb:1 }}>
                        <Typography gutterBottom>
                            Your entire team will lose access to our services
                            after 24 Feb 2022.
                        </Typography>
                        <Typography gutterBottom>
                            This account will no longer be available
                            and all your saved data will be permanentaly deleted
                        </Typography>

                        <Typography gutterBottom>
                            This action cannot be undone.
                        </Typography>

                    </Paper>

                    <FormControlLabel
                        value="end"
                        control={<Checkbox onClick={handleCheckBox} />}
                        label="I understand and I want to proceed with the account cancellation "
                        labelPlacement="end"
                    />
                </DialogContent>
                <DialogActions>
                    <Button size="small" autoFocus onClick={handleCloseCancelSubscriptionDailog} disabled={check} variant="outlined">
                        Cancel subscription
                    </Button>
                </DialogActions>
            </BootstrapDialog>

        </>
    )
}

export default CancelPayment
