import React, { useState } from 'react'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'

// reducer
import { useDispatch, useSelector } from 'react-redux'
import { ProfilePageActions } from 'src/slices/ProfilePage/ProfilePageFormSlice'
import {
  changePassword,
  PasswordResetActions,
} from 'src/slices/ProfilePage/passwordReset'
// reducer
import {
  PaswordResetButton,
  PaswordResetButtonDisabled,
  PaswordResetButtonLoading,
  PaswordResetClose,
  PaswordResetContainer,
  PaswordResetError,
  PaswordResetFields,
  PaswordResetFieldsForm,
  PaswordResetHeader,
  PaswordResetModal,
  PaswordResetTitle,
  ResetButtonWrapper,
} from './indexStyled'
import { TextField } from '@mui/material'
import { PasswordResetSuccess, PasswordResetError } from './snackBar'

export default function ResetPassword() {
  const dispatch = useDispatch()
  const openState = useSelector(
    (state: any) => state.profilePage.passwordReset.resetPasswordDailog,
  )
  const email = useSelector(
    (state: any) => state.profilePage.profileformData.profileData.email,
  )

  const OpenSuccessSnackBar = useSelector(
    (state: any) => state.passwordReset.resetPasswordStatus.setSuccess,
  )
  const OpenErrorSnackBar = useSelector(
    (state: any) => state.passwordReset.resetPasswordStatus.setError,
  )
  const isLoading = useSelector(
    (state: any) => state.passwordReset.resetPasswordLoading.setLoading,
  )

  const handleCloseSuccess = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(PasswordResetActions.setSuccessHandler())
    closeHandler()
  }
  const handleCloseError = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(PasswordResetActions.setErrorHandler())
  }
  // snackbars

  // states
  const [currentPassword, setCurrentPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('')

  // valid states
  const [validOldPassword, setValidOldPassword] = useState<boolean>(false)
  const [validNewPassord, setValidNewPassword] = useState<boolean>(false)
  const [validConfirmPassword, setValidConfirmPassword] = useState<boolean>(
    false,
  )

  // touched states
  const [oldPasswordTouched, setOldPasswordTouched] = useState<boolean>(false)
  const [newPasswordTouched, setNewPasswordTouched] = useState<boolean>(false)
  const [confirmNewPasswordTouched, setConfirmPasswordTouched] = useState<
    boolean
  >(false)

  // close modal
  const closeHandler = () => {
    dispatch(ProfilePageActions.setPasswordResetDailogBox())
    setCurrentPassword('')
    setNewPassword('')
    setConfirmNewPassword('')
    setValidOldPassword(true)
    setValidNewPassword(true)
    setValidConfirmPassword(true)
  }

  // change handlers

  // old password handler
  const oldPasswordChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regexPassword = /^[A-Za-z\d@$!%*#?&]*$/
    if (e.target.value.match(regexPassword)) {
      setValidOldPassword(true)
      setCurrentPassword(e.target.value)
    }
  }

  // new password handler
  const newPasswordHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regexPassword = /^[A-Za-z\d@$!%*#?&]*$/
    if (e.target.value.match(regexPassword)) {
      setValidNewPassword(true)
      setNewPassword(e.target.value)
    }
  }

  // confirm new password handler
  const confirmNewPasswordHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (e.target.value !== newPassword) {
      setValidConfirmPassword(false)
    }
    setConfirmNewPassword(e.target.value)
    setValidConfirmPassword(true)
  }

  // blur handlers
  // old password blur handler
  const oldPasswordBlurHabdler = () => {
    const regexPassword = /^(?=.*\d)[a-zA-Z\d@$!%*#?&]{7,20}$/
    setOldPasswordTouched(true)
    if (!regexPassword.test(currentPassword)) {
      setValidOldPassword(false)
    }
  }
  // new password blur handler
  const newPasswordBlurHandler = () => {
    setNewPasswordTouched(true)
    const regexPassword = /^(?=.*\d)[a-zA-Z\d@$!%*#?&]{7,20}$/
    if (!regexPassword.test(newPassword)) {
      setValidNewPassword(false)
    }
  }
  // confirm new password blur handler
  const confirmNewPasswordBlurHandler = () => {
    setConfirmPasswordTouched(true)
    if (!confirmNewPassword || confirmNewPassword !== newPassword) {
      setValidConfirmPassword(false)
    }
  }

  // form handler
  const resetPasswordHandler = (e: React.FormEvent) => {
    e.preventDefault()

    if (validOldPassword && validNewPassord && validConfirmPassword) {
      const resetData = {
        email,
        currentPassword,
        newPassword,
      }
      console.log(resetData)
      dispatch(changePassword(resetData))
    }
  }

  const validFields =
    validOldPassword && validNewPassord && validConfirmPassword
  const filledFields = currentPassword && newPassword && confirmNewPassword

  return (
    <>
      <PaswordResetModal open={openState}>
        {/* modal container */}
        <PaswordResetContainer>
          {/* close modal */}
          {/* <PaswordResetCloseBox> */}
          <PaswordResetClose
            color="primary"
            disableRipple
            onClick={closeHandler}
          >
            <HighlightOffOutlinedIcon />
          </PaswordResetClose>
          {/* </PaswordResetCloseBox> */}
          {/* close modal */}
          {/* modal title */}
          <PaswordResetHeader>
            <PaswordResetTitle>Reset password</PaswordResetTitle>
          </PaswordResetHeader>
          {/* modal title */}
          {/* modal form */}
          <form>
            <PaswordResetFieldsForm>
              {/* old password field */}
              <PaswordResetFields>
                <TextField
                  fullWidth
                  size="medium"
                  autoComplete="off"
                  type="password"
                  label="Current Password"
                  value={currentPassword}
                  placeholder="Enter password here.."
                  onChange={oldPasswordChangeHandler}
                  onBlur={oldPasswordBlurHabdler}
                />
                {!validOldPassword && oldPasswordTouched && (
                  <PaswordResetError>
                    Please enter correct password
                  </PaswordResetError>
                )}
              </PaswordResetFields>
              {/* old password field */}
              {/* new password field */}
              <PaswordResetFields>
                <TextField
                  fullWidth
                  size="medium"
                  value={newPassword}
                  type="password"
                  autoComplete="off"
                  label="New Password"
                  placeholder="Enter password here.."
                  onChange={newPasswordHandler}
                  onBlur={newPasswordBlurHandler}
                />
                {!validNewPassord && newPasswordTouched && (
                  <PaswordResetError>
                    Password must contain minimum 7 characters and 1 numerical
                  </PaswordResetError>
                )}
              </PaswordResetFields>
              {/* new password field */}
              {/* confirm new password field */}
              <PaswordResetFields>
                <TextField
                  fullWidth
                  size="medium"
                  value={confirmNewPassword}
                  type="password"
                  autoComplete="off"
                  label="Confirm New Password"
                  placeholder="Enter password here.."
                  onChange={confirmNewPasswordHandler}
                  onBlur={confirmNewPasswordBlurHandler}
                />
                {!validConfirmPassword && confirmNewPasswordTouched && (
                  <PaswordResetError>Password Doesn't match</PaswordResetError>
                )}{' '}
              </PaswordResetFields>
              {/* confirm new password field */}
              {/* reset button */}

              <ResetButtonWrapper>
                {validFields &&
                filledFields &&
                confirmNewPassword === newPassword ? (
                  !isLoading ? (
                    <PaswordResetButton
                      onClick={resetPasswordHandler}
                      type="submit"
                    >
                      Reset Password
                    </PaswordResetButton>
                  ) : (
                    <PaswordResetButtonLoading loading>
                      Reset Password
                    </PaswordResetButtonLoading>
                  )
                ) : (
                  <PaswordResetButtonDisabled disabled>
                    Reset Password
                  </PaswordResetButtonDisabled>
                )}
              </ResetButtonWrapper>
              {/* reset button */}
            </PaswordResetFieldsForm>
          </form>
          {/* modal form */}
        </PaswordResetContainer>
        {/* modal container */}
      </PaswordResetModal>

      {/* snackbars */}

      {OpenSuccessSnackBar && (
        <PasswordResetSuccess
          open={OpenSuccessSnackBar}
          setOpenSuccess={handleCloseSuccess}
        />
      )}
      {OpenErrorSnackBar && (
        <PasswordResetError
          open={OpenErrorSnackBar}
          setOpenError={handleCloseError}
        />
      )}
      {/* snackbars */}
    </>
  )
}

// import * as React from 'react';
// import Box from '@mui/material/Box';
// import IconButton from '@mui/material/IconButton';
// import OutlinedInput from '@mui/material/OutlinedInput';
// import InputLabel from '@mui/material/InputLabel';
// import InputAdornment from '@mui/material/InputAdornment';
// import FormControl from '@mui/material/FormControl';
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';

// interface State {
//   amount: string;
//   password: string;
//   weight: string;
//   weightRange: string;
//   showPassword: boolean;
// }

// export default function InputAdornments() {
//   const [values, setValues] = React.useState<State>({
//     amount: '',
//     password: '',
//     weight: '',
//     weightRange: '',
//     showPassword: false,
//   });

//   const handleChange =
//     (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
//       setValues({ ...values, [prop]: event.target.value });
//     };

//   const handleClickShowPassword = () => {
//     setValues({
//       ...values,
//       showPassword: !values.showPassword,
//     });
//   };

//   const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
//     event.preventDefault();
//   };

//   return (
//     <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
//       <div>

//         <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
//           <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
//           <OutlinedInput
//             id="outlined-adornment-password"
//             type={values.showPassword ? 'text' : 'password'}
//             value={values.password}
//             onChange={handleChange('password')}
//             endAdornment={
//               <InputAdornment position="end">
//                 <IconButton
//                   aria-label="toggle password visibility"
//                   onClick={handleClickShowPassword}
//                   onMouseDown={handleMouseDownPassword}
//                   edge="end"
//                 >
//                   {values.showPassword ? <VisibilityOff /> : <Visibility />}
//                 </IconButton>
//               </InputAdornment>
//             }
//             label="Password"
//           />
//         </FormControl>

//       </div>

//     </Box>
//   );
// }
