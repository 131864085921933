import {
  Autocomplete,
  Divider,
  IconButton,
  Pagination,
  TextField,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { Grid } from '@mui/material'
import { DataBox, GridContainer } from './indexStyled'
import AssessmentIcon from '@mui/icons-material/Assessment'
import { StatusCompleted, StatusLive } from './indexStyled'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { editTableData } from 'src/slices/apiKeys'
import {
  ConferenceAction,
  fetchCompleteData,
  fetchConferenceTableData,
} from 'src/slices/Conference/ConferenceTable'
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined'
import * as XLSX from 'xlsx'
import Loader from 'src/components/Loader/Loader'

// const tableData = [
//   {
//     appId: 'b401b148-e31b-4e31-b3b6-01e488ff0ea5',
//     roomName: 'Apple',
//     roomId: '1234567890',
//     participantName: 'Ranjan Nayak',
//     causeCode: '200',
//     reason: 'string',
//     cost: '43.76',
//     costCode: 'string',
//     sessionId: '739336f',
//     startTime: new Date('2022-07-22T16:34:01+05:30'),
//     endTime: new Date('2022-07-22T17:13:34+05:30'),
//     minutes: 127,
//     status: 'Live',
//   },
//   {
//     appId: 'b401b148-e31b-4e31-b3b6-01e488ff0ea5',
//     roomName: 'PineApple',
//     roomId: '1234567890',
//     participantName: 'Pavan Kumar',
//     causeCode: '400',
//     reason: 'string',
//     cost: '43.76',
//     costCode: 'string',
//     sessionId: '739336fa',
//     startTime: new Date('2022-07-22T16:34:01+05:30'),
//     endTime: new Date('2022-07-23T16:34:01+05:30'),
//     minutes: 127,
//     status: 'Live',
//   },
//   {
//     appId: 'b401b148-e31b-4e31-b3b6-01e488ff0ea5',
//     roomName: 'Banana',
//     roomId: '1234567890',
//     participantName: 'Athul',
//     causeCode: '100',
//     reason: 'string',
//     cost: '73.76',
//     costCode: 'string',
//     sessionId: '739336f',
//     startTime: new Date('2022-07-22T16:34:01+05:30'),
//     endTime: new Date('2022-07-22T11:34:01+05:30'),
//     minutes: 127,
//     status: 'COMPLETED',
//   },
//   {
//     appId: 'b401b148-e31b-4e31-b3b6-01e488ff0ea5',
//     roomName: 'Orange',
//     roomId: '1234567890',
//     participantName: 'Prathik Pai',
//     causeCode: '600',
//     reason: 'string',
//     cost: '43.76',
//     costCode: 'string',
//     sessionId: '739336fa',
//     startTime: new Date('2022-07-22T16:34:01+05:30'),
//     endTime: new Date('2022-07-22T12:34:01+05:30'),
//     minutes: 127,
//     status: 'Live',
//   },
//   {
//     appId: '5b401b148-e31b-4e31-b3b6-01e488ff0ea5',
//     roomName: 'Mango',
//     roomId: '1234567890',
//     participantName: 'Rahul B ',
//     causeCode: '800',
//     reason: 'string',
//     cost: '83.76',
//     costCode: 'string',
//     sessionId: '739336fa',
//     startTime: new Date('2022-07-22T16:34:01+05:30'),
//     endTime: new Date('2022-07-22T16:34:03+05:30'),
//     minutes: 127,
//     status: 'COMPLETED',
//   },
// ]

const staticIds = [
  {
    appId: 'b7398a1e-b5ef-4dcb-9b15-3be3f39a0970',
    name: 'ABC',
  },
]

function Index() {
  const dispatch = useDispatch()
  const appids = useSelector((state: any) => state.apiKeys.tableData)
  const selectedAppId = useSelector((state: any) => state.conference.appIdData)
  console.log('Selected App Id', selectedAppId)
  const metaDetails = useSelector((state: any) => state.conference.meta)
  const page = useSelector((state: any) => state.conference.page)
  const cdrData = useSelector((state: any) => state.conference.conferenceData)
  const loading = useSelector((state: any) => state.conference.loading)
  const message = useSelector((state: any) => state.conference.message)
  const FinalExcel = useSelector(
    (state: any) => state.conference.exportToExcel.excelData,
  )

  useEffect(() => {
    dispatch(
      fetchCompleteData(
        metaDetails.total_count ? metaDetails.total_count : 10000,
        selectedAppId.appId,
      ),
    )
  }, [metaDetails.total_count, dispatch, selectedAppId.appId])

  const idList = [...staticIds, ...appids]
  console.log('cdrData', metaDetails.page_count)
  useEffect(() => {
    dispatch(editTableData())
  }, [dispatch])

  const ExportData = FinalExcel.map((data) => {
    return {
      APP_ID: data.appId,
      ROOM_NAME: data.roomName,
      ROOM_ID: data.roomId,
      PARTICIPANT_NAME: data.participantName,
      CAUSE_CODE: data.causeCode,
      COST: data.cost,
      SESSION_ID: data.sessionId,
      START_TIME: data.startTime,
      END_TIME: data.endTime,
      MINUTES: data.minutes,
      STATUS: data.status,
    }
  })

  const exportToExcelHandler = () => {
    const wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(ExportData)
    XLSX.utils.book_append_sheet(wb, ws, 'MySheet1')
    XLSX.writeFile(wb, 'MyExcel.xlsx')
  }

  return (
    <>
      <GridContainer container>
        <Grid
          item
          xs={12}
          style={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
          }}
        >
          <Grid
            container
            spacing={3}
            style={{
              marginBottom: '15px',
              height: 'auto',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: 'auto',
            }}
          >
            <Grid
              item
              xs={11.2}
              style={{
                display: 'flex',
                justifyContent: 'space-betweeen',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Grid container>
                <Grid item>
                  <Typography variant="h4">Conference</Typography>
                </Grid>
              </Grid>
              <Grid item>
                {metaDetails.total_count >= 1 ? (
                  <IconButton color="primary" onClick={exportToExcelHandler}>
                    <SimCardDownloadOutlinedIcon />
                  </IconButton>
                ) : (
                  <IconButton disabled>
                    <SimCardDownloadOutlinedIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
            <Grid item xs={11}>
              <Autocomplete
                value={selectedAppId}
                onChange={(e: any, newvalue) => {
                  if (newvalue.appIds === null) {
                    dispatch(
                      ConferenceAction.setMessage(`Please select app id`),
                    )
                  }
                  dispatch(ConferenceAction.setAppId(newvalue))
                  dispatch(fetchConferenceTableData(1, newvalue.appId))
                  dispatch(ConferenceAction.setPageNumber(1))
                }}
                id="combo-box-demo"
                size="small"
                options={idList}
                getOptionLabel={(option: any) => option.appId}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="App Id"
                    fullWidth
                    placeholder="Select an app id here.."
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </Grid>

        {loading && (
          <Box
            style={{
              width: '100%',
              height: '510px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Loader />
          </Box>
        )}
        {!loading && metaDetails.total_count < 1 && (
          <Box
            style={{
              width: '100%',
              height: '510px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography>{message}</Typography>
          </Box>
        )}
        {!loading && metaDetails.total_count >= 1 && (
          <Box
            style={{
              width: '100%',
              overflow: 'auto',
              height: '510px',
            }}
          >
            {cdrData.map((row: any) => {
              return (
                <Grid
                  key={Math.random()}
                  item
                  xs={12}
                  style={{ marginTop: '1rem' }}
                >
                  <DataBox>
                    <Grid container>
                      <Grid item xs={1.5}>
                        <Box
                          style={{
                            margin: 'auto',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            // paddingTop: '1.5rem',
                          }}
                        >
                          <AssessmentIcon />
                        </Box>
                      </Grid>
                      <Grid item xs={6.4}>
                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'flexS-sart',
                            flexDirection: 'column',
                            gap: '5px',
                            fontSize: '10px',
                          }}
                        >
                          <Box
                            style={{
                              display: 'flex',
                              alignItems: 'flexS-sart',
                              flexDirection: 'column',
                            }}
                          >
                            <Typography style={{ fontWeight: 'bold' }}>
                              {row.roomName}
                            </Typography>

                            <Typography>{row.appId.slice(0, 10)}...</Typography>
                          </Box>
                          <Box
                            style={{
                              display: 'flex',
                              alignItems: 'flexS-sart',
                              flexDirection: 'column',
                            }}
                          >
                            <Typography style={{ fontWeight: 'bold' }}>
                              {row.sessionId}
                            </Typography>
                            <Typography style={{ fontWeight: 'bold' }}>
                              {row.participantName}
                            </Typography>
                          </Box>
                          <Box
                            style={{
                              display: 'flex',
                              alignItems: 'flexS-sart',
                              flexDirection: 'column',
                              textTransform: 'uppercase',
                            }}
                          >
                            <Typography>
                              {moment(new Date(row.startTime)).format(
                                'DD MMM YYYY',
                              )}
                              &nbsp;||&nbsp;
                              {moment(new Date(row.startTime)).format(
                                'hh:mm a',
                              )}
                            </Typography>
                            <Typography>
                              {moment(new Date(row.endTime)).format(
                                'DD MMM YYYY',
                              )}
                              &nbsp;||&nbsp;
                              {moment(new Date(row.endTime)).format('hh:mm a')}
                            </Typography>
                            <Typography>
                              {/* {Math.trunc(
                                ((new Date(row.endTime) as any) -
                                  (new Date(row.endTime) as any)) /
                                  (1000 * 60),
                              )} */}
                              {row.minutes} MINS
                            </Typography>
                          </Box>
                          <Box
                            style={{
                              display: 'flex',
                              alignItems: 'flexS-sart',
                              flexDirection: 'column',
                            }}
                          >
                            <Typography style={{ fontWeight: 'bold' }}>
                              {row.causeCode}
                            </Typography>
                            <Typography style={{ textTransform: 'uppercase' }}>
                              IT IS {row.status}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={4.1}>
                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            flexDirection: 'column',
                            height: '100%',
                          }}
                        >
                          <Typography style={{ fontWeight: 'bold' }}>
                            ${row.cost}
                          </Typography>
                          {row.status === 'live' ? (
                            <StatusLive label={row.status} />
                          ) : (
                            <StatusCompleted label={row.status} />
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </DataBox>
                  <Divider />
                </Grid>
              )
            })}
          </Box>
        )}
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '2rem ',
          }}
        >
          <Pagination
            count={metaDetails.page_count}
            shape="rounded"
            color="primary"
            page={page}
            onChange={(event, value) => {
              dispatch(ConferenceAction.setPageNumber(value))
              dispatch(fetchConferenceTableData(value, selectedAppId.appId))
            }}
          />
        </Grid>
      </GridContainer>
    </>
  )
}
export default Index
