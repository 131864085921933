import React, { useState } from "react";
import {
  Box,
  Typography,
  // Hidden,
  Grid,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";
import Particle from "./Particle";
// import GridImage from "../../assets/Svg/GridImage";
import { Check } from "@mui/icons-material";
import CopyToClipboard from "react-copy-to-clipboard";

const Hero = () => {
  const [copyState, setCopySTate] = useState("copy");
  return (
    <div>
      <Particle />

      <Grid
        container
        sx={{
          height: { xl: '500px', lg: "500px", md: "500px", sm: "400px", xs: "500px" },
          padding: "10px",
          display: "flex",
          justifyContent: {
            xl: 'center', lg: "space-evenly", md: "space-evenly", sm: "space-evenly", xs: "space-evenly"
          },
          alignItems: "center",
          // marginLeft: {
          //     xl: "350px",
          // },
          transform: "translate(0, -650px)",
          // border:'1px solid green'
        }}
      >
        {/* Left Panel */}
        <Grid
          item
          sm={5.2}
          md={4}
          lg={4}
          xl={4}
          sx={{
            marginTop: { lg: "100px", md: "100px", sm: "100px", xs: "60px" },
            marginLeft: { lg: "70px", md: "25px" },
          }}
        >
          <Typography
            sx={{
              fontSize: {
                lg: "35px",
                md: "30px",
                sm: "20px",
                xs: "20px",
              },
              textAlign: {
                lg: "left",
                md: "left",
                sm: "center",
                xs: "center",
              },
            }}
            fontFamily={"Poppins"}
            fontWeight={500}
            color={"#FFFFFF"}
          >
            Develop web applications
          </Typography>
          <Typography
            fontFamily={"Poppins"}
            color={"#D4D0D0"}
            mt={"1rem"}
            fontWeight={400}
            sx={{
              fontSize: {
                lg: "25px",
                md: "25px",
                sm: "15px",
                xs: "14px",
              },
              textAlign: {
                lg: "left",
                md: "left",
                sm: "center",
                xs: "center",
              },
            }}
          >
            with one command
          </Typography>
          <Typography
            sx={{
              fontSize: {
                lg: "16px",
                md: "16px",
                sm: "12px",
                xs: "12px",
              },
              textAlign: {
                lg: "left",
                md: "left",
                sm: "center",
                xs: "center",
              },
            }}
            fontFamily={"Poppins"}
            fontWeight={300}
            lineHeight={"24px"}
            mt={"1.3rem"}
            color={"#FFFFFF"}
          >
            <span
              style={{
                color: "#FFFFFF",
                fontWeight: "400",
                fontSize: "14px",
              }}
            >
              Ingressify
            </span>{" "}
            is an API-centric ingress platform that simplifies the process of
            adding connectivity, security, and observability features to your
            applications with a single command.
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              marginTop: "15px",
              justifyContent: {
                lg: "left",
                md: "left",
                sm: "center",
                xs: "center",
              },
            }}
          >
            {" "}
            <Button
              sx={{
                color: "#000000",
                background:
                  "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: "14px",
                borderRadius: "50px",
                transition: "all 0.3s ease",
                "&:hover": {
                  color: "#000000",
                  background: "#FFFFFF",
                },
              }}
            >
              START NOW
            </Button>
            <Button
              sx={{
                color: "#000000",
                background:
                  "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: "14px",
                borderRadius: "50px",
                transition: "all 0.3s ease",
                "&:hover": {
                  color: "#000000",
                  background: "#FFFFFF",
                },
              }}
            >
              CONTACT US
            </Button>
          </Box>
          ;
        </Grid>
        {/* Left Panel */}

        <Grid item md={7} lg={7} xs={12} sm={6} xl={4} >

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                border: "1px solid #FFFFFF",
                width: {
                  lg: "600px",
                  md: "450px",
                  sm: "350px",
                },
                height: {
                  lg: "190px",
                  md: "190px",
                  sm: "150px",
                  xs: "100px",
                },
                marginTop: {
                  lg: "110px",
                  md: "110px",
                  sm: "110px",
                },
                //   transform: {
                //     lg: "translate(150px, -450px)",
                //     md: "translate(70px, -300px)",
                //     sm: "translate(40px, -220px)",
                //     xs: "translate(0px, -230px)",
                //   },
                marginLeft: {
                  xl: "150px",
                  lg: "150px",
                  md: "80px",
                  // sm: "100px",
                  xs: "auto",
                },

                marginRight: {
                  xs: "auto",
                },
                backgroundColor: " rgba(255, 255, 255, 0.2)",
                borderRadius: "5px",
                padding: "5px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  padding: "5px",
                }}
              >
                {/* <Button
                                    sx={{
                                        position: "relative",
                                        color: "#60E1CB",
                                        border: "1px solid #60E1CB",
                                        backgroundColor: "rgba(96, 225, 20 3, 0.2)",
                                        fontSize: {
                                            lg: "16px",
                                            md: "16px",
                                            sm: "12px",
                                            xs: "12px",
                                        },
                                    }}
                                >
                                    Copy
                                </Button> */}

                {copyState === "copying" && (
                  <Button
                    sx={{
                      position: "relative",
                      color: "#60E1CB",
                      border: "1px solid #60E1CB",
                      backgroundColor: "rgba(96, 225, 20 3, 0.2)",
                      fontSize: {
                        lg: "16px",
                        md: "16px",
                        sm: "12px",
                        xs: "12px",
                      },
                      width: "80px",
                      height: "40px",
                    }}
                  >
                    <IconButton id="copying-msg" size="small" disableRipple>
                      <CircularProgress size={20} sx={{ color: "white" }} />
                    </IconButton>
                  </Button>
                )}
                {copyState === "copied" && (
                  <Button
                    sx={{
                      position: "relative",
                      color: "#60E1CB",
                      border: "1px solid #60E1CB",
                      backgroundColor: "rgba(96, 225, 20 3, 0.2)",
                      fontSize: {
                        lg: "16px",
                        md: "16px",
                        sm: "12px",
                        xs: "12px",
                      },
                      width: "80px",
                      height: "40px",
                    }}
                  >
                    <IconButton id="copied-msg" size="small" disableRipple>
                      <Check
                        sx={{
                          height: "20px",
                          width: "20px",
                          color: "#11BD60",
                        }}
                      />
                    </IconButton>
                  </Button>
                )}
                {copyState === "copy" && (
                  <CopyToClipboard
                    text={" ingressify proxy http 80"}
                    onCopy={() => {
                      setCopySTate("copying");
                      setTimeout(() => {
                        setCopySTate("copied");
                      }, 1000);
                      setTimeout(() => {
                        setCopySTate("copy");
                      }, 2000);
                    }}
                  // onCopy={() => setCopied(true)}
                  >
                    <Button
                      sx={{
                        position: "relative",
                        color: "#60E1CB",
                        border: "1px solid #60E1CB",
                        backgroundColor: "rgba(96, 225, 20 3, 0.2)",
                        fontSize: {
                          lg: "16px",
                          md: "16px",
                          sm: "12px",
                          xs: "12px",
                        },
                        width: "80px",
                        height: "40px",
                      }}
                    >
                      Copy
                    </Button>
                  </CopyToClipboard>
                )}
              </Box>

              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontSize: {
                    lg: "16px",
                    md: "16px",
                    sm: "12px",
                    xs: "12px",
                  },
                  marginTop: {
                    lg: "20px",
                    md: "20px",
                    sm: "20px",
                    xs: "10px",
                  },
                  paddingLeft: {
                    lg: "10px",
                    md: "10px",
                    sm: "10px",
                    xs: "0px",
                  },
                  textAlign: {
                    lg: "left",
                    md: "left",
                    sm: "center",
                    xs: "center",
                  },
                }}
              >
                <span style={{ color: "#A4A4A4" }}>[user@localhost]$</span>{" "}
                ingressify proxy http 80
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Hero;
