import React, { useEffect, useState } from 'react'
import {
  Autocomplete,
  Button,
  Divider,
  Grid,
  IconButton,
  Pagination,
  TextField,
  Typography,
} from '@mui/material'
import {
  ErrorMessage,
  GridContainer,
  GridContainerBox,
  StatusCompleted,
  StatusLive,
  TableBox,
  TableCells,
  TableContainerStack,
  TablePaginationBox,
  TableRowBox,
} from './indexStyled'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import WebhookOutlinedIcon from '@mui/icons-material/WebhookOutlined'
import { ExpandLess, KeyboardArrowDown } from '@mui/icons-material'
import { Box } from '@mui/system'
import moment from 'moment'
import './table.css'

import WebHookHitsDrawer from './WebHookHitsDrawer/WebHookHitsDrawer'
import { useDispatch, useSelector } from 'react-redux'
import { editTableData } from 'src/slices/apiKeys'
import { WebhookTableActions } from 'src/slices/WebhookHits/WebHookHits'

const hooknamesList = [
  {
    id: '00',
    name: 'ROOM_CREATED',
  },
  {
    id: '02',
    name: 'PARTICIPANT_JOINED',
  },
  {
    id: '03',
    name: 'SETTINGS_PROVISIONING',
  },
  {
    id: '04',
    name: 'PARTICIPANT_LEFT',
  },
  {
    id: '05',
    name: 'ROOM_DESTROYED',
  },
]

const tableData = [
  {
    appId: '123456',
    hookName: 'Room_Created',
    dateTime: new Date('2022-07-25T16:34:01+05:30'),
    status: true,
  },
  {
    appId: '342345',
    hookName: 'Participant_Joined',
    dateTime: new Date('2022-07-22T16:34:01+05:30'),
    status: false,
  },
  {
    appId: '876789',
    hookName: 'Settings_Provisioning',
    dateTime: new Date('2022-07-21T16:34:01+05:30'),
    status: true,
  },
  {
    appId: '445678',
    hookName: 'Participant_Left',
    dateTime: new Date('2022-07-20T16:34:01+05:30'),
    status: false,
  },
  {
    appId: '4432342',
    hookName: 'Room_Destroyed',
    dateTime: new Date('2022-07-22T16:34:01+05:30'),
    status: true,
  },
]

const WebhookHits = () => {
  const [sortAppIdIcon, setSortAppIdIcon] = useState<boolean>(false)
  const [sortHookNameIcon, setSortHookNameIcon] = useState<boolean>(false)
  const [sortDateTimeIcon, setSortDateTimeIcon] = useState<boolean>(false)
  const [sortStatusIcon, setSortStatusIcon] = useState<boolean>(false)
  const [validAppIdSelected, setValidAppIdSelected] = useState<boolean>(true)
  const [validHookNameSelected, setValidHookNameSelected] = useState<boolean>(
    true,
  )

  const [openDrawerBox, setOpenDrawerBox] = useState(false)
  const openDrawer = () => {
    setOpenDrawerBox(true)
  }
  const closeDrawerHandler = () => {
    setOpenDrawerBox(false)
  }

  const dispatch = useDispatch()
  const appids = useSelector((state: any) => state.apiKeys.tableData)
  console.log('appids', appids)
  const selectedAppId = useSelector((state: any) => state.webHookHits.appIdData)
  console.log('Selected App Id', selectedAppId)
  const selectedHookName = useSelector(
    (state: any) => state.webHookHits.hookName,
  )
  console.log('Selected Hook Name', selectedHookName)

  useEffect(() => {
    dispatch(editTableData())
  }, [dispatch])

  const handleClick = () => {
    console.log('selectedAppId', selectedAppId)
    if (selectedAppId.appId === '') {
      setValidAppIdSelected(false)
    } else {
      setValidAppIdSelected(true)
    }
    if (selectedHookName.name === '') {
      setValidHookNameSelected(false)
    } else {
      setValidHookNameSelected(true)
    }
  }

  return (
    <>
      {/* table container */}
      <TableContainerStack>
        {/* title and filteres */}
        <GridContainer container>
          {/* Title */}
          <Grid item xs={2}>
            <Typography variant="h3">Webhook Hits</Typography>
          </Grid>
          {/* Title */}
          <Grid item xs={10}>
            <GridContainerBox container spacing={2}>
              {/* select app id field */}
              <Grid
                item
                xs={3}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Autocomplete
                  value={selectedAppId}
                  onChange={(e: any, newvalue: string) =>
                    dispatch(WebhookTableActions.setAppId(newvalue))
                  }
                  id="combo-box-demo"
                  size="small"
                  options={appids}
                  getOptionLabel={(option: any) => option.appId}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="App Id"
                      fullWidth
                      placeholder="Select an app id here.."
                    />
                  )}
                />
                {!validAppIdSelected && (
                  <ErrorMessage>Please select app id </ErrorMessage>
                )}
              </Grid>
              {/* select app id field */}
              {/* select hook nameapp id field */}
              <Grid
                item
                xs={3}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Autocomplete
                  value={selectedHookName}
                  onChange={(e: any, newvalue: string) =>
                    dispatch(WebhookTableActions.setHookName(newvalue))
                  }
                  id="combo-box-demo"
                  size="small"
                  options={hooknamesList}
                  getOptionLabel={(option: any) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Hook Name"
                      placeholder="Select hook name here.."
                    />
                  )}
                />
                {!validHookNameSelected && (
                  <ErrorMessage>Please select hook name </ErrorMessage>
                )}
              </Grid>
              {/* select app id field */}
              <Grid
                item
                xs={1}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button variant="outlined" onClick={handleClick}>
                    Search
                  </Button>
                </Box>
              </Grid>
            </GridContainerBox>
          </Grid>
        </GridContainer>
        {/* title and filteres */}
        <Divider />
        <TableBox>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCells>
                    <Box className="wenhook_table_appId webhook_header_wrapper">
                      Hook ID
                      {sortAppIdIcon ? (
                        <ExpandLess
                          className="wenhook_table_appId_icon"
                          onClick={() => {
                            //   dispatch(
                            //     getTableDataSortedBy({
                            //       page: page,
                            //       sortBy: 'name',
                            //       sort: 'desc',
                            //     }),
                            //   )
                            setSortAppIdIcon(false)
                          }}
                        />
                      ) : (
                        <KeyboardArrowDown
                          className="wenhook_table_appId_icon"
                          onClick={() => {
                            //   dispatch(
                            //     getTableDataSortedBy({
                            //       page: page,
                            //       sortBy: 'name',
                            //       sort: 'asc',
                            //     }),
                            //   )
                            setSortAppIdIcon(true)
                          }}
                        />
                      )}
                    </Box>
                  </TableCells>
                  <TableCells>
                    <Box className="wenhook_table_hookName webhook_header_wrapper">
                      Hook Name
                      {sortHookNameIcon ? (
                        <ExpandLess
                          className="wenhook_table_hookName_icon"
                          onClick={() => {
                            //   dispatch(
                            //     getTableDataSortedBy({
                            //       page: page,
                            //       sortBy: 'name',
                            //       sort: 'desc',
                            //     }),
                            //   )
                            setSortHookNameIcon(false)
                          }}
                        />
                      ) : (
                        <KeyboardArrowDown
                          className="wenhook_table_hookName_icon"
                          onClick={() => {
                            //   dispatch(
                            //     getTableDataSortedBy({
                            //       page: page,
                            //       sortBy: 'name',
                            //       sort: 'asc',
                            //     }),
                            //   )
                            setSortHookNameIcon(true)
                          }}
                        />
                      )}
                    </Box>
                  </TableCells>
                  <TableCells>
                    <Box className="wenhook_table_datetime webhook_header_wrapper">
                      Date & Time
                      {sortDateTimeIcon ? (
                        <ExpandLess
                          className="wenhook_table_datetime_icon"
                          onClick={() => {
                            //   dispatch(
                            //     getTableDataSortedBy({
                            //       page: page,
                            //       sortBy: 'name',
                            //       sort: 'desc',
                            //     }),
                            //   )
                            setSortDateTimeIcon(false)
                          }}
                        />
                      ) : (
                        <KeyboardArrowDown
                          className="wenhook_table_datetime_icon"
                          onClick={() => {
                            //   dispatch(
                            //     getTableDataSortedBy({
                            //       page: page,
                            //       sortBy: 'name',
                            //       sort: 'asc',
                            //     }),
                            //   )
                            setSortDateTimeIcon(true)
                          }}
                        />
                      )}
                    </Box>
                  </TableCells>
                  <TableCells>
                    <Box className="wenhook_table_status webhook_header_wrapper_status">
                      STATUS
                      {sortStatusIcon ? (
                        <ExpandLess
                          className="wenhook_table_status_icon"
                          onClick={() => {
                            //   dispatch(
                            //     getTableDataSortedBy({
                            //       page: page,
                            //       sortBy: 'name',
                            //       sort: 'desc',
                            //     }),
                            //   )
                            setSortStatusIcon(false)
                          }}
                        />
                      ) : (
                        <KeyboardArrowDown
                          className="wenhook_table_status_icon"
                          onClick={() => {
                            //   dispatch(
                            //     getTableDataSortedBy({
                            //       page: page,
                            //       sortBy: 'name',
                            //       sort: 'asc',
                            //     }),
                            //   )
                            setSortStatusIcon(true)
                          }}
                        />
                      )}
                    </Box>
                  </TableCells>
                  <TableCells align="center">Action</TableCells>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row, index) => {
                  return (
                    <TableRowBox key={row.appId}>
                      <TableCells>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: '0.5rem',
                          }}
                        >
                          <WebhookOutlinedIcon sx={{ fontSize: '25px' }} />
                          <Typography>{row.appId}</Typography>
                        </Box>
                      </TableCells>

                      <TableCells style={{ textTransform: 'uppercase' }}>
                        {row.hookName}
                      </TableCells>
                      <TableCell style={{ textTransform: 'uppercase' }}>
                        {moment(row.dateTime).format('DD MMM YYYY')} ||{' '}
                        {moment(row.dateTime).format('hh:mm a')}
                      </TableCell>

                      <TableCell align="center">
                        {row.status === true ? (
                          <StatusLive label="Active" />
                        ) : (
                          <StatusCompleted label="Inactive" />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton onClick={openDrawer}>
                          <RemoveRedEyeOutlinedIcon color="primary" />
                        </IconButton>
                      </TableCell>
                    </TableRowBox>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </TableBox>
        <TablePaginationBox>
          <Pagination
            count={3}
            shape="rounded"
            color="primary"
            // page={page}
            // onChange={(event, value) => setPage(value)}
            // onChange={handlePage}
          />
        </TablePaginationBox>
      </TableContainerStack>
      {/* table container */}

      {/* webhook hits drawer */}
      <WebHookHitsDrawer
        openDrawer={openDrawerBox}
        closeDrawer={closeDrawerHandler}
      />
      {/* webhook hits drawer */}
    </>
  )
}
export default WebhookHits
