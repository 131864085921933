import { styled } from '@mui/material/styles'
import { Chip, Grid } from '@mui/material'
import { Box } from '@mui/system'

// table add filter button
export const GridContainer = styled(Grid)`
  box-shadow: 0px 9px 16px rgba(159, 162, 191, 0.18),
    0px 2px 2px rgba(159, 162, 191, 0.32);
  border-radius: 6px;
  padding-bottom: 15px;
  height: auto;
  margin: 0;
`
export const DataBox = styled(Box)`
  //   box-shadow: 0px 9px 16px rgba(159, 162, 191, 0.18),
  //     0px 2px 2px rgba(159, 162, 191, 0.32);
  //   border-radius: 6px;
  padding: 15px 0px;
  height: auto;
  width: 100%;
`
export const StatusCompleted = styled(Chip)`
  color: #3bb900;
  background: #ecfbe6;
  font-family: 'Roboto';
  font-style: normal;
  // font-weight: 700;
  font-size: 12px;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: bold;
`
export const StatusLive = styled(Chip)`
  color: #f4002c;
  background: #fbebe6;
  font-family: 'Roboto';
  font-style: normal;
  // font-weight: 700;
  font-size: 12px;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: bold;
`
