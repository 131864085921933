import { Typography, experimentalStyled } from "@mui/material";

export const KeyFeatureHeader = experimentalStyled(Typography)(
  () => `
    
      font-weight: 500;
      background-image: linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-top: 15px;
    `
);
