import React from 'react'

const Request = () => {
  return (
    <div><svg width="79" height="74" viewBox="0 0 79 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 21.5H63V32H0V21.5ZM0 0.5H63V11H0V0.5ZM0 42.5H42V53H0V42.5ZM52.5 74L78.75 58.25L52.5 42.5V74Z" fill="#35BBC5"/>
    </svg>
    </div>
  )
}

export default Request