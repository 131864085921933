import React from 'react'

const IngressifyLogo = ({props}) => {
    return (
        <div>
<svg width={props.width} height={props.height} viewBox="0 0 61 68" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M28.173 0.368302C26.3531 1.03325 24.3233 3.34306 24.0083 5.02292C23.6583 7.26274 23.7633 8.06767 24.6732 9.92252L25.5832 11.7074L24.8482 12.9673C23.9383 14.6121 23.2034 15.872 21.0685 19.5467L19.2837 22.6265L20.5436 23.4664C21.2085 23.9564 21.9435 24.2363 22.1885 24.1664C22.3984 24.0614 23.5183 22.3465 24.6732 20.2817C25.8282 18.2518 27.0531 16.117 27.368 15.5221C27.718 14.9271 28.173 14.2622 28.4179 14.0172C28.8029 13.6322 30.6928 13.3872 32.0926 13.4922C32.3026 13.5272 33.6325 15.5221 35.0324 17.9719C36.4323 20.4567 37.8672 22.8715 38.2171 23.3964L38.8821 24.3763L40.282 23.6764C41.8218 22.8715 41.9268 22.5915 41.0169 21.2966C39.722 19.4417 35.6973 12.1273 35.6973 11.6724C35.6973 11.4274 36.0123 10.6225 36.3973 9.85253C37.3072 8.06767 37.3422 5.02292 36.4673 3.41305C34.9974 0.683278 31.1827 -0.716609 28.173 0.368302ZM32.7226 3.86802C34.8924 5.51288 34.4724 8.83761 31.9876 9.88752C30.3428 10.5875 28.9429 10.2725 27.823 8.9076C26.5281 7.40273 26.6331 5.51288 28.033 4.113C29.3979 2.74811 31.1127 2.64312 32.7226 3.86802Z" fill="#24A9BF"/>
<path d="M4.05967 13.8772C1.32989 15.2421 0 17.3069 0 20.1067C0 25.6712 5.21457 28.856 10.3941 26.4062L12.004 25.6362L13.9639 26.7211C15.0488 27.2811 17.8835 28.926 20.2983 30.3258C22.7131 31.7257 25.1629 33.1256 25.7579 33.4756C28.5227 35.0855 28.3127 34.5955 28.4177 39.4251L28.5227 43.7997H30.4475H32.3723L32.4773 39.3901L32.5823 34.9805L33.8772 34.2805C34.5772 33.8606 35.7321 33.2306 36.432 32.8456C37.132 32.4607 38.7768 31.4808 40.0717 30.7108C41.3666 29.9059 42.4865 29.2759 42.5565 29.2759C42.6265 29.2759 43.5014 28.786 44.5163 28.191C49.241 25.3913 49.101 25.4262 50.8158 26.4762C52.2157 27.3161 55.3305 27.4211 57.0803 26.6861C59.5301 25.6362 61 23.2214 61 20.2817C61 17.2369 59.7751 15.3121 56.9753 13.8422C52.4957 11.5324 46.8962 15.4871 46.8962 20.9466C46.8962 21.9265 46.7212 22.2415 45.9512 22.6265C45.3913 22.9065 44.5863 23.3964 44.0964 23.7114C43.6064 24.0264 42.3465 24.7263 41.2966 25.3213C40.2467 25.9162 38.4968 26.8961 37.4469 27.5261C36.397 28.156 34.9621 28.996 34.2972 29.3459C30.2375 31.6207 30.4475 31.5507 29.6776 31.0608C29.2926 30.8158 28.4527 30.3258 27.8577 29.9759C27.2628 29.6609 25.9329 28.891 24.918 28.261C23.903 27.6661 22.9931 27.1761 22.9231 27.1761C22.8531 27.1761 21.5582 26.4412 20.0534 25.4962C18.5485 24.5863 16.5886 23.4664 15.6787 22.9764C14.0338 22.1365 13.9989 22.0665 13.9989 20.4917C13.9989 17.5169 12.529 15.1021 9.97418 13.9122C8.04934 13.0023 5.84452 13.0023 4.05967 13.8772ZM9.27424 17.8669C11.3041 19.8967 9.69421 23.6764 6.78944 23.6764C5.66954 23.6764 3.49971 21.6116 3.49971 20.5616C3.49971 19.5467 4.37464 17.8669 5.14458 17.3769C6.01951 16.852 8.5743 17.1669 9.27424 17.8669ZM56.3804 18.0419C58.0952 19.7567 57.5353 22.3815 55.2955 23.3264C54.0706 23.8514 52.9157 23.6064 51.7608 22.5915C50.5009 21.5066 50.5709 19.0218 51.9007 17.8669C53.3006 16.677 55.0505 16.747 56.3804 18.0419Z" fill="white"/>
<path d="M14.5589 30.7809C14.244 31.4108 12.8441 33.8606 11.4092 36.2754L8.8544 40.65L6.78957 40.86C4.23478 41.105 3.21986 41.525 2.09995 42.7849C0.665072 44.4297 0.350098 45.2697 0.350098 47.4045C0.350098 51.7441 3.21986 54.3689 7.6295 54.0539C10.0443 53.879 11.8992 52.6541 12.9841 50.5542L13.649 49.2243H30.4476H47.2463L48.0862 50.5192C49.801 53.214 51.3759 54.1239 54.3507 54.1239C58.7253 54.1239 61.7001 50.0643 60.5452 45.6896C60.0902 44.0098 58.5153 42.1549 56.9055 41.35C56.3455 41.07 55.1556 40.825 54.2457 40.825C52.7758 40.825 52.4608 40.685 51.7259 39.6701C51.2359 39.0752 50.466 37.8503 50.011 36.9753C49.5211 36.1004 48.4012 34.1056 47.4562 32.4957L45.7764 29.6259L44.4115 30.3959C43.6765 30.8159 43.0466 31.3058 43.0466 31.4808C43.0466 31.6908 44.0265 33.5106 45.1814 35.5405C48.6811 41.595 48.9961 42.1549 48.9961 42.5749C48.9961 42.7849 48.6111 43.5548 48.1212 44.3247L47.2463 45.7246H30.4826H13.719L12.8441 44.1498C11.9691 42.6449 11.9691 42.5749 12.5641 41.455C13.999 38.6902 15.8888 35.3655 16.2738 34.8755C16.8337 34.1756 17.8487 32.0757 17.8487 31.5858C17.8487 31.2008 16.7638 30.3609 15.6788 29.9059C15.2589 29.7309 14.9439 29.9759 14.5589 30.7809ZM8.18946 44.1148C9.69433 44.7447 10.4993 45.8996 10.4993 47.4745C10.4993 51.5342 5.38968 52.4441 3.60483 48.6994C3.04488 47.5445 3.04488 47.4045 3.60483 46.2496C4.12979 45.1647 5.94964 43.6248 6.75457 43.6248C6.89456 43.6248 7.55951 43.8348 8.18946 44.1148ZM56.0305 44.3597C56.8705 44.7797 57.7454 46.4946 57.7454 47.6495C57.7454 49.3993 55.9605 51.1142 54.1757 51.0792C52.7408 51.0442 52.4958 50.9392 51.6559 50.0993C48.6461 47.0895 52.2508 42.3299 56.0305 44.3597Z" fill="#24A9BF"/>
<path d="M28.5578 51.8841C28.4528 52.1991 28.3478 52.8641 28.3478 53.424C28.3478 54.124 28.1378 54.5089 27.6479 54.6489C26.563 54.9989 24.8831 56.5388 24.2532 57.7637C23.8682 58.4286 23.6582 59.6885 23.6582 61.1234C23.6582 63.8182 24.7081 65.778 26.8429 67.1079C28.7328 68.2978 32.5125 68.2628 34.3323 67.0729C36.5371 65.638 37.2721 64.1331 37.2721 61.1234C37.2721 58.0086 36.5022 56.5738 34.0524 54.9989C32.9674 54.3339 32.5475 53.809 32.5475 53.249C32.5475 51.6392 32.2325 51.3242 30.4476 51.3242C29.2577 51.3242 28.6978 51.4642 28.5578 51.8841ZM32.9324 58.8836C33.9824 60.0035 33.9824 62.2433 32.8974 63.3982C31.7075 64.6581 29.5027 64.6231 28.2078 63.3632C26.9479 62.1033 26.9479 60.1785 28.2078 58.9186C29.6427 57.4837 31.6026 57.4487 32.9324 58.8836Z" fill="white"/>
</svg>


        </div>
    )
}

export default IngressifyLogo