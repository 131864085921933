import  { useEffect, useState } from "react";
import { Box, Button, Grid,  IconButton, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import LaptopMacOutlinedIcon from "@mui/icons-material/LaptopMacOutlined";
import CardContent from "@mui/material/CardContent";
import { styled } from "@mui/system";

function Setupandinstall() {
    const [os, setOs] = useState('');

    useEffect(() => {
      const userAgent = window.navigator.userAgent;
      if (userAgent.indexOf('Win') !== -1) {
        setOs('Windows');
      } else if (userAgent.indexOf('Mac') !== -1) {
        setOs('MacOS');
      } else if (userAgent.indexOf('X11') !== -1) {
        setOs('UNIX');
      } else if (userAgent.indexOf('Linux') !== -1) {
        setOs('Linux');
      } else {
        setOs('Unknown');
      }
    }, []);
  
  const CardContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    ${(props) => props.theme.breakpoints.down("lg")} {
      gap: 10px;
    }
    ${(props) => props.theme.breakpoints.down("md")} {
      flex-direction: column;
      height: auto;
      margin: 1rem 0 4rem 0;
      gap: 30px;
    }
    ${(props) => props.theme.breakpoints.down("sm")} {
      height: auto;
      margin: 3rem 0 2rem 0;
    }
  `;

  const Cards = styled(Box)`
    // width: 320px;
    // height: 324px;
    background: #ffffff;
    border: 1px solid #f1f5fd;
    box-shadow: 0px 0px 80px rgba(91, 157, 255, 0.15);
    border-radius: 4px;
    padding: 30px 14px;
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: column;
  `;
  const CardHead = styled(Typography)`
    color: #212121;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    // border:1px solid blue;
    min-height: 30px;
    max-height: auto;
    width: 90%;
  `;

  return (
    <>
    <Grid
        container
        sx={{
          backgroundColor: "#f1f4fd",
          display: "flex",
          justifyContent: "space-around",
          mt: "50px",
        }}
      >
        <Grid item lg={5} xs={12} sm={12} sx={{display:'flex',justifyContent:'center'}}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            //   alignItems: {lg:"left",xs:'center',md:'left'},
              gap: "20px",
              marginLeft: "25px",
              width:{lg:'70%',xs:'100%'},
              padding:{lg:'25px'}
            }}
          >
            <Typography variant="h1" sx={{fontSize:{xs:'25px'}}}>Download ingressify</Typography>
            <Typography sx={{ width: {xs:"100%"} }}>
              ingressify is easy to install. Download a single binary with zero
              run-time dependencies.
            </Typography>
            <Button
              sx={{ background: "#3087ec", color: "white", width: {xs:'100%'} }}
            >
              <Typography>Download for {os}</Typography>
            </Button>
          </Box>
        </Grid>
        <Grid item lg={2} xs={12} sm={12}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Divider
              orientation="vertical"
              sx={{ width: "1px", height: "70%",color:'blue' }}
            />
          </Box>
        </Grid>
        <Grid item lg={5} xs={12} sx={{ display: "flex", alignItems: "center",justifyContent:{xs:"center"},mt:''}}>
          <Box
            sx={{
              display: "flex",
              justifyContent: {sm:"space-between",xs:'center'},
              alignItems:{sm: "center",xs:'center'},
              gap: "25px",
              flexDirection:{sm:'row',xs:'column'},
              mt:{xs:'30px'}
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton>
                <LaptopMacOutlinedIcon />
              </IconButton>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ color: "blue" }}>Macos</Typography>
                <Typography sx={{ color: "blue" }}>Macos(ARM64)</Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton>
                <LaptopMacOutlinedIcon />
              </IconButton>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ color: "blue" }}>Windows</Typography>
                <Typography sx={{ color: "blue" }}>Windows(32-Bit)</Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton>
                <LaptopMacOutlinedIcon />
              </IconButton>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ color: "blue" }}>Linux</Typography>
                <Typography sx={{ color: "blue" }}>Linux(32-Bit)</Typography>
                <Typography sx={{ color: "blue" }}>Linux(ARM)</Typography>
                <Typography sx={{ color: "blue" }}>Linux(ARM64)</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container sx={{display:'flex',justifyContent:'space-between',gap:'10px',mt:'55px'}}>
        <Grid item lg={12} xs={12}>
          <CardContainer sx={{width:'100%'}} >
            <Cards sx={{width:'70%', height: {lg:"220px",sm:'400px'}}}>
              <CardHead >1.Unzip to install</CardHead>
              <CardContent
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  width:'100%',
                  wordWrap:'break-word'
                }}
              >
                On Linux or Mac OS X you can unzip ngrok from a terminal with
                the following command. On Windows, just double click
                ingressify.zip to extract it.
              </CardContent>
              <CardContent
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#f0f2f5",
                }}
              >
                $ unzip /path/to/ingressify.zip
              </CardContent>
            </Cards>
          </CardContainer>
        </Grid>
        <Grid item lg={12} xs={12}>
          <CardContainer>
            <Cards  sx={{width:'70%', height: {lg:"220px",sm:'400px'}}}>
              <CardHead >2. Connect your account</CardHead>
              <CardContent
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  width:'100%',
                  wordWrap:'break-word'

                }}
              >
                Running this command will add your authtoken to the default
                ingressify.yml configuration file. This will grant you access to more
                features and longer session times. Running tunnels will be
                listed on the endpoints page of the dashboard.
              </CardContent>
              <CardContent
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#f0f2f5",
                  width:'100%',
                  wordWrap:'break-word'

                }}
              >
                $ ingressify config add-authtoken
                2Ng7S3EGoPm8U5uRSI54C3m6TD0_4YiPto5BEF8sniSa7RM5G
              </CardContent>
            </Cards>
          </CardContainer>
        </Grid>
        <Grid item lg={12}>
          <CardContainer>
            <Cards  sx={{width:'70%', height: {lg:"300px",sm:'400px'}}}>
              <CardHead >3. Fire it up</CardHead>
              <CardContent
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Read the documentation on how to use ingressify. Try it out by
                running it from the command line:
              </CardContent>
              <CardContent
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#f0f2f5",
                }}
              >
                ingressify help
              </CardContent>
              <CardContent
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                To start a HTTP tunnel forwarding to your local port 80, run
                this next:
              </CardContent>
              <CardContent
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#f0f2f5",
                }}
              >
                $ ingressify http 80
              </CardContent>
            </Cards>
          </CardContainer>
        </Grid>
      </Grid>
    </>
  );
}

export default Setupandinstall;
