import React from 'react'

const Tick = ({props}) => {
    return (
        <div><svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 6L5.5 9.5L12.5 2" stroke={props.color} stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        </div>
    )
}

export default Tick