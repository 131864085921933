import React from 'react'

const B2b = () => {
    return (
        <div><svg width="126" height="126" viewBox="0 0 126 126" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_226_1165)">
                <path d="M39.375 31.5H31.5V47.25H39.375V31.5Z" fill="#35BBC5" />
                <path d="M39.375 55.125H31.5V70.875H39.375V55.125Z" fill="#35BBC5" />
                <path d="M63 31.5H55.125V47.25H63V31.5Z" fill="#35BBC5" />
                <path d="M63 55.125H55.125V70.875H63V55.125Z" fill="#35BBC5" />
                <path d="M39.375 78.75H31.5V94.5H39.375V78.75Z" fill="#35BBC5" />
                <path d="M63 78.75H55.125V94.5H63V78.75Z" fill="#35BBC5" />
                <path d="M118.125 55.125C118.125 53.0364 117.295 51.0334 115.818 49.5565C114.342 48.0797 112.339 47.25 110.25 47.25H86.625V15.75C86.625 13.6614 85.7953 11.6584 84.3185 10.1815C82.8416 8.70468 80.8386 7.875 78.75 7.875H15.75C13.6614 7.875 11.6584 8.70468 10.1815 10.1815C8.70468 11.6584 7.875 13.6614 7.875 15.75V118.125H118.125V55.125ZM15.75 15.75H78.75V110.25H15.75V15.75ZM86.625 110.25V55.125H110.25V110.25H86.625Z" fill="#35BBC5" />
            </g>
            <defs>
                <clipPath id="clip0_226_1165">
                    <rect width="126" height="126" fill="white" />
                </clipPath>
            </defs>
        </svg>
        </div>
    )
}

export default B2b