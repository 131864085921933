import React from 'react'
import {
  Snackbar,
  Alert,
  AlertProps,
  Typography,
  IconButton,
} from '@mui/material'
import { forwardRef } from 'react'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import CloseIcon from '@mui/icons-material/Close'

const SnackbarAlertSuccess = forwardRef<HTMLDivElement, AlertProps>(
  function SnackbarAlert(props, ref) {
    return (
      <Alert
        elevation={6}
        ref={ref}
        {...props}
        sx={{ background: '#C5F2C7' }}
        icon={
          <DoneOutlinedIcon
            sx={{
              color: '#1F8B24',
              // fontWeight: 700,
              // fontSize: '25px',
              marginLeft: '10px',
            }}
          />
        }
      />
    )
  },
)
const SnackbarAlertError = forwardRef<HTMLDivElement, AlertProps>(
  function SnackbarAlert(props, ref) {
    return (
      <Alert
        elevation={6}
        ref={ref}
        {...props}
        sx={{ background: '#FFE8EC' }}
        icon={
          <ReportProblemOutlinedIcon
            sx={{
              color: '#FF1A43',
              // fontWeight: 700,
              // fontSize: '25px',
              marginLeft: '10px',
            }}
          />
        }
      />
    )
  },
)

export const PasswordResetSuccess = ({ open, setOpenSuccess }: any) => {
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={1500}
        onClose={setOpenSuccess}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={setOpenSuccess}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      >
        <SnackbarAlertSuccess severity="success">
          <Typography
            style={{
              color: '#1F8B24',
              fontFamily: 'Roboto',
              fontWeight: 700,
              // fontSize: '20px',
              // lineHeight: '23px',
              marginRight: '2rem',
            }}
          >
            Password Reset Successful
          </Typography>
        </SnackbarAlertSuccess>
      </Snackbar>
    </>
  )
}

export const PasswordResetError = ({ open, setOpenError }: any) => {
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={1500}
        onClose={setOpenError}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={setOpenError}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      >
        <SnackbarAlertError severity="success">
          <Typography
            style={{
              color: '#FF1A43',
              // fontFamily: 'Roboto',
              fontWeight: 700,
              // fontSize: '20px',
              // lineHeight: '23px',
              marginRight: '2rem',
            }}
          >
            Reset Failed! Try Again
          </Typography>
        </SnackbarAlertError>
      </Snackbar>
    </>
  )
}
