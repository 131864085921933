import React from 'react'

const Online = () => {
    return (
        <div><svg width="106" height="76" viewBox="0 0 106 76" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M71.375 35.3748C71.375 45.8748 58.25 53.7498 58.25 61.6248H47.75C47.75 53.7498 34.625 45.8748 34.625 35.3748C34.625 25.2423 42.8675 16.9998 53 16.9998C63.1325 16.9998 71.375 25.2423 71.375 35.3748ZM58.25 66.8748H47.75V74.7498H58.25V66.8748ZM105.5 37.9998C105.5 23.5098 99.62 10.3848 90.1175 0.882324L84.5525 6.44732C92.6375 14.5323 97.625 25.7148 97.625 37.9998C97.625 50.2848 92.6375 61.4673 84.5525 69.5523L90.1175 75.1173C99.62 65.6148 105.5 52.4898 105.5 37.9998ZM8.375 37.9998C8.375 25.7148 13.3625 14.5323 21.4475 6.44732L15.8825 0.882324C6.38 10.3848 0.5 23.5098 0.5 37.9998C0.5 52.4898 6.38 65.6148 15.8825 75.1173L21.4475 69.5523C13.3625 61.4673 8.375 50.2848 8.375 37.9998ZM81.875 37.9998C81.875 45.9798 78.62 53.1723 73.4225 58.4223L78.9875 63.9873C85.655 57.3198 89.75 48.1323 89.75 37.9998C89.75 27.8673 85.655 18.6798 78.9875 12.0123L73.4225 17.5773C78.62 22.8273 81.875 30.0198 81.875 37.9998ZM27.0125 63.9873L32.5775 58.4223C27.3275 53.1723 24.125 45.9798 24.125 37.9998C24.125 30.0198 27.38 22.8273 32.5775 17.5773L27.0125 12.0123C20.345 18.6798 16.25 27.8673 16.25 37.9998C16.25 48.1323 20.345 57.3198 27.0125 63.9873Z" fill="#35BBC5" />
        </svg>
        </div>
    )
}

export default Online