import { FC, ReactNode, createContext, useEffect, useReducer } from "react";
import { User } from "src/models/user";
import { Auth0Client } from '@auth0/auth0-spa-js';
import SuspenseLoader from "src/components/SuspenseLoader";
// import UserService from "../services/UserService";
import { auth0Config } from 'src/config';

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
 
}
// const auth0Config={
//   client_id:'dev-einstonlabs.us.auth0.com',
//   domian:'xBYYQC57FgqMefukKxooJYtivwllaVHY'
// }
export interface AuthContextValue extends AuthState {
  method: "Auth0";
  loginWithPopup: (options?: any) => Promise<void>;
  logout: () => void;
  getToken: () => any;
}

interface AuthProviderProps {
  children: ReactNode;
  // getToken: () => ""
}

type InitialiseAction = {
  type: "INITIALISE";
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: "LOGIN";
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: "LOGOUT";
};

type RegisterAction = {
  type: "REGISTER";
};
const getToken =  () => {
  if(auth0Client!==null){
    return auth0Client.getTokenSilently({
      audience: 'ingressify-dev',
      // scope: 'read:all'
    });
  }
}


type Action = InitialiseAction | LoginAction | LogoutAction | RegisterAction;
let auth0Client: Auth0Client | null = null;
const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case "INITIALISE": {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case "LOGIN": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: "Auth0",
  loginWithPopup: () => Promise.resolve(),
  logout: () => {},
  getToken: () => ""
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const loginWithPopup = async (options) => {
    try {
      await auth0Client.loginWithPopup(options);

      const isAuthenticated = await auth0Client.isAuthenticated();
  
      if (isAuthenticated) {
        const user = await auth0Client.getUser();
        console.log("----user", user);
        dispatch({
          type: "LOGIN",
          payload: {
            user: {
              id: user.sub,
              jobtitle: "Lead Developer",
              avatar: user.picture,
              email: user.email,
              name: user.name,
              role: user.role,
              location: user.location,
              username: user.preferred_username,
              posts: user.posts,
              coverImg: user.coverImg,
              followers: user.followers,
              description: user.description,
            },
          },
        });
      }
    } catch(error) {
      console.log('Auth 0 Error', error);
    }
  };

  const logout = async () => {
    await auth0Client.logout({
      returnTo: window.location.origin
    });
    dispatch({
      type: "LOGOUT",
    });
  };
  // const {getAccessTokenSilently} = auth0Client.getAccessTokenSilently();
 
  useEffect(() => {
    const initialise = async () => {
      try {
        auth0Client = new Auth0Client({
          thloginWithPopup_uri: window.location.origin,
              ...auth0Config
            });
            await auth0Client.checkSession();
        const isAuthenticated = await auth0Client.isAuthenticated();
         console.log("auth0",auth0Config)
        if (isAuthenticated) {
          // console.log("this is the token",getAccessTokenSilently)
          // localStorage.setItem("token", UserService.getToken());
          const user = await auth0Client.getUser();
          console.log(user);
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated,
              user: {
                id: user.sub,
                jobtitle: "Lead Developer",
                avatar: user.picture,
                email: user.email,
                // name: user,
                role: user.role,
                location: user.location,
                // username: user,
                posts: user.posts,
                coverImg: user.coverImg,
                followers: user.followers,
                description: user.description,
              },
            },
          });
        } else {
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALISE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SuspenseLoader />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "Auth0",
        loginWithPopup,
        getToken,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
export {
  getToken
};

