import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
// import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
import TextField from "@mui/material/TextField";
// import DateTimePicker from '@mui/lab/DateTimePicker';
// import { format } from 'date-fns';
// import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { IconButton } from "@mui/material";
import { ButtonPrimary } from "src/components/CustomButtons";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import Table from "./table";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
const Tcpaddresses = () => {
  return (
    <>
      <>
        <Box sx={{ mt: 2, mb: 2, ml: 2, mr: 2 }}>
          <Grid
            container
            className="apikey_header justify_space_between align_center"
          >
            <Grid item xs={12} sm={9} md={8}>
              <Box display="flex" flexDirection="column">
                <Typography
                  className="align_start apikey_heading"
                  sx={{ color: "#3086EC" }}
                >
                 Tunnel Agent Authtokens
                </Typography>
                <Typography
                  color="#808080"
                  className="align_start apikey_subheading"
                  sx={{ mt: "25px", width: "100%" }}
                >
                  Authtokens connect ngrok agents to your ngrok account. You
                  should provision a unique authtoken for every installed ngrok
                  agent. This allows for fine-grained permissions management via
                  ACLs and easy revocation if a machine is compromised or
                  deactivated.
                </Typography>
                <Typography                   color="#808080"
                  className="align_start apikey_subheading"
                  sx={{ mt: "25px", width: "100%" }}
>
                To install an authtoken, add authtoken: TOKEN to an ingressify.yml configuration file.
                </Typography>
              </Box>
            </Grid>

            {/* <Grid
              item
              xs={12}
              sm={3}
              md={4}
              className="align_center justify_end"
            >
              <ButtonPrimary id="create-api-keys" responsive={false}>
                <AddIcon fontSize="small" />
                invite
              </ButtonPrimary>
            </Grid> */}
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: "35px",
            }}
          >
            <Grid item lg={7}>
              <Box sx={{}}>
                <TextField
                  fullWidth
                  size="small"
                  id="outlined-basic"
                  label=""
                  name="name"
                  placeholder="Filter Authtokens. . ."
                  variant="outlined"
                  InputLabelProps={{ shrink: false }}
                  sx={{
                    width: "100%",
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-endAdornment": {
                      order: 1,
                      margin: 0,
                      "& > button": {
                        marginLeft: "8px",
                      },
                    },
                    border: "none",
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Box>
            </Grid>

            <Grid item lg={5}>
              <Box
                sx={{ display: "flex", justifyContent: "flex-end", gap: "5px",height:{xs:'55px'},mt:{xs:'25px'} }}
              >
                <ButtonPrimary id="create-api-keys" responsive={false}>
                  <InsertDriveFileOutlinedIcon />
                  API Docs
                </ButtonPrimary>
                <ButtonPrimary id="create-api-keys" responsive={false}>
                  <AddIcon/>Add Tunnel Authtoken
                </ButtonPrimary>
              </Box>
            </Grid>
          </Grid>
          <Table />
        </Box>
      </>
    </>
  );
};
export default Tcpaddresses;
