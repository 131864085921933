import React from 'react'

const Scalability = () => {
  return (
    <div>
     <svg width="100%" height="100%" viewBox="0 0 393 393" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_223_204)">
<path d="M215.67 49.125C213.861 54.2468 212.875 59.7583 212.875 65.5C212.875 78.0545 217.584 89.5092 225.333 98.1935L196.443 127.083C187.759 119.334 176.305 114.625 163.75 114.625C136.619 114.625 114.625 136.619 114.625 163.75V225.156C114.625 254.548 138.452 278.375 167.844 278.375H229.25C256.382 278.375 278.375 256.382 278.375 229.25C278.375 216.695 273.666 205.241 265.917 196.557L294.807 167.667C303.491 175.416 314.945 180.125 327.5 180.125C333.241 180.125 338.753 179.139 343.875 177.33V311.125C343.875 329.213 329.213 343.875 311.125 343.875H81.875C63.7877 343.875 49.125 329.213 49.125 311.125V81.875C49.125 63.7877 63.7877 49.125 81.875 49.125H215.67ZM323.406 49.125C334.712 49.125 343.875 58.2891 343.875 69.5938V131C343.875 140.044 336.544 147.375 327.5 147.375C318.456 147.375 311.125 140.044 311.125 131V105.033L203.283 212.875H229.25C238.294 212.875 245.625 220.206 245.625 229.25C245.625 238.294 238.294 245.625 229.25 245.625H167.844C156.539 245.625 147.375 236.462 147.375 225.156V163.75C147.375 154.706 154.706 147.375 163.75 147.375C172.794 147.375 180.125 154.706 180.125 163.75V189.717L287.967 81.875H262C252.956 81.875 245.625 74.5436 245.625 65.5C245.625 56.4564 252.956 49.125 262 49.125H323.406Z" fill="#35BBC5"/>
</g>
<defs>
<clipPath id="clip0_223_204">
<rect width="393" height="393" fill="white"/>
</clipPath>
</defs>
</svg>

    </div>
  )
}

export default Scalability