import React from 'react'

const Zerotrust = () => {
  return (
    <div><svg width="126" height="126" viewBox="0 0 126 126" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_226_1161)">
    <path d="M63 5.25L15.75 26.25V57.75C15.75 86.8875 35.91 114.135 63 120.75C90.09 114.135 110.25 86.8875 110.25 57.75V26.25L63 5.25ZM63 109.882V63H26.25V33.075L63 16.7475V62.9475H99.75C96.9675 84.5775 82.53 103.845 63 109.882Z" fill="#35BBC5"/>
    </g>
    <defs>
    <clipPath id="clip0_226_1161">
    <rect width="126" height="126" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    </div>
  )
}

export default Zerotrust