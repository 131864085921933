import { Box, Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import Tick from "src/assets/Svg/Tick";

const basicData = [
  "1 user request",
  "1 design account",
  "Email support",
  "10 deployment",
];

const premiumData = [
  "10 user request",
  "3 design account",
  "Email support",
  "100 deployment",
  "24/7 Support",
  "Premium Support",
];

const enterpiseData = [
  "Unlimited user request",
  "3 to 50 design account",
  "Email support",
  "Unlimited deployment",
  "24/7 Support",
  "Advanced security ",
  "Invoice and billing",
];

const Pricing = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };
  const handleHover2 = () => {
    setIsHovered2(true);
  };
  const handleHover3 = () => {
    setIsHovered3(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };
  const handleMouseLeave3 = () => {
    setIsHovered3(false);
  };

  const color = isHovered ? "white" : "black";
  const color2 = isHovered2 ? "white" : "black";
  const color3 = isHovered3 ? "white" : "black";

  return (
    <div>
      {" "}
      <Box sx={{ marginTop: "50px" }}>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: {
              lg: "40px",
              md: "30px",
              sm: "30px",
              xs: "20px",
            },
            fontWeight: "500",
            backgroundImage:
              "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Pricing
        </Typography>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            marginTop: "30px",
          }}
        >
          <Grid
            item
            onMouseEnter={handleHover}
            onMouseLeave={handleMouseLeave}
            sx={{
              width: {
                lg: "350px",
                md: "300px",
                sm: "250px",
                xs: "250px",
              },
              height: {
                lg: "550px",
                md: "500px",
                sm: "400px",
                xs: "400px",
              },
              background: "white",
              borderRadius: "10px",
              "&:hover": {
                color: color,
                background:
                  "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
                transform: {
                  lg: "scale(1.12)",
                  md: "scale(1.13)",
                  sm: "scale(1.1)",
                  xs: "scale(1.1)",
                },
              },
              transition: "transform 0.5s",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                sx={{
                  fontSize: {
                    lg: "28px",
                    md: "25px",
                    sm: "18px",
                    xs: "18px",
                  },
                  marginTop: "20px",
                }}
              >
                Free
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    lg: "28px",
                    md: "25px",
                    sm: "18px",
                    xs: "18px",
                  },
                }}
              >
                $0<span style={{ color: "#999999", fontSize: "20px" }}>/</span>
                <span style={{ color: "#999999", fontSize: "15px" }}>mo</span>
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: {
                  lg: "50px",
                  md: "40px",
                  sm: "30px",
                  xs: "20px",
                },
                marginLeft: {
                  lg: "70px",
                  md: "70px",
                  sm: "50px",
                  xs: "50px",
                },
              }}
            >
              {basicData.map((value, index) => {
                return (
                  <Box
                    sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                    key={index}
                  >
                    <Box>
                      {" "}
                      <Tick props={{ color: color }} />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: {
                          lg: "20px",
                          md: "16px",
                          sm: "14px",
                          xs: "12px",
                        },
                        marginLeft: "10px",
                        marginTop: "5px",
                      }}
                    >
                      {" "}
                      {value}
                    </Typography>
                  </Box>
                );
              })}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: {
                  lg: "150px",
                  md: "160px",
                  sm: "110px",
                  xs: "120px",
                },
              }}
            >
              <Button
                sx={{
                  color: " #FFFFFF",
                  background:
                    "linear-gradient(180deg, #60E1CB 0%, #00C2FF 100%)",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "14px",
                  borderRadius: "5px",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    color: "#7C99FF",
                    background: "#FFFFFF",
                  },
                }}
              >
                Start with Free
              </Button>
            </Box>
          </Grid>

          <Grid
            item
            onMouseEnter={handleHover2}
            onMouseLeave={handleMouseLeave2}
            sx={{
              width: {
                lg: "350px",
                md: "300px",
                sm: "250px",
                xs: "250px",
              },
              height: {
                lg: "550px",
                md: "500px",
                sm: "400px",
                xs: "400px",
              },
              background: "white",
              borderRadius: "10px",
              "&:hover": {
                color: color2,
                background:
                  "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
                transform: {
                  lg: "scale(1.12)",
                  md: "scale(1.13)",
                  sm: "scale(1.1)",
                  xs: "scale(1.1)",
                },
              },
              transition: "transform 0.5s",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                sx={{
                  fontSize: {
                    lg: "28px",
                    md: "25px",
                    sm: "18px",
                    xs: "18px",
                  },
                  marginTop: "20px",
                }}
              >
                Premium
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    lg: "28px",
                    md: "25px",
                    sm: "18px",
                    xs: "18px",
                  },
                }}
              >
                $55<span style={{ color: "#999999", fontSize: "20px" }}>/</span>
                <span style={{ color: "#999999", fontSize: "15px" }}>mo</span>
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: {
                  lg: "50px",
                  md: "40px",
                  sm: "30px",
                  xs: "20px",
                },
                marginLeft: {
                  lg: "70px",
                  md: "70px",
                  sm: "50px",
                  xs: "50px",
                },
              }}
            >
              {premiumData.map((value, index) => {
                return (
                  <Box
                    sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                    key={index}
                  >
                    <Box>
                      {" "}
                      <Tick props={{ color: color2 }} />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: {
                          lg: "20px",
                          md: "16px",
                          sm: "14px",
                          xs: "12px",
                        },
                        marginLeft: "10px",
                        marginTop: "5px",
                      }}
                    >
                      {" "}
                      {value}
                    </Typography>
                  </Box>
                );
              })}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: {
                  lg: "90px",
                  md: "105px",
                  sm: "60px",
                  xs: "80px",
                },
              }}
            >
              <Button
                sx={{
                  color: " #FFFFFF",
                  background:
                    "linear-gradient(180deg, #60E1CB 0%, #00C2FF 100%)",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "14px",
                  borderRadius: "5px",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    color: "#7C99FF",
                    background: "#FFFFFF",
                  },
                }}
              >
                Choose Plan
              </Button>
            </Box>
          </Grid>

          <Grid
            item
            onMouseEnter={handleHover3}
            onMouseLeave={handleMouseLeave3}
            sx={{
              width: {
                lg: "350px",
                md: "300px",
                sm: "250px",
                xs: "250px",
              },
              height: {
                lg: "550px",
                md: "500px",
                sm: "400px",
                xs: "400px",
              },
              background: "white",
              borderRadius: "10px",
              "&:hover": {
                color: color3,
                background:
                  "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
                transform: {
                  lg: "scale(1.12)",
                  md: "scale(1.13)",
                  sm: "scale(1.1)",
                  xs: "scale(1.1)",
                },
              },
              transition: "transform 0.5s",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                sx={{
                  fontSize: {
                    lg: "28px",
                    md: "25px",
                    sm: "18px",
                    xs: "18px",
                  },
                  marginTop: "20px",
                }}
              >
                Enterprise
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    lg: "28px",
                    md: "25px",
                    sm: "18px",
                    xs: "18px",
                  },
                }}
              >
                $105
                <span style={{ color: "#999999", fontSize: "20px" }}>/</span>
                <span style={{ color: "#999999", fontSize: "15px" }}>mo</span>
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: {
                  lg: "50px",
                  md: "40px",
                  sm: "30px",
                  xs: "20px",
                },
                marginLeft: {
                  lg: "50px",
                  md: "50px",
                  sm: "30px",
                  xs: "30px",
                },
              }}
            >
              {enterpiseData.map((value, index) => {
                return (
                  <Box
                    sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                    key={index}
                  >
                    <Box>
                      {" "}
                      <Tick props={{ color: color3 }} />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: {
                          lg: "20px",
                          md: "16px",
                          sm: "14px",
                          xs: "12px",
                        },
                        marginLeft: "10px",
                        marginTop: "5px",
                      }}
                    >
                      {" "}
                      {value}
                    </Typography>
                  </Box>
                );
              })}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: {
                  lg: "50px",
                  md: "75px",
                  sm: "35px",
                  xs: "60px",
                },
              }}
            >
              <Button
                sx={{
                  color: " #FFFFFF",
                  background:
                    "linear-gradient(180deg, #60E1CB 0%, #00C2FF 100%)",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "14px",
                  borderRadius: "5px",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    color: "#7C99FF",
                    background: "#FFFFFF",
                  },
                }}
              >
                Choose Plan
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Pricing;
