import React from 'react'

const Phone = () => {
  return (
    <div>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.99979 5.00024C12.1028 5.00024 12.9998 5.89724 12.9998 8.00024H14.9998C14.9998 4.77524 13.2248 3.00024 9.99979 3.00024V5.00024ZM13.4218 10.4432C13.2296 10.2686 12.9771 10.1754 12.7176 10.1835C12.4581 10.1915 12.2118 10.3001 12.0308 10.4862L9.63779 12.9472C9.06179 12.8372 7.90379 12.4762 6.71179 11.2872C5.51979 10.0942 5.15879 8.93324 5.05179 8.36124L7.51079 5.96724C7.69721 5.78637 7.80593 5.54006 7.81396 5.28045C7.82198 5.02083 7.72868 4.76828 7.55379 4.57624L3.85879 0.513239C3.68384 0.320596 3.44067 0.203743 3.18095 0.187499C2.92122 0.171254 2.66539 0.256897 2.46779 0.426239L0.297794 2.28724C0.124905 2.46075 0.0217132 2.69169 0.00779368 2.93624C-0.00720632 3.18624 -0.293206 9.10824 4.29879 13.7022C8.30479 17.7072 13.3228 18.0002 14.7048 18.0002C14.9068 18.0002 15.0308 17.9942 15.0638 17.9922C15.3083 17.9786 15.5391 17.8749 15.7118 17.7012L17.5718 15.5302C17.7413 15.3328 17.8271 15.077 17.811 14.8173C17.795 14.5576 17.6783 14.3143 17.4858 14.1392L13.4218 10.4432Z" fill="white"/>
    </svg>
    </div>
  )
}

export default Phone