import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
// import DateTimePicker from '@mui/lab/DateTimePicker';
// import { format } from 'date-fns';
// import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Table from "./table";
import React from "react";
const Usib = () => {

  //   const data =
  //     "Use the --remote-addr=YOUR_ADDRESS when starting the ngrok agent to accept traffic from a TCP address you have created.";
  //     useEffect(()=>{
  // setExpand(data);
  //     },[]);
  return (
    <>
      <>
        <Box sx={{ mt: 2, mb: 2, ml: 2, mr: 2 }}>
          <Grid
            container
            className="apikey_header justify_space_between align_center"
          >
            <Grid item xs={12} sm={9} md={8}>
              <Box display="flex" flexDirection="column">
                <Typography
                  className="align_start apikey_heading"
                  sx={{ color: "#3086EC" }}
                >
                  USBIP
                </Typography>
              </Box>
            </Grid>

            {/* <Grid
              item
              xs={12}
              sm={3}
              md={4}
              className="align_center justify_end"
            >
              <ButtonPrimary id="create-api-keys" responsive={false}>
                <AddIcon fontSize="small" />
                invite
              </ButtonPrimary>
            </Grid> */}
          </Grid>
          {/* <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: "55px",
            }}
          >
            <Grid item lg={7}>
              <Box sx={{}}>
                <TextField
                  fullWidth
                  size="small"
                  id="outlined-basic"
                  label=""
                  name="name"
                  placeholder="Filter Addresses. . ."
                  variant="outlined"
                  InputLabelProps={{ shrink: false }}
                  sx={{
                    width: "100%",
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-endAdornment": {
                      order: 1,
                      margin: 0,
                      "& > button": {
                        marginLeft: "8px",
                      },
                    },
                    border: "none",
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Box>
            </Grid>

            <Grid item lg={5}>
              <Box
                sx={{ display: "flex", justifyContent: "flex-end", gap: "5px",height:{xs:'55px'},mt:{xs:'25px',lg:'0px'} }}
              >
                <ButtonPrimary id="create-api-keys" responsive={false}>
                  <InsertDriveFileOutlinedIcon />
                  API Docs
                </ButtonPrimary>

                <ButtonPrimary id="create-api-keys" responsive={false}>
                  <AddIcon fontSize="small" />
                  New TCP Address
                </ButtonPrimary>
              </Box>
            </Grid>
          </Grid> */}
          <Table />
        </Box>
      </>
    </>
  );
};
export default Usib;
