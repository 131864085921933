import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

interface notificationsPayload {
  notificationsData: [
    {
      appId: string
      roomName: string
      roomId: string
      participantName: string
      causeCode: string
      reason: string
      cost: string
      costCode: string
      sessionId: string
      startTime: Date
      endTime: Date
      minutes: number
      status: string
    },
  ]
  loading: boolean
}

const initialState: notificationsPayload = {
  notificationsData: [
    {
      appId: '',
      roomName: '',
      roomId: '',
      participantName: '',
      causeCode: '',
      reason: '',
      cost: '',
      costCode: '',
      sessionId: '',
      startTime: null,
      endTime: null,
      minutes: null,
      status: '',
    },
  ],
  loading: false,
}

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setConferenceTableData(state, action) {
      state.notificationsData = action.payload
    },
    setLoading(state) {
      state.loading = !state.loading
    },
  },
})

export const ConferenceAction = slice.actions
const BASE_URL = process.env.REACT_APP_API_URL;
// fetching conference data
export const getNotificationsData = (page: number) => async (dispatch) => {
  dispatch(slice.actions.setLoading())
  await axios
    .get(
      `${BASE_URL}/api/v1/notifications?page=${page}&per_page=6&meta=true`,
    )
    .then((res) => {
      console.log('notifications Table Data Response', res)
      dispatch(slice.actions.setLoading())
    })
    .catch((err) => {
      console.log(err)
    })
}

export const reducer = slice.reducer

export default slice
