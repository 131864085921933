import { createSlice } from '@reduxjs/toolkit'
// import { patchMethod } from 'src/services/requesthandler'
import axios from 'axios'

interface passwordReset {
  resetPassword: {
    oldPassword: string
    newPassword: string
    confirmNewPassword: string
  }
  resetPasswordStatus: {
    setSuccess: boolean
    setError: boolean
  }
  resetPasswordLoading: {
    setLoading: boolean
  }
}

const initialState: passwordReset = {
  resetPassword: {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  },
  resetPasswordStatus: {
    setSuccess: false,
    setError: false,
  },
  resetPasswordLoading: {
    setLoading: false,
  },
}

const slice = createSlice({
  name: 'passwordReset',
  initialState,
  reducers: {
    setSuccessHandler(state) {
      state.resetPasswordStatus.setSuccess = !state.resetPasswordStatus
        .setSuccess
    },
    setErrorHandler(state) {
      state.resetPasswordStatus.setError = !state.resetPasswordStatus.setError
    },
    setLoadingStateTrue(state) {
      state.resetPasswordLoading.setLoading = true
    },
    setLoadingStateFalse(state) {
      state.resetPasswordLoading.setLoading = false
    },
  },
})

export const PasswordResetActions = slice.actions

// password change handler
export const changePassword = (data) => async (dispatch) => {
  dispatch(slice.actions.setLoadingStateTrue())
  await axios.patch('/user/change-password', data)
  // .then((res) => {
  //   if (res.message === 'Password changed successfully') {
  //     dispatch(slice.actions.setSuccessHandler())
  //   } else {
  //     dispatch(slice.actions.setErrorHandler())
  //   }
  //   dispatch(slice.actions.setLoadingStateFalse())
  // })
  // .catch((err) => {
  //   console.log(err)
  // })
}

export const reducer = slice.reducer

export default slice
