import { Drawer, Grid, IconButton, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'

// xs, extra-small: 0px or larger
// sm, small: 600px or larger
// md, medium: 960px or larger
// lg, large: 1280px or larger
// xl, extra-large: 1920px or larger

export const WebhookDrawer = styled(Drawer)`
  background: none;
  flexshrink: 0;
  & .MuiDrawer-paper {
    width: 100%;
    boxsizing: border-box;
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    & .MuiDrawer-paper {
      width: 30%;
      boxsizing: border-box;
    }
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    & .MuiDrawer-paper {
      width: 30%;
      boxsizing: border-box;
    }
  }
`

export const WebHookHitsStack = styled(Stack)`
justify-content: space-between;
align-items:center;
    padding:43px 25px;
    ${(props) => props.theme.breakpoints.up('md')} {
      padding:43px;
    `

export const WebHookHitsTitle = styled(Typography)`
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;

  ${(props) => props.theme.breakpoints.up('sm')} {
    font-size: 24px;
    font-weight: bold;
    font-family: 'Roboto';
  }
`
export const WebHookDrawerCloseIcon = styled(IconButton)`
  // color: #1a75ff;
  width: 22px;
  position: absolute;
  top: 10px;
  right: 20px;
  ${(props) => props.theme.breakpoints.up('sm')} {
    // color: #1a75ff;
    width: 24px;
  }
`

export const GridContainer = styled(Grid)`
  width: 100%;
  padding: 25px 43px;
`
export const GridFieldBox = styled(Box)`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  border: 1px solid gray;
  height: 60px;
`
