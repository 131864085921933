import { BrowserRouter } from "react-router-dom";
import routes, { renderRoutes } from "./router";
import { SnackbarProvider } from "notistack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ScrollToTop from "./utils/ScrollToTop";
import { AuthProvider } from "./contexts/Auth0Context";
import ThemeProvider from "./theme/ThemeProvider";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import CustomSnackbar from "./components/Snackbar";

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SnackbarProvider
            maxSnack={6}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <CustomSnackbar />
            <BrowserRouter>
              <ScrollToTop />
              <CssBaseline />
              <AuthProvider>{renderRoutes(routes)}</AuthProvider>
            </BrowserRouter>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
export default App;
