import React,{useEffect}  from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper, Box, Pagination, Typography, Button } from '@mui/material';
import { RootState } from 'src/store';
import { useSelector,useDispatch } from 'react-redux';
import helper from 'src/services/helper';
import { editInvoice } from 'src/slices/billing';




export default function BillingTable() {
    const { invoice ,invoiceMeta } = useSelector((state: RootState) => state.Billing);
    const dispatch=useDispatch();

    const handleChangePagination = (event, value) => {
        console.log("value",value,);
        dispatch(editInvoice(value))
  
    };

    useEffect(()=>{
        dispatch(editInvoice(1))
    },[dispatch])


return (
    <Paper square style={{ backgroundColor: "#ffffff" }}>

        {invoice ? <TableContainer component={Box} sx={{ height: 400 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Invoice Number</TableCell>
                        <TableCell align="left">Status</TableCell>
                        <TableCell align="left">Amount</TableCell>
                        <TableCell align="left">Issue Date</TableCell>
                        <TableCell align="left">Due Date</TableCell>
                        <TableCell align="center">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {invoice && invoice.map  ((row) => (
                        <TableRow
                            key={row.invoiceId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row?.invoiceId}
                            </TableCell>
                            <TableCell align="center"> 
                            {row?.status==="PENDING"?
                            <Typography style={{ color: "#F4022C",width:"85px",borderRadius:"4px", backgroundColor: "#ffe8ec", }}>PENDING</Typography> :
                            <Typography style={{ color: "#3AB900",width:"85px",borderRadius:"4px", backgroundColor: "#ECFBE6", }}>PAID</Typography> 
                            }
                            </TableCell>
                            <TableCell align="left">{row?.amount?.unit}{row?.amount?.value}</TableCell>
                            <TableCell align="left">{helper.dateFormate(row?.issueDate)}</TableCell>
                            <TableCell align="left">{helper.dateFormate(row?.dueDate)}</TableCell>
                            <TableCell align="center"><Button color="primary" size="small">View</Button></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>:
        <Box display="flex" justifyContent="center" alignItems="center" sx={{height:440}}>
            <Typography variant="h2"> Data not found </Typography>
        </Box>
        
        }
            {invoice && <Box display="flex" justifyContent="center" alignContent="center" sx={{ p: 1 }}>
            <Pagination
                style={{ justifyContent: "center" }}
                count={invoiceMeta?.page_count}
                page={invoiceMeta?.page}
                onChange={handleChangePagination}
                color="primary"
         
            />
        </Box>}

    </Paper>

);
}


