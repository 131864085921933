import { Box, Card, Slider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

//Custom slider
export const PrettoSlider = styled(Slider)({
    color: '#3687EC',
    height: 5,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 14,
        width: 14,
        backgroundColor: '#fff',
        border: '2px solid currentcolor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-rail': {
        backgroundColor: 'grey',
    },
});

export const MainContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: '20px',
    // border: "1px solid red",
  }));

// export const Heading = styled(Typography)(() => ({
//     fontSize: '20px',
//     fontWeight: 'bold',
//     textAlign: 'center',
//     // background: 'linear-gradient(90deg, #EC4899 0%, #8B5CF6 100%)',
//     // backgroundClip: 'text',
//     // textFillColor: 'transparent',
// }));

export const FeedbackText = styled(Typography)(() => ({
    fontSize: '16px',
    fontWeight: '400',
    textAlign: 'justify',
    marginBottom:'25px',
}));

// export const SmallText = styled(Typography)(() => ({
//     fontSize: '14px',
//     fontWeight: 300,
//     marginBottom: '10px',
//     textAlign: 'right'
// }));

// export const CustomInfoCard = styled(Card)(() => ({
//     // backgroundColor: 'white',
//     // backgroundImage: 'linear-gradient(to right, red , yellow)',
//     background: 'linear-gradient(89.45deg, #9665E9 0.43%, #EDAD86 99.53%)',
//     color: 'black',
//     boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 3px rgba(0,0,0,0.24)',
//     padding: '10px 10px',
//     height: '100%',
//     borderRadius: '8px',
// }));

export const CustomBillingInfoCard = styled(Card)(() => ({
    backgroundColor: '#F6F6F6',
    // backgroundImage: 'linear-gradient(to right, red , yellow)',
    color: 'black',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 3px rgba(0,0,0,0.24)',
    padding: '10px 10px',
    height: '450px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
}));

export const CustomBox = styled(Box)(() => ({
    textAlign: 'center',
    marginBottom: '20px',
}));
export const HeadingTitle = styled(Typography)(() => ({
    fontSize: '20px',
    fontWeight: 500,
    textAlign: 'center',
}));

export const Heading = styled(Typography)(() => ({
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'center',
    // background: 'linear-gradient(90deg, #EC4899 0%, #8B5CF6 100%)',
    // backgroundClip: 'text',
    // textFillColor: 'transparent',
}));

export const SubHeading = styled(Typography)(() => ({
    fontSize: '14px',
    fontWeight: 400,
    textAlign: 'center',
    color:'#000000',
}));

export const SmallText = styled(Typography)(() => ({
    fontSize: '12px',
    fontWeight: 300,
    marginBottom: '10px',
    textAlign: 'left',
    color:'#666666',
}));

export const CustomInfoCard = styled(Card)(() => ({
    // backgroundColor: 'white',
    // backgroundImage: 'linear-gradient(to right, red , yellow)',
    // background: 'linear-gradient(89.45deg, #9665E9 0.43%, #EDAD86 99.53%)',
    color: 'black',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 3px rgba(0,0,0,0.24)',
    padding: '10px 10px',
    height: '100%',
    borderRadius: '8px',
}));

export const Title = styled(Typography)(() => ({
    fontSize: '12px',
    fontWeight: 400,
    marginBottom:'15px'
}));

export const AmountText = styled(Typography)(() => ({
    fontSize: '24px',
    fontWeight: 400,
}));
