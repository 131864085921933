import { Divider, Pagination, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Grid } from '@mui/material'
import { DataBox, GridContainer } from './indexStyled'
import NotificationsIcon from '@mui/icons-material/Notifications'
import moment from 'moment'

const tableData = [
  {
    appId: 'b401b148-e31b-4e31-b3b6-01e488ff05a5',
    title: 'ROOM_Created',
    description: 'A new participant has joined the room',
    createdDate: new Date('2022-07-25T16:34:01+05:30'),
  },
  {
    appId: 'b401b148-e31b-4e31-b3b6-01e487ff0ea5',
    title: 'PARTICIPANT_JOINED',
    description:
      'Your subscription for gold will expire soon. Upgrade your plan',
    createdDate: new Date('2022-07-25T16:34:01+05:30'),
  },
  {
    appId: 'b401b148-e31b-4e31-b3b6-019488ff0ea5',
    title: 'SETTINGS_PROVISIONING',
    description: 'A new participant has joined the room',
    createdDate: new Date('2022-07-25T16:34:01+05:30'),
  },
  {
    appId: 'b401b148-e31b-4e31-b3b6-01eh88ff0ea5',
    title: 'PARTICIPANT_LEFT',
    description:
      'Your subscription for gold will expire soon. Upgrade your plan',
    createdDate: new Date('2022-07-25T16:34:01+05:30'),
  },
  {
    appId: 'b401b148-e31b-4e31-b3b6-01e488ff0ea5',
    title: 'ROOM_DESTROYED',
    description: 'A new participant has joined the room',
    createdDate: new Date('2022-07-25T16:34:01+05:30'),
  },
]
function NotificationTableMobileVersion() {
  return (
    <>
      <GridContainer container>
        <Grid item xs={12}>
          <Box fontStyle={{ marginBottom: '10px' }}>
            <Typography variant="h4">Notifications</Typography>
          </Box>
          <Divider />
        </Grid>
        <Box
          style={{
            width: '100%',
            overflow: 'auto',
            height: '510px',
          }}
        >
          {tableData.map((row) => {
            return (
              <Grid key={row.appId} item xs={12} style={{ marginTop: '0rem' }}>
                <DataBox>
                  <Grid
                    container
                    // style={{ border: '1px solid red', height: '100%' }}
                  >
                    <Grid item xs={3}>
                      <Box
                        style={{
                          margin: 'auto',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                        }}
                      >
                        <NotificationsIcon />
                      </Box>
                    </Grid>
                    <Grid item xs={9}>
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'flexS-sart',
                          flexDirection: 'column',
                          gap: '5px',
                          fontSize: '10px',
                        }}
                      >
                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'flexS-sart',
                            flexDirection: 'column',
                          }}
                        >
                          <Typography>{row.appId.slice(0, 25)}...</Typography>
                        </Box>
                        <Box>
                          <Typography style={{ fontWeight: 'bold' }}>
                            {row.description.slice(0, 30)}...
                          </Typography>
                        </Box>
                        <Box>
                          <Typography>{row.title}</Typography>
                        </Box>

                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'flexS-sart',
                            flexDirection: 'column',
                            textTransform: 'uppercase',
                          }}
                        >
                          <Typography>
                            {moment(row.createdDate).format('DD MMM YYYY')} ||
                            &nbsp;
                            {moment(row.createdDate).format('hh:mm a')}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </DataBox>
                <Divider />
              </Grid>
            )
          })}
        </Box>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '2rem ',
          }}
        >
          <Pagination
            count={3}
            shape="rounded"
            color="primary"
            // page={page}
            // onChange={(event, value) => setPage(value)}
            // onChange={handlePage}
          />
        </Grid>
      </GridContainer>
    </>
  )
}
export default NotificationTableMobileVersion
