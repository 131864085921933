import React from 'react'

const Http = () => {
  return (
    <div><svg width="134" height="60" viewBox="0 0 134 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M104.218 59.794L133.917 30.0955L104.218 0.397031L104.218 24.824H29.9721L29.9721 0.397031L0.273625 30.0955L29.9721 59.794L29.9721 35.367H104.218L104.218 59.794Z" fill="#35BBC5"/>
    </svg>
    </div>
  )
}

export default Http