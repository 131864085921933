import {
  Button,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { SideBarDrawer, DrawerContainer } from "./indexStyled";
import { Box } from "@mui/system";
import useAuth from "src/hooks/useAuth";
import { NavLink, useNavigate } from "react-router-dom";
// import mqttService from "src/services/MqttService";
import { useEffect, useState } from "react";
import { ExpandLess, ExpandMore} from "@mui/icons-material";
import RocketOutlinedIcon from "@mui/icons-material/RocketOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import SyncAltOutlinedIcon from "@mui/icons-material/SyncAltOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import AccountBalanceTwoToneIcon from "@mui/icons-material/AccountBalanceTwoTone";
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import { useDispatch } from "react-redux";
// import { ProfileAction, resetPreferences } from "src/slices/profile";
import "./indexStyled";
const SidebarMobile = (props) => {
  const dispatch = useDispatch();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [openList, setOpenList] = useState(false);
  const [openStart, setOpenStart] = useState(false);
  const [openEdge, setOpenEdge] = useState(false);

  const handleLogout = async (): Promise<void> => {
    try {
      navigate("/", { replace: true });
      await logout();
      // mqttService.closeConnection();
      // dispatch(resetPreferences(false));
      localStorage.clear();
    } catch (err) {
      throw new Error(err);
    }
  };

  const handleOpenList = () => {
    setOpenList(!openList);
  };
  const handleOpenStarted = () => {
    setOpenStart(!openStart);
  };
  const handleOpenEdge = () => {
    setOpenEdge(!openEdge);
  };
  const handleClick = (data: string) => {
    sessionStorage.setItem("currentStep", data);
    props.close();
  };
  // const closeMyAccountDropDown = () => {
  //   setOpenList(false);
  //   props.close();
  // };

  useEffect(() => {
    // if (sessionStorage.getItem("currentStep")) {
    //   dispatch(
    //     ProfileAction.setTabState(sessionStorage.getItem("currentStep"))
    //   );
    // }
  }, [dispatch]);
  return (
    <SideBarDrawer
      BackdropProps={{ invisible: false }}
      anchor="right"
      open={props.open}
    >
      <DrawerContainer sx={{ mt: "25px" }}>
        <List
          className="sidebar_menu"
          sx={{
            p: 1,
            minWidth: 210,
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
          }}
          component="nav"
        >
          {/* <Button
            className="sideBarButton"
            onClick={closeMyAccountDropDown}
            to="/my-learnings"
            component={NavLink}
            // startIcon={<MyLearning />}
            style={{
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
            }}
            id="my-learnings"
          >
            Getting Started
          </Button> */}
          {/* <Button
            className="sideBarButton"
            onClick={closeMyAccountDropDown}
            to="/messaging"
            component={NavLink}
            startIcon={<Message props={{ width: '20px', height: '20px' }} />}
            style={{
              fontFamily: 'Poppins',
              fontWeight: '400',
              fontSize: '14px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'left',
            }}
          >
            My Messages
          </Button> */}
          <List className="sidebar_menu" sx={{ padding: "0" }} component="nav">
            <ListItemButton onClick={handleOpenStarted}>
              <ListItemIcon
                sx={{
                  minWidth: "auto",
                  color: "black",
                  marginLeft: "-5px",
                  marginRight: "8px",
                }}
                className="MuiButton-startIcon"
              >
                <RocketOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                id="manage-spaces"
                primary="Getting Started"
                disableTypography={true}
                style={{ fontFamily: "Poppins" }}
              />
              {openStart ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openStart} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 6, fontFamily: "Poppins" }}
                  onClick={() => handleClick("/apikeys")}
                  to="/gettingstarted/setup&installation"
                  component={NavLink}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "auto",
                      marginLeft: "-5px",
                      marginRight: "8px",
                    }}
                    className="MuiButton-startIcon"
                  >
                    {/* <WorkspaceIcon /> */}
                  </ListItemIcon>
                  <ListItemText
                    id="my-workspace"
                    primary="Setup & installation"
                    // disableTypography={true}
                    // style={{ fontFamily: "Poppins",border:'2px solid red' }}
                  />
                </ListItemButton>
                {/* <ListItemButton
                  sx={{ pl: 6 }}
                  onClick={() => handleClick("/space")}
                  to="/space"
                  component={NavLink}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "auto",
                      marginLeft: "-5px",
                      marginRight: "8px",
                    }}
                    className="MuiButton-startIcon"
                  >
                    <AllSpacesIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="All Spaces"
                    disableTypography={true}
                    style={{ fontFamily: "Poppins" }}
                  />
                </ListItemButton> */}
                <ListItemButton
                  sx={{ pl: 6, fontFamily: "Poppins" }}
                  onClick={() => handleClick("/spaces/images")}
                  to="/gettingstarted/authtoken"
                  component={NavLink}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "auto",
                      marginLeft: "-5px",
                      marginRight: "8px",
                    }}
                    className="MuiButton-startIcon"
                  >
                    {/* <CustomImageIcon /> */}
                  </ListItemIcon>
                  <ListItemText
                    id="custom-images"
                    primary="Your Auth Token"
                    disableTypography={true}
                    sx={{ fontFamily: "Poppins" }}
                  />
                </ListItemButton>
                {/* <ListItemButton
                  sx={{ pl: 6 }}
                  onClick={() => handleClick("/spaces/activities")}
                  to="/spaces/activities"
                  component={NavLink}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "auto",
                      marginLeft: "-5px",
                      marginRight: "8px",
                    }}
                    className="MuiButton-startIcon"
                  >
                    <ActivitiesIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Activities"
                    disableTypography={true}
                    sx={{ fontFamily: "Poppins" }}
                  />
                </ListItemButton> */}
                {/* Usage */}
              </List>
            </Collapse>
          </List>

          <List className="sidebar_menu" sx={{ padding: "0" }} component="nav">
            <ListItemButton onClick={handleOpenEdge}>
              <ListItemIcon
                sx={{
                  minWidth: "auto",
                  color: "black",
                  marginLeft: "-5px",
                  marginRight: "8px",
                }}
                className="MuiButton-startIcon"
              >
                <LanguageOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                id="manage-spaces"
                primary="Edge"
                disableTypography={true}
                style={{ fontFamily: "Poppins" }}
              />
              {openEdge ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openEdge} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 6, fontFamily: "Poppins" }}
                  onClick={() => handleClick("/spaces/my-workspace")}
                  to="/edge/endpoints"
                  component={NavLink}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "auto",
                      marginLeft: "-5px",
                      marginRight: "8px",
                    }}
                    className="MuiButton-startIcon"
                  >
                    {/* <WorkspaceIcon /> */}
                  </ListItemIcon>
                  <ListItemText
                    id="my-workspace"
                    primary="Endpoints"
                    // disableTypography={true}
                    style={{ fontFamily: "Poppins" }}
                  />
                </ListItemButton>
                {/* <ListItemButton
                  sx={{ pl: 6 }}
                  onClick={() => handleClick("/space")}
                  to="/space"
                  component={NavLink}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "auto",
                      marginLeft: "-5px",
                      marginRight: "8px",
                    }}
                    className="MuiButton-startIcon"
                  >
                    <AllSpacesIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="All Spaces"
                    disableTypography={true}
                    style={{ fontFamily: "Poppins" }}
                  />
                </ListItemButton> */}
                                <ListItemButton
                  sx={{ pl: 6, fontFamily: "Poppins" }}
                  onClick={() => handleClick("/spaces/my-workspace")}
                  to="/edge/domain"
                  component={NavLink}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "auto",
                      marginLeft: "-5px",
                      marginRight: "8px",
                    }}
                    className="MuiButton-startIcon"
                  >
                    {/* <WorkspaceIcon /> */}
                  </ListItemIcon>
                  <ListItemText
                    id="my-workspace"
                    primary="domains"
                    // disableTypography={true}
                    style={{ fontFamily: "Poppins" }}
                  />
                </ListItemButton>

                <ListItemButton
                  sx={{ pl: 6 }}
                  onClick={() => handleClick("/spaces/images")}
                  to="/edge/tcpaddresses"
                  component={NavLink}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "auto",
                      marginLeft: "-5px",
                      marginRight: "8px",
                    }}
                    className="MuiButton-startIcon"
                  >
                    {/* <CustomImageIcon /> */}
                  </ListItemIcon>
                  <ListItemText
                    id="custom-images"
                    primary="Tcp Addresses"
                    disableTypography={true}
                    sx={{ fontFamily: "Poppins" }}
                  />
                </ListItemButton>
                {/* <ListItemButton
                  sx={{ pl: 6 }}
                  onClick={() => handleClick("/spaces/activities")}
                  to="/spaces/activities"
                  component={NavLink}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "auto",
                      marginLeft: "-5px",
                      marginRight: "8px",
                    }}
                    className="MuiButton-startIcon"
                  >
                    <ActivitiesIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Activities"
                    disableTypography={true}
                    sx={{ fontFamily: "Poppins" }}
                  />
                </ListItemButton> */}
                {/* Usage */}
              </List>
            </Collapse>
          </List>
          <List className="sidebar_menu" sx={{ padding: "0" }} component="nav">
            <ListItemButton onClick={handleOpenList}>
              <ListItemIcon
                sx={{
                  minWidth: "auto",
                  color: "black",
                  marginLeft: "-5px",
                  marginRight: "8px",
                }}
                className="MuiButton-startIcon"
              >
                <SyncAltOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                id="manage-spaces"
                primary="Tunnels"
                disableTypography={true}
                style={{ fontFamily: "Poppins" }}
              />
              {openList ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openList} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 6, fontFamily: "Poppins" }}
                  onClick={() => handleClick("/spaces/my-workspace")}
                  to="/tunnels/agents"
                  component={NavLink}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "auto",
                      marginLeft: "-5px",
                      marginRight: "8px",
                    }}
                    className="MuiButton-startIcon"
                  >
                    {/* <WorkspaceIcon /> */}
                  </ListItemIcon>
                  <ListItemText
                    id="my-workspace"
                    primary="Agents"
                    // disableTypography={true}
                    style={{ fontFamily: "Poppins" }}
                  />
                </ListItemButton>
                {/* <ListItemButton
                  sx={{ pl: 6 }}
                  onClick={() => handleClick("/space")}
                  to="/space"
                  component={NavLink}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "auto",
                      marginLeft: "-5px",
                      marginRight: "8px",
                    }}
                    className="MuiButton-startIcon"
                  >
                    <AllSpacesIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="All Spaces"
                    disableTypography={true}
                    style={{ fontFamily: "Poppins" }}
                  />
                </ListItemButton> */}
                <ListItemButton
                  sx={{ pl: 6 }}
                  onClick={() => handleClick("/spaces/images")}
                  to="/tunnels/authtoken"
                  component={NavLink}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "auto",
                      marginLeft: "-5px",
                      marginRight: "8px",
                    }}
                    className="MuiButton-startIcon"
                  >
                    {/* <CustomImageIcon /> */}
                  </ListItemIcon>
                  <ListItemText
                    id="custom-images"
                    primary="Auth Token"
                    disableTypography={true}
                    sx={{ fontFamily: "Poppins" }}
                  />
                </ListItemButton>
                {/* <ListItemButton
                  sx={{ pl: 6 }}
                  onClick={() => handleClick("/spaces/activities")}
                  to="/spaces/activities"
                  component={NavLink}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "auto",
                      marginLeft: "-5px",
                      marginRight: "8px",
                    }}
                    className="MuiButton-startIcon"
                  >
                    <ActivitiesIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Activities"
                    disableTypography={true}
                    sx={{ fontFamily: "Poppins" }}
                  />
                </ListItemButton> */}
                {/* Usage */}
              </List>
            </Collapse>
          </List>
          <List className="sidebar_menu" sx={{ padding: "0" }} component="nav">
            <ListItemButton onClick={handleOpenList}>
              <ListItemIcon
                sx={{
                  minWidth: "auto",
                  color: "black",
                  marginLeft: "-5px",
                  marginRight: "8px",
                }}
                className="MuiButton-startIcon"
              >
                <SecurityOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                id="manage-spaces"
                primary="USB Proxy"
                disableTypography={true}
                style={{ fontFamily: "Poppins" }}
              />
              {openList ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openList} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 6, fontFamily: "Poppins" }}
                  onClick={() => handleClick("/spaces/my-workspace")}
                  to="/usbproxy/usbip"
                  component={NavLink}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "auto",
                      marginLeft: "-5px",
                      marginRight: "8px",
                    }}
                    className="MuiButton-startIcon"
                  >
                    {/* <WorkspaceIcon /> */}
                  </ListItemIcon>
                  <ListItemText
                    id="my-workspace"
                    primary="USIBP"
                    // disableTypography={true}
                    style={{ fontFamily: "Poppins" }}
                  />
                </ListItemButton>
                {/* <ListItemButton
                  sx={{ pl: 6 }}
                  onClick={() => handleClick("/space")}
                  to="/space"
                  component={NavLink}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "auto",
                      marginLeft: "-5px",
                      marginRight: "8px",
                    }}
                    className="MuiButton-startIcon"
                  >
                    <AllSpacesIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="All Spaces"
                    disableTypography={true}
                    style={{ fontFamily: "Poppins" }}
                  />
                </ListItemButton> */}
                {/* <ListItemButton
                  sx={{ pl: 6 }}
                  onClick={() => handleClick("/spaces/activities")}
                  to="/spaces/activities"
                  component={NavLink}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "auto",
                      marginLeft: "-5px",
                      marginRight: "8px",
                    }}
                    className="MuiButton-startIcon"
                  >
                    <ActivitiesIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Activities"
                    disableTypography={true}
                    sx={{ fontFamily: "Poppins" }}
                  />
                </ListItemButton> */}
                {/* Usage */}
              </List>
            </Collapse>
          </List>

          <List className="sidebar_menu" sx={{ padding: "0" }} component="nav">
            <ListItemButton
              onClick={() => handleClick("/spaces/images")}
              to="/billing"
              component={NavLink}
            >
              <ListItemIcon
                sx={{
                  minWidth: "auto",
                  color: "black",
                  marginLeft: "-5px",
                  marginRight: "8px",
                }}
                className="MuiButton-startIcon"
              >
                <AccountBalanceTwoToneIcon />
              </ListItemIcon>
              <ListItemText
                id="manage-spaces"
                primary="Billing"
                disableTypography={true}
                style={{ fontFamily: "Poppins" }}
              />
            </ListItemButton>
          </List>
          <List className="sidebar_menu" sx={{ padding: "0" }} component="nav">
            <ListItemButton
              onClick={() => handleClick("/spaces/images")}
              to="/users"
              component={NavLink}
            >
              <ListItemIcon
                sx={{
                  minWidth: "auto",
                  color: "black",
                  marginLeft: "-5px",
                  marginRight: "8px",
                }}
                className="MuiButton-startIcon"
              >
                <PeopleAltOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                id="manage-spaces"
                primary="Users"
                disableTypography={true}
                style={{ fontFamily: "Poppins" }}
              />
            </ListItemButton>
          </List>
          <List className="sidebar_menu" sx={{ padding: "0" }} component="nav">
            <ListItemButton
              onClick={() => handleClick("/spaces/images")}
              to="/members"
              component={NavLink}
            >
              <ListItemIcon
                sx={{
                  minWidth: "auto",
                  color: "black",
                  marginLeft: "-5px",
                  marginRight: "8px",
                }}
                className="MuiButton-startIcon"
              >
                <PeopleAltOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                id="manage-spaces"
                primary="Members"
                disableTypography={true}
                style={{ fontFamily: "Poppins" }}
              />
            </ListItemButton>
          </List>


          {/* documentation */}

          {/* <Button
            id="documentation"
            className="sideBarButton"
            onClick={() => {
              closeMyAccountDropDown();
              window.open("https://docs.einstonlabs.com/", "_blank") ||
                window.open("https://docs.einstonlabs.com/");
            }}
            // startIcon={<Doccumentation />}
            style={{
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
            }}
          >
Billing          </Button> */}
          {/* documentation */}
          {/* <Button
            id="support"
            className="sideBarButton"
            onClick={closeMyAccountDropDown}
            to="/support"
            component={NavLink}
            // startIcon={<Support />}
            style={{
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
            }}
          >
Users         
 </Button> */}
        </List>

        {/* my account list */}

        {/* my account list */}
        {/* <Divider /> */}
        {/* Sign out */}
        <Box sx={{ ml: 4, mr: 4, mt: 3 }} className="signOut">
          <Button
            className="signOutBotton"
            color="primary"
            variant="contained"
            fullWidth
            sx={{ fontFamily: "Poppins" }}
            onClick={handleLogout}
            id="signout-button"
            // startIcon={<Unlock props={{ width: "20px", height: "20px" }} />}
          >
            {"Sign out"}
          </Button>
        </Box>
        {/* Sign out */}
      </DrawerContainer>
    </SideBarDrawer>
  );
};

export default SidebarMobile;
