import {
  Autocomplete,
  Button,
  Divider,
  IconButton,
  Pagination,
  TextField,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import {
  DataBox,
  GridContainer,
  StatusActive,
  StatusInActive,
} from './indexStyled'
import WebhookOutlinedIcon from '@mui/icons-material/WebhookOutlined'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import moment from 'moment'
import WebHookHitsDrawer from '../WebHookHitsDrawer/WebHookHitsDrawer'
import { useDispatch, useSelector } from 'react-redux'
import { editTableData } from 'src/slices/apiKeys'
import { ErrorMessage } from '../indexStyled'
import { WebhookTableActions } from 'src/slices/WebhookHits/WebHookHits'

const hooknamesList = [
  {
    id: '00',
    name: 'ROOM_CREATED',
  },
  {
    id: '02',
    name: 'PARTICIPANT_JOINED',
  },
  {
    id: '03',
    name: 'SETTINGS_PROVISIONING',
  },
  {
    id: '04',
    name: 'PARTICIPANT_LEFT',
  },
  {
    id: '05',
    name: 'ROOM_DESTROYED',
  },
]

const tableData = [
  {
    appId: '123456',
    hookName: 'Room_Created',
    createdDate: new Date('2022-07-25T16:34:01+05:30'),
    status: true,
  },
  {
    appId: '342345',
    hookName: 'Participant_Joined',
    createdDate: new Date('2022-07-22T16:34:01+05:30'),
    status: false,
  },
  {
    appId: '876789',
    hookName: 'Settings_Provisioning',
    createdDate: new Date('2022-07-21T16:34:01+05:30'),
    status: true,
  },
  {
    appId: '445678',
    hookName: 'Participant_Left',
    createdDate: new Date('2022-07-20T16:34:01+05:30'),
    status: false,
  },
  {
    appId: '4432342',
    hookName: 'Room_Destroyed',
    createdDate: new Date('2022-07-22T16:34:01+05:30'),
    status: true,
  },
]

function WebHookHitsMobileVersion() {
  const [openDrawerBox, setOpenDrawerBox] = useState(false)
  const openDrawer = () => {
    setOpenDrawerBox(true)
  }
  const closeDrawerHandler = () => {
    setOpenDrawerBox(false)
  }

  const dispatch = useDispatch()
  const [validAppIdSelected, setValidAppIdSelected] = useState<boolean>(true)
  const [validHookNameSelected, setValidHookNameSelected] = useState<boolean>(
    true,
  )

  const appids = useSelector((state: any) => state.apiKeys.tableData)
  console.log('appids', appids)
  const selectedAppId = useSelector((state: any) => state.webHookHits.appIdData)
  console.log('Selected App Id', selectedAppId)
  const selectedHookName = useSelector(
    (state: any) => state.webHookHits.hookName,
  )
  console.log('Selected Hook Name', selectedHookName)

  useEffect(() => {
    dispatch(editTableData())
  }, [dispatch])

  const handleClick = () => {
    console.log('selectedAppId', selectedAppId)
    if (selectedAppId.appId === '') {
      setValidAppIdSelected(false)
    } else {
      setValidAppIdSelected(true)
    }
    if (selectedHookName.name === '') {
      setValidHookNameSelected(false)
    } else {
      setValidHookNameSelected(true)
    }
  }

  return (
    <>
      <GridContainer container>
        <Grid
          item
          xs={12}
          style={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
            marginBottom: '1rem',
          }}
        >
          <Grid
            container
            spacing={3}
            style={{
              height: 'auto',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: 'auto',
            }}
          >
            <Grid item xs={12}>
              <Typography variant="h4">Webhook Hits</Typography>
            </Grid>
            {/* app id */}
            <Grid
              item
              xs={11}
              style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
            >
              <Autocomplete
                value={selectedAppId}
                onChange={(e: any, newvalue: string) =>
                  dispatch(WebhookTableActions.setAppId(newvalue))
                }
                id="combo-box-demo"
                size="small"
                options={appids}
                getOptionLabel={(option: any) => option.appId}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="App Id"
                    fullWidth
                    placeholder="Select an app id here.."
                  />
                )}
              />
              {!validAppIdSelected && (
                <ErrorMessage>Please select app id </ErrorMessage>
              )}
            </Grid>

            {/* app id */}

            {/* hook name  */}
            <Grid
              item
              xs={11}
              style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
            >
              <Autocomplete
                value={selectedHookName}
                onChange={(e: any, newvalue: string) =>
                  dispatch(WebhookTableActions.setHookName(newvalue))
                }
                id="combo-box-demo"
                size="small"
                options={hooknamesList}
                getOptionLabel={(option: any) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Hook Name"
                    fullWidth
                    placeholder="Select hook name here.."
                  />
                )}
              />
              {!validHookNameSelected && (
                <ErrorMessage>Please select hook name </ErrorMessage>
              )}
            </Grid>
            {/* hook name  */}
            <Grid item xs={12}>
              <Button variant="outlined" onClick={handleClick}>
                Search
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </Grid>
        <Box
          style={{
            width: '100%',
            overflow: 'auto',
            height: '510px',
          }}
        >
          {tableData.map((row) => {
            return (
              <Grid key={row.appId} item xs={12} style={{ marginTop: '0rem' }}>
                <DataBox>
                  <Grid
                    container
                    // style={{ border: '1px solid red', height: '100%' }}
                  >
                    <Grid item xs={1.5}>
                      <Box
                        style={{
                          margin: 'auto',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          // paddingTop: '1.5rem',
                        }}
                      >
                        <WebhookOutlinedIcon />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'flexS-sart',
                          flexDirection: 'column',
                          gap: '5px',
                          fontSize: '10px',
                        }}
                      >
                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'flexS-sart',
                            flexDirection: 'column',
                          }}
                        >
                          <Typography style={{ fontWeight: 'bold' }}>
                            {row.appId}
                          </Typography>
                        </Box>
                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'flexS-sart',
                            flexDirection: 'column',
                          }}
                        >
                          <Typography style={{ fontWeight: 'bold' }}>
                            {row.hookName}
                          </Typography>
                        </Box>
                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'flexS-sart',
                            flexDirection: 'column',
                            textTransform: 'uppercase',
                          }}
                        >
                          <Typography>
                            {moment(row.createdDate).format('DD MMM YYYY')} ||{' '}
                            {moment(row.createdDate).format('hh:mm a')}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4.5}>
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                          flexDirection: 'column',
                          height: '100%',
                        }}
                      >
                        {row.status === true ? (
                          <StatusActive label="Active" />
                        ) : (
                          <StatusInActive label="Inactive" />
                        )}

                        <IconButton onClick={openDrawer}>
                          <RemoveRedEyeOutlinedIcon color="primary" />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                </DataBox>
                <Divider />
              </Grid>
            )
          })}
        </Box>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '2rem ',
          }}
        >
          <Pagination
            count={3}
            shape="rounded"
            color="primary"
            // page={page}
            // onChange={(event, value) => setPage(value)}
            // onChange={handlePage}
          />
        </Grid>
      </GridContainer>

      <WebHookHitsDrawer
        openDrawer={openDrawerBox}
        closeDrawer={closeDrawerHandler}
      />
    </>
  )
}
export default WebHookHitsMobileVersion
