import { Link } from "react-router-dom";
import { styled as experimentalStyled } from "@mui/material/styles";
//import portegoLogo from "src/assets/Portego-logo.png";
// import PortegoIcon from "src/assets/icons/PortegoIcon";
import IngressifyLogo from "src/assets/Svg/IngressifyLogo";

const LogoWrapper = experimentalStyled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        // padding: ${theme.spacing(0, 1, 0, 0)};
        height: 40px;
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);

function Logo() {

  return (
    <LogoWrapper to="/">
      {/* <img src={portegoLogo} alt="Portego Logo" /> */}
      <IngressifyLogo props={{height: '50px', width: '50px'}}/>
    </LogoWrapper>
  );
}

export default Logo;
