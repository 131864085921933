import React from 'react';
import { Box, Grid, styled, Typography } from '@mui/material';
import TopCard from './topCard';
import { ButtonPrimary, ButtonSecondary } from 'src/components/CustomButtons';
import PricingCalculator from './pricingCalculator';
import { FeedbackText } from './pricingStyle';
// import { useNavigate } from 'react-router-dom';

const CustomBox = styled(Box)(() => ({
    textAlign: 'center',
    marginBottom: '50px',
}));

const Pricing = () => {
    // const navigate = useNavigate();
    return (
        <div style={{ marginBottom: '20px' }}>
            <Typography variant="h1" gutterBottom className="pricing_title">
                Transparent and predictable pricing
            </Typography>
            <Typography variant="h1" gutterBottom className="pricing_subtitle">
                Embark on your passion project, foster teamwork, and expand your reach to a global audience of millions
            </Typography>
            <CustomBox>
                <ButtonPrimary
                    id="sign-up"
                    responsive={false}
                    margin="1.3rem 1.3rem 0 0"
                    fixedHeight="40px"
                // onClick={() => navigate("/contact-us")}
                >
                    Start today
                </ButtonPrimary>
                <ButtonSecondary
                    id="contact-us"
                    responsive={false}
                    margin="1.3rem 0 0 0"
                    fixedHeight="40px"
                // onClick={() => navigate("/pages/get-demo")}
                >
                    Talk to an Expert
                </ButtonSecondary>
            </CustomBox>
            <TopCard />
            <br />
            <PricingCalculator />
            <Typography variant='h2' mt={'30px'} mb={'30px'} className="pricing_title">Discover why customers opt for Portego</Typography>
            <Grid container sx={{ width: { xs: '95%', sm: '90%', md: '75%' }, margin: 'auto', display: 'flex', padding: '10px' }} columnGap={3} rowGap={2}>
                <Grid item xs={12} sm={12} md={2.5} lg={2.5} xl={2.5}>Image</Grid>
                <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                    <FeedbackText>
                        We were looking for an affordable and easy-to-integrate cloud communication platform that
                        could enable seamless chat, video, and voice communication within our app, and Portego
                        proved to be the perfect solution.
                    </FeedbackText>
                    <Typography>MUKESH CHUGHWANI</Typography>
                    <Typography>Senior Developer, Miss Malini</Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default Pricing;