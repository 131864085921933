import React from 'react'

const Twitter = () => {
  return (
    <div><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="15" fill="#1B1B1B"/>
    <path d="M21.3055 11.0554C20.7426 11.2644 20.1301 11.4148 19.4992 11.4733C20.1543 11.1384 20.6448 10.6093 20.8788 9.98524C20.2641 10.2999 19.5907 10.5206 18.8886 10.6375C18.5951 10.3674 18.2401 10.1523 17.8458 10.0055C17.4515 9.85871 17.0263 9.78337 16.5967 9.78419C14.8585 9.78419 13.4605 10.9968 13.4605 12.4849C13.4605 12.6939 13.49 12.9029 13.5378 13.1039C10.9351 12.9868 8.61376 11.9166 7.07052 10.2781C6.78933 10.6915 6.64197 11.1621 6.64378 11.6412C6.64378 12.5783 7.19744 13.4047 8.04171 13.8907C7.54417 13.8739 7.05828 13.7562 6.62355 13.5472V13.5804C6.62355 14.8928 7.70143 15.9804 9.13799 16.2305C8.86826 16.2908 8.59078 16.3217 8.3121 16.3224C8.10793 16.3224 7.9148 16.3049 7.71982 16.2812C8.11713 17.3514 9.2741 18.1287 10.6518 18.154C9.57392 18.8806 8.22381 19.3081 6.75783 19.3081C6.49479 19.3081 6.252 19.3001 6 19.2748C7.39057 20.0426 9.0405 20.4859 10.8173 20.4859C16.5856 20.4859 19.742 16.373 19.742 12.8031C19.742 12.686 19.742 12.5688 19.7328 12.4517C20.3435 12.067 20.8788 11.5905 21.3055 11.0554Z" fill="white"/>
    </svg>
    
    
    
    </div>
  )
}

export default Twitter