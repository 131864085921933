import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import axios from "../utils/axios"
import { SuccessSnackbar, FailureSnackbar } from "src/slices/Snackbar";

const arr =[
  { id: "ROOM_CREATED", name: "ROOM_CREATED", checked: false },
  { id: "PARTICIPANT_JOINED", name: "PARTICIPANT_JOINED", checked: false },
  { id: "SETTINGS_PROVISIONING", name: "SETTINGS_PROVISIONING", checked: false },
  { id: "PARTICIPANT_LEFT", name: "PARTICIPANT_LEFT", checked: false },
  { id: "ROOM_DESTROYED", name: "ROOM_DESTROYED", checked: false },
]
interface allData {
  id: string;
  appId: string;
  appName: string;
  hookNames: string[];
  registeredHookNames: string[];
  createdDate: string;
  status: string;
  url: string;
  description: string;
  authorizationHeader: string;
}
interface getAppNameInfo {
  appId: string,
  name: string
}
interface hookNamesObj{
  id: string;
  name: string;
  checked: boolean;
}
interface WebHook {
  webhook: {
    id: string;
    appId: string;
    appName: string;
    hookNames: string[];
    registeredHookNames: string[]
    createdDate: string;
    status: string;
    url: string;
    description: string;
    authorizationHeader: string;
    button: boolean;
  };
  Addsnack: boolean;
  Editsnack: boolean;
  Deletesnack: boolean;
  allData: allData[];
  getAppName: getAppNameInfo[];
  editgetData: allData[];
  openWebhook: boolean;
  webhookMode: string;
  storeId: string;
  hookNamesObj: hookNamesObj[];
  dialogLoader: boolean;
  tableLoader: boolean;
}

const initialState: WebHook = {
  webhook: {
    id: "",
    appId: "",
    appName: "",
    hookNames: [],
    registeredHookNames: [],
    createdDate: "",
    status: "",
    url: "",
    description: "",
    authorizationHeader: "",
    button: true,
  },
  Addsnack: false,
  Editsnack: false,
  Deletesnack: false,
  allData: [],
  getAppName: [],
  editgetData: [],
  openWebhook: false,
  webhookMode: "",
  storeId: "",
  hookNamesObj: arr,
  dialogLoader: false,
  tableLoader: false,
};

const slice = createSlice({
  name: "WebHook",
  initialState,
  reducers: {
    // Single Payload
    webhookdata(state: any, action) {
      state.webhook[action.payload.key] = action.payload.value;
    },

    webhookdata1(state: any, action) {
      state.webhook = action.payload;
    },

    webdatapush(state, action) {
      state.allData = action.payload;
    },

    deleteStoreData(state, action) {
      state.allData = action.payload;
    },

    webhookEdit(state, action) {
      state.allData = action.payload;
    },

    Addsnack(state: any, action) {
      state.Addsnack = action.payload;
    },
    Editsnack(state: any, action) {
      state.Editsnack = action.payload;
    },
    Deletesnack(state: any, action) {
      state.Deletesnack = action.payload;
    },

    // get appname and store
    getAppName(state, action) {
      state.getAppName = action.payload;
    },
    getEditData(state, action) {
      // state.editgetData = action.payload;
      action.payload.forEach((value) => {
        state.webhook.appId = value.appId
        state.webhook.appName = value.appName
        state.webhook.url = value.url
        state.webhook.description = value.description
        state.webhook.authorizationHeader = value.authorizationHeader
        state.webhook.registeredHookNames = value.registeredHookNames
        state.webhook.hookNames = value.registeredHookNames
      })
    },
    setOpenWebhook(state, action) {
      state.openWebhook = action.payload;
    },
    setWebhookMpde(state, action) {
      state.webhookMode = action.payload;
    },
    setStoreId(state, action) {
      state.storeId = action.payload;
    },
    setHookNamesObj(state, action) {
      state.hookNamesObj = action.payload;
    },
    setDialogLoader(state, action) {
      state.dialogLoader = action.payload;
    },
    setTableLoader(state, action) {
      state.tableLoader = action.payload;
    },
  },
});

export const setTableLoaders= (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.setTableLoader(data));
};

export const setDialogLoaders= (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.setDialogLoader(data));
};
export const setHookNamesObject= (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.setHookNamesObj(data));
};

export const setStoreId1= (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.setStoreId(data));
};
export const webhookMode1= (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.setWebhookMpde(data));
};

export const webhookInfo = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.webhookdata(data));
};

export const webdata = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.webdatapush(data));
};

export const webhookEdit = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.webhookEdit(data));
};
export const deleteData = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.deleteStoreData(data));
};

export const webhookeditstore = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.webhookdata1(data));
};

export const AddSnackInfo = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.Addsnack(data));
};
export const EditSnackInfo = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.Editsnack(data));
};
export const DeleteSnackInfo = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.Deletesnack(data));
};
export const setOpenWebhook = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.setOpenWebhook(data));
};

const BASE_URL = process.env.REACT_APP_API_URL;

// In dialog fetch appname from application api 
export const fetchAppName = () => async (dispatch: any) => {
  const response = await axios.get(`${BASE_URL}/api/v1/application`);
  dispatch(slice.actions.getAppName(response.data.data));
};



// After refresh fetch table from webhook api
export const fetchWebhookTable = () => async (dispatch: any) => {
  dispatch(slice.actions.setTableLoader(true));
  try{
    const getResponse = await axios.get(`${BASE_URL}/api/v1/webhooks`);
    if(getResponse.data.status){
      console.log("webhook",getResponse.data.data)
      dispatch(slice.actions.webdatapush(getResponse.data.data));
    }else{
      dispatch(slice.actions.webdatapush([]));
      throw new Error(getResponse?.data?.message)
    }
  }finally{
    dispatch(slice.actions.setTableLoader(false));
  }
};


// Webhook add post api
export const webHookDataPost = (data: any) => async (dispatch) => {
  try {
    const webhookData = {
      url: data.url,
      appId: data.appId,
      description: data.description,
      authorizationHeader: data.authorizationHeader,
      hookNames: data.hookNames
    };

    const postResponse = await axios.post(`${BASE_URL}/api/v1/webhooks`, webhookData);
    if (!postResponse.data?.status) {
      throw new Error(postResponse.data?.message);
    }
    dispatch(slice.actions.webhookdata(postResponse.data.data));

    const getResponse = await axios.get(`${BASE_URL}/api/v1/webhooks`);
    dispatch(slice.actions.webdatapush(getResponse.data.data));
    dispatch(SuccessSnackbar({ message: postResponse.data?.message, open: true }));
  } catch (error) {
    dispatch(FailureSnackbar({ message: error?.message || error, open: true }))
  }
};



// Webhook get edit data from webhook api
export const webHookEdit = (data: any) => async (dispatch) => {
  dispatch(slice.actions.setDialogLoader(true));
  try{
    const editWebhookData = await axios.get(`${BASE_URL}/api/v1/webhooks/?app_id=${data}`);
     if(editWebhookData?.data?.status){
      dispatch(slice.actions.getEditData(editWebhookData?.data?.data));
     }else{
       throw new Error(editWebhookData?.data?.message)
     }
  }catch(error){
   console.log(error);
  }finally{
    dispatch(slice.actions.setDialogLoader(false));
  }
};



// Webhook edit data and update to webhook api
export const webHookEditPost = (data: any) => async (dispatch) => {
  try {
    const webhookData = {
      url: data.url,
      // appId: data.appId,
      description: data.description,
      authorizationHeader: data.authorizationHeader,
      hookNames: data.hookNames
    };

    const postResponse = await axios.patch(`${BASE_URL}/api/v1/webhooks/${data.id}`, webhookData);
    if (!postResponse.data?.data) {
      throw new Error(postResponse.data?.message);
    }
    dispatch(slice.actions.webhookdata(postResponse.data.data));

    const getResponse = await axios.get(`${BASE_URL}/api/v1/webhooks`);
    dispatch(slice.actions.webdatapush(getResponse.data.data));
    dispatch(SuccessSnackbar({ message: postResponse.data?.message, open: true }));
  } catch (error) {
    dispatch(FailureSnackbar({ message: error?.message || error, open: true }))
  }
};


// Webhook delete table row from webhook and application api
export const deleteWebhook = (data: any) => async (dispatch: any) => {
  try {
    const deleteResponse = await axios.delete(`${BASE_URL}/api/v1/webhooks/${data}`);
    if (!deleteResponse.data?.status) {
      throw new Error(deleteResponse.data?.message);
    }

    const getResponse = await axios.get(`${BASE_URL}/api/v1/webhooks`);
    dispatch(slice.actions.webdatapush(getResponse.data.data));
    dispatch(SuccessSnackbar({ message: deleteResponse.data?.message, open: true }));
  } catch (error) {
    dispatch(FailureSnackbar({ message: error?.message || error, open: true }))
  }

  // dispatch(slice.actions.getAppName(response.data.data));
};




export const reducer = slice.reducer;
export default slice;
