import React, { useEffect } from 'react'
import {
  Divider,
  Pagination,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material'
import { ContentCopy } from '@mui/icons-material'
import {
  AddFilterStack,
  TableBox,
  TableCells,
  TableContainerStack,
  TablePaginationBox,
} from './indexStyled'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import styled from '@emotion/styled'
import { IconBase } from 'react-icons'
import CopyToClipboard from 'react-copy-to-clipboard'
import './table.css'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { Box } from '@mui/system'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { getNotificationsData } from 'src/slices/Notifications/Notification'

const tableData = [
  {
    appId: 'b401b148-e31b-4e31-b3b6-01e488ff05a5',
    title: 'ROOM_Created',
    description: 'A new participant has joined the room',
    createdDate: new Date('2022-07-25T16:34:01+05:30'),
  },
  {
    appId: 'b401b148-e31b-4e31-b3b6-01e487ff0ea5',
    title: 'PARTICIPANT_JOINED',
    description:
      'Your subscription for gold will expire soon. Upgrade your plan',
    createdDate: new Date('2022-07-25T16:34:01+05:30'),
  },
  {
    appId: 'b401b148-e31b-4e31-b3b6-019488ff0ea5',
    title: 'SETTINGS_PROVISIONING',
    description: 'A new participant has joined the room',
    createdDate: new Date('2022-07-25T16:34:01+05:30'),
  },
  {
    appId: 'b401b148-e31b-4e31-b3b6-01eh88ff0ea5',
    title: 'PARTICIPANT_LEFT',
    description:
      'Your subscription for gold will expire soon. Upgrade your plan',
    createdDate: new Date('2022-07-25T16:34:01+05:30'),
  },
  {
    appId: 'b401b148-e31b-4e31-b3b6-01e488ff0ea5',
    title: 'ROOM_DESTROYED',
    description: 'A new participant has joined the room',
    createdDate: new Date('2022-07-25T16:34:01+05:30'),
  },
]
const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
})

const NotificationsTable = () => {
  const dispatch = useDispatch()
  const appIdList = useSelector((State: any) => State.apiKeys.tableData)
  console.log('APPID lists', appIdList)
  useEffect(() => {
    dispatch(getNotificationsData(1))
  })

  return (
    <>
      {/* table container */}
      <TableContainerStack>
        {/* add filter button */}
        <AddFilterStack>
          <Typography variant="h3">Notifications</Typography>
        </AddFilterStack>
        {/* add filter button */}
        <Divider />
        <TableBox>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCells style={{ width: '20%' }}>APP ID</TableCells>
                  <TableCells style={{ width: '30%' }}>Description</TableCells>
                  <TableCells style={{ width: '20%' }}>TITILE</TableCells>
                  <TableCells style={{ width: '20%' }}>DATE & TIME</TableCells>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row) => {
                  return (
                    <TableRow>
                      <TableCells>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: '0.5rem',
                          }}
                        >
                          <NotificationsIcon sx={{ fontSize: '25px' }} />
                          <Typography>
                            <NoMaxWidthTooltip
                              title={
                                <Typography
                                  style={{
                                    display: 'flex',
                                    gap: '0.5rem',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  {row.appId}
                                  <CopyToClipboard text={row.appId}>
                                    <IconBase>
                                      <ContentCopy />
                                    </IconBase>
                                  </CopyToClipboard>
                                </Typography>
                              }
                            >
                              <div>{row.appId.slice(0, 16)}...</div>
                            </NoMaxWidthTooltip>
                          </Typography>
                        </Box>
                      </TableCells>
                      <Tooltip title={row.description}>
                        {row.description.length >= 40 ? (
                          <TableCell style={{ fontWeight: 'bold' }}>
                            {row.description.slice(0, 40)}...
                          </TableCell>
                        ) : (
                          <TableCell style={{ fontWeight: 'bold' }}>
                            {row.description}
                          </TableCell>
                        )}
                      </Tooltip>

                      <TableCell style={{ fontWeight: 'bold' }}>
                        {row.title}
                      </TableCell>
                      <TableCells>
                        {moment(row.createdDate).format('DD MMM YYYY')} ||{' '}
                        {moment(row.createdDate).format('hh:mm a')}
                      </TableCells>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </TableBox>
        <TablePaginationBox>
          <Pagination
            count={3}
            shape="rounded"
            color="primary"
            // page={page}
            // onChange={(event, value) => setPage(value)}
            // onChange={handlePage}
          />
        </TablePaginationBox>
      </TableContainerStack>
      {/* table container */}
    </>
  )
}
export default NotificationsTable
