import React, { useState } from 'react';
// import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from "@mui/styles";
// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
import { Divider, Typography, Box, Grid, TextField, Button, Paper } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store';
import helper from 'src/services/helper';


import { editPortegoDev } from '../../slices/billing';

// import styled from 'styled-components';
// import { styled } from '@mui/system';

import styled from '@emotion/styled';

// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';





// const PaymentWrapper = styled('div')({
//     display: 'flex',
//     flexDirection: 'row',
//     justifyContent: "space-evenly",
//         @media(maxWidth: "768px") {
//     flexDirection: "column";
// });


const PaymentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content:space-evenly;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;


const AddPaymentGateway = styled.div`

width:30%;
height:100%;
@media (max-width: 768px) {
    width:100%;
    height:100%;
  }

`;

const PortegoDev = styled.div`

width:32%;
height: 100%;

@media (max-width: 768px) {
    width:100%;
    height:100%;

`


export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}





const useStyles = makeStyles({
    dialog: { minWidth: "80%", height: "100%", marginLeft: "4%", marginTop: "0%" },
    customTextField: {
        "& input::placeholder": {
            fontSize: "12px"
        }
    }

});

interface State {
    name: string,
    cardNumber: number,
    cvv: number,
    expiration: string,
    nameOnCard: string,
    postalCode: number,
    couponCode: string,
}


const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon color="primary" />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export default function AddPayment({ paymentDataPass }) {
    const { portegoDev } = useSelector((state: RootState) => state.Billing)
    const dispatch = useDispatch();


    React.useState(() => {
        dispatch(editPortegoDev(portegoDev))

    })

    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };
    const handleClose = () => {
        setOpen(false);
        paymentDataPass(false)
    };

    const [validationValues, setValidationValues] = useState({
        name: "",
        cardNumber: "",
        cvv: "",
        expiration: "",
        nameOnCard: "",
        postalCode: "",
        couponCode: "",
    });

    const [validate, setValidate] = React.useState(false);


    // const checkValidation = () => {
    //     return 
    //        ( !helper.validateName(values.firstName) )

    // };


    const handleChangeInputFeild = (prop: keyof State) => (
        event: React.ChangeEvent<HTMLInputElement>

    ) => {
        switch (prop) {
            case 'cardNumber':
                setValidationValues({
                    ...validationValues, [prop]: event.target.value ?
                    event.target.value.length <= 16 && helper.cardNumber(event.target.value) ? event.target.value : validationValues.cardNumber : event.target.value
                });
                break;
                case 'cvv':
                setValidationValues({
                    ...validationValues, [prop]: event.target.value ?
                    event.target.value.length <= 3 && helper.cvv(event.target.value) ? event.target.value : validationValues.cvv : event.target.value
                });
                break;
                case 'expiration':
                    
                    if(validationValues.expiration.length===2){
                       

                        setValidationValues({ ...validationValues, [prop]: `${validationValues.expiration}/` });
                    }else{
                       
                        setValidationValues({ ...validationValues, [prop]: event.target.value });

                    }

                    
                    // setValidationValues({
                    //     ...validationValues, [prop]: event.target.value ?
                    //     event.target.value.length <= 5 && helper.expiration(event.target.value) ? event.target.value : validationValues.expiration : event.target.value
                    // });

                
                    break;

                
           
            default:
                setValidationValues({ ...validationValues, [prop]: event.target.value });
                break;
        }
        console.log("event", event.target)


    }
    // const [date, setDate] = useState<Date | null>(null);


    const checkValidation = () => {
        return !helper.validateName(validationValues.name) && 
        !helper.cardNumber(validationValues.cardNumber) && 
        !helper.cvv(validationValues.cvv)  && 
        !helper.validateName(validationValues.nameOnCard) && 
        !helper.postalCode(validationValues.postalCode) && 
        !helper.couponCode(validationValues.couponCode) && 
        !helper.expiration(validationValues.expiration)
        ;

    };

    const onSubmit = async () => {
        if (checkValidation() === true) {
            // dispatch(toggleFormLoader(true));
            // let postData = {
            //     name: values.name,
            //     email: values.email,
            // };
            // dispatch(addInviteMember(postData));

        }
        setValidate(true);
    };





    return (
        <div>
            <Dialog
                PaperComponent={Paper}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className={classes.dialog}
                maxWidth="lg"

            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>

                </BootstrapDialogTitle>


                <DialogContent >

                    <PaymentWrapper >
                        <PortegoDev>
                            <Typography variant="h2">Portego Dev</Typography>
                            <Box display="flex" justifyContent="space-between" sx={{ marginTop: "2rem", borderBottom: "1px solid black", paddingBottom: "1rem" }}>
                                <Typography fontSize="18px">{portegoDev.activeUserCount} Monthly Active Users</Typography>
                                <Typography fontSize="18px">{portegoDev.activeUserCountTag}</Typography>
                            </Box>


                            <Typography fontSize="16px" sx={{ pb: 2, pt: 2 }}>{portegoDev.discription}</Typography>

                            <Typography fontSize="16px" sx={{ fontWeight: "bold" }}>Add-ons(paid per usage):</Typography>
                            <Divider sx={{ mt: 1 }} />

                            <Box display="flex" justifyContent="space-between" sx={{ mt: 1 }}>
                                <Typography >Recording</Typography>
                                <Typography >${portegoDev.recording}/min</Typography>
                            </Box>
                            <Divider sx={{ mt: 1 }} />

                            <Box display="flex" justifyContent="space-between" sx={{ mt: 1 }}>
                                <Typography >RTMP Streaming</Typography>
                                <Typography >${portegoDev.RTMP}/min</Typography>
                            </Box>
                            <Divider sx={{ mt: 1 }} />

                            <Box display="flex" justifyContent="space-between" sx={{ mt: 1 }}>
                                <Typography >Transcription</Typography>
                                <Typography >${portegoDev.transcription}/min</Typography>
                            </Box>
                            <Divider sx={{ mt: 1 }} />


                            <Box display="flex" justifyContent="space-between" sx={{ mt: 1 }}>
                                <Typography >PSTN Outbound</Typography>
                                <Typography >{portegoDev.PSTNOutboundFirst} MAU+${portegoDev.PSTNOutboundSecond}/min</Typography>
                            </Box>
                            <Divider sx={{ mt: 1 }} />


                            <Box display="flex" justifyContent="space-between" sx={{ mt: 1 }}>
                                <Typography >PSTN Inbound</Typography>
                                <Typography >{portegoDev.PSTNInbound}MAU</Typography>
                            </Box>
                            <Divider sx={{ mt: 1 }} />


                            <Box display="flex" justifyContent="space-between" sx={{ mt: 1 }}>
                                <Typography >SIP in/out</Typography>
                                <Typography >${portegoDev.SIPInOut}/min</Typography>
                            </Box>
                            <Divider sx={{ mt: 1 }} />

                            <Typography fontSize="12px" sx={{ mt: 2 }}>These prices do not include taxes and fees</Typography>
                            <Typography fontSize="12px" sx={{ mt: 2 }}>*A Monthly Active User (MAU) is defined as a unique user who attended at least one meeting, with at least one other user ,in the same month. A user is defined as a unique end point </Typography>
                        </PortegoDev>


                        <AddPaymentGateway>

                            <Paper variant="outlined">


                                <Grid container style={{ padding: "4%" }} >
                                    <Grid xs={12} md={12} sx={{ mt: 2 }}>
                                        <Box display="flex" justifyContent="center">
                                            <Typography variant="h3" >Add Payment Method</Typography>

                                        </Box>
                                    </Grid>
                                    <Grid xs={12} md={12} sx={{ mt: 1 }}>
                                        <Typography>Name:</Typography>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            placeholder="Name"
                                            value={validationValues.name}
                                            error={validate && Boolean(helper.validateName(validationValues.name))}
                                            helperText={validate && helper.validateName(validationValues.name)}
                                            onChange={handleChangeInputFeild("name")}

                                        ></TextField>
                                    </Grid>

                                    <Grid xs={12} md={12} sx={{ mt: 1 }}>
                                        <Typography>Card Number:</Typography>
                                        <TextField fullWidth size="small"
                                          value={validationValues.cardNumber}
                                          error={validate && Boolean(helper.cardNumber(validationValues.cardNumber))}
                                          helperText={validate && helper.cardNumber(validationValues.cardNumber)}
                                          onChange={handleChangeInputFeild("cardNumber")}
                                        ></TextField>
                                    </Grid>



                                    <Grid container display="flex" justifyContent="space-between">


                                        <Grid xs={12} md={5.5} sx={{ mt: 1 }}>
                                            <Typography>Expiration Date:</Typography>
                                            <TextField fullWidth size="small"
                                            value={validationValues.expiration}
                                            error={validate && Boolean(helper.expiration(validationValues.expiration))}
                                            helperText={validate && helper.expiration(validationValues.expiration)}
                                            onChange={handleChangeInputFeild("expiration")}></TextField>
                                            {/* <LocalizationProvider dateAdapter={AdapterDateFns}> 
                                            <DatePicker
                                                label="Select date"
                                                value={validationValues.expiration}
                                                inputFormat="mm/yyyy"
                                                onChange={handleChangeInputFeild("expiration")}
                                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                            />
                                        </LocalizationProvider> */}

                                               
                                        </Grid>

                                        <Grid xs={12} md={5.5} sx={{ mt: 1 }}>
                                            <Typography>CVC Code:</Typography>
                                            <TextField fullWidth size="small"
                                                value={validationValues.cvv}
                                                error={validate && Boolean(helper.cvv(validationValues.cvv))}
                                                helperText={validate && helper.cvv(validationValues.cvv)}
                                                onChange={handleChangeInputFeild("cvv")}
                                            ></TextField>
                                        </Grid>


                                    </Grid>

                                    <Grid xs={12} md={12} sx={{ mt: 1 }}>
                                        <Typography>Name on the Card:</Typography>
                                        <TextField fullWidth size="small"
                                          value={validationValues.nameOnCard}
                                          error={validate && Boolean(helper.validateName(validationValues.nameOnCard))}
                                          helperText={validate && helper.validateName(validationValues.nameOnCard)}
                                          onChange={handleChangeInputFeild("nameOnCard")}

                                        ></TextField>
                                    </Grid>




                                    <Grid container display="flex" justifyContent="space-between">





                                        <Grid xs={12} md={5.5} sx={{ mt: 1 }} >
                                            <Typography>Zip/Postal Code:</Typography>
                                            <TextField fullWidth size="small"
                                                   value={validationValues.postalCode}
                                                   error={validate && Boolean(helper.postalCode(validationValues.postalCode))}
                                                   helperText={validate && helper.postalCode(validationValues.postalCode)}
                                                   onChange={handleChangeInputFeild("postalCode")}

                                            ></TextField>
                                        </Grid>

                                        <Grid xs={12} md={5.5} sx={{ mt: 1 }} >
                                            <Typography>Country:</Typography>
                                            <TextField fullWidth size="small"
                                            
                                            ></TextField>
                                        </Grid>

                                    </Grid>


                                    <Grid xs={12} md={12} sx={{ mt: 1 }}>
                                        <Typography>Coupon Code:</Typography>
                                        <TextField fullWidth size="small"
                                             value={validationValues.couponCode}
                                             error={validate && Boolean(helper.couponCode(validationValues.couponCode))}
                                             helperText={validate && helper.couponCode(validationValues.couponCode)}
                                             onChange={handleChangeInputFeild("couponCode")}
                                        ></TextField>
                                    </Grid>

                                    <Grid xs={12} md={12} sx={{ mt: 2 }}>
                                        <Button variant="contained" fullWidth onClick={() => onSubmit()}>Add Payment Method</Button>
                                    </Grid>

                                    <Grid xs={12} md={12} sx={{ mt: 2 }}>
                                        <Typography>By saving your card information, you allow portego to change for future payments in accordance with their terms </Typography>
                                    </Grid>


                                </Grid>

                            </Paper>

                        </AddPaymentGateway>
                    </PaymentWrapper>


                </DialogContent>

            </Dialog>
        </div>
    );
}
