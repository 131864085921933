import { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
//import Footer from "src/components/Footer";
import Sidebar from "./Sidebar";
import Header from "./Header";

interface AccentHeaderLayoutProps {
  children?: ReactNode;
}

const MainWrapper = styled(Box)(
  ({ theme }) => `
        flex: 1 1 auto;
        display: flex;
        height: 97%;
`
);

const MainContent = styled(Box)(
  () => `
        margin-top: 74px;
        flex: 1 1 auto;
        overflow: auto;
`
);

const AccentHeaderLayout: FC<AccentHeaderLayoutProps> = ({ children }) => {
  return (
    <>
      <Header />
      <MainWrapper>
        <Sidebar />
        <MainContent>{children}</MainContent>
        {/* <Footer /> */}
      </MainWrapper>
    </>
  );
};

AccentHeaderLayout.propTypes = {
  children: PropTypes.node,
};

export default AccentHeaderLayout;
