import React from 'react'
// import Home from './Home'
// import Box from "@mui/material/Box";
import "./ApiKeyDialog.css";

function Index() {
    return (
        // <Box sx={{ mt: 2, mb: 2, ml: 2, mr: 2 }}>
        //     <Home/>
        // </Box>
        <>
        Setup and installation
        </>
    )
}

export default Index
