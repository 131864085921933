import { useContext } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { SidebarContext } from "src/contexts/SidebarContext";
import { Box, Button, Drawer, Hidden } from "@mui/material";
import { styled } from "@mui/material/styles";
import MenuContainer from "src/container/MenuContainer";
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone'
import useAuth from "src/hooks/useAuth";
const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        position: relative;
        z-index: 5;
        height: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
          height: calc(100% - 74px);
          margin-top: 74px;
        }
`
);

const TopSection = styled(Box)(
  ({ theme }) => `
        margin: ${theme.spacing(2, 2)};
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const { logout } = useAuth();
  const closeSidebar = () => toggleSidebar();
  const handleLogout = async (): Promise<void> => {
    try {
      await logout()
    } catch (err) {
      console.error(err)
    }
  }
  return (
    <>
      <Hidden lgDown>
        <SidebarWrapper>
          <Scrollbars autoHide>
            <MenuContainer />
            <Box className="signOut">
            <Button
              fullWidth
              sx={{
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#373737",
                "&.active": {
                  color: "#ffffff",
                  borderRadius: "6px",
                  fontWeight: 500,
                  backgroundColor: "#3087EC"
                }
              }}
              onClick={handleLogout}
              id="signout-button"
              startIcon={<LockOpenTwoToneIcon sx={{ mr: 1 }} />}
            >
              {"Sign out"}
            </Button>
          </Box>
          </Scrollbars>
        </SidebarWrapper>
      </Hidden>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggle}
          onClose={closeSidebar}
          variant="temporary"
          elevation={9}
        
        >
          <SidebarWrapper>
            <Scrollbars autoHide>
              <TopSection></TopSection>
              <MenuContainer />
            </Scrollbars>
          </SidebarWrapper>
        </Drawer>
      </Hidden>
    </>
  );
}

export default Sidebar;
