import {
  Chip,
  Grid,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'

// table add filter button
export const AddFilterStack = styled(Stack)`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 19px;
`
export const GridContainer = styled(Grid)`
  width: 100%;
  height: auto;
  padding: 19px;
`
export const GridContainerBox = styled(Grid)`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;
`

// table container
export const TableContainerStack = styled(Stack)`
  font-size: 15px;
  width: 98%;
  height: auto;
  margin-left: 19px;
  margin-right: 19px;
  margin-bottom: 16px;
  box-shadow: 0px 9px 16px rgba(159, 162, 191, 0.18),
    0px 9px 16px rgba(159, 162, 191, 0.18),
    0px 2px 2px rgba(159, 162, 191, 0.32);
  border-radius: 6px;
  //   display: grid;
  //   grid-template-rows: 15% 73% 12%;
  display: flex;
  flex-direction: column;
`
export const TableBox = styled(Stack)`
  width: 100%;
  height: 512px;
  // overflow: hidden;
  margin-top: 16px;
  position: relative;
`
export const TablePaginationBox = styled(Stack)`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const TableRowBox = styled(TableRow)`
  // height: 80px;
  //   background: #ffffff;
  :hover {
    background: rgba(157, 191, 242, 0.25);
  }
`
export const TableColumn = styled(Typography)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  //   color: #223354;
`
export const TableDataCells = styled(Typography)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  //   color: #223354;
`
export const TableDataCellUptime = styled(Typography)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  //   color: #223354;
  opacity: 0.5;
`
export const StatusLive = styled(Chip)`
  color: #3bb900;
  background: #ecfbe6;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  border-radius: 3px;
  text-transform: uppercase;
`
export const StatusCompleted = styled(Chip)`
  color: #f4002c;
  background: #fbebe6;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  border-radius: 3px;
  text-transform: uppercase;
`
export const TableAction = styled(Box)`
  width: 100%;
  height: 100%;
  dispkay: flex;
  justify-content: space-between;
`

////////////////////////////
export const TableCells = styled(TableCell)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  //   color: #223354;
`
export const TableCellsHeader = styled(Typography)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  //   color: #223354;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
