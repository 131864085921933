import { Autocomplete, Grid, TextField } from '@mui/material'
import React from 'react'
import { useState, useEffect } from 'react'

import {
  ErrorMessage,
  FormSubmitButton,
  FormSubmitButtonLoading,
  FormWrapper,
  PaswordWrapperLG,
  PaswordWrapperXS,
  ProfilePageHeaderBox,
  ProfilePageHeaderHeader,
  ResetButton,
  TextFieldWrapper,
} from './indexStyled'

// reducers
import { useDispatch, useSelector } from 'react-redux'
import {
  getUserData,
  ProfilePageActions,
  updateUserDetails,
} from 'src/slices/ProfilePage/ProfilePageFormSlice'
import ResetPassword from './ResetPassword'
import FileUploads from './FileUploads'
// import ResetPassword from './ResetPassword'
// reducers

export default function Index() {
  const dispatch = useDispatch()
  const user = useSelector(
    (state: any) => state.profilePage.profileformData.profileData,
  )
  console.log('user data', user)
  const addressDetails = useSelector(
    (state: any) => state.profilePage.profileformData.profileData.address,
  )
  const statesList = useSelector(
    (state: any) => state.profilePage.stateCountryData.states,
  )
  const countryList = useSelector(
    (state: any) => state.profilePage.stateCountryData.country,
  )
  const isLoading = useSelector(
    (state: any) => state.profilePage.loadingState.setLoading,
  )

  //   touched states
  const [touchedFirst_name, setTouchedFirst_name] = useState<boolean>(false)
  const [touchedLast_name, setTouchedLast_name] = useState<boolean>(false)
  const [touchedCompany_name, setTouchedCompany_name] = useState<boolean>(false)
  const [touchedAddressLine1, setTouchedAddressLine1] = useState<boolean>(false)
  const [touchedAddressLine2, setTouchedAddressLine2] = useState<boolean>(false)
  const [touchedLandmark, setTouchedLandmark] = useState<boolean>(false)
  const [touchedDistrict, setTouchedDistrict] = useState<boolean>(false)
  const [touchedPincode, setTouchedPincode] = useState<boolean>(false)
  const [touchedState, setTouchedState] = useState<boolean>(false)
  //   touched states
  //   valid states
  const [validFirst_name, setValidFirst_name] = useState<boolean>(true)
  const [validLast_name, setValidLast_name] = useState<boolean>(true)
  const [validCompany_name, setValidCompany_name] = useState<boolean>(true)
  const [validAddressLine1, setValidAddressLine1] = useState<boolean>(true)
  const [validAddressLine2, setValidAddressLine2] = useState<boolean>(true)
  const [validLandmark, setValidLandmark] = useState<boolean>(true)
  const [validDistrict, setValidDistrict] = useState<boolean>(true)
  const [validPincode, SetValidPincode] = useState<boolean>(true)
  const [validState, setValidState] = useState<boolean>(true)
  //   valid states
  //   validations
  const validateFirst_name = /^[a-zA-Z]{3,50}$/
  const validateLast_name = /^[a-zA-Z]{1,50}$/
  const validateCompany_name = /^.{3,50}$/
  const validateAddress = /^.{3,50}$/
  const validatePincode = /^[1-9][0-9]{5}$/
  //   validations
  //   changeHandlers
  const firstNameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const firstName = /^[A-Za-z]*$/
    if (e.target.value.match(firstName)) {
      dispatch(ProfilePageActions.setProfileFirstName(e.target.value))
      setValidFirst_name(true)
    }
  }

  const lastNameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const lastName = /^[A-Za-z]*$/
    if (e.target.value.match(lastName)) {
      dispatch(ProfilePageActions.setProfileLastName(e.target.value))
      setValidLast_name(true)
    }
  }

  const companyNameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const companyName = /^[A-Za-z0-9\s]*$/
    if (e.target.value.match(companyName)) {
      dispatch(ProfilePageActions.setProfileCompanyName(e.target.value))
      setValidCompany_name(true)
    }
  }

  // address fields
  const addressLine1ChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const addressLine1 = /^[A-Za-z0-9\s#/-]*$/
    if (e.target.value.match(addressLine1)) {
      dispatch(ProfilePageActions.setAddressLine1(e.target.value))
      setValidAddressLine1(true)
    }
  }

  const addressLine2ChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const addressLine2 = /^[A-Za-z0-9\s#/-]*$/
    if (e.target.value.match(addressLine2)) {
      dispatch(ProfilePageActions.setAddressLine2(e.target.value))
      setValidAddressLine2(true)
    }
  }
  const landmarkChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const landmark = /^[A-Za-z\s]*$/
    if (e.target.value.match(landmark)) {
      dispatch(ProfilePageActions.setLandmark(e.target.value))
      setValidLandmark(true)
    }
  }
  const districtChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const district = /^[A-Za-z\s]*$/
    if (e.target.value.match(district)) {
      dispatch(ProfilePageActions.setDistrict(e.target.value))
      setValidDistrict(true)
    }
  }
  const stateChangeHandler = (newvalue: string) => {
    if (newvalue) {
      dispatch(ProfilePageActions.setState(newvalue))
      setValidState(true)
    }
  }

  const pincodeChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const pincode = /^[0-9]*$/
    if (e.target.value.match(pincode)) {
      dispatch(ProfilePageActions.setPincode(e.target.value))
      SetValidPincode(true)
    }
  }
  const countryChangeHandler = (newvalue: string) => {
    if (newvalue) {
      dispatch(ProfilePageActions.setCountry(newvalue))
    }
  }

  //   changeHandlers
  // password reset dailog
  const passwordResetDailogHandler = () => {
    dispatch(ProfilePageActions.setPasswordResetDailogBox())
  }
  // password reset dailog
  //   blur handlers
  const firstNameBlurHandler = () => {
    setTouchedFirst_name(true)
    if (!validateFirst_name.test(user.firstName)) {
      setValidFirst_name(false)
    }
  }
  const lastNameBlurHandler = () => {
    setTouchedLast_name(true)
    if (!validateLast_name.test(user.lastName)) {
      setValidLast_name(false)
    }
  }

  const companyNameBlurHandler = () => {
    setTouchedCompany_name(true)
    if (!validateCompany_name.test(user.organizationName)) {
      setValidCompany_name(false)
    }
  }

  // address fields
  const addressLine1BlurHandler = () => {
    setTouchedAddressLine1(true)
    if (!validateAddress.test(addressDetails.addressLine1)) {
      setValidAddressLine1(false)
    }
  }
  const addressLine2BlurHandler = () => {
    setTouchedAddressLine2(true)
    if (!validateAddress.test(addressDetails.addressLine2)) {
      setValidAddressLine2(false)
    }
  }
  const landmarkBlurHandler = () => {
    setTouchedLandmark(true)
    if (!validateAddress.test(addressDetails.landmark)) {
      setValidLandmark(false)
    }
  }
  const districtBlurHandler = () => {
    setTouchedDistrict(true)
    if (!validateAddress.test(addressDetails.district)) {
      setValidDistrict(false)
    }
  }
  const stateBlurHandler = () => {
    setTouchedState(true)
    if (!addressDetails.state) {
      setValidState(false)
    }
  }

  const pincodeBlurHandler = () => {
    setTouchedPincode(true)
    if (!validatePincode.test(addressDetails.pincode)) {
      SetValidPincode(false)
    }
  }
  //   blur handlers

  // form submit handler
  const ProfilePageSubmitHandler = (e: React.FormEvent) => {
    e.preventDefault()
    if (!user.firstName || !validateLast_name.test(user.firstName)) {
      setValidLast_name(false)
      setTouchedFirst_name(true)
    }
    if (!user.lastName || !validateLast_name.test(user.lastName)) {
      setValidLast_name(false)
      setTouchedLast_name(true)
    }
    if (!user.organizationName) {
      setTouchedCompany_name(true)
      setValidCompany_name(false)
    }
    if (!addressDetails.addressLine1) {
      setTouchedAddressLine1(true)
      setValidAddressLine1(false)
    }
    if (!addressDetails.addressLine2) {
      setTouchedAddressLine2(true)
      setValidAddressLine2(false)
    }
    if (!addressDetails.landmark) {
      setTouchedLandmark(true)
      setValidLandmark(false)
    }

    if (!addressDetails.district) {
      setTouchedDistrict(true)
      setValidDistrict(false)
    }

    if (!addressDetails.state) {
      setTouchedState(true)
      setValidState(false)
    }
    if (!addressDetails.pincode) {
      setTouchedPincode(true)
      SetValidPincode(false)
    }

    // if (
    //   !touchedCompany_name &&
    //   !user.organizationName &&
    //   !validateCompany_name.test(user.organizationName)
    // ) {
    //   setValidCompany_name(true)
    //   setTouchedCompany_name(false)
    // } else if (
    //   user.organizationName &&
    //   validateCompany_name.test(user.organizationName)
    // ) {
    //   setValidCompany_name(true)
    //   setTouchedCompany_name(false)
    // } else {
    //   setValidCompany_name(false)
    //   setTouchedCompany_name(true)
    // }

    if (
      validFirst_name &&
      validLast_name &&
      validCompany_name &&
      validAddressLine1 &&
      validAddressLine2 &&
      validLandmark &&
      validDistrict &&
      validState &&
      validPincode
    ) {
      const userData = {
        firstName: user.firstName,
        lastName: user.lastName,
        organizationName: user.organizationName,
        address: {
          addressLine1: addressDetails.addressLine1,
          addressLine2: addressDetails.addressLine2,
          landmark: addressDetails.landmark,
          district: addressDetails.district,
          state: addressDetails.state,
          pincode: +addressDetails.pincode,
          country: addressDetails.country,
        },
      }
      console.log('userData', userData)
      dispatch(updateUserDetails(userData))
    }
  }

  useEffect(() => {
    dispatch(getUserData())
  }, [dispatch])

  // form submit handler
  return (
    <div>
      {/* Profile Page Heading */}
      <ProfilePageHeaderBox>
        <ProfilePageHeaderHeader>Profile</ProfilePageHeaderHeader>
      </ProfilePageHeaderBox>
      {/* Profile Page Heading */}

      {/* form */}

      <FormWrapper component="form" onSubmit={ProfilePageSubmitHandler}>
        <Grid spacing={3} container>
          <Grid item lg={4.5} xs={12}>
            {/* first name */}
            <TextFieldWrapper>
              <TextField
                autoComplete="off"
                fullWidth
                size="medium"
                variant="outlined"
                label="First Name"
                placeholder="Enter first name here.."
                value={user.firstName}
                onChange={firstNameChangeHandler}
                onBlur={firstNameBlurHandler}
              />
              {!validFirst_name && touchedFirst_name && (
                <ErrorMessage>Please enter minimum 3 characters</ErrorMessage>
              )}
            </TextFieldWrapper>
            {/* last name */}
            <TextFieldWrapper>
              <TextField
                autoComplete="off"
                fullWidth
                size="medium"
                variant="outlined"
                label="Last Name"
                placeholder="Enter last name here.."
                value={user.lastName}
                onChange={lastNameChangeHandler}
                onBlur={lastNameBlurHandler}
              />
              {!validLast_name && touchedLast_name && (
                <ErrorMessage> Please enter minimum 1 character</ErrorMessage>
              )}
            </TextFieldWrapper>
            {/* contact */}
            <TextFieldWrapper>
              <TextField
                autoComplete="off"
                fullWidth
                size="medium"
                variant="outlined"
                label="Mobile Number"
                value={user.contact}
                disabled
              />
            </TextFieldWrapper>
            {/* email */}
            <TextFieldWrapper>
              <TextField
                autoComplete="off"
                fullWidth
                size="medium"
                variant="outlined"
                label="Email Address"
                // placeholder="Enter email address here.."
                value={user.email}
                disabled
              />
            </TextFieldWrapper>
            {/* password reeet */}
            <PaswordWrapperLG>
              <TextField
                autoComplete="off"
                fullWidth
                type="password"
                InputProps={{
                  readOnly: true,
                }}
                size="medium"
                variant="outlined"
                label="Password"
                value="*************"
                disabled
              />
              <ResetButton onClick={passwordResetDailogHandler}>
                Reset Password
              </ResetButton>
            </PaswordWrapperLG>
          </Grid>
          <Grid item lg={4.5} xs={12}>
            {/* company name */}
            <TextFieldWrapper>
              <TextField
                autoComplete="off"
                fullWidth
                size="medium"
                variant="outlined"
                label="Company Name"
                placeholder="Enter company name here.."
                value={user.organizationName}
                onChange={companyNameChangeHandler}
                onBlur={companyNameBlurHandler}
              />
              {!validCompany_name && touchedCompany_name && (
                <ErrorMessage>
                  Please enter name between 3-50 alphanumericals
                </ErrorMessage>
              )}
            </TextFieldWrapper>
            {/* company Address */}
            <Grid container>
              <Grid item xs={12}>
                <TextFieldWrapper>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    size="medium"
                    variant="outlined"
                    label="Address 1"
                    placeholder="Enter company address here.."
                    value={addressDetails.addressLine1}
                    onChange={addressLine1ChangeHandler}
                    onBlur={addressLine1BlurHandler}
                  />
                  {!validAddressLine1 && touchedAddressLine1 && (
                    <ErrorMessage>Please enter 3-50 character</ErrorMessage>
                  )}
                </TextFieldWrapper>
              </Grid>
              <Grid item xs={12}>
                <TextFieldWrapper>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    size="medium"
                    variant="outlined"
                    label="Address 2"
                    placeholder="Enter company address here.."
                    value={addressDetails.addressLine2}
                    onChange={addressLine2ChangeHandler}
                    onBlur={addressLine2BlurHandler}
                  />
                  {!validAddressLine2 && touchedAddressLine2 && (
                    <ErrorMessage>Please enter 3-50 character</ErrorMessage>
                  )}
                </TextFieldWrapper>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextFieldWrapper>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      size="medium"
                      variant="outlined"
                      label="Landmark"
                      placeholder="Enter landmark here.."
                      value={addressDetails.landmark}
                      onChange={landmarkChangeHandler}
                      onBlur={landmarkBlurHandler}
                    />
                    {!validLandmark && touchedLandmark && (
                      <ErrorMessage>Please enter 3-50 character</ErrorMessage>
                    )}
                  </TextFieldWrapper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFieldWrapper>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      size="medium"
                      variant="outlined"
                      label="District"
                      placeholder="Enter district here.."
                      value={addressDetails.district}
                      onChange={districtChangeHandler}
                      onBlur={districtBlurHandler}
                    />
                    {!validDistrict && touchedDistrict && (
                      <ErrorMessage>Please enter 3-50 character</ErrorMessage>
                    )}
                  </TextFieldWrapper>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextFieldWrapper>
                    <Autocomplete
                      value={addressDetails.state}
                      onChange={(e: any, newvalue: string) =>
                        stateChangeHandler(newvalue)
                      }
                      onBlur={stateBlurHandler}
                      id="combo-box-demo"
                      options={statesList}
                      renderInput={(params) => (
                        <TextField {...params} label="State" />
                      )}
                    />
                    {!validState && touchedState && (
                      <ErrorMessage>Please select state</ErrorMessage>
                    )}
                  </TextFieldWrapper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFieldWrapper>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      size="medium"
                      variant="outlined"
                      label="Pincode"
                      placeholder="Enter pincode here.."
                      value={addressDetails.pincode}
                      onChange={pincodeChangeHandler}
                      onBlur={pincodeBlurHandler}
                    />
                    {!validPincode && touchedPincode && (
                      <ErrorMessage>Please enter valid pincode</ErrorMessage>
                    )}
                  </TextFieldWrapper>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextFieldWrapper>
                  <Autocomplete
                    value={addressDetails.country}
                    onChange={(e: any, newvalue: string) =>
                      countryChangeHandler(newvalue)
                    }
                    id="combo-box-demo"
                    options={countryList}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        value={addressDetails?.country}
                      />
                    )}
                  />
                </TextFieldWrapper>
              </Grid>
            </Grid>
            {/* password reeet */}
            <PaswordWrapperXS>
              <TextField
                autoComplete="off"
                fullWidth
                type="password"
                InputProps={{
                  readOnly: true,
                }}
                size="medium"
                variant="outlined"
                label="Password"
                value="*************"
                disabled
              />
              <ResetButton onClick={passwordResetDailogHandler}>
                Reset Password
              </ResetButton>
            </PaswordWrapperXS>
          </Grid>
          <Grid item lg={3} xs={12}>
            <FileUploads />
          </Grid>
        </Grid>
        {/* form submit button */}
        {!isLoading ? (
          <FormSubmitButton type="submit">Save</FormSubmitButton>
        ) : (
          <FormSubmitButtonLoading loading>Save</FormSubmitButtonLoading>
        )}
      </FormWrapper>
      {/* form */}
      {/* password reset dailog */}
      <ResetPassword />
      {/* password reset dailog */}
    </div>
  )
}
