import React from "react";
import { Box, Typography, Hidden, Grid, } from "@mui/material";
import Scalability from "src/assets/Svg/Scalability";
import ScalabilityIcon from "src/assets/Svg/ScalabilityIcon";
import Particle1 from "./Particle1";

const ScalabilityComponent = () => {
  return (
    <div>
      {" "}
      <Hidden smDown>
        <div>
          <Hidden mdDown>
            <Particle1 />
          </Hidden>

          <Box
            sx={{
              marginTop: "50px",
              transform: {
                xl: "translate(0, -750px)",
                lg: "translate(0, -750px)",
                md: "translate(0, -750px)",
              },
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontSize: {
                  lg: "35px",
                  md: "25px",
                  sm: "25px",
                  xs: "15px",
                },
                fontWeight: "500",
                backgroundImage:
                  "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Scalabilty
            </Typography>

            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <Grid
                item
                xl={3}
                lg={2.5}
                md={3}
                sm={3}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                      paddingLeft: "10px",
                    }}
                  >
                    {/* <CompressIcon sx={{ color: "white", fontSize: "40px" }} /> */}
                    <ScalabilityIcon
                      props={{
                        width: "54px",
                        height: "54px",
                      }}
                    />
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: {
                          lg: "28px",
                          md: "18px",
                          sm: "18px",
                          xs: "14px",
                        },
                        fontWeight: "400",
                        backgroundImage:
                          "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      Resiliency
                    </Typography>
                  </Box>

                  <Typography
                    sx={{
                      fontSize: {
                        lg: "20px",
                        md: "14px",
                        sm: "12px",
                        xs: "12px",
                      },
                      fontWeight: "500",
                      color: "#FFFFFF",
                      // border:'1px solid red',
                      paddingLeft: "10px",
                    }}
                  >
                    <ul>
                      <li>Load Balancing</li>
                      <li>Automatic Failover</li>
                      <li>Weighted Balancing</li>
                      <li>Circuit Breaker</li>
                    </ul>
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                xl={3}
                lg={4}
                sm={5}
                md={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "20px",
                  gap: {
                    lg: "20px",
                    md: "15px",
                    sm: "10px",
                    xs: "5px",
                  },
                }}
              >
                <Box
                  sx={{
                    width: {
                      lg: "400px",
                      md: "300px",
                      sm: "200px",
                      xs: "250px",
                    },
                    height: {
                      lg: "400px",
                      md: "300px",
                      sm: "200px",
                      xs: "250px",
                    },
                  }}
                >
                  <Scalability />
                </Box>

                <Box
                  sx={{
                    width: {
                      lg: "400px",
                      md: "400px",
                      sm: "300px",
                      xs: "250px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                      paddingLeft: "10px",
                    }}
                  >
                    {/* <ConnectWithoutContactIcon
                  sx={{ color: "white", fontSize: "40px" }}
                /> */}
                    <ScalabilityIcon
                      props={{
                        width: "54px",
                        height: "54px",
                      }}
                    />
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: {
                          lg: "28px",
                          md: "18px",
                          sm: "18px",
                          xs: "14px",
                        },
                        fontWeight: "400",
                        backgroundImage:
                          "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      Universal Connectivity
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "20px",
                        md: "14px",
                        sm: "12px",
                        xs: "12px",
                      },
                      fontWeight: "500",
                      color: "#FFFFFF",
                      paddingLeft: "10px",
                    }}
                  >
                    <ul>
                      <li>
                        Connect to any system regardless of network or location
                      </li>
                    </ul>
                  </Typography>
                </Box>
              </Grid>

              <Grid item xl={3} lg={2.5} md={3} sm={3}>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                      paddingLeft: "10px",
                    }}
                  >
                    {/* <MultilineChartIcon
                  sx={{ color: "white", fontSize: "40px" }}
                /> */}
                    <ScalabilityIcon
                      props={{
                        width: "54px",
                        height: "54px",
                      }}
                    />
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: {
                          lg: "28px",
                          md: "18px",
                          sm: "18px",
                          xs: "14px",
                        },
                        fontWeight: "400",
                        backgroundImage:
                          "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      Performance
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "20px",
                        md: "14px",
                        sm: "12px",
                        xs: "12px",
                      },
                      fontWeight: "500",
                      color: "#FFFFFF",
                      paddingLeft: "10px",
                    }}
                  >
                    <ul>
                      <li>HTTP Compression</li>
                      <li>Automatic Failover</li>
                      <li>Weighted Balancing</li>
                      <li>Circuit Breaker</li>
                    </ul>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </div>
      </Hidden>
      <Hidden smUp>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontSize: {
                lg: "35px",
                md: "25px",
                sm: "25px",
                xs: "20px",
              },
              fontWeight: "500",
              backgroundImage:
                "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Scalabilty
          </Typography>
          <Box sx={{ width: "250px", height: "250px", marginTop: "10px" }}>
            <Scalability />
          </Box>

          <Box
            sx={{
              width: {
                xs: "250px",

                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
                paddingLeft: "10px",
              }}
            >
              {/* <CompressIcon sx={{ color: "white", fontSize: "30px" }} /> */}
              <ScalabilityIcon
                props={{
                  width: "35x",
                  height: "35px",
                }}
              />
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: {
                    lg: "28px",
                    md: "18px",
                    sm: "18px",
                    xs: "14px",
                  },
                  fontWeight: "400",
                  backgroundImage:
                    "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Resiliency
              </Typography>
            </Box>

            <Typography
              sx={{
                fontSize: {
                  lg: "20px",
                  md: "14px",
                  sm: "12px",
                  xs: "12px",
                },
                fontWeight: "500",
                color: "#FFFFFF",
              }}
            >
              <ul>
                <li>Load Balancing</li>
                <li>Automatic Failover</li>
                <li>Weighted Balancing</li>
                <li>Circuit Breaker</li>
              </ul>
            </Typography>
          </Box>
          <Box
            sx={{
              width: {
                xs: "250px",
              },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
                paddingLeft: "10px",
              }}
            >
              {/* <ConnectWithoutContactIcon
            sx={{ color: "white", fontSize: "30px" }}
          /> */}
              <ScalabilityIcon
                props={{
                  width: "35px",
                  height: "35px",
                }}
              />
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: {
                    lg: "28px",
                    md: "18px",
                    sm: "18px",
                    xs: "14px",
                  },
                  fontWeight: "400",
                  backgroundImage:
                    "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Universal Connectivity
              </Typography>
            </Box>
            <Typography
              sx={{
                fontSize: {
                  lg: "20px",
                  md: "14px",
                  sm: "12px",
                  xs: "12px",
                },
                fontWeight: "500",
                color: "#FFFFFF",
                textAlign: "center",
              }}
            >
              <ul>
                <li>Connect to any system regardless of network or location</li>
              </ul>
            </Typography>
          </Box>
          <Box
            sx={{
              width: {
                xs: "250px",
              },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
                paddingLeft: "10px",
              }}
            >
              {/* <MultilineChartIcon sx={{ color: "white", fontSize: "30px" }} /> */}
              <ScalabilityIcon
                props={{
                  width: "35px",
                  height: "35px",
                }}
              />
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: {
                    lg: "28px",
                    md: "18px",
                    sm: "18px",
                    xs: "14px",
                  },
                  fontWeight: "400",
                  backgroundImage:
                    "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Performance
              </Typography>
            </Box>
            <Typography
              sx={{
                fontSize: {
                  lg: "20px",
                  md: "14px",
                  sm: "12px",
                  xs: "12px",
                },
                fontWeight: "500",
                color: "#FFFFFF",
              }}
            >
              <ul>
                <li>HTTP Compression</li>
                <li>Automatic Failover</li>
                <li>Weighted Balancing</li>
                <li>Circuit Breaker</li>
              </ul>
            </Typography>
          </Box>
        </Box>
      </Hidden>
    </div>
  );
};

export default ScalabilityComponent;
