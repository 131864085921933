
import HeaderUserbox from "./Userbox";
import { Box, Card, Hidden, IconButton, alpha, Tooltip,  } from "@mui/material";
import { styled } from "@mui/material/styles";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
// import Logo from "./Logo";
import { useState } from "react";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import SidebarMobile from "src/layouts/AccentSidebarLayout/Sidebar/SidebarMobile";
import Logo from "./Logo";
const HeaderWrapper = styled(Card)(
  ({ theme }) => `
    height: 74px;
    color: black;
    padding: ${theme.spacing(0, 2)};
    right: 0;
    z-index: 6;
    background-color: #fffff;
    position: fixed;
    justify-content: space-between;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 0;
`
);

const IconButtonPrimary = styled(IconButton)(
  ({ theme }) => `
    background: ${theme.colors.alpha.trueWhite[10]};
    color: ${theme.colors.alpha.trueWhite[70]};
    padding: 0;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    margin-left: ${theme.spacing(2)};

    &.active,
    &:active,
    &:hover {
      background: ${alpha(theme.colors.alpha.trueWhite[30], 0.2)};
      color: ${theme.colors.alpha.trueWhite[100]};
    }
`
);

function Header() {
  const [openSidebar, setOpenDrawer] = useState(false);

  const handleSidebar = () => {
    setOpenDrawer(!openSidebar);
  };
  const closeSidebar = () => {
    setOpenDrawer(false);
  };
  return (
    <HeaderWrapper>
      <Box display="flex" alignItems="center">
        <Logo />

      </Box>
      <Box display="flex" alignItems="center">
        <HeaderUserbox />
        <Hidden lgUp>
            <Tooltip arrow title="Menu">
            <IconButtonPrimary sx={{ color: "#3086EC" ,'&:hover': {color: "#3086EC"}}}
              color="primary"
              onClick={handleSidebar}
              id="account-button"

            >
              {!openSidebar ? (
                <MenuTwoToneIcon
                  style={{
                    color: "#3087EC",
                  }}
                />
              ) : (
                <CloseTwoToneIcon
                  style={{
                    color: "black",
                  }}
                />
              )}
            </IconButtonPrimary>
          </Tooltip>
        </Hidden>
      </Box>
      <SidebarMobile open={openSidebar} close={closeSidebar} />
    </HeaderWrapper>
  );
}

export default Header;
