import React from 'react'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import {
  ClosedalBox,
  ClosedalButton,
  DeleteFilesModal,
  DeleteFilesModalBox,
  DeleteFilesModalConfirmButton,
  DeleteFilesModalTitle,
} from './indexStyled'

export function DeleteProfileImage({ open, setOpen }: any) {
  return (
    <>
      <DeleteFilesModal open={open}>
        <DeleteFilesModalBox>
          {/* close modal box */}
          <ClosedalBox>
            <ClosedalButton disableRipple onClick={setOpen}>
              <HighlightOffOutlinedIcon />
            </ClosedalButton>
          </ClosedalBox>
          {/* close modal box */}
          {/* title */}
          <DeleteFilesModalTitle>
            Are you sure you want to Delete this Image?
          </DeleteFilesModalTitle>
          {/* title */}
          {/* confirm button */}
          <DeleteFilesModalConfirmButton>Confirm</DeleteFilesModalConfirmButton>
          {/* confirm button */}
        </DeleteFilesModalBox>
      </DeleteFilesModal>
    </>
  )
}

export function DeleteIdProof({ open, setOpen }: any) {
  return (
    <>
      <DeleteFilesModal open={open}>
        <DeleteFilesModalBox>
          {/* close modal box */}
          <ClosedalBox>
            <ClosedalButton disableRipple onClick={setOpen}>
              <HighlightOffOutlinedIcon />
            </ClosedalButton>
          </ClosedalBox>
          {/* close modal box */}
          {/* title */}
          <DeleteFilesModalTitle>
            Are you sure you want to Delete this Image?
          </DeleteFilesModalTitle>
          {/* title */}
          {/* confirm button */}
          <DeleteFilesModalConfirmButton>Confirm</DeleteFilesModalConfirmButton>
          {/* confirm button */}
        </DeleteFilesModalBox>
      </DeleteFilesModal>
    </>
  )
}
