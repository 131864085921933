import React from 'react'

const Mail = () => {
  return (
    <div><svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 0H0V16H20V0ZM18 4L10 9L2 4V2L10 7L18 2V4Z" fill="white"/>
    </svg>
    </div>
  )
}

export default Mail