import { Chip, Stack, TableCell, TableRow, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'

// table add filter button
export const AddFilterStack = styled(Stack)`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 19px;
`

export const ListContainerStack = styled(Stack)`
    // padding: 15px 10px;
    border-radius: 5px;
    box-shadow: 0px 9px 16px rgba(159, 162, 191, 0.18),
        0px 9px 16px rgba(159, 162, 191, 0.18),
        0px 2px 2px rgba(159, 162, 191, 0.32);
`;

export const ListTitle = styled(Stack)`
    padding: 15px 10px;
    margin: 10px 0;
    border-bottom: 1px solid #EEEEEE;
`;

export const ListBody = styled(Stack)`
    padding: 0 10px;
    margin: 10px 0;
`;

export const TabBox = styled(Box)`
    padding: 10px 20px;
`;

export const IconContainer = styled(Box)`
    padding: 10px 14px;
    border-radius: 50%;
    background: #ECFBE6;
    display: inline-block;
`;

export const DownloadIconContainer = styled(Box)`
    padding: 4px 5px;
    border-radius: 25%;
    background: #E8F1FF;
    display: flex;
    justify-content: center;
    align_items: center;
    margin-left: -14px;
`;

// table container
export const TableContainerStack = styled(Stack)`
  font-size: 15px;
  width: 98%;
  height: auto;
  margin-left: 19px;
  margin-right: 19px;
  margin-bottom: 16px;
  box-shadow: 0px 9px 16px rgba(159, 162, 191, 0.18),
    0px 9px 16px rgba(159, 162, 191, 0.18),
    0px 2px 2px rgba(159, 162, 191, 0.32);
  border-radius: 6px;
  //   display: grid;
  //   grid-template-rows: 15% 73% 12%;
  display: flex;
  flex-direction: column;
`
export const TableBox = styled(Stack)`
  width: 100%;
  height: auto;
  // overflow: hidden;
  margin-top: 0;
`
export const TablePaginationBox = styled(Stack)`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ListPaginationBox = styled(Stack)`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TableRowBox = styled(TableRow)`
  // height: 80px;
  //   background: #ffffff;
  :hover {
    background: rgba(157, 191, 242, 0.25);
  }
`
export const TableColumn = styled(Typography)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  //   color: #223354;
`
export const TableDataCells = styled(Typography)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  //   color: #223354;
`
export const TableDataCellUptime = styled(Typography)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  //   color: #223354;
  opacity: 0.5;
`
export const StatusLive = styled(Chip)`
  color: #3bb900;
  background: #ecfbe6;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  border-radius: 3px;
  text-transform: uppercase;
`
export const StatusCompleted = styled(Chip)`
  color: #f4002c;
  background: #fbebe6;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  border-radius: 3px;
  text-transform: uppercase;
`
export const TableAction = styled(Box)`
  width: 100%;
  height: 100%;
  dispkay: flex;
  justify-content: space-between;
`

////////////////////////////
export const TableHeadCells = styled(TableCell)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  //   color: #223354;
//   background: #e6e8eB;
`
export const TableCellsHeader = styled(Typography)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  //   color: #223354;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
export const TextElipsis = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 70%;
`

