import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
// import { Grid, Typography } from '@mui/material'

import {
  WebhookDrawer,
  WebHookDrawerCloseIcon,
  WebHookHitsStack,
  WebHookHitsTitle,
} from './WebHookHitsDrawerStyled'

function WebHookHitsDrawer({ openDrawer, closeDrawer }) {
  return (
    <>
      <WebhookDrawer
        anchor="right"
        open={openDrawer}
        BackdropProps={{ invisible: false }}
      >
        {/* haeder */}
        <WebHookHitsStack direction="row">
          <WebHookHitsTitle>Webhook Hits</WebHookHitsTitle>
          <WebHookDrawerCloseIcon
            color="primary"
            onClick={closeDrawer}
            disableRipple
          >
            <HighlightOffOutlinedIcon
              style={{ height: 'inherit', width: 'inherit' }}
            />
          </WebHookDrawerCloseIcon>
        </WebHookHitsStack>
        {/* haeder */}

        {/* <GridContainer container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">Header :</Typography>
            <GridFieldBox></GridFieldBox>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Header :</Typography>
            <GridFieldBox></GridFieldBox>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Header :</Typography>
            <GridFieldBox></GridFieldBox>
          </Grid>
        </GridContainer> */}
      </WebhookDrawer>
    </>
  )
}

export default WebHookHitsDrawer
