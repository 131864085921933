import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TablePagination from "@mui/material/TablePagination";
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import { RootState } from 'src/store';
import CloseAlert from 'src/components/AlertDialog';
// import CustomDialogBox from 'src/components/CustomDialog';
// import CalendarIcon from 'src/assets/icons/calendarIcon';
import {
     editTableData1,editTableData,deleteTableData
} from '../../slices/apiKeys';
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { Box } from '@mui/system';

export default function BasicTable() {
    const [openDeleteDailog, setOpenDeleteDailog] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [storeOptionId, setStoreOptionId] = React.useState()
    const open = Boolean(anchorEl);
    const tableData: any = [
        {
          id: "radkjfdkfjdjxocUD",
          region: "US",
          domain: "asdf.ingressify.io",
          description: "",
          tls:"*.ingressify.io",
          status: "",
          date: "2mo ago",
        },
      ];
    
    
    const dispatch = useDispatch();

    const { meta } = useSelector((state: RootState) => state.apiKeys)

    const handleChangePage = (event: unknown, newPage: number) => {
        const data = {
            page: newPage + 1,
            per_page: meta.per_page,
        }
        dispatch(editTableData1(data))
    }
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const data = {
            page: 1,
            per_page: event.target.value,
        }
        dispatch(editTableData1(data))
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseDeleteDailog = () => {
        setOpenDeleteDailog(false);
        handleClose();
    };

    useEffect(()=>{
        dispatch(editTableData())

    },[dispatch])

    const handleClickOption = (event: React.MouseEvent<HTMLElement>, id: any) => {
        setStoreOptionId(id);
        setAnchorEl(event.currentTarget);

    };


    //    const  handleGenerateKey=()=>{

    //    }

    // const handleClickOpen = (id: any) => {
    //     setOpenDailog(true);
    //     // setDeleteID(id)
    // };
    const handleDeleteTableData = () => {
        handleClose()
        // setDeleteNotification(true)
        const data = {
            page: meta.page,
            per_page: meta.per_page,
            page_count: meta.page_count,
            total_count: meta.total_count
        }
        dispatch(deleteTableData(storeOptionId, data))
        setOpenDeleteDailog(false)
    }


    //const [openGenerateKeyDetails, setOpenGenerateKeyDetails] = React.useState(false);
    // setGenerateKeyDetails



    

    // const expiryOnChangeHandler = (newValue) => {
    //     const currentDate = new Date().toLocaleDateString('en-CA');
    //     const selectedDate = new Date(newValue);
    //     const date = moment(selectedDate).format("YYYY-MM-DD");

    //     if (currentDate > date) {
    //         dispatch(newExiryValue(null));
    //         dispatch(FailureSnackbar({ message: "Please enter a valid date", open: true }))
    //     } else {
    //         dispatch(newExiryValue(new Date(date).toISOString()));
    //     }
    // }

    // React.useEffect(() => {
    //     dispatch(editTableData())
    // }, [dispatch])

    return (
        <>
                        <CloseAlert
                    id={handleDeleteTableData}
                    openCloseDialog={openDeleteDailog}
                    setOpenCloseDialog={handleCloseDeleteDailog}
                    accept={`Yes`}
                    cancel={`No`}
                    title={`Are you sure want to delete this member?`}
                    mainTitle={`Delete Member`}
                    subTitle={
                        "This action cannot be undone. Please make sure you are performing the correct operation."
                    }
                    textColor={"#FF5A5A"}
                    BackgroundColor={"#FF5A5A"}
                />

                <>
                        {/* <Hidden smDown> */}
                            <Paper >
                                <TableContainer sx={{ height: '40vh', width: "100%",mt:'25px' }}>
                                    <Table stickyHeader sx={{ width: "100%" }}>
                                        <TableHead>
                                            <TableRow>
                                                {/* <TableCell align="center">  <Checkbox {...label} /></TableCell> */}
                                                <TableCell width="15%" align="left" sx={{zIndex:"1",color:'rgb(48, 135, 236)'}}>ID</TableCell>
                                                <TableCell width="12%" align="left" sx={{zIndex:"1",color:'rgb(48, 135, 236)'}}>Region</TableCell>

                                                <TableCell width="12%" align="left" sx={{zIndex:"1",color:'rgb(48, 135, 236)'}} >Domain</TableCell>
                                                <TableCell width="12%" align="left" sx={{zIndex:"1",color:'rgb(48, 135, 236)'}}>Description</TableCell>

                                                <TableCell width="12%" align="left" sx={{zIndex:"1",color:'rgb(48, 135, 236)'}}>TLS</TableCell>
                                                <TableCell width="12%" align="left" sx={{zIndex:"1",color:'rgb(48, 135, 236)'}}>Status</TableCell>
                                                <TableCell width="12%" align="left" sx={{zIndex:"1",color:'rgb(48, 135, 236)'}}>Created</TableCell>
                                                <TableCell width="12%" align="left" sx={{zIndex:"1",color:'rgb(48, 135, 236)'}}>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {tableData?.map((row:any) => (
                                                    <TableRow
                                                        key={row.name}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row" align="left" sx={{ maxWidth: "200px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {row.id.substring(0,3)}...{row.id.substring(row.id.length-5)}
                          <IconButton
                            onClick={() => {
                              navigator.clipboard.writeText(row?.id);
                            //   handleCopied("");
                            }}
                          >
                            <FileCopyOutlinedIcon
                              sx={{ width: "20px", mt: "3px" }}
                            />
                          </IconButton>
                        </Box>
                                                        </TableCell>
                                                        <TableCell align="left">{row.region}</TableCell>
                                                        <TableCell align="left">{row.domain}</TableCell>
                                                        <TableCell align="left">{row.description}</TableCell>
                                                        <TableCell align="left">{row.tls}</TableCell>
                                                        <TableCell align="left">{row.status}</TableCell>

                                                        <TableCell align="left" >
                                                        {row.date}
                                                                {/* <MenuItem onClick={() => extendExpiryHandler(false)}>Generate key</MenuItem>


                                                                <MenuItem onClick={() => setOpenDeleteDailog(true)}>Delete</MenuItem>
                                                                {(new Date(appDetails[0]?.expiry).toLocaleDateString('en-CA') >= new Date().toLocaleDateString('en-CA')) &&
                                                                    <MenuItem onClick={() => extendExpiryHandler()}>Extend expiry</MenuItem>
                                                                } */}
                                                        </TableCell>

                                                        <TableCell align="left" >
                                                            <IconButton
                                                                id="fade-button"
                                                                onClick={(event) => handleClickOption(event, row.appId)}
                                                                aria-controls={open ? 'fade-menu' : undefined}
                                                                aria-haspopup="true"
                                                                aria-expanded={open ? 'true' : undefined}

                                                            ><MoreVertIcon /></IconButton>
                                                            <Menu
                                                                id="apikeys-menu"
                                                                MenuListProps={{
                                                                    'aria-labelledby': 'fade-button',
                                                                }}
                                                                anchorEl={anchorEl}
                                                                open={open }
                                                                onClose={handleClose}
                                                                TransitionComponent={Fade}
                                                                sx={{ mt: "-15px" }}
                                                                PaperProps={{
                                                                    style: {
                                                                        padding: 0,
                                                                        margin: 0,
                                                                        transform: 'translateX(-30px)',
                                                                        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 2px 2px',
                                                                    },
                                                                }}
                                                            >


                                                                <MenuItem onClick={() => setOpenDeleteDailog(true)}>Delete</MenuItem>
                                                            </Menu>
                                                        </TableCell>


                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer >
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 100]}
                                    sx={{ margin: '10px' }}
                                    component="div"
                                    count={meta.total_count}
                                    rowsPerPage={meta.per_page}
                                    SelectProps={{
                                        inputProps: {
                                            "aria-label": "rows per page"
                                        },
                                    }}
                                    page={meta.page - 1}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    backIconButtonProps={{
                                        style: {
                                            background: 'rgb(221,239,255,0.7)',
                                            color: '#0062FD',
                                            borderRadius: '50%',
                                            marginRight: '2px',
                                        },
                                    }}
                                    nextIconButtonProps={{
                                        style: {
                                            background: 'rgb(221,239,255,0.7)',
                                            color: '#0062FD',
                                            borderRadius: '50%',
                                            marginLeft: '2px',
                                        },
                                    }}
                                />

                            </Paper>
                        {/* </Hidden> */}
                    
                </>
            
        </>
    );
}


