import React, { useEffect } from "react";
import {
  Divider,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import AddIcon from "@mui/icons-material/Add";
import Loader from 'src/components/Loader/Loader';
import "./Webhook.css";
import { useSelector, useDispatch } from "react-redux";
import {
  webhookInfo,
  webhookeditstore,
  webHookDataPost,
  fetchAppName,
  setOpenWebhook,
  webhookMode1,
  webHookEditPost,
  setStoreId1,
  setHookNamesObject,
} from "../../slices/WebHook";
import { RootState } from "../../store";
import helper from "src/services/helper";
import CustomDialogBox from "src/components/CustomDialog";
import { ButtonPrimary } from "src/components/CustomButtons";

const useStyles = makeStyles((theme) => ({
  labelcolor: {
    color: "black",
  },
  buttonStyle: {
    textTransform: "none",
    '@media(max-Width: 600px)': {
      padding: "8px 14px",
      fontSize: "12px"
    }
  },
  webhookTitle: {
    fontWeight: "bold",
    height: '100%',
    fontSize: "22px",
    display: "flex",
    color: '#3086EC',
    borderBottom: '1px solid #C4C4C4',
    background: "#ffffff",
    justifyContent: "space-between",
    '@media(max-Width: 600px)': {
      fontSize: "16px",
    }
  },
  col: {
    display: "flex",
    flexDirection: "column",
    // width: "22rem",
    rowGap: "1.3rem",
    marginTop: "0.2rem",
  },
  submitbutton: {
    backgroundColor: "#0096FF",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#0096FF",
      color: "#ffffff",
    },
  },
  nobutton: {
    backgroundColor: "#F76E11",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#F76E11",
      color: "#ffffff",
    },
  },
  dialog: {
    width: "25rem",
    '@media(max-Width: 600px)': {
      width: '100%'
    }
  },
  dialogCloseButton: {
    paddingRight: '5px',
    paddingLeft: '5px',
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '-10px',
    marginTop: '-5px',
    minWidth: '32px'
  }
}));

export default function AddWebHook() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { webhook, getAppName, openWebhook, webhookMode, storeId, hookNamesObj, dialogLoader } = useSelector(
    (state: RootState) => state.WebHook
  );
  const [urlValidate, setUrlValidate] = React.useState(false);
  const [descValidate, setDescValidate] = React.useState(false);
  const [nameValidate, setNameValidate] = React.useState(false);
  const [authValidate, setAuthValidate] = React.useState(false);
  const [hookValidate, setHookValidate] = React.useState(false);
  const theme = useTheme();
  // eslint-disable-next-line
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleClose = () => {
    dispatch(setOpenWebhook(false))
    const emptydata = {
      url: "",
      appId: "",
      description: "",
      authorizationHeader: "",
      hookNames: [],
      button: true
    };
    dispatch(webhookeditstore(emptydata));
    resetItems()
    dispatch(webhookMode1(""))
    dispatch(setStoreId1(""));
  };

  const handleSubmit = () => {
    if (helper.validateUrl(webhook.url) !== "") {
      setUrlValidate(true);
      setTimeout(() => {
        setUrlValidate(false);
      }, 3000)
    } else if (webhook.appId === "" || webhook.appId === undefined) {
      setNameValidate(true);
      setTimeout(() => {
        setNameValidate(false);
      }, 3000)
    }
    else if (webhook.description?.length <= 2) {
      setDescValidate(true);
      setTimeout(() => {
        setDescValidate(false);
      }, 3000)
    }
    else if (webhook.authorizationHeader?.length <= 2) {
      setAuthValidate(true);
      setTimeout(() => {
        setAuthValidate(false);
      }, 3000)
    } else if (webhook.hookNames?.length === 0) {
      setHookValidate(true);
      setTimeout(() => {
        setHookValidate(false);
      }, 3000)
    } else {
      if (webhookMode === "edit") {
        const id = storeId;
        const data = {
          id: id,
          url: webhook.url,
          appId: webhook.appId,
          description: webhook.description,
          authorizationHeader: webhook.authorizationHeader,
          hookNames: webhook.hookNames,
        };
        dispatch(webHookEditPost(data));
      } else {
        const data = {
          url: webhook.url,
          appId: webhook.appId,
          description: webhook.description,
          authorizationHeader: webhook.authorizationHeader,
          hookNames: webhook.hookNames,
        };
        dispatch(webHookDataPost(data));
      }
      handleClose();
      const emptydata = {
        url: "",
        appId: "",
        description: "",
        authorizationHeader: "",
        hookNames: [],
        button: true
      };
      dispatch(webhookeditstore(emptydata));
    }
  };

  const resetItems = () => {
    const checkboxmark = hookNamesObj.map((item) => ({ ...item, checked: false }));
    dispatch(setHookNamesObject(checkboxmark));
  }

  const addWebHook = (event: { target: { name: any; value: any } }) => {
    dispatch(
      webhookInfo({ key: event.target.name, value: event.target.value })
    );

    const webhookData = JSON.parse(JSON.stringify(webhook));
    webhookData[event.target.name] = event.target.value;
  };

  // From Application API Fetch AppName
  useEffect(() => {
    dispatch(fetchAppName());
  }, [dispatch]);

  const handleChange = (event) => {
    const { value } = event.currentTarget;
    const checkboxmark = hookNamesObj.map((item) => {
      return item.name === event.target.value ? { ...item, checked: !item.checked } : { ...item };
    })
    dispatch(setHookNamesObject(checkboxmark));
    let checkdata = [];
    console.log("checkbox1", checkdata, event.target.checked)
    if (event.target.checked === true) {
      checkdata = [...webhook.hookNames, value];
    }
    else {
      if (event.target.checked === false) {
        checkdata = [...webhook.hookNames]
        const removeItem = checkdata.indexOf(event.target.value)
        checkdata.splice(removeItem, 1)
      }
    }
    dispatch(webhookInfo({ key: "hookNames", value: checkdata }));
    const webhookData = JSON.parse(JSON.stringify(webhook));
    webhookData.hookNames = checkdata;
  };

  return (
    <CustomDialogBox
      open={openWebhook}
      setOpen={handleClose}
      background={"transparent"}
      closeIconId="close-view-resource-btn2"
      width={{
        xs: "90vw",
        sm: "50vw",
        md: "50vw",
        lg: "30vw",
        xl: "30vw",
      }}
      height={{
        xs: "65vh",
        sm: "60vh",
        md: "60vh",
        lg: "60vh",
        xl: "60vh",
      }}
      childrens={
        <Box sx={{ height: '100%', width: "100%" }}>
          {dialogLoader ? <Box sx={{ height: '100%' }}><Loader /></Box> : <>
            <Box >
              <Typography sx={{ color: '#3086EC', m: 0, p: '16px 16px 12px 16px', fontSize: "16px", }}>
                {webhookMode === "edit" ? "Edit webhook endpoint" : "Add webhook endpoint"}
              </Typography>
              <Divider sx={{ width: '100%' }} />
              {/* <DialogTitle className={classes.webhookTitle}>
                {webhookMode === "edit" ? "Edit webhook endpoint" : "Add webhook endpoint"}
              </DialogTitle> */}
            </Box>
            <Box sx={{ height: '75%', width: "100%", overflow: "scroll" }}>
              <DialogContent>
                <DialogContentText>
                  <form>
                    <Box className={classes.col} >
                      <InputLabel className={classes.labelcolor}>
                        Endpoint URL
                      </InputLabel>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        sx={{ mt: "-1rem" }}
                        onChange={(e) => addWebHook(e)}
                        name="url"
                        value={webhook.url}
                        error={urlValidate}
                        helperText={urlValidate && "Please enter a valid url"}
                      />
                      <InputLabel
                        id="demo-simple-select-label"
                        className={classes.labelcolor}
                      >
                        Application name
                      </InputLabel>
                      <Box sx={{ width: '100%' }}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          size="small"
                          sx={{ mt: "-1rem", width: '100%' }}
                          value={webhook?.appId}
                          name="appId"
                          error={nameValidate}
                          defaultValue=""
                          onChange={(e) => addWebHook(e)}
                          disabled={webhookMode === "edit"}
                        >
                          {getAppName?.length &&
                            getAppName.map((option, index) => (
                              <MenuItem key={index} value={option.appId}>{option.name}</MenuItem>
                            ))}
                        </Select>
                        {nameValidate && <Typography sx={{ color: 'red' }}>Please select application name</Typography>}
                      </Box>
                      <InputLabel className={classes.labelcolor}>
                        Description
                      </InputLabel>
                      <TextField
                        id="standard-basic"
                        multiline
                        defaultValue=""
                        style={{ marginTop: "-1rem" }}
                        onChange={(e) => addWebHook(e)}
                        name="description"
                        value={webhook.description}
                        error={descValidate}
                        helperText={descValidate && "Please enter minimum 3 characters"}
                        InputProps={{
                          inputComponent: TextareaAutosize,
                          sx: { height: "auto" }
                        }}
                      />
                      <InputLabel className={classes.labelcolor}>
                        Authorization header
                      </InputLabel>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        sx={{ mt: "-1rem" }}
                        onChange={(e) => addWebHook(e)}
                        name="authorizationHeader"
                        value={webhook.authorizationHeader}
                        error={authValidate}
                        helperText={authValidate && "Please enter minimum 3 characters"}
                      />
                      <Box>
                        <InputLabel className={classes.labelcolor}>HookName</InputLabel>
                        {hookValidate && <Typography sx={{ color: 'red' }}>Hook name required</Typography>}
                      </Box>
                      <Box>
                        {hookNamesObj.map((item, index) => (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                              id={item.id}
                              value={item.id}
                              // checked={selected.some((val) => val === item.id)}
                              checked={item.checked}
                              onChange={handleChange}
                              key={index}
                            />
                            <label htmlFor={item.id} style={{ color: "black" }}>{item.name}</label>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </form>
                </DialogContentText>
              </DialogContent>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <DialogActions
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <ButtonPrimary id='save-webhooks' responsive={false} onClick={handleSubmit}>
                  {webhookMode === "" && <AddIcon />}
                  {webhookMode === "edit" ? "Update" : "Create"}
                </ButtonPrimary>
              </DialogActions>
            </Box>
          </>}
        </Box>
      }
    />
  );
}
