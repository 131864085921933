import React from 'react'

const CloudLock = () => {
    return (
        <div>
        <svg width="100%" height="100%" viewBox="0 0 301 395" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M105.425 216.113V226.364H95.1738V216.113H105.425Z" fill="#FDB714" />
                <path d="M155.588 224.701V234.953H145.337V224.701H155.588Z" fill="#FDB714" />
                <path d="M205.208 216.065V226.316H194.957V216.065H205.208Z" fill="#FDB714" />
                <path d="M128.135 190.501V196.58H113.662V190.501H128.135Z" fill="#FDB714" />
                <path d="M186.644 190.514V196.593H172.171V190.514H186.644Z" fill="#FDB714" />
                <path d="M157.699 189.843V195.921H143.226V189.843H157.699Z" fill="#FDB714" />
                <path d="M179.445 187.556V222.131H203.969V248.959" stroke="#FDB714" stroke-width="5.2" stroke-linejoin="round" />
                <path d="M150.5 186.17V257.271" stroke="#FDB714" stroke-width="5.2" stroke-miterlimit="10" />
                <path d="M120.952 187.556V222.131H96.4277V248.959" stroke="#FDB714" stroke-width="5.2" stroke-linejoin="round" />
                <path d="M295.227 138.302C295.227 140.769 295.046 143.189 294.683 145.55C291.196 168.547 271.362 186.171 247.413 186.171H60.1508C36.2963 186.171 16.0333 170.794 8.70656 149.404C6.80582 143.867 5.77344 137.92 5.77344 131.734C5.77344 101.679 30.1152 77.3044 60.1508 77.3044C64.8122 77.3044 69.341 77.8882 73.6683 78.9965C80.2509 37.2103 116.4 5.26074 160.001 5.26074C207.501 5.26074 246.152 43.1863 247.385 90.4432H247.413C273.818 90.4432 295.227 111.871 295.227 138.302Z" stroke="#35BBC5" stroke-width="10.4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M161.044 384.146C161.044 390.019 156.283 394.779 150.409 394.779C144.536 394.779 139.774 390.019 139.774 384.146C139.774 378.272 144.536 373.511 150.409 373.511C156.283 373.511 161.044 378.272 161.044 384.146Z" fill="#FDB714" />
                <path d="M75.5182 384.146C75.5182 387.628 72.6936 390.453 69.2105 390.453C65.7286 390.453 62.9053 387.628 62.9053 384.146C62.9053 380.662 65.7286 377.84 69.2105 377.84C72.6936 377.84 75.5182 380.662 75.5182 384.146Z" stroke="#FDB714" stroke-width="3.9" stroke-miterlimit="10" />
                <path d="M237.027 384.146C237.027 387.628 234.202 390.453 230.72 390.453C227.238 390.453 224.415 387.628 224.415 384.146C224.415 380.662 227.238 377.84 230.72 377.84C234.202 377.84 237.027 380.662 237.027 384.146Z" stroke="#FDB714" stroke-width="3.9" stroke-miterlimit="10" />
                <path d="M150.41 382.74V248.957" stroke="#FDB714" stroke-width="5.2" stroke-miterlimit="10" />
                <path d="M76.4048 384.146H224.414" stroke="#FDB714" stroke-width="5.2" stroke-miterlimit="10" />
                <path d="M238.599 364.316H61.1953V329.452H238.599V364.316Z" fill="#393D4D" />
                <path d="M215.922 339.334C215.922 341.245 214.374 342.792 212.463 342.792C210.554 342.792 209.004 341.245 209.004 339.334C209.004 337.424 210.554 335.876 212.463 335.876C214.374 335.876 215.922 337.424 215.922 339.334Z" fill="white" />
                <path d="M228.774 339.334C228.774 341.245 227.226 342.792 225.315 342.792C223.405 342.792 221.856 341.245 221.856 339.334C221.856 337.424 223.405 335.876 225.315 335.876C227.226 335.876 228.774 337.424 228.774 339.334Z" fill="white" />
                <path d="M84.4699 348.564H70.7378V345.206H84.4699V348.564Z" fill="white" />
                <path d="M84.4699 356.114H70.7378V352.756H84.4699V356.114Z" fill="white" />
                <path d="M84.4699 341.014H70.7378V337.657H84.4699V341.014Z" fill="white" />
                <path d="M238.599 322.447H61.1953V287.58H238.599V322.447Z" fill="#393D4D" />
                <path d="M215.922 297.464C215.922 299.374 214.374 300.921 212.463 300.921C210.554 300.921 209.004 299.374 209.004 297.464C209.004 295.553 210.554 294.003 212.463 294.003C214.374 294.003 215.922 295.553 215.922 297.464Z" fill="white" />
                <path d="M228.774 297.464C228.774 299.374 227.226 300.921 225.315 300.921C223.405 300.921 221.856 299.374 221.856 297.464C221.856 295.553 223.405 294.003 225.315 294.003C227.226 294.003 228.774 295.553 228.774 297.464Z" fill="white" />
                <path d="M84.4699 306.694H70.7378V303.336H84.4699V306.694Z" fill="white" />
                <path d="M84.4699 314.243H70.7378V310.886H84.4699V314.243Z" fill="white" />
                <path d="M84.4699 299.141H70.7378V295.784H84.4699V299.141Z" fill="white" />
                <path d="M238.599 280.574H61.1953V245.709H238.599V280.574Z" fill="#393D4D" />
                <path d="M215.922 255.591C215.922 257.501 214.374 259.051 212.463 259.051C210.554 259.051 209.004 257.501 209.004 255.591C209.004 253.68 210.554 252.133 212.463 252.133C214.374 252.133 215.922 253.68 215.922 255.591Z" fill="white" />
                <path d="M228.774 255.591C228.774 257.501 227.226 259.051 225.315 259.051C223.405 259.051 221.856 257.501 221.856 255.591C221.856 253.68 223.405 252.133 225.315 252.133C227.226 252.133 228.774 253.68 228.774 255.591Z" fill="white" />
                <path d="M84.4699 264.821H70.7378V261.463H84.4699V264.821Z" fill="white" />
                <path d="M84.4699 272.373H70.7378V269.015H84.4699V272.373Z" fill="white" />
                <path d="M84.4699 257.272H70.7378V253.914H84.4699V257.272Z" fill="white" />
                <path d="M172.563 90.559V86.8941C172.563 74.726 162.667 64.8264 150.502 64.8264C138.334 64.8264 128.434 74.726 128.434 86.8941V90.559H124.478V86.8941C124.478 72.544 136.153 60.8702 150.502 60.8702C164.852 60.8702 176.529 72.544 176.529 86.8941V90.559H172.563Z" fill="#FDB714" />
                <path d="M183.081 150.512H117.917C116.221 150.512 114.846 149.139 114.846 147.445V94.8197C114.846 93.1237 116.221 91.7473 117.917 91.7473H183.081C184.779 91.7473 186.154 93.1237 186.154 94.8197V147.445C186.154 149.139 184.779 150.512 183.081 150.512Z" stroke="#FDB714" stroke-width="5.382" stroke-miterlimit="10" />
                <path d="M158.431 136.505L154.662 119.775C156.746 118.414 158.128 116.062 158.128 113.386C158.128 109.17 154.712 105.755 150.498 105.755C146.284 105.755 142.867 109.17 142.867 113.386C142.867 116.062 144.249 118.414 146.334 119.775L142.566 136.505H158.431Z" stroke="#FDB714" stroke-width="3.588" stroke-linejoin="round" />
            </svg>

        </div>
    )
}

export default CloudLock