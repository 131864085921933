import { createSlice } from "@reduxjs/toolkit";
import axios from '../utils/axios';
interface portegoDev {
    activeUserCount: number;
    activeUserCountTag: string;
    discription: string;
    recording: number;
    RTMP: number;
    transcription: number;
    PSTNOutboundFirst: number,
    PSTNOutboundSecond: number,
    PSTNInbound: number,
    SIPInOut: number;

}


interface outstanding {  //top of the table data 
    billingDate: string,
    id: string,
    userId: string,
    status: string,
    amount: {
        unit: string,
        value: number,
    }
}

interface invoice { // table data array of objects
    invoiceId: string,
    status: string, // "PENDING/PAID/DEFAULTED",
    amount: {
        unit: string,  //"$",
        value: number, // 23.23
    },
    userId: string, 
    id: string, 
    issueDate: string,
    dueDate: string,
}


interface invoiceMeta {
    page: number, //5
    per_page: number//20,
    page_count: number, //20,
    total_count: number, //  521,
}


interface addPayment {
    name: string;
    cardNumber: number;
    expirationDate: string;
    CVCcode: number;
    NameOnTheCard: string;
    zipCose: number;
    country: string;
    couponCode: string;
}


interface billing {
    portegoDev: portegoDev;
    addPayment: addPayment;

    outstanding: outstanding[];
    invoice: invoice[];
    invoiceMeta: invoiceMeta;

}

const initialState: billing = {

    portegoDev: {
        activeUserCount: 25,
        activeUserCountTag: "Free",
        discription: "Your Plan includes a fixed number of monthly active users (MAU*).If you exceed your includes MAU,we will apply an overage charge of $0.99 per MAU",
        recording: 0.01,
        RTMP: 0.01,
        transcription: 0.01,
        PSTNOutboundFirst: 0.01,
        PSTNOutboundSecond: 0.03,
        PSTNInbound: 2,
        SIPInOut: 0.06,
    },

    addPayment: {
        name: "",
        cardNumber: null,
        expirationDate: "",
        CVCcode: null,
        NameOnTheCard: "",
        zipCose: null,
        country: "",
        couponCode: "",
    },
    outstanding: [],
    invoice: [],
    invoiceMeta: {
        page: 0,
        per_page: 0,
        page_count: 0,
        total_count: 0,
    }



}

const slice = createSlice({
    name: "billing",
    initialState,
    reducers: {
        portegoDevReducer(state, action) {
            state.portegoDev = action.payload
        },
        addPaymentReducer(state, action) {
            state.addPayment[action.payload.key] = action.payload.value;
        },
        outstandingReducer(state, action) {
            state.outstanding = action.payload;
        },
        invoiceReducer(state, action) {
            state.invoice = action.payload.data;
            state.invoiceMeta = action?.payload?.meta;
        }
    }
})

const BASE_URL = process.env.REACT_APP_API_URL;

export const editPortegoDev = (data: any) => async (dispatch: any) => {
    dispatch(slice.actions.portegoDevReducer(data))
}

export const editOutstanding = (data: any) => async (dispatch: any) => {
    const outstandingResponse = await axios.get(` ${BASE_URL}/api/v1/billing/outstanding`);
    dispatch(slice.actions.outstandingReducer(outstandingResponse.data.data));
}

export const editInvoice = (data: any) => async (dispatch: any) => {
    const invoice = await axios.get(`${BASE_URL}/api/v1/billing/invoices?page=${data}&per_page=5`);
    dispatch(slice.actions.invoiceReducer(invoice.data));
}


export const editAddPaymentPost = (data: any) => async (dispatch: any) => {
    dispatch(slice.actions.addPaymentReducer(data))
}


export const reducer = slice.reducer;
export default slice;



