import { createSlice } from '@reduxjs/toolkit'
// import { patchMethod, getMethod } from 'src/services/requesthandler'
import axios from 'axios'

interface profile {
  profileData: {
    firstName: string
    lastName: string
    contact: string
    email: string
    organizationName?: string
    profile_photo?: string
    id_proof?: string
    address: {
      addressLine1: string
      addressLine2: string
      landmark: string
      district: string
      state: string
      pincode: string
      country: string
    }
  }
}

interface loadingStates {
  setLoading: boolean
}

interface stateandcountry {
  states: string[]
  country: string[]
}

interface profileUploads {
  profile_photo?: File
  id_proof?: File
}
interface PasswordResetdailo {
  resetPasswordDailog: boolean
}

interface root {
  profileformData: profile

  stateCountryData: stateandcountry
  profileFiles: profileUploads
  passwordReset: PasswordResetdailo
  loadingState: loadingStates
}

const initialState: root = {
  profileformData: {
    profileData: {
      firstName: '',
      lastName: '',
      contact: '',
      email: '',
      organizationName: '',
      profile_photo: '',
      id_proof: '',
      address: {
        addressLine1: '',
        addressLine2: '',
        landmark: '',
        district: '',
        state: '',
        pincode: '',
        country: 'India',
      },
    },
  },

  loadingState: {
    setLoading: false,
  },
  stateCountryData: {
    states: [
      'Andhra Pradesh',
      'Arunachal Pradesh',
      'Assam',
      'Bihar',
      'Chhattisgarh',
      'Goa',
      'Gujarat',
      'Haryana',
      'Himachal Pradesh',
      'Jammu and Kashmir',
      'Jharkhand',
      'Karnataka',
      'Kerala',
      'Madhya Pradesh',
      'Maharashtra',
      'Manipur',
      'Meghalaya',
      'Mizoram',
      'Nagaland',
      'Odisha',
      'Punjab',
      'Rajasthan',
      'Sikkim',
      'Tamil Nadu',
      'Telangana',
      'Tripura',
      'Uttarakhand',
      'Uttar Pradesh',
      'West Bengal',
      'Andaman and Nicobar Islands',
      'Chandigarh',
      'Dadra and Nagar Haveli',
      'Daman and Diu',
      'Delhi',
      'Lakshadweep',
      'Puducherry',
    ],
    country: [
      'Afghanistan',
      'Albania',
      'Algeria',
      'American Samoa',
      'Andorra',
      'Angola',
      'Anguilla',
      'Antarctica',
      'Antigua and Barbuda',
      'Argentina',
      'Armenia',
      'Aruba',
      'Australia',
      'Austria',
      'Azerbaijan',
      'Bahamas (the)',
      'Bahrain',
      'Bangladesh',
      'Barbados',
      'Belarus',
      'Belgium',
      'Belize',
      'Benin',
      'Bermuda',
      'Bhutan',
      'Bolivia (Plurinational State of)',
      'Bonaire, Sint Eustatius and Saba',
      'Bosnia and Herzegovina',
      'Botswana',
      'Bouvet Island',
      'Brazil',
      'British Indian Ocean Territory (the)',
      'Brunei Darussalam',
      'Bulgaria',
      'Burkina Faso',
      'Burundi',
      'Cabo Verde',
      'Cambodia',
      'Cameroon',
      'Canada',
      'Cayman Islands (the)',
      'Central African Republic (the)',
      'Chad',
      'Chile',
      'China',
      'Christmas Island',
      'Cocos (Keeling) Islands (the)',
      'Colombia',
      'Comoros (the)',
      'Congo (the Democratic Republic of the)',
      'Congo (the)',
      'Cook Islands (the)',
      'Costa Rica',
      'Croatia',
      'Cuba',
      'Curaçao',
      'Cyprus',
      'Czechia',
      "Côte d'Ivoire",
      'Denmark',
      'Djibouti',
      'Dominica',
      'Dominican Republic (the)',
      'Ecuador',
      'Egypt',
      'El Salvador',
      'Equatorial Guinea',
      'Eritrea',
      'Estonia',
      'Eswatini',
      'Ethiopia',
      'Falkland Islands (the) [Malvinas]',
      'Faroe Islands (the)',
      'Fiji',
      'Finland',
      'France',
      'French Guiana',
      'French Polynesia',
      'French Southern Territories (the)',
      'Gabon',
      'Gambia (the)',
      'Georgia',
      'Germany',
      'Ghana',
      'Gibraltar',
      'Greece',
      'Greenland',
      'Grenada',
      'Guadeloupe',
      'Guam',
      'Guatemala',
      'Guernsey',
      'Guinea',
      'Guinea-Bissau',
      'Guyana',
      'Haiti',
      'Heard Island and McDonald Islands',
      'Holy See (the)',
      'Honduras',
      'Hong Kong',
      'Hungary',
      'Iceland',
      'India',
      'Indonesia',
      'Iran (Islamic Republic of)',
      'Iraq',
      'Ireland',
      'Isle of Man',
      'Israel',
      'Italy',
      'Jamaica',
      'Japan',
      'Jersey',
      'Jordan',
      'Kazakhstan',
      'Kenya',
      'Kiribati',
      "Korea (the Democratic People's Republic of)",
      'Korea (the Republic of)',
      'Kuwait',
      'Kyrgyzstan',
      "Lao People's Democratic Republic (the)",
      'Latvia',
      'Lebanon',
      'Lesotho',
      'Liberia',
      'Libya',
      'Liechtenstein',
      'Lithuania',
      'Luxembourg',
      'Macao',
      'Madagascar',
      'Malawi',
      'Malaysia',
      'Maldives',
      'Mali',
      'Malta',
      'Marshall Islands (the)',
      'Martinique',
      'Mauritania',
      'Mauritius',
      'Mayotte',
      'Mexico',
      'Micronesia (Federated States of)',
      'Moldova (the Republic of)',
      'Monaco',
      'Mongolia',
      'Montenegro',
      'Montserrat',
      'Morocco',
      'Mozambique',
      'Myanmar',
      'Namibia',
      'Nauru',
      'Nepal',
      'Netherlands (the)',
      'New Caledonia',
      'New Zealand',
      'Nicaragua',
      'Niger (the)',
      'Nigeria',
      'Niue',
      'Norfolk Island',
      'Northern Mariana Islands (the)',
      'Norway',
      'Oman',
      'Pakistan',
      'Palau',
      'Palestine, State of',
      'Panama',
      'Papua New Guinea',
      'Paraguay',
      'Peru',
      'Philippines (the)',
      'Pitcairn',
      'Poland',
      'Portugal',
      'Puerto Rico',
      'Qatar',
      'Republic of North Macedonia',
      'Romania',
      'Russian Federation (the)',
      'Rwanda',
      'Réunion',
      'Saint Barthélemy',
      'Saint Helena, Ascension and Tristan da Cunha',
      'Saint Kitts and Nevis',
      'Saint Lucia',
      'Saint Martin (French part)',
      'Saint Pierre and Miquelon',
      'Saint Vincent and the Grenadines',
      'Samoa',
      'San Marino',
      'Sao Tome and Principe',
      'Saudi Arabia',
      'Senegal',
      'Serbia',
      'Seychelles',
      'Sierra Leone',
      'Singapore',
      'Sint Maarten (Dutch part)',
      'Slovakia',
      'Slovenia',
      'Solomon Islands',
      'Somalia',
      'South Africa',
      'South Georgia and the South Sandwich Islands',
      'South Sudan',
      'Spain',
      'Sri Lanka',
      'Sudan (the)',
      'Suriname',
      'Svalbard and Jan Mayen',
      'Sweden',
      'Switzerland',
      'Syrian Arab Republic',
      'Taiwan',
      'Tajikistan',
      'Tanzania, United Republic of',
      'Thailand',
      'Timor-Leste',
      'Togo',
      'Tokelau',
      'Tonga',
      'Trinidad and Tobago',
      'Tunisia',
      'Turkey',
      'Turkmenistan',
      'Turks and Caicos Islands (the)',
      'Tuvalu',
      'Uganda',
      'Ukraine',
      'United Arab Emirates (the)',
      'United Kingdom of Great Britain and Northern Ireland (the)',
      'United States Minor Outlying Islands (the)',
      'United States of America (the)',
      'Uruguay',
      'Uzbekistan',
      'Vanuatu',
      'Venezuela (Bolivarian Republic of)',
      'Viet Nam',
      'Virgin Islands (British)',
      'Virgin Islands (U.S.)',
      'Wallis and Futuna',
      'Western Sahara',
      'Yemen',
      'Zambia',
      'Zimbabwe',
      'Åland Islands',
    ],
  },
  profileFiles: {
    profile_photo: undefined,
    id_proof: undefined,
  },
  passwordReset: {
    resetPasswordDailog: false,
  },
}

const slice = createSlice({
  name: 'profilePage',
  initialState,
  reducers: {
    getUserData(state, action) {
      const data = action.payload
      state.profileformData.profileData.firstName = data.firstName
      state.profileformData.profileData.lastName = data.lastName
      state.profileformData.profileData.contact = data.contact
      state.profileformData.profileData.email = data.email
      state.profileformData.profileData.organizationName = data.organizationName
        ? data.organizationName
        : ''
      state.profileformData.profileData.address.addressLine1 = data.address
        ? data.address.addressLine1
        : ''
      state.profileformData.profileData.address.addressLine2 = data.address
        ? data.address.addressLine2
        : ''
      state.profileformData.profileData.address.landmark = data.address
        ? data.address.landmark
        : ''
      state.profileformData.profileData.address.district = data.address
        ? data.address.district
        : ''
      state.profileformData.profileData.address.state = data.address
        ? data.address.state
        : ''
      state.profileformData.profileData.address.pincode = data.address
        ? data.address.pincode
        : ''
      state.profileformData.profileData.address.country = data.address
        ? data.address.country
        : 'India'
    },
    setProfileFirstName(state, action) {
      state.profileformData.profileData.firstName = action.payload
    },
    setProfileLastName(state, action) {
      state.profileformData.profileData.lastName = action.payload
    },

    setProfileCompanyName(state, action) {
      state.profileformData.profileData.organizationName = action.payload
    },
    setProfilePicture(state, action) {
      state.profileFiles.profile_photo = action.payload
      console.log(state.profileFiles.profile_photo)
    },
    setIdProof(state, action) {
      state.profileFiles.id_proof = action.payload
      console.log(state.profileFiles.id_proof)
    },
    setPasswordResetDailogBox(state) {
      state.passwordReset.resetPasswordDailog = !state.passwordReset
        .resetPasswordDailog
      console.log(state.passwordReset.resetPasswordDailog)
    },
    // address fields
    setAddressLine1(state, action) {
      state.profileformData.profileData.address.addressLine1 = action.payload
    },
    setAddressLine2(state, action) {
      state.profileformData.profileData.address.addressLine2 = action.payload
    },
    setLandmark(state, action) {
      state.profileformData.profileData.address.landmark = action.payload
    },
    setDistrict(state, action) {
      state.profileformData.profileData.address.district = action.payload
    },
    setState(state, action) {
      state.profileformData.profileData.address.state = action.payload
    },
    setPincode(state, action) {
      state.profileformData.profileData.address.pincode = action.payload
    },
    setCountry(state, action) {
      state.profileformData.profileData.address.country = action.payload
    },
    setLoadingStateTrue(state) {
      state.loadingState.setLoading = true
    },
    setLoadingStateFalse(state) {
      state.loadingState.setLoading = false
    },
  },
})

export const ProfilePageActions = slice.actions

// getting user details
export const getUserData = () => async (dispatch) => {
  console.log('getUserData')
  await axios.get('/user')
  // .then((res) => {
  //   console.log('res', res)
  //   dispatch(slice.actions.getUserData(res.data))
  // })
  // .catch((err) => {
  //   console.log(err)
  // })

  // const token = localStorage.getItem('token')
  // const api = await axios.get(`https://api.densio.in/api/v1/user`, {
  //   headers: {
  //     Authorization: 'Bearer ' + localStorage.getItem('token'),
  //   },
  // })
  // console.log(api.data.data)
  // dispatch(slice.actions.getUserData(api.data.data))
}

// updating user details
export const updateUserDetails = (data) => async (dispatch) => {
  dispatch(slice.actions.setLoadingStateTrue())
  await axios.patch('/user', data)
  // .then((res) => {
  //   if (res.status) {
  //     console.log('success')
  //   } else {
  //     console.log('failed')
  //   }
  //   dispatch(slice.actions.setLoadingStateFalse())
  // })
  // .catch((err) => {
  //   console.log(err)
  // })
}

export const reducer = slice.reducer

export default slice

// https://images.unsplash.com/photo-1638913970895-d3df59be1466?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1031&q=80
// const res = await fetch('https://api.densio.in/api/v1/user')
// const data = await res.json()
// console.log(data)
