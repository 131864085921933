import { createSlice } from '@reduxjs/toolkit'
import axios from '../../utils/axios'
interface conferencePayload {
  conferenceData: [
    {
      appId: string
      roomName: string
      roomId: string
      participantName: string
      causeCode: string
      reason: string
      cost: string
      costCode: string
      sessionId: string
      startTime: Date
      endTime: Date
      minutes: number
      status: string
    },
  ]
  meta: {
    page_count: number
    total_count: number
  }
  appIdData: {
    appId: string
  }
  exportToExcel: {
    page: number
    per_page: number
    excelData: [
      {
        appId: string
        roomName: string
        roomId: string
        participantName: string
        causeCode: string
        reason: string
        cost: string
        costCode: string
        sessionId: string
        startTime: Date
        endTime: Date
        minutes: number
        status: string
      },
    ]
  }
  page: number
  loading: boolean
  message: string
}

const initialState: conferencePayload = {
  conferenceData: [
    {
      appId: '',
      roomName: '',
      roomId: '',
      participantName: '',
      causeCode: '',
      reason: '',
      cost: '',
      costCode: '',
      sessionId: '',
      startTime: null,
      endTime: null,
      minutes: null,
      status: '',
    },
  ],
  meta: {
    page_count: 0,
    total_count: 0,
  },
  appIdData: {
    appId: '',
  },
  exportToExcel: {
    page: 1,
    per_page: 2,
    excelData: [
      {
        appId: '',
        roomName: '',
        roomId: '',
        participantName: '',
        causeCode: '',
        reason: '',
        cost: '',
        costCode: '',
        sessionId: '',
        startTime: null,
        endTime: null,
        minutes: null,
        status: '',
      },
    ],
  },
  page: 1,
  loading: false,
  message: 'Please select app id',
}

const slice = createSlice({
  name: 'conference',
  initialState,
  reducers: {
    setConferenceTableData(state, action) {
      state.conferenceData = action.payload
    },
    setMeta(state, action) {
      state.meta = action.payload
    },
    setLoadingTrue(state) {
      state.loading = true
    },
    setLoadingFalse(state) {
      state.loading = false
    },
    setAppId(state, action) {
      state.appIdData = action.payload
    },
    setPageNumber(state, action) {
      state.page = action.payload
    },
    setMessage(state, action) {
      state.message = action.payload
    },
    setExcelData(state, action) {
      state.exportToExcel.excelData = action.payload
    },
  },
})

export const ConferenceAction = slice.actions
const BASE_URL = process.env.REACT_APP_API_URL;

// fetching conference data
export const fetchConferenceTableData = (page: number, id: string) => async (
  dispatch: any,
) => {
  dispatch(slice.actions.setLoadingTrue())
  await axios
    .get(
      `${BASE_URL}/api/v1/events/cdr-summary?page=${page}&per_page=6&meta=true&app_id=${id}`,
    )
    .then((res) => {
      if (res.data.message.startsWith('Data not found')) {
        dispatch(slice.actions.setLoadingFalse())
        dispatch(slice.actions.setMessage(`No data found for selected app id`))
        dispatch(
          slice.actions.setMeta({
            page_count: 0,
            total_count: 0,
          }),
        )
      }
      console.log('conference Table Data Response', res.data)
      dispatch(slice.actions.setLoadingFalse())
      let data = []
      for (const k in res.data.data) {
        data.push({
          appId: res.data.data[k].appId,
          roomName: res.data.data[k].roomName,
          roomId: res.data.data[k].roomId,
          participantName: res.data.data[k].participantName,
          causeCode: res.data.data[k].causeCode,
          reason: res.data.data[k].reason,
          cost: res.data.data[k].cost,
          costCode: res.data.data[k].costCode,
          sessionId: res.data.data[k].sessionId,
          startTime: res.data.data[k].startTime,
          endTime: res.data.data[k].endTime,
          minutes: res.data.data[k].minutes,
          status: res.data.data[k].status,
        })
      }
      console.log('metadat', res.data.meta.page_count)
      dispatch(slice.actions.setConferenceTableData(data))
      dispatch(
        slice.actions.setMeta({
          page_count: res.data.meta.page_count,
          total_count: res.data.meta.total_count,
        }),
      )
    })
    .catch((err) => {
      console.log(err)
    })
}

export const fetchCompleteData = (per_page: number, id: string) => async (
  dispatch: any,
) => {
  await axios
    .get(
      `${BASE_URL}/api/v1/events/cdr-summary?page=1&per_page=${per_page}&meta=true&app_id=${id}`,
    )
    .then((res: any) => {
      console.log('2nd conference Table Data Response', res.data.data)
      let data = []
      if (res.data.data) {
        for (const k in res.data.data) {
          data.push({
            appId: res.data.data[k].appId,
            roomName: res.data.data[k].roomName,
            roomId: res.data.data[k].roomId,
            participantName: res.data.data[k].participantName,
            causeCode: res.data.data[k].causeCode,
            reason: res.data.data[k].reason,
            cost: res.data.data[k].cost,
            costCode: res.data.data[k].costCode,
            sessionId: res.data.data[k].sessionId,
            startTime: res.data.data[k].startTime,
            endTime: res.data.data[k].endTime,
            minutes: res.data.data[k].minutes,
            status: res.data.data[k].status,
          })
        }
        dispatch(slice.actions.setExcelData(data))
      }
      if (res.detail.msg.startsWith('ensure this value is ')) return
      if (res.data.message.startsWith('Data not found')) return
    })
    .catch((err) => {
      console.log(err)
    })
}

export const reducer = slice.reducer

export default slice
