import React from 'react'

const Native = () => {
    return (
        <div><svg width="98" height="98" viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M73.5215 61.1273C74.8443 60.2493 75.7262 59.3713 76.608 58.4933L89.8359 45.3234C99.9772 35.2265 99.5363 18.1056 88.5131 8.44769C78.3718 -1.64923 62.0574 -1.64923 51.9161 8.44769L44.4203 15.9106C42.6566 17.6666 42.6566 20.3006 44.4203 22.0566C46.184 23.8126 48.8296 23.8126 50.5933 22.0566L58.0891 14.5936C65.1439 8.00869 75.7262 8.00869 82.781 14.5936C89.8359 21.1786 89.8359 32.1535 83.222 39.1775L69.9941 52.3474C69.5532 53.2253 69.1123 53.6643 68.2304 54.1033C60.2937 59.8103 49.2705 58.4933 43.5384 50.5914C42.2156 48.8354 39.1291 48.3964 37.3654 49.7134C35.6017 51.0304 35.1608 54.1033 36.4836 55.8593C41.7747 62.8833 49.7114 66.3952 57.6481 66.3952C63.3802 66.3952 68.6713 64.6393 73.5215 61.1273ZM39.1291 83.0771L46.6249 75.6142C48.3886 73.8582 51.4751 74.2972 53.2388 76.0532C55.0026 77.8092 55.0026 80.4431 53.2388 82.1991L45.7431 89.6621C40.4519 94.491 33.838 97.125 27.2241 97.125C20.6101 97.125 13.9962 94.491 8.70507 89.6621C-1.43628 79.5651 -1.87721 62.8833 8.26414 52.3474L21.492 39.1775L24.1376 36.5435C29.8696 32.1535 36.9245 30.3975 43.5384 31.7145C50.1523 32.5925 56.3253 36.1045 60.7346 41.8114C62.0574 43.5674 61.6165 46.6404 59.8528 47.9574C58.0891 49.2744 55.0026 48.8354 53.6798 47.0794C51.0342 43.1284 47.0658 40.9334 42.2156 40.0554C37.3654 39.6164 32.9561 40.9334 28.9878 43.5674L27.2241 45.3234L13.9962 58.4933C7.38229 65.0783 7.38229 76.4922 14.4371 83.0771C21.492 89.6621 32.0743 89.6621 39.1291 83.0771Z" fill="#35BBC5" />
        </svg>
        </div>
    )
}

export default Native