import React from 'react'

const ScalabilityIcon = ({props}) => {
  return (
    <div><svg width={props.width} height={props.height} viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="27" cy="27" r="27" fill="#35BBC5"/>
    <g clip-path="url(#clip0_0_1)">
    <path d="M25.5 31H22.5C22.5 23.545 28.545 17.5 36 17.5V20.5C30.195 20.5 25.5 25.195 25.5 31ZM36 26.5V23.5C31.86 23.5 28.5 26.86 28.5 31H31.5C31.5 28.51 33.51 26.5 36 26.5ZM19.5 16C19.5 14.335 18.165 13 16.5 13C14.835 13 13.5 14.335 13.5 16C13.5 17.665 14.835 19 16.5 19C18.165 19 19.5 17.665 19.5 16ZM26.175 16.75H23.175C22.815 18.88 20.985 20.5 18.75 20.5H14.25C13.005 20.5 12 21.505 12 22.75V26.5H21V23.11C23.79 22.225 25.875 19.765 26.175 16.75ZM37.5 35.5C39.165 35.5 40.5 34.165 40.5 32.5C40.5 30.835 39.165 29.5 37.5 29.5C35.835 29.5 34.5 30.835 34.5 32.5C34.5 34.165 35.835 35.5 37.5 35.5ZM39.75 37H35.25C33.015 37 31.185 35.38 30.825 33.25H27.825C28.125 36.265 30.21 38.725 33 39.61V43H42V39.25C42 38.005 40.995 37 39.75 37Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_0_1">
    <rect width="36" height="36" fill="white" transform="translate(9 10)"/>
    </clipPath>
    </defs>
    </svg>
    </div>
  )
}

export default ScalabilityIcon