import { createSlice } from '@reduxjs/toolkit'
// import axios from 'axios'

interface webhookHitsPayload {
  webHookHitsData: [
    {
      appId: string
      hookName: string
      createdDate: Date
      status: boolean
    },
  ]
  meta: {
    page_count: number
    total_count: number
  }
  appIdData: {
    appId: string
  }
  hookName: {
    name: string
  }
  loading: boolean
}

const initialState: webhookHitsPayload = {
  webHookHitsData: [
    {
      appId: '',
      hookName: '',
      createdDate: null,
      status: null,
    },
  ],
  meta: {
    page_count: null,
    total_count: null,
  },
  appIdData: {
    appId: '',
  },
  hookName: {
    name: '',
  },
  loading: false,
}

const slice = createSlice({
  name: 'webHookHits',
  initialState,
  reducers: {
    setWbHookHitsData(state, action) {
      state.webHookHitsData = action.payload.data
      state.meta = action.payload.meta
    },
    setLoading(state) {
      state.loading = !state.loading
    },
    setAppId(state, action) {
      state.appIdData = action.payload
    },
    setHookName(state, action) {
      state.hookName = action.payload
    },
  },
})

export const WebhookTableActions = slice.actions

// fetching conference data
// export const getWebHookHitsData = (page: number) => async (dispatch) => {
//   // dispatch(slice.actions.loading())
//   await axios
//     .get(`/endpoint/?page=${page}&per_page=6&meta=true`)
//     .then((res) => {
//       console.log('conference Table Data Response', res)
//       // dispatch(slice.actions.loading())
//       // dispatch(slice.actions.setConferenceTableData(res))

//     })
//     .catch((err) => {
//       console.log(err)
//     })
// }

export const reducer = slice.reducer

export default slice
