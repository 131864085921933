import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { styled } from "@mui/system";
import { CopyAllOutlined } from "@mui/icons-material";

function UserToken() {
  const CardContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    ${(props) => props.theme.breakpoints.down("lg")} {
      gap: 10px;
    }
    ${(props) => props.theme.breakpoints.down("md")} {
      flex-direction: column;
      height: auto;
      margin: 1rem 0 4rem 0;
      gap: 30px;
    }
    ${(props) => props.theme.breakpoints.down("sm")} {
      height: 100%;
    }
  `;

  const Cards = styled(Box)`
    // width: 320px;
    // height: 324px;
    background: #ffffff;
    border: 1px solid #f1f5fd;
    box-shadow: 0px 0px 80px rgba(91, 157, 255, 0.15);
    border-radius: 4px;
    padding: 30px 14px;
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: column;
  `;
  const CardHead = styled(Typography)`
    color: #212121;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    // border:1px solid blue;
    min-height: 30px;
    max-height: auto;
    width: 90%;
  `;

  return (
    <>
      <Grid
        container
        sx={{
          backgroundColor: "#f1f4fd",
          display: "flex",
          justifyContent: "center",
          mt: { lg: "35px", sm: "5px" },
        }}
      >
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            //   //ml: {lg:"25px",sm:'5px'},
            //   width: "100%",
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
          >
            <Typography variant="h1">Your Authtoken</Typography>
            <Typography sx={{ width: "100%", wordWrap: "break-word" }}>
              This is your personal Authtoken. Use this to authenticate the
              ingressify agent that you downloaded.
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                width: "100%",
                flexDirection: {lg:"row",xs:'column'}

              }}
            >
              <Box
                sx={{
                  background: "white",
                  height: "25px",
                  display: "flex",
                  alignItems: "center",
                  padding: "15px",
                  wordWrap: "break-word",
                  width:'100%'
                }}
              >
                2Ng7S3EGoPm8U5uRSI54C3m6TD0
              </Box>
              <Button
                sx={{
                  background: "#3087ec",
                  color: "white",
                  width: "100px",
                  height:'25px'
                //   padding: "10px",
                }}
              >
                <IconButton sx={{ color: "white",height:'25px' }}>
                  <CopyAllOutlined sx={{height:'25px'}}/>
                </IconButton>
                <Typography sx={{width:'100%'}}>copy</Typography>
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: {lg:"10px",sm:'0px'},
          mt: {lg:"35px",sm:'5px'}
        }}
      >
        <Grid item lg={12} sm={12} xs={12}>
          <CardContainer sx={{ width: "100%" }}>
            <Cards sx={{ width: "70%", height: {lg:"200px",sm:'400px'}}}>
              <CardHead>Command Line</CardHead>
              <CardContent
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Authenticate your ingressify agent. You only have to do this
                once. The Authtoken is saved in the default configuration file.
              </CardContent>
              <CardContent
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#f0f2f5",
                  width:'100%',
                  wordWrap:'break-word'
                }}
              >
                $ ingressify config add-authtoken
                2Ng7S3EGoPm8U5uRSI54C3m6TD0_4YiPto5BEF8sniSa7RM5G
              </CardContent>
            </Cards>
          </CardContainer>
        </Grid>
        <Grid item lg={12} xs={12}>
          <CardContainer>
            <Cards sx={{ width: "70%", height: {lg:"200px",sm:'400px'}}}>
              <CardHead>Configuration File</CardHead>
              <CardContent
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  width:'100%',
                  wordWrap:'break-word'

                }}
              >
                Alternatively, you can directly add the Authtoken to your
                ngrok.yml configuration file. Use ngrok config edit to open the
                file.
              </CardContent>
              <CardContent
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#f0f2f5",
                  width:'100%',
                  wordWrap:'break-word'

                }}
              >
                # in ingressify.yml authtoken:
                2Ng7S3EGoPm8U5uRSI54C3m6TD0_4YiPto5BEF8sniSa7RM5G
              </CardContent>
            </Cards>
          </CardContainer>
        </Grid>
        <Grid item lg={12} xs={12}>
          <CardContainer>
            <Cards sx={{ width: "70%", height: {lg:"200px",sm:'400px'}}}>
              <CardHead>Reset Your Authtoken</CardHead>
              <CardContent
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                This cannot be undone. After you reset the Authtoken you must
                update your ingressify agent configuration with the new
                Authtoken using either of the steps above.{" "}
              </CardContent>
              <CardContent
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button sx={{color:'red',border:'1px red solid'}}><Typography>Reset Authtoken</Typography></Button>
              </CardContent>
            </Cards>
          </CardContainer>
        </Grid>
      </Grid>
    </>
  );
}

export default UserToken;
