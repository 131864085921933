import React from 'react'

const FileServer = () => {
  return (
    <div><svg width="106" height="106" viewBox="0 0 106 106" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M53 0.5C24.02 0.5 0.5 24.02 0.5 53C0.5 81.98 24.02 105.5 53 105.5C81.98 105.5 105.5 81.98 105.5 53C105.5 24.02 81.98 0.5 53 0.5ZM53 95C29.8475 95 11 76.1525 11 53C11 29.8475 29.8475 11 53 11C76.1525 11 95 29.8475 95 53C95 76.1525 76.1525 95 53 95ZM75.5225 43.4975C73.4225 32.945 64.1825 25.0175 53 25.0175C44.1275 25.0175 36.4625 30.0575 32.63 37.4075C23.39 38.3525 16.25 46.175 16.25 55.625C16.25 65.7575 24.4925 74 34.625 74H74.42C82.8725 74 89.75 67.1225 89.75 58.67C89.75 50.585 83.45 44.0225 75.5225 43.4975ZM74 63.5H34.625C30.2675 63.5 26.75 59.9825 26.75 55.625C26.75 51.2675 30.2675 47.75 34.625 47.75H39.35L41.9225 42.2375C44.075 38.09 48.3275 35.5175 53 35.5175C58.9325 35.5175 64.0775 39.7175 65.2325 45.545L66.7025 53H74C76.8875 53 79.25 55.3625 79.25 58.25C79.25 61.1375 76.8875 63.5 74 63.5Z" fill="#35BBC5"/>
    </svg>
    </div>
  )
}

export default FileServer