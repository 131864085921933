import Link from "@mui/material/Link";
import "./Footer.css";
// import Divider from '@mui/material/Divider'
// import { NavLink } from "react-router-dom";
import { Box } from "@mui/system";
// import FbIcon from "src/assets/icons/FacebookIcon";
import {
  Divider,
  experimentalStyled,
  Grid,
  Hidden,
  // IconButton, 
  Typography,
} from "@mui/material";
// import TweetIcon from "src/assets/icons/TweetIcon";
// import LinkedinIcon from "src/assets/icons/LinkedinIcon";
// import FooterBackground from "src/assets/Svg/FooterBackground";
// import { MessageIcon } from 'src/assets/Icons/SummeryIcons'
// import ELLogo from "src/assets/logo/EL-White.svg";

// import homeImage from "src/assets/home-image.png";
import FooterBanner from "src/assets/Svg/FooterBanner.svg";

const CopyrightWrapper = experimentalStyled(Box)(
  () => `
  border-radius:0px;
  width: 100%;
  height:auto;
  padding-left:35px;
  padding-bottom:30px;
`
);

const CopyrightWrapperMobile = experimentalStyled(Box)(
  () => `
  border-radius:0px;
  width: 100%;
  height:auto;
  padding-bottom:30px;
`
);

function Footer() {
  return (
    <Box
      sx={{
        backgroundImage: `url(${FooterBanner})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        paddingLeft: {
          xs: "10px",
          sm: "20px",
          md: "90px",
          lg: "100px",
          xl: "100px",
        },
        paddingRight: {
          xs: "10px",
          sm: "10px",
          md: "20px",
          lg: "40px",
          xl: "20px",
        },
      }}
    >
      {/* Footer For Other Devices Start */}
      <Hidden only={"xs"}>
        <footer className="footer">
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12} rowGap={1}>
            {/* <Grid item xs={12} sm={5} md={5} lg={3} xl={3}> */}
            <Grid item xs={12} sm={3.5} md={3} lg={4} xl={3} >
              <li className="nav__item">
                <Box
                  sx={{
                    height: "180px",
                    width: {xl:'300px',lg:'300px',md:'180px',sm:'180px'},
                    // border: '1px solid white',
                    marginTop: "15px",
                    // marginLeft: '15px',
                  }}
                >
                  {/* <img src={ELLogo} alt="logo" height="100%" width="100%" /> */}
                  <h2 className="nav__head">Ingressify</h2>
                  <Typography sx={{ color: "#292525" }}>
                    Unlock Your Service's Potential: Ingressify and Go Global!"
                  </Typography>
                </Box>
              </li>
            </Grid>

            <Grid item xs={12} sm={2.5} md={2} lg={2} xl={3}>
              <li className="nav__item">
                <h2 className="nav__title">Work</h2>

                <ul className="nav__ul nav__ul--extra">
                  <li className="sub-list">
                    <Link
                      color="inherit"
                      underline="none"
                    // component={NavLink}
                    // to="/pricing"
                    >
                      Blog
                    </Link>
                  </li>
                  <li className="sub-list">
                    <Link
                      color="inherit"
                      underline="none"
                    // component={NavLink}
                    // to="/pages/support-plans"
                    >
                      How We Work
                    </Link>
                  </li>
                  {/* <li className="sub-list">
                    <Link
                      color="inherit"
                      underline="none"
                    // component={NavLink}
                    // to="/pages/solutions"
                    >
                      Testimonials
                    </Link>
                  </li> */}
                </ul>
              </li>
            </Grid>

            <Grid item xs={12} sm={3} md={3} lg={2} xl={2.5}>
              <li className="nav__item">
                <h2 className="nav__title">Services</h2>

                <ul className="nav__ul nav__ul--extra">
                  <li className="sub-list">
                    <Link
                      color="inherit"
                      underline="none"
                    // component={NavLink}
                    // to="/pages/about-us"
                    >
                     Professional Services
                    </Link>
                  </li>
                  {/* <li className="sub-list">
                    <Link
                      color="inherit"
                      underline="none"
                    // component={NavLink}
                    // to="/pages/careers"
                    >
                      Website Optimization
                    </Link>
                  </li>
                  <li className="sub-list">
                    <Link
                      color="inherit"
                      underline="none"
                    // component={NavLink}
                    // to="/pages/our-partners"
                    >
                      Email Marketing
                    </Link>
                  </li> */}
                </ul>
              </li>
            </Grid>

            <Grid item xs={12} sm={3} md={3} lg={2} xl={3}>
              <li className="nav__item">
                <h2 className="nav__title"> Business Solution </h2>
                <ul className="nav__ul nav__ul--extra">
                  <li className="sub-list">
                    <Link
                      color="inherit"
                      underline="none"
                    // component={NavLink}
                    // to="/contact-us"
                    >
                      Partnership
                    </Link>
                  </li>
                  <li className="sub-list">
                    <Link color="inherit" underline="none">
                    Corporate
                    </Link>
                  </li>
                  {/* <li className="sub-list">
                    <Link
                      color="inherit"
                      underline="none"
                    // component={NavLink}
                    // to="/support"
                    >
                      About Project
                    </Link>
                  </li>

                  <li className="sub-list">
                    <Link
                      color="inherit"
                      underline="none"
                    // component={NavLink}
                    // to="/support"
                    >
                      Corporate
                    </Link>
                  </li> */}
                </ul>
              </li>
            </Grid>
          </Grid>
          <Box height={"30px"}></Box>
        </footer>
        <Hidden only={["xs"]}>
          <div style={{ paddingLeft: "20px" }}>
            <Divider
              style={{
                background: "white",
                marginBottom: "10px",
                width: "90%",
              }}
              variant="middle"
            />
          </div>
        </Hidden>

        <CopyrightWrapper>
          <Box
            display={{
              xs: "block",
              sm: "flex",
              md: "flex",
              lg: "flex",
              xl: "flex",
            }}
            alignItems="center"
            textAlign={{ xs: "left", lg: "left" }}
            justifyContent={{
              sm: "space-between",
              md: "space-between",
              lg: "space-between",
              xl: "space-between",
            }}
            width={'90%'}
          >
            <Box>
              <Typography
                variant="subtitle1"
                fontFamily={"Poppins"}
                color={"white"}
                fontSize={"12px"}
                fontWeight={400}
              >
                Copyright &copy; 2023 Ingresify. All rights reserved.
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                className="footer-privacy-condition"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                 
                }}
              >
                <Typography
                  variant="subtitle1"
                  fontFamily={"Poppins"}
                  color={"white"}
                  fontSize={"12px"}
                  fontWeight={400}
                >
                   Privacy Policy
                  {/* <a
                    href="/"
                    style={{ textDecoration: "none"}}
                  >
                    Privacy Policy
                  </a> */}
                  &emsp;|&emsp;
                  Terms and Conditions
                  {/* <a
                    href="/"
                    style={{ textDecoration: "none" }}
                  >
                    Terms and Conditions
                  </a> */}
                  {/* &emsp;|&emsp; */}
                  {/* <IconButton
                    style={{
                      cursor: "pointer",
                      padding: "0px",
                      marginRight: "10px",
                    }}
                    onClick={() => {
                      window.open(
                        "https://www.facebook.com/logichivesolutions",
                        "_blank"
                      );
                    }}
                  >
                    <FbIcon />
                  </IconButton>
                  <IconButton
                    style={{
                      cursor: "pointer",
                      padding: "0px",
                      marginRight: "10px",
                    }}
                    onClick={() => {
                      window.open("https://logichive.in/", "_blank");
                    }}
                  >
                    <TweetIcon />
                  </IconButton>
                  <IconButton
                    style={{ cursor: "pointer", padding: "0px" }}
                    onClick={() => {
                      window.open(
                        "https://www.linkedin.com/company/logichive-solutions-pvt-ltd/mycompany/",
                        "_blank"
                      );
                    }}
                  >
                    <LinkedinIcon />
                  </IconButton> */}
                </Typography>
              </Box>
            </Box>
          </Box>
        </CopyrightWrapper>
      </Hidden>
      {/* Footer For Other Devices End */}

      {/* Footer For Mobile Start */}
      <Hidden smUp>
        <footer className="footer-mobile">
          <Grid container xs={12} rowGap={1}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: "10px",
              }}
            >
              {/* <li className="nav__item"> */}
              {/* <Box
                sx={{
                  height: "100px",
                  width: "100px",
                  // border: '1px solid white',
                  marginTop: "15px",
                  // marginLeft: '15px',
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              > */}
              {/* <img src={ELLogo} alt="logo" height="100%" width="100%" /> */}
              <Box
                sx={{
                  height: "180px",
                  width: "180px",
                  // border: '1px solid white',
                  // marginTop: "15px",
                  // marginLeft: '15px',
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {/* <img src={ELLogo} alt="logo" height="100%" width="100%" /> */}
                <h2 className="nav__head">Ingressify</h2>
                <Typography sx={{ color: "#292525", textAlign: "center" }}>
                  Unlock Your Service's Potential: Ingressify and Go Global!"
                </Typography>
              </Box>
              {/* </Box> */}
              {/* </li> */}
            </Grid>
            <Divider
              style={{
                background: "#47454E",
                marginTop: "10px",
                // marginBottom: '10px',
                width: "90%",
              }}
              variant="middle"
            />
            <Grid item className="footer-item-left-box" xs={6}>
              <li className="nav__item">
                <h2 className="nav__title_mobile">Work</h2>

                <ul className="nav__ul nav__ul--extra">
                  <li className="sub-list_mobile">
                    <Link
                      color="inherit"
                      underline="none"
                    // component={NavLink}
                    // to="/pricing"
                    >
                      Blog
                    </Link>
                  </li>
                  <li className="sub-list_mobile">
                    <Link
                      color="inherit"
                      underline="none"
                    // component={NavLink}
                    // to="/pages/support-plans"
                    >
                      How We Work
                    </Link>
                  </li>

                  {/* <li className="sub-list_mobile">
                    <Link
                      color="inherit"
                      underline="none"
                    // component={NavLink}
                    // to="/pages/solutions"
                    >
                      Testimonials
                    </Link>
                  </li> */}
                </ul>
              </li>
            </Grid>
            {/* <Grid item className="footer-item-right-box" xs={6}>
              <li className="nav__item">
                <h2 className="nav__title_mobile">Events</h2>

                <ul className="nav__ul nav__ul--extra">
                  <li className="sub-list_mobile">
                    <Link color="inherit" underline="none">
                      Ongoing Events
                    </Link>
                  </li>
                  <li className="sub-list_mobile">
                    <Link color="inherit" underline="none">
                      Upcoming Events
                    </Link>
                  </li>
                  <li className="sub-list_mobile">
                    <Link color="inherit" underline="none">
                      Trainings
                    </Link>
                  </li>
                  <li className="sub-list_mobile">
                    <Link color="inherit" underline="none">
                      Webinars
                    </Link>
                  </li>
                </ul>
              </li>
            </Grid> */}
            <Grid item className="footer-item-left-box" xs={6}>
              <li className="nav__item">
                <h2 className="nav__title_mobile">Services</h2>

                <ul className="nav__ul nav__ul--extra">
                  <li className="sub-list_mobile">
                    <Link
                      color="inherit"
                      underline="none"
                    // component={NavLink}
                    // to="/pages/about-us"
                    >
                     Professional Services
                    </Link>
                  </li>
                  {/* <li className="sub-list_mobile">
                    <Link
                      color="inherit"
                      underline="none"
                    // component={NavLink}
                    // to="/pages/careers"
                    >
                      Website Optimization
                    </Link>
                  </li>
                  <li className="sub-list_mobile">
                    <Link
                      color="inherit"
                      underline="none"
                    // component={NavLink}
                    // to="/pages/our-partners"
                    >
                      Email Marketing
                    </Link>
                  </li> */}
                </ul>
              </li>
            </Grid>
            {/* <Grid item className="footer-item-right-box" xs={6}> */}
            <Grid item className="footer-item-left-box" xs={12}>
              <li className="nav__item">
                <h2 className="nav__title_mobile">Business Solution</h2>
                <ul className="nav__ul nav__ul--extra">
                  <li className="sub-list_mobile">
                    <Link
                      color="inherit"
                      underline="none"
                    // component={NavLink}
                    // to="/contact-us"
                    >
                      Partnership
                    </Link>
                  </li>
                  {/* <li className="sub-list_mobile">
                    <Link color="inherit" underline="none">
                      About Project
                    </Link>
                  </li> */}
                  <li className="sub-list_mobile">
                    <Link
                      color="inherit"
                      underline="none"
                    // component={NavLink}
                    // to="/support"
                    >
                      Corporate
                    </Link>
                  </li>
                  {/* <li className="sub-list_mobile">
                    <Link color="inherit" underline="none">
                      FAQ&#39;s
                    </Link>
                  </li> */}
                </ul>
              </li>
            </Grid>
          </Grid>

          <Box height={"30px"}></Box>
        </footer>

        <Divider
          style={{
            background: "#47454E",
            marginTop: "10px",
            marginBottom: "15px",
            width: "90%",
          }}
          variant="middle"
        />

        <CopyrightWrapperMobile>
          <Box
            display={{
              xs: "block",
            }}
            alignItems="center"
            textAlign={{ xs: "center" }}
          >
            <Box>
              <Typography
                variant="subtitle1"
                fontFamily={"Poppins"}
                color={"black"}
                fontSize={"12px"}
                fontWeight={400}
                mb={"5px"}
              >
                Copyright &copy; 2023 Ingressify. All rights reserved.
              </Typography>
            </Box>

            {/* <Box
              sx={{
                display: "block",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                className="footer-privacy-condition"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="subtitle1"
                  fontFamily={"Poppins"}
                  color={"white"}
                  fontSize={"12px"}
                  fontWeight={400}
                  mb={"5px"}
                >
                  <a
                    href="/pages/privacy-policy"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Privacy Policy
                  </a>
                  &emsp;|&emsp;
                  <a
                    href="/pages/terms-and-conditions"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Terms and Conditions
                  </a>
                </Typography>
              </Box>

              <Box
                sx={{
                  marginLeft: {
                    xs: "0px",
                    sm: "10px",
                    md: "75px",
                    lg: "115px",
                    xl: "150px",
                  },
                }}
              >
                <IconButton
                  style={{
                    cursor: "pointer",
                    padding: "0px",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    window.open(process.env.REACT_FACEBOOK_URL, "_blank");
                  }}
                >
                  <FbIcon />
                </IconButton>
                <IconButton
                  style={{
                    cursor: "pointer",
                    padding: "0px",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    window.open(process.env.REACT_TWITTER_URL, "_blank");
                  }}
                >
                  <TweetIcon />
                </IconButton>
                <IconButton
                  style={{ cursor: "pointer", padding: "0px" }}
                  onClick={() => {
                    window.open(process.env.REACT_LINKEDIN_URL, "_blank");
                  }}
                >
                  <LinkedinIcon />
                </IconButton>
              </Box>
            </Box> */}
          </Box>
        </CopyrightWrapperMobile>
      </Hidden>
      {/* Footer For Mobile End*/}
    </Box>
  );
}
export default Footer;
