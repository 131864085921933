import {
  Box,
  Button,
  FormControlLabel,
  Hidden,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import Discord from "src/assets/Svg/Discord";
import Instagram from "src/assets/Svg/Instagram";
import Location from "src/assets/Svg/Location";
import Mail from "src/assets/Svg/Mail";
import Phone from "src/assets/Svg/Phone";
import Twitter from "src/assets/Svg/Twitter";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MuiCheckbox from "@mui/material/Checkbox";
import contactUs from "../../assets/Svg/ContactUs.svg";

const ContactUs = () => {
  function CheckboxClick({ label, icon, checkedIcon }) {
    return (
      <FormControlLabel
        label={label}
        control={<MuiCheckbox icon={icon} checkedIcon={checkedIcon} />}
      />
    );
  }
  return (
    <div>
      <Hidden smDown>
        <Box
          sx={{
            marginTop: {
              sm: "50px",
              md: "50px",
              lg: "-900px",
              xl: "-900px",
            },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "50px",
          }}
        >
          <Typography
            sx={{
              fontSize: {
                lg: "50px",
                md: "40px",
                sm: "30px",
                xs: "20px",
              },
              color: "white",
              textAlign: "center",
            }}
          >
            Contact Us
          </Typography>

          <Box
            sx={{
              display: "flex",
              background: "white",
              marginTop: "30px",
              width: {
                xl: "50%",
                lg: "80%",
                md: "90%",
                sm: "90%",
                xs: "80%",
              },
            }}
          >
            <Box
              sx={{
                width: "40%",
                padding: "40px",
                backgroundImage: `url(${contactUs})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    lg: "28px",
                    md: "18px",
                    sm: "18px",
                    xs: "14px",
                  },
                  color: "white",
                }}
              >
                Get in touch with us!
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    lg: "18px",
                    md: "14px",
                    sm: "12px",
                    xs: "10px",
                  },
                  marginTop: "10px",
                  color: "white",
                }}
              >
                Our team will get in touch with you as soon as possible.
              </Typography>
              <Box sx={{ display: "flex", gap: "20px", marginTop: "20px" }}>
                <Phone />
                <Typography
                  sx={{
                    fontSize: {
                      lg: "16px",
                      md: "12px",
                      sm: "12px",
                      xs: "12px",
                    },
                    fontWeight: "400",
                    color: "white",
                  }}
                >
                  +1012 3456 789
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                <Mail />
                <Typography
                  sx={{
                    fontSize: {
                      lg: "16px",
                      md: "12px",
                      sm: "12px",
                      xs: "12px",
                    },
                    fontWeight: "400",
                    color: "white",
                  }}
                >
                  demo@gmail.com
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "20px", marginTop: "20px" }}>
                <Location />
                <Typography
                  sx={{
                    fontSize: {
                      lg: "16px",
                      md: "12px",
                      sm: "12px",
                      xs: "12px",
                    },
                    fontWeight: "400",
                    color: "white",
                  }}
                >
                  132 Dartmouth Street Boston, Massachusetts 02156 United States
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "15px", marginTop: "60px" }}>
                <Twitter />
                <Instagram />
                <Discord />
              </Box>
            </Box>
            <Box sx={{ width: "60%", padding: "50px" }}>
              <Box sx={{ display: "flex", gap: "40px" }}>
                <TextField
                  id="standard-basic"
                  label="First Name"
                  variant="standard"
                  sx={{
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "black",
                    },
                    "& label.Mui-focused": {
                      color: "black",
                    },
                  }}
                  fullWidth
                />
                <TextField
                  id="standard-basic"
                  label="Last Name"
                  variant="standard"
                  sx={{
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "black",
                    },
                    "& label.Mui-focused": {
                      color: "black",
                    },
                  }}
                  fullWidth
                />
              </Box>
              <Box sx={{ display: "flex", gap: "40px", marginTop: "30px" }}>
                <TextField
                  id="standard-basic"
                  label="Email"
                  variant="standard"
                  sx={{
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "black",
                    },
                    "& label.Mui-focused": {
                      color: "black",
                    },
                  }}
                  fullWidth
                />
                <TextField
                  id="standard-basic"
                  label="Phone Number"
                  variant="standard"
                  sx={{
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "black",
                    },
                    "& label.Mui-focused": {
                      color: "black",
                    },
                  }}
                  fullWidth
                />
              </Box>
              <Box sx={{ marginTop: "30px" }}>
                <Typography
                  sx={{
                    fontSize: {
                      lg: "20px",
                      md: "16px",
                      sm: "14px",
                      xs: "12px",
                    },
                    marginTop: "20px",
                  }}
                >
                  Select Subject ?
                </Typography>
                <Box sx={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                  {/* <FormControlLabel
                    value="end"
                    control={<Checkbox
                      sx={{
                        '&.Mui-checked': {
                          color: 'black',
                          borderRadius: '50%'
                        },
                      }} />}

                    label="General Inquiry"
                    labelPlacement="end"

                  />
                  <FormControlLabel
                    value="end"
                    control={<Checkbox sx={{
                      '&.Mui-checked': {
                        color: 'black',
                        borderRadius: '50%'
                      },
                    }} />}
                    label="Request a callback"
                    labelPlacement="end"
                  /> */}

                  <CheckboxClick
                    // value="General Inquiry"
                    label="General Inquiry"
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={
                      <CheckCircleIcon
                        sx={{
                          color: "black",
                        }}
                      />
                    }
                  />

                  <CheckboxClick
                    label="Request a callback"
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={
                      <CheckCircleIcon
                        sx={{
                          color: "black",
                        }}
                      />
                    }
                  />
                </Box>
              </Box>

              <Box sx={{ display: "flex", gap: "10px", marginTop: "20px" }}>
                <TextField
                  id="standard-basic"
                  label="Message"
                  variant="standard"
                  placeholder="Write your message.."
                  fullWidth
                  sx={{
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "black",
                    },
                    "& label.Mui-focused": {
                      color: "black",
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  marginTop: "50px",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  sx={{
                    color: " #FFFFFF",
                    background:
                      "linear-gradient(180deg, #60E1CB 0%, #00C2FF 100%)",
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: "14px",
                    borderRadius: "5px",
                    transition: "all 0.3s ease",
                    // "&:hover": {
                    //   color: "black",
                    //   background: "#FFFFFF",

                    // },
                  }}
                >
                  Send Message
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Hidden>

      <Hidden smUp>
        <Box
          sx={{
            marginTop: "30px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "50px",
          }}
        >
          <Typography
            sx={{
              fontSize: {
                lg: "50px",
                md: "40px",
                sm: "30px",
                xs: "20px",
              },
              color: "white",
              textAlign: "center",
            }}
          >
            Contact Us
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              background: "white",
              marginTop: "30px",
              width: {
                xl: "50%",
                lg: "80%",
                md: "90%",
                sm: "90%",
                xs: "80%",
              },
            }}
          >
            <Box sx={{ width: "100%", padding: "30px" }}>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <TextField
                  id="standard-basic"
                  label="First Name"
                  variant="standard"
                  sx={{
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "black",
                    },
                    "& label.Mui-focused": {
                      color: "black",
                    },
                  }}
                  fullWidth
                />
                <TextField
                  id="standard-basic"
                  label="Last Name"
                  variant="standard"
                  sx={{
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "black",
                    },
                    "& label.Mui-focused": {
                      color: "black",
                    },
                  }}
                  fullWidth
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  marginTop: "10px",
                }}
              >
                <TextField
                  id="standard-basic"
                  label="Email"
                  variant="standard"
                  sx={{
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "black",
                    },
                    "& label.Mui-focused": {
                      color: "black",
                    },
                  }}
                  fullWidth
                />
                <TextField
                  id="standard-basic"
                  label="Phone Number"
                  variant="standard"
                  sx={{
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "black",
                    },
                    "& label.Mui-focused": {
                      color: "black",
                    },
                  }}
                  fullWidth
                />
              </Box>
              <Box sx={{ marginTop: "30px" }}>
                <Typography
                  sx={{
                    fontSize: {
                      lg: "20px",
                      md: "16px",
                      sm: "14px",
                      xs: "12px",
                    },
                    marginTop: "20px",
                  }}
                >
                  Select Subject ?
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                  {/* <FormControlLabel
                    value="end"
                    control={<Checkbox
                      sx={{
                        '&.Mui-checked': {
                          color: 'black',
                          borderRadius: '50%'
                        },
                      }} />}

                    label="General Inquiry"
                    labelPlacement="end"

                  /> */}
                  {/* <FormControlLabel
                    value="end"
                    control={<CheckboxClick
                    label="Request a callback"
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleIcon sx={{
                      color:'black',
                      
                    }} />}}
                    label="Request a callback"
                    labelPlacement="end"
                  /> */}
                  <CheckboxClick
                    // value="General Inquiry"
                    label="General Inquiry"
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={
                      <CheckCircleIcon
                        sx={{
                          color: "black",
                        }}
                      />
                    }
                  />

                  <CheckboxClick
                    label="Request a callback"
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={
                      <CheckCircleIcon
                        sx={{
                          color: "black",
                        }}
                      />
                    }
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  marginTop: "20px",
                }}
              >
                <TextField
                  id="standard-basic"
                  label="Message"
                  variant="standard"
                  placeholder="Write your message.."
                  fullWidth
                  sx={{
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "black",
                    },
                    "& label.Mui-focused": {
                      color: "black",
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  marginTop: "50px",
                  justifyContent: "center",
                }}
              >
                <Button
                  sx={{
                    color: " #FFFFFF",
                    background:
                      "linear-gradient(180deg, #60E1CB 0%, #00C2FF 100%)",
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: "14px",
                    borderRadius: "5px",
                    transition: "all 0.3s ease",
                    // "&:hover": {
                    //   color: "black",
                    //   background: "#FFFFFF",

                    // },
                  }}
                >
                  Send Message
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                padding: "30px",
                backgroundImage: `url(${contactUs})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    lg: "28px",
                    md: "18px",
                    sm: "18px",
                    xs: "14px",
                  },
                  color: "white",
                }}
              >
                Get in touch with us!
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    lg: "18px",
                    md: "14px",
                    sm: "12px",
                    xs: "10px",
                  },
                  marginTop: "10px",
                  color: "white",
                }}
              >
                Our team will get in touch with you as soon as possible.
              </Typography>
              <Box sx={{ display: "flex", gap: "20px", marginTop: "20px" }}>
                <Phone />
                <Typography
                  sx={{
                    fontSize: {
                      lg: "16px",
                      md: "12px",
                      sm: "12px",
                      xs: "12px",
                    },
                    fontWeight: "400",
                    color: "white",
                  }}
                >
                  +1012 3456 789
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                <Mail />
                <Typography
                  sx={{
                    fontSize: {
                      lg: "16px",
                      md: "12px",
                      sm: "12px",
                      xs: "12px",
                    },
                    fontWeight: "400",
                    color: "white",
                  }}
                >
                  demo@gmail.com
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "20px", marginTop: "20px" }}>
                <Location />
                <Typography
                  sx={{
                    fontSize: {
                      lg: "16px",
                      md: "12px",
                      sm: "12px",
                      xs: "12px",
                    },
                    fontWeight: "400",
                    color: "white",
                  }}
                >
                  132 Dartmouth Street Boston, Massachusetts 02156 United States
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "15px",
                  marginTop: "60px",
                  justifyContent: "center",
                }}
              >
                <Twitter />
                <Instagram />
                <Discord />
              </Box>
            </Box>
          </Box>
        </Box>
      </Hidden>
    </div>
  );
};

export default ContactUs;
