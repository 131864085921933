

import { createSlice } from "@reduxjs/toolkit";

interface IPricing {
    participantCount: number;
    timeValue: number;
    monthValue: number;
    speakerCount: number;
    viewerCount: number;
    isConferencing: boolean;
    isAudio: boolean;
    isRecording: boolean;
    isStorage: boolean;
    isSimulcast: boolean;
    tabState:number;
}

const initialState: IPricing = {
    participantCount: 1,
    timeValue: 60,
    monthValue: 1,
    speakerCount: 4,
    viewerCount: 100,
    isConferencing: true,
    isAudio: true,
    isRecording: false,
    isStorage:false,
    isSimulcast:false,
    tabState:1,
}

const slice = createSlice({
    name: "Pricing",
    initialState,

    reducers: {
        SetParticapantCount(state, action) {
            state.participantCount = action.payload;
        },
        SetTimeValue(state, action) {
            state.timeValue = action.payload;
        },
        SetMonthValue(state, action) {
            state.monthValue = action.payload;
        },
        SetSpeakerCount(state, action) {
            state.speakerCount = action.payload;
        },
        SetViewerCount(state, action) {
            state.viewerCount = action.payload;
        },
        ShowConferencingData(state, action) {
            state.isConferencing = action.payload;
        },
        ShowAudioData(state, action) {
            state.isAudio = action.payload;
        },
        ShowRecordingData(state, action) {
            state.isRecording = action.payload;
        },
        ShowStorageData(state, action) {
            state.isStorage = action.payload;
        },
        ShowSimulcastData(state, action) {
            state.isSimulcast = action.payload;
        },
        SetTabState(state, action) {
            state.tabState = action.payload;
        },
    },
});

export const setParticapantCount = (data) => (dispatch) => {
    dispatch(slice.actions.SetParticapantCount(data));
};

export const setTimeValue = (data) => (dispatch) => {
    dispatch(slice.actions.SetTimeValue(data));
};

export const setMonthValue = (data) => (dispatch) => {
    dispatch(slice.actions.SetMonthValue(data));
};

export const setSpeakerCount = (data) => (dispatch) => {
    dispatch(slice.actions.SetSpeakerCount(data));
};

export const setViewerCount = (data) => (dispatch) => {
    dispatch(slice.actions.SetViewerCount(data));
};

export const showConferencingData = (data) => (dispatch) => {
    dispatch(slice.actions.ShowConferencingData(data));
};

export const showAudioData = (data) => (dispatch) => {
    dispatch(slice.actions.ShowAudioData(data));
};

export const showRecordingData = (data) => (dispatch) => {
    dispatch(slice.actions.ShowRecordingData(data));
};

export const showStorageData = (data) => (dispatch) => {
    dispatch(slice.actions.ShowStorageData(data));
};

export const showSimulcastData = (data) => (dispatch) => {
    dispatch(slice.actions.ShowSimulcastData(data));
};
export const setTabState = (data) => (dispatch) => {
    dispatch(slice.actions.SetTabState(data));
};

export const reducer = slice.reducer;

export default slice;