import React from "react";
import CloudImage from "src/assets/Svg/CloudImage";
import CloudLock from "src/assets/Svg/CloudLock";
import { Box, Typography, Hidden } from "@mui/material";

const CloudEdge = () => {
  return (
    <div>
      {" "}
      <Box sx={{ marginTop: "50px" }}>
        <Typography
          sx={{
            color: "white",
            textAlign: "center",
            fontSize: {
              lg: "35px",
              md: "30px",
              sm: "20px",
              xs: "16px",
            },
            fontWeight: "500",
          }}
        >
          Ingressify cloud edge
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography
            sx={{
              color: "white",
              textAlign: "center",
              fontSize: {
                lg: "16px",
                md: "16px",
                sm: "12px",
                xs: "12px",
              },
              fontWeight: "400",
              marginTop: "30px",
              width: {
                xs: "100%",
                sm: "80%",
                md: "80%",
                lg: "80%",
                xl: "80%",
              },
            }}
          >
            With our cloud edge platform, you can enhance the security,
            scalability, and observability of your applications without needing
            to modify any code.
          </Typography>
        </Box>
        <Box
          sx={{
            display: {
              xl: "flex",
              lg: "flex",
              md: "flex",
              sm: "flex",
            },

            marginTop: "40px",
          }}
        >
          <Box
            sx={{
              marginTop: {
                lg: "-55px",
                md: "-20px",
                sm: "-40px",
                xs: "-30px",
              },
              marginRight: {
                xs: "auto",
              },
              marginLeft: "auto",

              width: {
                lg: "600px",
                md: "600px",
                sm: "500px",
                xs: "300px",
              },
              height: {
                lg: "600px",
                md: "500px",
                sm: "500px",
                xs: "350px",
              },
            }}
          >
            <CloudImage />
          </Box>
          <Hidden smDown>
            {" "}
            <Box
              sx={{
                marginTop: {
                  lg: "10px",
                  md: "10px",
                  sm: "30px",
                  xs: "-30px",
                },
                marginRight: "auto",
                // marginLeft: "auto",
                // border:'1px solid green',
                width: {
                  lg: "300px",
                  md: "300px",
                  sm: "220px",
                  xs: "300px",
                },
                height: {
                  lg: "300px",
                  md: "300px",
                  sm: "220px",
                  xs: "350px",
                },
              }}
            >
              <CloudLock />
            </Box>
          </Hidden>
        </Box>
      </Box>
    </div>
  );
};

export default CloudEdge;
