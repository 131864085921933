import React from 'react'
import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";
import { ThemeProvider } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
// import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContent from '@mui/material/DialogContent';
import TextField from "@mui/material/TextField";
import { useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { editApiKeys, editTableData } from '../../slices/apiKeys';
import { RootState } from '../../store';
import Table from './table'
// import DateTimePicker from '@mui/lab/DateTimePicker';
// import { format } from 'date-fns';
// import MuiAlert, { AlertProps } from '@mui/material/Alert';
import CustomDialogBox from 'src/components/CustomDialog';
import Loader from 'src/components/Loader/Loader';
import { Divider } from '@mui/material';
import { ButtonPrimary } from 'src/components/CustomButtons';

// const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
//     props,
//     ref,
// ) {
//     return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
}

function Members() {
    const { addApiKeys, pageLoader } = useSelector((state: RootState) => state.apiKeys)
    const dispatch = useDispatch();
    const [openDailogAddApiKey, setOpenDailogAddApiKey] = React.useState(false);
    const theme = useTheme();
    const [progress, setProgress] = React.useState(false)
    // const [openFromDate, setOpenFromDate] = useState<boolean>(false);

    const onLoad = () => {
        if (addApiKeys.name.length < 3) {
            setNameValidation({ value: true, text: "Please enter minimum 3 characters" })
            setTimeout(() => {
                setNameValidation({ value: false, text: "" })
            }, 3000);
        } else {
            // dispatch(postEditApiKeys(addApiKeys))
            dispatch(editApiKeys({ key: "name", value: "" }))
            dispatch(editApiKeys({ key: "expiry", value: "" }))
            setOpenDailogAddApiKey(false)
        }
    }



    const handleClickOpen = () => {
        // dispatch(editApiKeys({ key: "name", value: "" }))
        // dispatch(editApiKeys({ key: "expiry", value: "" }))
        // setNameValidation({ value: false, text: "" })
        setOpenDailogAddApiKey(true);
    };

    const handleClose = () => {
        setOpenDailogAddApiKey(false);
    };


    const [nameValidation, setNameValidation] = React.useState({
        value: false,
        text: "",
    })
    // const searchFilter = tableData?.filter(function (data) {
    //     if (data.name === addApiKeys.name) {
    //         return data.name
    //     } else {
    //         return null
    //     }

    // })

    const handleChangeGenerateNameKeys = (e: any) => {
        const data = { key: e.target.name, value: e.target.value }
        dispatch(editApiKeys(data))
    }

    // const handleChangeGenerateExpiryKeys = (newValue) => {
    //     if (!(newValue instanceof Date && !isNaN(newValue.valueOf()))) return;
    //     const date = moment(newValue).format("YYYY-MM-DD");

    //     const currentDate = new Date().toLocaleDateString('en-CA')

    //     if (currentDate <= date) {
    //         const formatedDate = new Date(date).toISOString();
    //         const data = { key: "expiry", value: formatedDate }
    //         dispatch(editApiKeys(data))
    //     } else {
    //         setDeleteAlert(true);
    //     }
    // }

    React.useEffect(() => {
        if (progress) {
            setTimeout(() => {
                setProgress(false)
            }, 2000)
        }
    }, [progress])

    React.useEffect(() => {
        dispatch(editTableData())
    }, [dispatch])

    // const [deleteAlert, setDeleteAlert] = React.useState(false)

    // const handleCloseDeleteAlert = (event?: React.SyntheticEvent, reason?: string) => {
    //     if (reason === 'clickaway') {
    //         return;
    //     }
    //     setDeleteAlert(false);
    // };

    return (

        <>
                    {pageLoader ? <Box sx={{ height: '90vh' }}><Loader /></Box> :

                <>
                    <Box sx={{ mt: 2, mb: 2, ml: 2, mr: 2 }}>
                        <Grid container className="apikey_header justify_space_between align_center">
                            <Grid item xs={12} sm={9} md={8} >
                                <ThemeProvider theme={theme}>
                                    <Box display="flex" flexDirection="column">
                                        <Typography className="align_start apikey_heading" sx={{ color: "#3086EC" }}>
                                            Members
                                        </Typography>
                                        <Typography color="#808080" className="align_start apikey_subheading">
                                            Members for User Management
                                        </Typography>
                                    </Box>
                                </ThemeProvider>
                            </Grid>

                            <Grid item xs={12} sm={3} md={4} className="align_center justify_end">
                                <ButtonPrimary id='create-api-keys' responsive={false} onClick={handleClickOpen}>
                                    <AddIcon fontSize="small" />invite
                                </ButtonPrimary>
                            </Grid>

                        </Grid>

                            <Table />
                            <div>
                            {progress === false ?
                                <CustomDialogBox
                                    open={openDailogAddApiKey}
                                    setOpen={handleClose}
                                    background={"transparent"}
                                    closeIconId="close-view-resource-btn"
                                    width={{
                                        xs: "300px",
                                        sm: "400px",
                                        md: "450px",
                                        lg: "450px",
                                        xl: "500px",
                                    }}
                                    height={{
                                        xs: "300px",
                                        sm: "300px",
                                        md: "300px",
                                        lg: "300px",
                                        xl: "350px",
                                    }}
                                    childrens={
                                        <>
                                            <Typography sx={{ color: '#3086EC', m: 0, p: '16px 16px 0 16px', fontSize: "16px", }}>
                                                Invite
                                            </Typography>
                                            <Divider sx={{ width: '100%' }} />
                                            <DialogContent sx={{ width: '100%' }}>
                                                <Grid container>
                                                    <Grid item md={12} xs={12} lg={12} sx={{ mt: 0 }}>
                                                        <Typography>Name</Typography>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            id="outlined-basic"
                                                            label=""
                                                            name="name"
                                                            onChange={handleChangeGenerateNameKeys}
                                                            value={addApiKeys.name}
                                                            placeholder="Enter name here"
                                                            variant="outlined"
                                                            InputLabelProps={{ shrink: false }}
                                                            sx={{ width: '100%' }}
                                                        />
                                                        <Typography color="red">{nameValidation.text?.length > 0 ? <>{nameValidation.text}</> : ""}</Typography>
                                                    </Grid>

                                                    <Grid item md={12} xs={12} lg={12} sx={{ mt: 2 }}>
                                                        <Typography>Email</Typography>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            id="outlined-basic"
                                                            label=""
                                                            name="name"
                                                            onChange={handleChangeGenerateNameKeys}
                                                            value={addApiKeys.name}
                                                            placeholder="Enter email here"
                                                            variant="outlined"
                                                            InputLabelProps={{ shrink: false }}
                                                            sx={{ width: '100%' }}
                                                        />

                                                    </Grid>
                                                </Grid>
                                            </DialogContent>

                                            <Box sx={{ width: '96%', display: 'flex', justifyContent: 'end', mb: "3%" }}>
                                                <ButtonPrimary id='generate' responsive={false} onClick={onLoad}>
                                                    <AddIcon fontSize="small" />Invite
                                                </ButtonPrimary>
                                            </Box>
                                        </>
                                    }
                                />
                                : <Box width="100%" height="40%" display="flex" position="relative" justifyContent="center" alignItems="center"><CircularProgress /></Box>}

                            </div>
                    </Box>
                </>
}
        </>
    )
}

export default Members
