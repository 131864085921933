import { Divider, Grid, IconButton } from '@mui/material'
import { useState } from 'react'
import {
  DragAndDrop,
  DragAndDropText,
  FileContainer,
  FileHeader,
  FileWrapper,
  PreviewBox,
} from './indexStyled'
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone'
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone'
import FileUploadTwoToneIcon from '@mui/icons-material/FileUploadTwoTone'

// reducer
import { useDispatch, useSelector } from 'react-redux'
import { ProfilePageActions } from 'src/slices/ProfilePage/ProfilePageFormSlice'
import { DeleteIdProof, DeleteProfileImage } from './DeleteFileModal'
// reducer

export default function FileUploads() {
  const dispatch = useDispatch()
  const user = useSelector(
    (state: any) => state.profilePage.profileformData.profileData,
  )

  const [profileImgPreview, setProfileImgPreview] = useState<string>(
    user.profile_photo,
  )
  const [idProofPreview, setIdProofPreview] = useState<string>(user.id_proof)
  const [deleteProfileModal, setDeleteProfileModal] = useState<boolean>(false)
  const [deleteIdProofModal, setDeleteIdProofModal] = useState<boolean>(false)
  // profile picture handler
  const profilePictureHandler = (e: any) => {
    const profile = e.target.files[0]
    console.log('filee', e)
    if (
      profile.type === 'image/jpeg' ||
      profile.type === 'image/jpg' ||
      profile.type === 'image/png' ||
      profile.type === 'image/avif'
    ) {
      const reader = new FileReader()
      reader.onload = () => {
        if (reader.readyState === 2) {
          setProfileImgPreview(reader.result as string)
        }
      }
      reader.readAsDataURL(profile)
      dispatch(ProfilePageActions.setProfilePicture(profile))
    }
  }
  // profile picture handler
  // ID Proofre handler
  const idProofHandler = (e: any) => {
    const idproof = e.target.files[0]
    if (
      idproof.type === 'image/jpeg' ||
      idproof.type === 'image/jpg' ||
      idproof.type === 'image/png' ||
      idproof.type === 'image/avif'
    ) {
      const idReader = new FileReader()
      idReader.onload = () => {
        if (idReader.readyState === 2) {
          setIdProofPreview(idReader.result as string)
        }
      }
      idReader.readAsDataURL(idproof)
      dispatch(ProfilePageActions.setIdProof(idproof))
    }
  }
  // ID Proofre handler

  //   delete  handler
  const deleteProfilePictureHandler = () => {
    setDeleteProfileModal(true)
  }
  const idProofDeleteHandler = () => {
    setDeleteIdProofModal(true)
  }

  const closeDeleteProfile = () => {
    setDeleteProfileModal(false)
  }
  const closeDeleyeIdProof = () => {
    setDeleteIdProofModal(false)
  }

  return (
    <>
      <Grid container>
        <FileWrapper item xs={12} sm={6} md={6} lg={12}>
          <FileContainer container>
            <Grid item xs={8}>
              <FileHeader>Profile Picture</FileHeader>
            </Grid>
            <Grid item xs={2}>
              <label
                htmlFor="contained-button-file-profilePicture"
                style={{
                  width: 'inherit',
                  height: 'inherit',
                  position: 'relative',
                  top: 0,
                  left: 0,
                }}
              >
                <input
                  accept="image/*"
                  id="contained-button-file-profilePicture"
                  type="file"
                  name="profile_picture"
                  style={{
                    width: '32px',
                    opacity: 0,
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                    zIndex: 100,
                  }}
                  onChange={profilePictureHandler}
                />
                <IconButton>
                  <ModeEditTwoToneIcon sx={{ color: '#1a75ff' }} />
                </IconButton>
              </label>
            </Grid>
            <Grid item xs={2}>
              {profileImgPreview !== '' ? (
                <IconButton onClick={deleteProfilePictureHandler}>
                  <DeleteTwoToneIcon style={{ color: '#ff1a43' }} />
                </IconButton>
              ) : (
                <IconButton
                  disableRipple
                  style={{ color: 'rgb(211, 211, 211)' }}
                >
                  <DeleteTwoToneIcon />
                </IconButton>
              )}
            </Grid>
          </FileContainer>
          <Divider />
          {profileImgPreview === '' ? (
            <DragAndDrop>
              <label
                htmlFor="contained-button-file-profile"
                style={{
                  height: '100%',
                  width: '100%',
                  textTransform: 'lowercase',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: 'auto',
                  overflow: 'Hidden',
                  position: 'relative',
                  top: '0px',
                  left: '0px',
                }}
              >
                <input
                  accept="image/*"
                  id="contained-button-file-profile"
                  type="file"
                  name="profile_picture"
                  style={{
                    width: 'inherit',
                    height: 'inherit',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    opacity: 0,
                    zIndex: 100,
                  }}
                  onChange={profilePictureHandler}
                />

                <FileUploadTwoToneIcon
                  style={{
                    color: '#1A75FF',
                    width: '28.67px',
                    height: '28.67px',
                  }}
                />
                <DragAndDropText>drag & drop file here</DragAndDropText>
              </label>
            </DragAndDrop>
          ) : (
            <PreviewBox>
              <img
                src={profileImgPreview}
                alt="profilepicture"
                height="100%"
                width="100%"
              />
            </PreviewBox>
          )}
        </FileWrapper>
        <FileWrapper item xs={12} sm={6} md={6} lg={12}>
          <FileContainer container>
            <Grid item xs={8}>
              <FileHeader>ID Proof</FileHeader>
            </Grid>
            <Grid item xs={2}>
              <label
                htmlFor="contained-button-file-idproof"
                style={{
                  width: 'inherit',
                  height: 'inherit',
                  position: 'relative',
                  top: 0,
                  left: 0,
                }}
              >
                <input
                  accept="image/*"
                  id="contained-button-file-idproof"
                  type="file"
                  name="id_proof"
                  style={{
                    width: '32px',
                    opacity: 0,
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                    zIndex: 100,
                  }}
                  onChange={idProofHandler}
                />
                <IconButton>
                  <ModeEditTwoToneIcon sx={{ color: '#1a75ff' }} />
                </IconButton>
              </label>
            </Grid>
            <Grid item xs={2}>
              {idProofPreview !== '' ? (
                <IconButton onClick={idProofDeleteHandler}>
                  <DeleteTwoToneIcon style={{ color: '#ff1a43' }} />
                </IconButton>
              ) : (
                <IconButton
                  disableRipple
                  style={{ color: 'rgb(211, 211, 211)' }}
                >
                  <DeleteTwoToneIcon />
                </IconButton>
              )}
            </Grid>
          </FileContainer>
          <Divider />
          {idProofPreview === '' ? (
            <DragAndDrop>
              <label
                htmlFor="contained-button-file-idproof"
                style={{
                  height: '100%',
                  width: '100%',
                  textTransform: 'lowercase',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: 'auto',
                  overflow: 'Hidden',
                  position: 'relative',
                  top: '0px',
                  left: '0px',
                }}
              >
                <input
                  accept="image/*"
                  id="contained-button-file-idproof"
                  type="file"
                  name="id_proof"
                  style={{
                    width: 'inherit',
                    height: 'inherit',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    opacity: 0,
                    zIndex: 100,
                  }}
                  onChange={idProofHandler}
                />

                <FileUploadTwoToneIcon
                  style={{
                    color: '#1A75FF',
                    width: '28.67px',
                    height: '28.67px',
                  }}
                />
                <DragAndDropText>drag & drop file here</DragAndDropText>
              </label>
            </DragAndDrop>
          ) : (
            <PreviewBox>
              <img
                src={idProofPreview}
                alt="profilepicture"
                height="100%"
                width="100%"
              />
            </PreviewBox>
          )}
        </FileWrapper>
      </Grid>

      {/* delete profile picture modal */}
      {deleteProfileModal && (
        <DeleteProfileImage
          open={deleteProfileModal}
          setOpen={closeDeleteProfile}
        />
      )}
      {/* delete profile picture modal */}

      {/* delete id proof modal */}
      {deleteIdProofModal && (
        <DeleteIdProof open={deleteIdProofModal} setOpen={closeDeleyeIdProof} />
      )}
      {/* delete profile modal */}
    </>
  )
}
