import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles, ThemeProvider, useTheme } from "@mui/styles";
import AddIcon from '@mui/icons-material/Add';
import {
  Grid,
  Hidden,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import AddWebHook from "./AddWebHook";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import Chip from "@mui/material/Chip";
import {
  webhookInfo,
  fetchWebhookTable,
  webHookEdit,
  deleteWebhook,
  setOpenWebhook,
  webhookMode1,
  setStoreId1,
  setHookNamesObject,
} from "../../slices/WebHook";
import { useEffect } from "react";
import { List, ListItem, Divider, ListItemText } from '@mui/material';
import { Scrollbars } from "react-custom-scrollbars-2";
import "./Webhook.css";
import CloseAlert from "src/components/AlertDialog";
import Loader from "src/components/Loader/Loader";
import { ButtonPrimary } from "src/components/CustomButtons";
import WebHookIcon from "src/assets/icons/webHookIcon";

const useStyles = makeStyles((theme) => ({
  labelcolor: {
    color: "black",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: ".5rem",
  },
  webhookTitle: {
    fontWeight: "bold",
    fontSize: "22px",
    display: "flex",
    justifyContent: "space-between",
    '@media(max-Width: 600px)': {
      fontSize: "16px",
    }
  },
  webText: {
    fontWeight: "bold",
    marginTop: ".5rem",
  },
  tableColor: {
    backgroundColor: "#DEE2F3",
  },
  textStyle: {
    fontWeight: "bold",
  },
  buttonStyle: {
    textTransform: "none",
  },
  dialog: {
    width: "25rem",
    '@media(max-Width: 600px)': {
      width: '100%'
    }
  },
  col: {
    display: "flex",
    flexDirection: "column",
    rowGap: "1.5rem",
    marginTop: "1rem"
  },
  updatebutton: {
    backgroundColor: "#0096FF",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#0096FF",
      color: "#ffffff",
    },
  },
  nobutton: {
    backgroundColor: "#F76E11",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#F76E11",
      color: "#ffffff",
    },
  },
  dialogCloseButton: {
    paddingRight: '5px',
    paddingLeft: '5px',
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '-10px',
    marginTop: '-5px',
    minWidth: '32px'
  }
}));

const ITEM_HEIGHT = 30;


export default function WebHookRegister() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { allData, storeId, hookNamesObj, tableLoader } = useSelector((state: RootState) => state.WebHook);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>, id: any) => {
    dispatch(setStoreId1(id));
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (e: any) => {
    handleClose();
    dispatch(deleteWebhook(storeId));
  };
  const [deleteOpen, setOpen] = React.useState(false);
  const handleClickOpenConfirmation = (e: any) => {
    setOpen(true);
  };
  const handleCloseDeleteButton = () => {
    setOpen(false);
    handleClose();
  };

  const handleEdit = (e: any, id: any) => {
    dispatch(setOpenWebhook(true))
    dispatch(webhookMode1("edit"))
    handleClose();
    const data = allData.filter((value) => value.id === storeId);
    if (data[0]) {
      dispatch(webHookEdit(data[0].appId));
      dispatch(webhookInfo({ key: "button", value: false }));
      const checkboxmark = hookNamesObj.map((item) => {
        let isChecked = false;
        if (data[0] && data[0].registeredHookNames && data[0].registeredHookNames.length) {
          isChecked = data[0]?.registeredHookNames.includes(item.name)
        }
        return { ...item, checked: isChecked }
      });
      dispatch(setHookNamesObject(checkboxmark));
    }
  };

  useEffect(() => {
    dispatch(fetchWebhookTable());
  }, [dispatch]);

  return (
    <>
      {tableLoader ? <Box sx={{ height: '100%' }}><Loader /></Box> :
        <>
          <Box sx={{ pl: "2rem", pr: "2rem" }}>
            <Grid container className="apikey_header justify_space_between align_center" sx={{ mt: "3rem" }} rowGap={2}>
              <Grid item xs={12} sm={9} md={8} >
                <ThemeProvider theme={theme}>
                  <Box display="flex" flexDirection="column">
                    <Typography className="align_start apikey_heading" sx={{ color: "#3086EC" }}>
                      Web Hooks
                    </Typography>
                    <Typography color="#808080" className="align_start apikey_subheading">
                      Create webhooks for your application
                    </Typography>
                  </Box>
                </ThemeProvider>
              </Grid>
              <Grid item xs={12} sm={3} md={4} className="align_center justify_end">
                {allData?.length > 0 &&
                  <ButtonPrimary id='add-web-hooks' responsive={false} onClick={() => dispatch(setOpenWebhook(true))}>
                    <AddIcon fontSize="small" />
                    <Typography sx={{fontSize:{xs:'10px', sm:'14px'}}}>Add Webhooks</Typography>
                  </ButtonPrimary>}
              </Grid>
            </Grid>

            {allData === null || allData?.length === 0 ?
              <Box sx={{ width: '100%', height: '60vh', display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: 'center' }}>
                <WebHookIcon props={{ height: "50%", width: '20vh' }} />
                <ButtonPrimary id='add-web-hooks' responsive={false} onClick={() => dispatch(setOpenWebhook(true))}>
                  <AddIcon fontSize="small" />
                  Add Webhooks
                </ButtonPrimary>
              </Box> : <>
                <Box>
                  <TableContainer component={Paper} sx={{ mt: "1rem", width: "100%" }} className="webhook_table_container">
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell width="20%" align="left">App Name</TableCell>
                          <TableCell width="45%" align="left">Hook Name</TableCell>
                          <TableCell width="25%" align="left">Created Date</TableCell>
                          <TableCell width="10%" align="left">Status</TableCell>
                          <TableCell align="center">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allData &&
                          allData.map((row) => (
                            <TableRow
                              key={row.appName}
                              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                              <TableCell component="th" scope="row" align="left" sx={{ maxWidth: "200px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                {row?.appName?.length > 20 ?
                                  <Tooltip title={row.appName} placement={'top-start'}
                                    componentsProps={{
                                      tooltip: {
                                        sx: {
                                          padding: '10px',
                                          // '& .MuiTooltip-arrow': {
                                          //   color: 'common.black',
                                          // },
                                        },
                                      },
                                    }}>
                                    <span> {row.appName}</span>
                                  </Tooltip>
                                  : <span> {row.appName}</span>
                                }
                              </TableCell>
                              <TableCell align="left">
                                {row.registeredHookNames &&
                                  row.registeredHookNames.map((value) => (
                                    <Chip
                                      label={value}
                                      sx={{ marginRight: "2px", marginBottom: "3px" }}
                                    />
                                  ))}
                              </TableCell>
                              <TableCell align="left">
                                {new Date(row.createdDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }).replace(/\//g, '-')}
                              </TableCell>
                              <TableCell align="left">
                                <Typography
                                  component="div"
                                  variant="body2"
                                  color="text.primary"
                                  sx={{ textAlign: "center" }}
                                  className={`${row.status === 'active' ? 'active_webhook_status' : 'inactive_webhook_status'}`}
                                >
                                  {row.status.toUpperCase()}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <IconButton
                                  aria-label="more"
                                  aria-controls={open ? "long-menu" : undefined}
                                  aria-expanded={open ? "true" : undefined}
                                  aria-haspopup="true"
                                  onClick={(event) => handleClick(event, row.id)}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                                <Menu
                                  id="webhook-menu"
                                  MenuListProps={{
                                    "aria-labelledby": "long-button",
                                  }}
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                  PaperProps={{
                                    style: {
                                      maxHeight: ITEM_HEIGHT * 4.5,
                                      width: "15ch",
                                      marginLeft: "-4rem",
                                    },
                                  }}
                                >
                                  <MenuItem onClick={(e) => handleEdit(e, row.id)}>
                                    Edit
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => handleClickOpenConfirmation(row.id)}
                                  >
                                    Delete
                                  </MenuItem>
                                  <CloseAlert
                                    id={() => handleDelete(row.id)}
                                    openCloseDialog={deleteOpen}
                                    setOpenCloseDialog={handleCloseDeleteButton}
                                    accept={`Yes`}
                                    cancel={`No`}
                                    title={`Are you sure want to delete this record?`}
                                    mainTitle={`Delete webhooks`}
                                    subTitle={
                                      "This action cannot be undone. Please make sure you are performing the correct operation."
                                    }
                                    textColor={"#FF5A5A"}
                                    BackgroundColor={"#FF5A5A"}
                                  />
                                </Menu>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Hidden smUp>
                  <Box sx={{ mt: 2, mb: 2, ml: 0, mr: 0, }}>
                    <div>
                      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <Scrollbars style={{ height: 450 }}>
                          {allData &&
                            allData.map((row, index) => (
                              <React.Fragment key={index}>
                                <ListItem alignItems="center" disablePadding>
                                  <ListItemText
                                    primary={
                                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography sx={{ fontWeight: "bold", maxWidth: "180px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                          {row.appName}
                                        </Typography>
                                        <IconButton
                                          id="fade-button"
                                          onClick={(event) => handleClick(event, row.id)}
                                          aria-controls={open ? 'fade-menu' : undefined}
                                          aria-haspopup="true"
                                          aria-expanded={open ? 'true' : undefined}
                                        ><MoreVertIcon /></IconButton>
                                      </div>
                                    }
                                    secondary={
                                      <>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                          <Typography
                                            sx={{ mt: "2px" }}
                                            component="div"
                                            variant="body2"
                                            color="text.primary"
                                          >
                                            {new Date(row.createdDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }).replace(/\//g, '-')}
                                          </Typography>
                                          <Typography
                                            component="div"
                                            variant="body2"
                                            color="text.primary"
                                            className={`${row.status === 'active' ? 'active_webhook_status' : 'inactive_webhook_status'} `}
                                          >
                                            {row.status.toUpperCase()}
                                          </Typography>
                                        </div>
                                        <div>
                                          <Typography
                                            sx={{ mt: "2px", fontWeight: "bold" }}
                                            component="div"
                                            variant="body2"
                                            color="text.primary"
                                          >
                                            {row.registeredHookNames && row.registeredHookNames.map((data) =>
                                              <Chip
                                                label={data}
                                                sx={{ marginRight: "2px", marginBottom: "3px", fontSize: "10px" }}
                                              />)}
                                          </Typography>
                                        </div>
                                      </>
                                    }
                                  />
                                </ListItem>
                                <Divider variant="inset" component="li" sx={{ ml: 0, mt: 1, mb: 1 }} className="divider" />
                              </React.Fragment>
                            ))}
                        </Scrollbars>
                      </List>
                    </div>
                  </Box>

                </Hidden>
              </>}
          </Box>
        </>}
      <Box className={classes.row}>
        <AddWebHook />
      </Box>
    </>
  );
}
