import React, { ChangeEvent, useState } from 'react'
import { Box, CardContent, Tabs, Tab, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { experimentalStyled } from '@mui/material/styles'
import ConferenceTable from './ConferenceTable'
// import LibraryBooksSharpIcon from '@mui/icons-material/LibraryBooksSharp'
import ConferenceTableMobile from './ConferenceTable/MobileVersion/index'

import { styled } from '@mui/material/styles'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import WebhookHits from './WebhookHits'
import WebHookHitsMobileVersion from './WebhookHits/MobileVersion'
import NotificationsTable from './Notification'
import NotificationTableMobileVersion from './Notification/MobileVersion'

const DesktopVersion = styled(Box)`
  ${(props) => props.theme.breakpoints.down('sm')} {
    display: none;
  }
`
const MobileVersion = styled(Box)`
  display: none;
  ${(props) => props.theme.breakpoints.down('sm')} {
    display: block;
  }
`

const TabsContainerWrapper = experimentalStyled(CardContent)(
  ({ theme }) => `
      background-color: ${theme.colors.alpha.black[5]};
`,
)

function Index() {
  const { t }: { t: any } = useTranslation()
  const [mobileActiveValue, setMobileActiveValue] = useState('1')

  const [currentTab, setCurrentTab] = useState<string>('conferenceReport')
  const tabs = [
    { value: 'conferenceReport', label: t('Conference Report') },
    { value: 'webhookHits', label: t('Webhook Hits') },
    { value: 'notifications', label: t('Notifications') },
  ]
  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value)
  }
  const handleChange = (e: React.SyntheticEvent, newValue: string) => {
    setMobileActiveValue(newValue)
  }

  return (
    <>
      {/* desktop version */}
      <DesktopVersion>
        <Grid
          container
          style={{
            // position: 'sticky',
            // top: 0,
            zIndex: 100,
            display: 'flex',
            justifyContent: 'flex-start',
            height: 'auto',
            width: '100%',
          }}
        >
          <Grid xs={12} md={12} lg={112} xl={12}>
            <TabsContainerWrapper>
              <Tabs
                onChange={handleTabsChange}
                value={currentTab}
                variant="scrollable"
                scrollButtons="auto"
                textColor="primary"
                indicatorColor="primary"
              >
                {tabs.map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </Tabs>
            </TabsContainerWrapper>
          </Grid>
        </Grid>

        <Box>
          {currentTab === 'conferenceReport' && (
            <>
              <ConferenceTable />
            </>
          )}
          {currentTab === 'webhookHits' && (
            <>
              <WebhookHits />
            </>
          )}
          {currentTab === 'notifications' && (
            <>
              <NotificationsTable />
            </>
          )}
        </Box>
      </DesktopVersion>
      {/* mobile version */}
      <MobileVersion>
        <TabContext value={mobileActiveValue}>
          <Box style={{ padding: '5px 15px' }}>
            <TabList onChange={handleChange}>
              <Tab
                label="Conference"
                value="1"
                // icon={<LibraryBooksSharpIcon />}
                // iconPosition="start"
              />
              <Tab
                label="Webhook Hits"
                value="2"
                // icon={<LibraryBooksSharpIcon />}
              />
              <Tab
                label="Notifications"
                value="3"
                // icon={<LibraryBooksSharpIcon />}
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <ConferenceTableMobile />
          </TabPanel>
          <TabPanel value="2">
            <WebHookHitsMobileVersion />
          </TabPanel>
          <TabPanel value="3">
            <NotificationTableMobileVersion />
          </TabPanel>
        </TabContext>
      </MobileVersion>
    </>
  )
}
export default Index
