import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'

// table add filter button
export const GridContainer = styled(Grid)`
  box-shadow: 0px 9px 16px rgba(159, 162, 191, 0.18),
    0px 2px 2px rgba(159, 162, 191, 0.32);
  border-radius: 6px;
  padding: 15px 0px;
  height: auto;
  margin: 0;
`
export const DataBox = styled(Box)`
  //   box-shadow: 0px 9px 16px rgba(159, 162, 191, 0.18),
  //     0px 2px 2px rgba(159, 162, 191, 0.32);
  //   border-radius: 6px;
  padding: 15px 0px 20px 0;
  height: auto;
  width: 100%;
`
