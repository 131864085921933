import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  Typography,
  Grid,
  Dialog,
} from '@mui/material'
import { styled } from '@mui/material/styles'

// header
export const ProfilePageHeaderBox = styled(Box)`
  width: 100%;
  //   height: 70px;
  background: #f6f8fb;

  ${(props) => props.theme.breakpoints.down('sm')} {
    // height: 69px;
  }
`
export const ProfilePageHeaderHeader = styled(Typography)`
  padding: 10px 28px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 25px;
  color: #000000;
  ${(props) => props.theme.breakpoints.down('sm')} {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    padding: 15px 19px;
  }
`
// form wrapper
export const FormWrapper = styled(Box)`
  margin-top: 5px;
  margin-left: 2px;
  padding: 15px 28px;
  // background: white;
  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: 15px 19px;
  }
`

// textfield wrapper
export const TextFieldWrapper = styled(Box)`
  width: 100%;
  height: auto;
  margin-bottom: 20px;
`
export const PaswordWrapperLG = styled(Box)`
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    display: none;
  }
`
export const PaswordWrapperXS = styled(Box)`
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  display: none;
  ${(props) => props.theme.breakpoints.down('sm')} {
    display: block;
  }
`

// error message
export const ErrorMessage = styled(Typography)`
  color: #f80f0f;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  padding-top: 5px;
  padding-left: 10px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 13px;
  }
`
// reset button

export const ResetButton = styled(Button)`
  background: #1a75ff;
  color: #ffffff;
  border-radius: 6px;
  :hover {
    background: #1a75ff;
    color: #ffffff;
  }
  text-transform: capitalize;
  font-family: 'Roboto';
  margin-top: 15px;
  font-weight: 700;
  font-size: 16px;
`
// form submit button
export const FormSubmitButton = styled(Button)`
  background: #1a75ff;
  width: 120px;
  border-radius: 6px;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  text-transform: capitalize;
  :hover {
    background: #1a75ff;
    color: #ffffff;
  }
`
export const FormSubmitButtonLoading = styled(LoadingButton)`
  width: 120px;
  background: rgba(34, 51, 84, 0.05);
`

// password reset dailog box

export const PaswordResetModal = styled(Dialog)`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => props.theme.breakpoints.down('sm')} {
    align-items: flex-flex-start;
    justify-content: center;
    margin-top: 74px;
    z-index: 400;
  }
`
export const PaswordResetContainer = styled(Box)`
  margin: auto;
  width: 643px;
  height: auto;
  // background: #ffffff;
  // border: 1px solid #cacaca;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  ${(props) => props.theme.breakpoints.down('sm')} {
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 340px;
    height: 100%;
    margin: 0;
    padding: 0;
    // transform: translateY(40px);
  }
`

export const PaswordResetCloseBox = styled(Stack)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

export const PaswordResetClose = styled(IconButton)`
  // color: #1a75ff;
  // width: 27px;
  height: 27px;
  padding-right: 65px;
  margin-top: 29px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  ${(props) => props.theme.breakpoints.down('sm')} {
    position:absolute:
    right:10px;
    top:-12px;
    // // width: 45px;
    transform: translateX(40px);
    // transform: translateY(-20px);
  }
`

export const PaswordResetHeader = styled(Stack)`
  margin: 7px 65px;
  height: auto;

  ${(props) => props.theme.breakpoints.down('sm')} {
    position: absolute;
    top: 17px;
    left: 0;
    margin: 0 17px;
  }
`

export const PaswordResetTitle = styled(Typography)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  // color: #000000;
`

export const PaswordResetFieldsForm = styled(Stack)`
  margin: 35px 65px;
  height: auto;
  width: 493px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  ${(props) => props.theme.breakpoints.down('sm')} {
    width: auto;
    margin: 20px 17px;
  }
`
export const PaswordResetFields = styled(Box)`
  width: inherrit;
  height: auto;
  display: flex;
  flex-direction: column;
  // gap: 0.3rem;
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100%;
    gap: 0.5rem;
  }
`

export const PaswordResetError = styled(Typography)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  padding-left: 6px;
  color: #f80f0f;
`

// export const PaswordResetButtonBox = styled(Box)`
//   height:auto;
//   width:100%:
//   // border:2px solid red;
//   display:flex;
//   justify-content:flex-end;

//   `
export const ResetButtonWrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;
  ${(props) => props.theme.breakpoints.down('sm')} {
    justify-content: flex-end;
  }
`
export const PaswordResetButton = styled(Button)`
  width: 150px;
  height: 45px;
  background: #1a75ff;
  border-radius: 6px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  text-transform: capitalize;
  :hover {
    background: #1a75ff;
    color: #ffffff;
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 16px;
  }
`
export const PaswordResetButtonLoading = styled(LoadingButton)`
  width: 150px;
  height: 45px;
  background: rgba(34, 51, 84, 0.05);
`
export const PaswordResetButtonDisabled = styled(Button)`
  width: 150px;
  height: 45px;
  background: rgba(34, 51, 84, 0.15);
  border-radius: 6px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 16px;
  }
`
// delete profile files modal

export const DeleteFilesModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => props.theme.breakpoints.down('sm')} {
    align-items: flex-flex-start;
    justify-content: center;
  }
`

export const DeleteFilesModalBox = styled(Box)`
  margin: auto;
  width: 582px;
  height: 291px;
  background: #ffffff;
  border: 1px solid #cacaca;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  top: 0;
  left: 0;

  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 90%;
    height: auto;
  }
`

export const ClosedalBox = styled(Stack)`
  width: 31px;
  height: auto;
  float: right;
  margin-top: 18px;
  margin-right: 16px;
  background: #e8f1ff;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ClosedalButton = styled(IconButton)`
  color: #1a75ff;
  padding: 3.83px 6.33px;
`

export const DeleteFilesModalTitle = styled(Typography)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 130.9%;
  color: #223354;
  margin: 68px 40px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 19px;
    margin: 68px 20px;
  }
`

export const DeleteFilesModalConfirmButton = styled(Button)`
  padding: 22px;
  width: 140px;
  height: 38px;
  background: #ffcbca;
  border-radius: 6px;
  :hover {
    background: #ffcbca;
    color: #da100b;
  }

  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #da100b;
  text-transform: capitalize;
  position: absolute;
  bottom: 43px;
  right: 29px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: 18px;
    width: 120px;
    height: 34px;
    bottom: 18px;
    right: 29px;
  }
`

// files
export const FileWrapper = styled(Grid)`
  width: 100%;
  height: auto;
  padding: 5px 10px;
  box-shadow: 0px 9px 16px rgba(159, 162, 191, 0.18),
    0px 2px 2px rgba(159, 162, 191, 0.32);
  border-radius: 6px;
  margin-bottom: 1.5rem;
`
export const FileContainer = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const FileHeader = styled(Typography)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #1a75ff;

  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 16px;
    line-height: 19px;
  }
`

export const DragAndDrop = styled(Box)`
  width: 100%;
  height: 130px;
  background: rgba(34, 51, 84, 0.05);
  border: 1px dashed rgb(34, 51, 84, 0.5);
  margin: 13px 0;
`
export const DragAndDropText = styled(Typography)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 130.3%;
  color: rgba(34, 51, 84, 0.5);
`
export const PreviewBox = styled(Box)`
  width: 100%;
  height: 130px;
  margin: 10px 0;
  border-radius: 6px;
  overflow: hidden;
  margin: 13px 0;
`

// const tagsArray = []
//     for (const k in value) {
//       tagsArray.push([value[k].tag_name])
//     }
//     console.log('tagsArray', tagsArray)
