import React from 'react';
import { Grid, Box, Divider } from '@mui/material';
import { ButtonPrimary } from 'src/components/CustomButtons';
import RewardIcon from 'src/assets/icons/reward';
import { CustomBillingInfoCard, CustomBox, CustomInfoCard, Heading, SmallText, SubHeading } from './pricingStyle';
// import { useNavigate } from "react-router-dom";
// import useAuth from "src/hooks/useAuth";

const TopCard = () => {
    // const { isAuthenticated } = useAuth();
    // const { loginWithPopup } = useAuth() as any;
    // const navigate = useNavigate();

    const topCardData = [
        {
            id: 0,
            icon: <RewardIcon props={{ width: '38px', height: '38px' }} />,
            head: "Pay as you go",
            content: "No upfront, first use then pay",
        },
        {
            id: 1,
            icon: <RewardIcon props={{ width: '38px', height: '38px' }} />,
            head: "Free 10,000 minutes",
            content: "Every month free conferencing mins",
        },
        {
            id: 2,
            icon: <RewardIcon props={{ width: '38px', height: '38px' }} />,
            head: "Volume discounts",
            content: "Never worry about large invoices",
        },
    ];

    const middleCardData = [
        {
            id: 0,
            icon: <RewardIcon props={{ width: '38px', height: '38px' }} />,
            head: "Audio & Video Conferencing",
            subheadname1: 'Video call',
            subheadrate1: '$0.003',
            person1: "per participant min",
            rule1: <Divider />,
            subheadname2: 'Audio call',
            subheadrate2: '$0.0006',
            person2: "per participant min",
            rule2: '',
            subheadname3: '',
            subheadrate3: '',
            person3: "",
        },
        {
            id: 1,
            icon: <RewardIcon props={{ width: '38px', height: '38px' }} />,
            head: "Interactive Live Streaming",
            subheadname1: 'Livestream (Speaker)',
            subheadrate1: '$0.003',
            rule1: <Divider />,
            person1: "per speaker min",
            subheadname2: 'Livestream (Viewer)',
            subheadrate2: '$0.0015',
            person2: "per viewer min",
            rule2: <Divider />,
            subheadname3: 'Encoding',
            subheadrate3: '$0.0015',
            person3: "per encoded min",
        },
    ];

    // const selectPlan = async (): Promise<void> => {
    //     try {
    //         await loginWithPopup();
    //         navigate("/plans");
    //     } finally {
    //         console.log("Logged In");
    //     }
    // }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '50px' }}>
            <Grid container sx={{ width: { xs: '95%', sm: '90%', md: '70%' }, margin: 'auto', display: 'flex', justifyContent: 'center' }} columnGap={2} rowGap={2} >
                {topCardData.map((data, index) =>
                    <Grid item xs={12} sm={3} md={3.5} lg={3.5} xl={3.5} key={index}>
                        <CustomInfoCard>
                            <SubHeading gutterBottom>{data.icon}</SubHeading>
                            <Heading gutterBottom >{data.head}</Heading>
                            <SubHeading gutterBottom>{data.content}</SubHeading>
                        </CustomInfoCard>
                    </Grid>
                )}
            </Grid>
            <Grid container sx={{ width: { xs: '95%', sm: '100%', md: '80%' }, margin: 'auto', display: 'flex', justifyContent: 'center' }} columnGap={2} rowGap={2} >
                {middleCardData.map((data, index) =>
                    <Grid item xs={12} sm={5.5} md={5.5} lg={5.5} xl={5.5} key={index}>
                        <CustomBillingInfoCard>
                            <div style={{ height: '400px' }}>
                                <SubHeading gutterBottom>{data.icon}</SubHeading>
                                <Heading gutterBottom sx={{ marginBottom: '50px' }}>{data.head}</Heading>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <SubHeading gutterBottom >{data.subheadname1}</SubHeading>
                                    <SubHeading gutterBottom>{data.subheadrate1}</SubHeading>
                                </Box>
                                <SmallText gutterBottom>{data.person1}</SmallText>
                                <Heading gutterBottom sx={{ marginBottom: '10px' }}>{data.rule1}</Heading>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <SubHeading gutterBottom >{data.subheadname2}</SubHeading>
                                    <SubHeading gutterBottom>{data.subheadrate2}</SubHeading>
                                </Box>
                                <SmallText gutterBottom >{data.person2}</SmallText>
                                <Heading gutterBottom sx={{ marginBottom: '10px' }}>{data.rule2}</Heading>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <SubHeading gutterBottom >{data.subheadname3}</SubHeading>
                                    <SubHeading gutterBottom>{data.subheadrate3}</SubHeading>
                                </Box>
                                <SmallText gutterBottom sx={{ marginBottom: '10px', textAlign: 'right' }}>{data.person3}</SmallText>
                            </div>
                            <CustomBox>
                                <ButtonPrimary
                                    id="estimate-your-usage"
                                    responsive={false}
                                    margin="1.3rem 1.3rem 0 0"
                                    fixedHeight="40px"
                                // onClick={() => navigate("/contact-us")}
                                >
                                    Estimate your usage
                                </ButtonPrimary>
                            </CustomBox>
                        </CustomBillingInfoCard>
                    </Grid>
                )}
            </Grid>
        </Box>
    )
}

export default TopCard;