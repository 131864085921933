export const scoreWords = ['Too short', 'Weak', 'Good', 'Strong'];
export const mobileEmailError = 'Please enter a valid Email or Mobile number';
export const statusConstant = { reject: 'Rejected', approve: 'Accepted', pending: 'Pending' };
export const monthList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const errorMessage = {
    validateField: 'Please enter a valid',
    validateName: 'Please enter name with minimum 3 character and maximum 64',
    cardNumber:"Please enter 16 digits",
    cvv:"Please enter correct CVV",
    nameOnCard:"Please enter name with minimum 3 character and maximum 64",
    postalCode:"Please enter max 10 numeric digits",
    couponCode:"Please enter maximum 16 numeric digits",
    expiration:"Please type correct expiration date",
    validUrl: "Please enter an valid url"
 
};
export const dataNotFound = "Data not found";
export const dateFormat = '2022-05-03';
export const String = {
    deviceStatus: [
        { label: 'Accepted' },
        { label: 'Rejected' },

    ],
    assignFilter:[
        {label: 'All'},
        {label: 'Unassigned'},
        {label:"Assigned"}
    ]
};

export const audioVideoText = {
    title:'Live Audio & Video Calling SDK',
    subTitle:'Portego provides live audio-video global infrastructure, Easily integrates real-time audio-video to your app or website in minutes.',
    button1:'Try now, its free',
    button2:'Talk to us',
    featureHead:'Features that Build NexGen Audio & Video Calling Apps',
    firstFeatureTitle:'Live audio streaming',
    firstFeatureContent:'Easily integrate live audio & video calling API into your website or app. Set up live audio & video call worldwide without worrying about scale and latency. Our audio & video SDK enables an opportunity to integrate full bandwidth audio, and adaptive audio bitrate with a smooth experience.',
    secondFeatureTitle:'Customize audio & video experience',
    secondFeatureContent:'Build cross-channel live audio and video calling experience. Easily build a complete customized experience embedded in your app. Portego works on every platform such as Web, React Native, Flutter, Android, and iOS.',
    thirdFeatureTitle:'Record live audio and video',
    thirdFeatureContent:'Portego recording is a service that lets you capture & record live audio and video calls to meet your business needs. Choose your layout, then record seperate audio and video calling live. You can also export the recorded audio or video calls, add watermarks, or use snapshots from them in real-time for content moderation.',
    fourthFeatureTitle:'Connect rooms with each other',
    fourthFeatureContent:'Building smooth backstage, on-stage, or breakout rooms API is now easy. We provide simple video calling APIs to connect multiple rooms with each other and on top of that, you can switch participants, send chat messages, fetch other room information, etc.',
    fifthFeatureTitle:'Moderate camera and mic permissions',
    fifthFeatureContent:'Now its easy to build applications with multiple roles such as host moderator, and co-host. Our moderation video calling APIs help you to mute, unmute, enable the camera and disable the camera of other participants as a host.',
    sixthFeatureTitle:'Low latency globally',
    sixthFeatureContent:'With a global intelligent network designed to reduce the latency and quality in any corner of the world. Our Video calling SDK edge network locations are the best in the market to deliver high-quality live video and audio.',
    seventhFeatureTitle:'Active speaker indication',
    seventhFeatureContent:'Our intelligent active speaker technology identifies or observers active speakers, not just based on high volume but also on who is speaking consistently in the meeting. Our audio & video calling APIs helps to enable best experience on medium and large group meetings.',
    extraFeatureHead:'Essential features for live audio & video apps',
}



