import Hero from "./Hero";
import ContactUs from "./ContactUs";
import DeveloperFriendly from "./DeveloperFriendly";
import Pricing from "./Pricing";
import Solutions from "./Solutions";
import ScalabilityComponent from "./ScalabilityComponent";
import EdgeRule from "./EdgeRule";
import CloudEdge from "./CloudEdge";
import KeyFeatures from "./KeyFeatures";

const Home = () => {
  return (
    <div style={{ background: "#202020", padding: "10px" }}>
      <Hero />
      <KeyFeatures />
      <CloudEdge />
      <EdgeRule />
      <ScalabilityComponent />
      <Solutions />
      <Pricing />
      <DeveloperFriendly />
      <ContactUs />
    </div>
  );
};

export default Home;
