import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { showConferencingData } from 'src/slices/pricing';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { Conferencing } from './conferencingData';
import { LiveStreaming } from './livestreamData';
import { MainContainer } from './pricingStyle';

const PricingCalculator = () => {
  const dispatch = useDispatch();
  const { isConferencing } = useSelector(
    (state: RootState) => state.pricing);

  return (
    <>
      <MainContainer>
        <Typography variant='h1'> Pricing Calculator</Typography>
        <Box
          sx={{
            width: {
              xl: "280px",
              lg: "280px",
              md: "300px",
              sm: "330px",
            },
            marginBottom: "2rem",
            height: "auto",
            // background: "#F1F5FD",
            border: "1px solid #F1F5FD",
            borderRadius: "16px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: 'center'
          }}
        >
          <Button
            id="conferencing-btn"
            sx={{
              height: "40px",
              width: "140px",
              borderRadius: "16px",
              background: isConferencing ? "#3087EC" : "transparent",
              color: isConferencing ? "white" : "#686868",
              // ml: "10px",
              "&:hover": {
                background: isConferencing ? "#3087EC" : "none",
              },
            }}
            onClick={() => {
              dispatch(showConferencingData(true));
            }}
          >
            <Typography
              sx={{
                fontSize: {
                  xl: "14px",
                  lg: "14px",
                  md: "14px",
                  sm: "14px",
                },
              }}
            >
              Conferencing
            </Typography>
          </Button>
          <Button
            id="livestream-btn"
            sx={{
              height: "40px",
              borderRadius: "16px",
              width: "140px",
              color: isConferencing ? "#686868" : "white",
              // mr: "10px",
              background: isConferencing ? "transperant" : "#3087EC",
              "&:hover": {
                background: isConferencing ? "none" : "#3087EC",
              },
            }}
            onClick={() => {
              dispatch(showConferencingData(false));
            }}
          >
            <Typography
              sx={{
                fontSize: {
                  xl: "14px",
                  lg: "14px",
                  md: "14px",
                  sm: "14px",
                },
              }}
            >
              Live Streaming
            </Typography>
          </Button>
        </Box>
        {isConferencing ? <Conferencing /> : <LiveStreaming />}
      </MainContainer>
    </>
  );
}

export default PricingCalculator;

