import React from "react";
import { Box, Typography, Hidden, Grid } from "@mui/material";
import EdgeEngine from "src/assets/Svg/EdgeEngine";

const EdgeRule = () => {
  return (
    <div>
      {" "}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {" "}
        <Box
          sx={{
            background:
              "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
            marginTop: "-20px",
            width: {
              xl: "70%",
              lg: "80%",
              md: "90%",
              sm: "90%",
              xs: "90%",
            },
            borderRadius: "10px",
            padding: "20px",
          }}
        >
          <Typography
            sx={{
              color: "white",
              textAlign: "center",
              fontSize: {
                lg: "35px",
                md: "30px",
                sm: "20px",
                xs: "16px",
              },
              fontWeight: "500",
            }}
          >
            Edge Rule Engine
          </Typography>
          <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            <Hidden smUp>
              <Grid
                item
                xl={2}
                lg={4}
                md={6}
                sm={4}
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <Box
                  sx={{
                    width: {
                      lg: "300px",
                      md: "200px",
                      sm: "150px",
                      xs: "200px",
                    },
                    height: {
                      lg: "300px",
                      md: "200px",
                      sm: "150px",
                      xs: "200px",
                    },
                  }}
                >
                  <EdgeEngine />
                </Box>
              </Grid>
            </Hidden>
            <Grid item xl={3} lg={4} md={4} sm={4} xs={12}>
              <Typography
                sx={{
                  fontSize: {
                    lg: "20px",
                    md: "14px",
                    sm: "12px",
                    xs: "12px",
                  },
                  fontWeight: "500",
                  marginTop: {
                    lg: "30px",
                    md: "30px",
                    sm: "30px",
                    xs: "-10px",
                  },
                  textAlign: {
                    xl: "left",
                    lg: "left",
                    md: "left",
                    sm: "left",
                    xs: "center",
                  },
                  padding: "5px",
                }}
              >
                Modular Policies
              </Typography>

              <Typography
                sx={{
                  padding: "5px",
                  fontSize: {
                    lg: "16px",
                    md: "14px",
                    sm: "12px",
                    xs: "12px",
                  },
                  fontWeight: "400",
                  color: "#FFFFFF",
                  textAlign: {
                    xl: "left",
                    lg: "left",
                    md: "left",
                    sm: "left",
                    xs: "center",
                  },
                }}
              >
                Add security, observability, encryption, and resiliency to edge
                traffic in real time with modules
              </Typography>

              <Typography
                sx={{
                  padding: "5px",
                  fontSize: {
                    lg: "20px",
                    md: "14px",
                    sm: "12px",
                    xs: "12px",
                  },
                  fontWeight: "500",
                  marginTop: {
                    lg: "30px",
                    md: "30px",
                    // sm: "30px",
                    xs: "20px",
                  },
                  textAlign: {
                    xl: "left",
                    lg: "left",
                    md: "left",
                    sm: "left",
                    xs: "center",
                  },
                }}
              >
                Route-Based Configuration
              </Typography>

              <Typography
                sx={{
                  padding: "5px",
                  fontSize: {
                    lg: "16px",
                    md: "14px",
                    sm: "12px",
                    xs: "12px",
                  },
                  fontWeight: "400",
                  color: "#FFFFFF",
                  textAlign: {
                    xl: "left",
                    lg: "left",
                    md: "left",
                    sm: "left",
                    xs: "center",
                  },
                }}
              >
                Apply granular traffic behavior per URL path
              </Typography>
            </Grid>
            <Hidden smDown>
              <Grid
                item
                xl={3}
                lg={4}
                md={4}
                sm={4}
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: {
                      lg: "300px",
                      md: "200px",
                      sm: "150px",
                      xs: "200px",
                    },
                    height: {
                      lg: "300px",
                      md: "200px",
                      sm: "150px",
                      xs: "200px",
                    },
                  }}
                >
                  <EdgeEngine />{" "}
                </Box>
              </Grid>
            </Hidden>

            <Grid item xl={3} lg={4} md={4} sm={4} xs={12}>
              <Typography
                sx={{
                  padding: "5px",
                  fontSize: {
                    lg: "20px",
                    md: "14px",
                    sm: "12px",
                    xs: "12px",
                  },
                  fontWeight: "500",
                  marginTop: {
                    lg: "30px",
                    md: "30px",
                    sm: "30px",
                    xs: "20px",
                  },
                  textAlign: {
                    xl: "left",
                    lg: "left",
                    md: "left",
                    sm: "left",
                    xs: "center",
                  },
                }}
              >
                HTTP Header Manipulation
              </Typography>

              <Typography
                sx={{
                  padding: "5px",
                  fontSize: {
                    lg: "16px",
                    md: "14px",
                    sm: "12px",
                    xs: "12px",
                  },

                  fontWeight: "400",
                  color: "#FFFFFF",
                  textAlign: {
                    xl: "left",
                    lg: "left",
                    md: "left",
                    sm: "left",
                    xs: "center",
                  },
                }}
              >
                Enrich requests with data and enforce client security policies
              </Typography>

              <Typography
                sx={{
                  padding: "5px",
                  fontSize: {
                    lg: "20px",
                    md: "14px",
                    sm: "12px",
                    xs: "12px",
                  },
                  fontWeight: "500",
                  marginTop: {
                    xl: "30px",
                    lg: "50px",
                    md: "50px",
                    sm: "55px",
                    xs: "20px",
                  },
                  textAlign: {
                    xl: "left",
                    lg: "left",
                    md: "left",
                    sm: "left",
                    xs: "center",
                  },
                }}
              >
                Geo Enrichment
              </Typography>

              <Typography
                sx={{
                  padding: "5px",
                  fontSize: {
                    lg: "16px",
                    md: "14px",
                    sm: "12px",
                    xs: "12px",
                  },
                  fontWeight: "400",
                  color: "#FFFFFF",
                  textAlign: {
                    xl: "left",
                    lg: "left",
                    md: "left",
                    sm: "left",
                    xs: "center",
                  },
                }}
              >
                Enrich event logs and request headers with IP and geographic
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default EdgeRule;
