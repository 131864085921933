import { createSlice } from "@reduxjs/toolkit";
import axios from "../utils/axios";
import { SuccessSnackbar, FailureSnackbar } from "src/slices/Snackbar";

// import axios from 'axios';

// const yy = process.env.URL_PREFIX
interface Member {
    name: string;
    email: string;
    status: string;
    permission: string;
    createdDate: string;
  }
  
  
interface getKeyDetails {
  name: string;
  expiry: string;
  appId: string;
  roomPrefix: string;
  secretKey: string;
  appSecret: string;
}

interface generateKey {
  name: string;
  expiry: string;
  appId: string;
  roomPrefix: string;
  secretKey: string;
  appSecret: string;
}

interface tableData {
  applicationName: string;
  appId: string;
  expiry: string;
  appSecret: string;
  createdDate: string;
  name: string;
}

interface apiKeys {
   data:Member[];
  addApiKeys: {
    name: string;
    expiry: string;
  };
  keyDetails: getKeyDetails[];
  tableData: tableData[];
  generateKey: generateKey[];
  id: string;
  appDetails: getKeyDetails[];
  appDetailsLoader?: boolean;
  updateExpiry: string;
  tableLoader: boolean;
  pageLoader: boolean;
  keyDetailsData: boolean;
  openGenerateKeyDetails: boolean;
  meta: {
    page: number;
    per_page: number;
    page_count: number;
    total_count: number;
  };
}

const initialState: apiKeys = {
     data:[],
  addApiKeys: {
    name: "",
    expiry: "",
  },
  keyDetails: [],
  generateKey: [],
  tableData: [],
  id: "",
  appDetails: [],
  appDetailsLoader: true,
  updateExpiry: "",
  tableLoader: false,
  pageLoader: false,
  keyDetailsData: false,
  openGenerateKeyDetails: false,
  meta: {
    page: 1,
    per_page: 5,
    page_count: 0,
    total_count: 0,
  },
};

const slice = createSlice({
  name: "apiKeys",
  initialState,
  reducers: {
    addApiKeysReducers(state: any, action) {
      state.addApiKeys[action.payload.key] = action.payload.value;
    },
    keyDetailsReducers(state: any, action) {
      state.keyDetails = action.payload;
    },
    genearteKeyDetails(state: any, action) {
      state.generateKey = [action.payload];
    },
    tableDataReducers(state, action) {
      state.tableData = action.payload;
    },
    deleteTableData(state: any, action) {
      state.tableData = action.payload;
    },
    id(state: any, action) {
      state.id = action.payload;
    },
    appDetails(state: any, action) {
      state.appDetails = action.payload;
    },
    appDetailsLoader(state: any, action) {
      state.appDetailsLoader = action.payload;
    },
    updatedExpiryValue(state: any, action) {
      state.updateExpiry = action.payload;
    },
    setTableLoader(state: any, action) {
      state.tableLoader = action.payload;
    },
    setPageLoader(state: any, action) {
      state.pageLoader = action.payload;
    },
    setKeyDetailsData(state: any, action) {
      state.keyDetailsData = action.payload;
    },
    setGenerateKeyDetails(state: any, action) {
      state.openGenerateKeyDetails = action.payload;
    },
    setMeta(state: any, action) {
      state.meta = action.payload;
    },
    setMemberData(state:any,action){
        state.data=action.payload;
    }
  },
});

const BASE_URL = process.env.REACT_APP_API_URL;

export const editTableData = () => async (dispatch: any) => {
  try {
    dispatch(slice.actions.setTableLoader(true));
    const responseTableData = await axios.get(`${BASE_URL}/api/v1/application`);
    if (responseTableData.status === 200 || responseTableData.status === 201) {
      dispatch(
        slice.actions.setMemberData(
          responseTableData.data.data ? responseTableData.data.data : []
        )
      );
      dispatch(slice.actions.setTableLoader(false));
      // dispatch(slice.actions.setMeta(responseTableData?.data?.meta))
    } else {
      dispatch(slice.actions.setMeta({
          page: 1,
          per_page: 5,
          page_count: 0,
          total_count: 0,
        }))
      dispatch(slice.actions.setMemberData([]));
      dispatch(slice.actions.setTableLoader(false));
      throw new Error(responseTableData?.data?.message);
    }
  } catch (err) {
    //dispatch(FailureSnackbar({ message: err || "something went wrong", open: true }))
  } finally {
    dispatch(slice.actions.setTableLoader(false));
  }
};
export const deleteTableData = (data: any, data1) => async (dispatch: any) => {
  dispatch(slice.actions.setTableLoader(true));
  try {
    const deleteResponse = await axios.delete(
      `${BASE_URL}/api/v1/application/${data}`
    );
    if (!deleteResponse.data?.status) {
      throw new Error(deleteResponse.data?.message);
    }
    if((data1.total_count % data1.per_page === 1) && data1.page === data1.page_count && data1.page !== 1){

        dispatch(editTableData1({page: data1.page-1, per_page:data1.per_page}));
    }else{
        dispatch(editTableData1(data1));
    }
    dispatch(
      SuccessSnackbar({ message: deleteResponse.data?.message, open: true })
    );
  } catch (error) {
    dispatch(slice.actions.setTableLoader(false));
    dispatch(
      FailureSnackbar({
        message: error?.message || "something went wrong",
        open: true,
      })
    );
  } finally {
  }
};

export const editTableData1 = (data) => async (dispatch: any) => {
  dispatch(slice.actions.setTableLoader(true));
  try {
    const responseTableData = await axios.get(
      `${BASE_URL}/api/v1/application?meta=true&page=${data.page}&per_page=${data.per_page}`
    );
    if (responseTableData.status === 200 || responseTableData.status === 201) {
      if (responseTableData.data.data !== null) {
        dispatch(
          slice.actions.tableDataReducers(
            responseTableData.data.data ? responseTableData.data.data : []
          )
        );
        dispatch(slice.actions.setMeta(responseTableData?.data?.meta));
      } else {
        dispatch(slice.actions.tableDataReducers([]));
        dispatch(
          slice.actions.setMeta({
            page: 1,
            per_page: 5,
            page_count: 0,
            total_count: 0,
          })
        );
      }
    } else {
      dispatch(
        slice.actions.setMeta({
          page: 1,
          per_page: 5,
          page_count: 0,
          total_count: 0,
        })
      );
      throw new Error(responseTableData?.data?.message);
    }
  } catch (err) {
    // dispatch(FailureSnackbar({ message: err || "something went wrong", open: true }))
  } finally {
    dispatch(slice.actions.setTableLoader(false));
  }
};

export const editTableData2 = (data) => async (dispatch: any) => {
  dispatch(slice.actions.setPageLoader(true));
  try {
    const responseTableData = await axios.get(
      `${BASE_URL}/api/v1/application?meta=true&page=${data.page}&per_page=${data.per_page}`
    );
    if (responseTableData.status === 200 || responseTableData.status === 201) {
      if (responseTableData.data.data !== null) {
        dispatch(
          slice.actions.tableDataReducers(
            responseTableData.data.data ? responseTableData.data.data : []
          )
        );
        dispatch(slice.actions.setMeta(responseTableData?.data?.meta));
      } else {
        dispatch(slice.actions.tableDataReducers([]));
        dispatch(
          slice.actions.setMeta({
            page: 1,
            per_page: 5,
            page_count: 0,
            total_count: 0,
          })
        );
      }
    } else {
      dispatch(
        slice.actions.setMeta({
          page: 1,
          per_page: 5,
          page_count: 0,
          total_count: 0,
        })
      );
      throw new Error(responseTableData?.data?.message);
    }
  } catch (err) {
    // dispatch(FailureSnackbar({ message: err || "something went wrong", open: true }))
  } finally {
    dispatch(slice.actions.setPageLoader(false));
  }
};

export const editGenerateKey = (data) => async (dispatch: any) => {
  const responseGenerateeData = await axios.get(
    `${BASE_URL}/api/v1/application/${data}`
  );
  dispatch(slice.actions.genearteKeyDetails(responseGenerateeData.data.data));
};

export const setGenerateKeyDetails = (data) => async (dispatch: any) => {
  dispatch(slice.actions.setGenerateKeyDetails(data));
};

// export const deleteTableData = (data: any, data1) => async (dispatch: any) => {
//   dispatch(slice.actions.setTableLoader(true));
//   try {
//     const deleteResponse = await axios.delete(
//       `${BASE_URL}/api/v1/application/${data}`
//     );
//     if (!deleteResponse.data?.status) {
//       throw new Error(deleteResponse.data?.message);
//     }
//     if((data1.total_count % data1.per_page === 1) && data1.page === data1.page_count && data1.page !== 1){

//         dispatch(editTableData1({page: data1.page-1, per_page:data1.per_page}));
//     }else{
//         dispatch(editTableData1(data1));
//     }
//     dispatch(
//       SuccessSnackbar({ message: deleteResponse.data?.message, open: true })
//     );
//   } catch (error) {
//     dispatch(slice.actions.setTableLoader(false));
//     dispatch(
//       FailureSnackbar({
//         message: error?.message || "something went wrong",
//         open: true,
//       })
//     );
//   } finally {
//   }
// };

export const editDeleteTableData = (data: any) => (dispatch: any) => {
  dispatch(slice.actions.deleteTableData(data));
};

export const editID = (data: any) => (dispatch: any) => {
  dispatch(slice.actions.id(data));
};

export const editApiKeys = (data: any) => (dispatch: any) => {
  dispatch(slice.actions.addApiKeysReducers(data));
};

export const getApplicationDetails = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.appDetailsLoader(true));
    const responseData = await axios.get(
      `${BASE_URL}/api/v1/application?app_id=${data}`
    );
    if (!responseData.data?.status) {
      throw new Error(responseData.data?.message);
    }

    dispatch(slice.actions.appDetails(responseData.data.data));
    //dispatch(SuccessSnackbar({ message: responseData.data?.message, open: true }));
  } catch (error) {
    dispatch(
      FailureSnackbar({
        message: error?.message || "something went wrong",
        open: true,
      })
    );
  } finally {
    dispatch(slice.actions.appDetailsLoader(false));
  }
};

export const newExiryValue = (data) => (dispatch) => {
  dispatch(slice.actions.updatedExpiryValue(data));
};

export const updateExpiryDate = (data) => async (dispatch) => {
  dispatch(slice.actions.setTableLoader(true));
  try {
    const formattedData = {
      name: data.name,
      expiry: new Date(data.expiry).toISOString(),
    };
    const responseData = await axios.patch(
      `${BASE_URL}/api/v1/application/${data.appId}`,
      formattedData
    );
    if (!responseData.data?.status) {
      throw new Error(responseData.data?.message);
    }
    dispatch(editTableData1(data));
    dispatch(
      SuccessSnackbar({ message: responseData.data?.message, open: true })
    );
  } catch (error) {
    dispatch(
      FailureSnackbar({
        message: error?.message || "something went wrong",
        open: true,
      })
    );
  } finally {
    dispatch(slice.actions.setTableLoader(false));
  }
};

export const postGenerateKey = (data: any) => async (dispatch: any) => {
  try {
    dispatch(slice.actions.appDetailsLoader(true));
    dispatch(slice.actions.setGenerateKeyDetails(true));
    const responsePost = await axios.post(
      `${BASE_URL}/api/v1/application/regenerate/${data}`
    );
    if (!responsePost.data?.status) {
      throw new Error(responsePost.data?.message);
    }

    dispatch(slice.actions.genearteKeyDetails(responsePost.data.data));
    const responseData = await axios.get(
      `${BASE_URL}/api/v1/application?app_id=${responsePost.data.data.appId}`
    );
    if (!responseData.data?.status) {
      throw new Error(responseData.data?.message);
    }
    dispatch(slice.actions.keyDetailsReducers(responseData.data.data));
    dispatch(
      SuccessSnackbar({ message: responseData.data?.message, open: true })
    );
  } catch (error) {
    dispatch(slice.actions.genearteKeyDetails([]));
    dispatch(
      FailureSnackbar({
        message: error?.message || "something went wrong",
        open: true,
      })
    );
  } finally {
    dispatch(slice.actions.appDetailsLoader(false));
  }
};

// export const updateExpiryAndPostGenerateKey =
//   (data: any, data1) => async (dispatch: any) => {
//     try {
//       const formattedData = {
//         name: data.name,
//         expiry: new Date(data.expiry).toISOString(),
//       };
//       dispatch(slice.actions.appDetailsLoader(true));
//       const responseData = await axios.patch(
//         `${BASE_URL}/api/v1/application/${data.appId}`,
//         formattedData
//       );
//       if (!responseData.data?.status) {
//         throw new Error(responseData.data?.message);
//       }
//       dispatch(slice.actions.setGenerateKeyDetails(true));
//       dispatch(editTableData1(data1));
//       dispatch(
//         SuccessSnackbar({ message: responseData.data?.message, open: true })
//       );
//       dispatch(postGenerateKey(data.appId));
//     } catch (error) {
//       dispatch(slice.actions.appDetailsLoader(false));
//       dispatch(
//         FailureSnackbar({
//           message: error?.message || "something went wrong",
//           open: true,
//         })
//       );
//     }
//   };

export const postEditApiKeys = (data: any, data1) => async (dispatch: any) => {
  dispatch(slice.actions.setTableLoader(true));
  const apiKeys = { name: data.name, expiry: data.expiry };
  try {
    const responsePost = await axios.post(
      `${BASE_URL}/api/v1/application`,
      apiKeys
    );
    if (responsePost?.status === 201 || responsePost?.status === 200) {
      if (responsePost.data.status) {
        dispatch(slice.actions.keyDetailsReducers([responsePost.data.data]));
        dispatch(slice.actions.setKeyDetailsData(true));
        dispatch(editTableData1(data1));
        dispatch(
          SuccessSnackbar({ message: responsePost.data?.message, open: true })
        );
      } else {
        dispatch(
          FailureSnackbar({
            message: responsePost?.data?.message || "something went wrong",
            open: true,
          })
        );
      }
    } else {
      throw new Error("Failed to create API Keys");
    }
  } catch (error) {
  } finally {
    dispatch(slice.actions.setTableLoader(false));
  }
};

export const keyDetailsDataFn = (data) => async (dispatch) => {
  dispatch(slice.actions.setKeyDetailsData(data));
};
export const reducer = slice.reducer;

export default slice;
