import React from "react";
import HttpsIcon from "@mui/icons-material/Https";
import TrafficIcon from "@mui/icons-material/Traffic";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import WifiIcon from "@mui/icons-material/Wifi";
import WebhookIcon from "@mui/icons-material/Webhook";
import LockIcon from "@mui/icons-material/Lock";
import { KeyFeatureHeader } from "./Home-css";
import { Box, Typography, Grid } from "@mui/material";

const KeyFeatures = () => {
  return (
    <div>
      {" "}
      <Box
        sx={{
          marginTop: {
            xs: "-500px",
            sm: "-500px",
            md: "-500px",
            lg: "-500px",
            xl: "-500px",
          },
          padding: "10px",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontSize: {
              lg: "40px",
              md: "30px",
              sm: "30px",
              xs: "20px",
            },
            fontWeight: "500",
            backgroundImage:
              "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Key features
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography
            sx={{
              marginTop: "30px",
              width: {
                xs: "100%",
                sm: "80%",
                md: "80%",
                lg: "80%",
                xl: "80%",
              },
              textAlign: "center",
              fontSize: {
                lg: "16px",
                md: "14px",
                sm: "12px",
                xs: "12px",
              },
              fontWeight: "400",
              color: "#FFFFFF",
            }}
          >
            Your security is our top priority, and we make it affordable for
            you. Get the best of both worlds with our unbeatable combination of
            maximum security and affordable prices
          </Typography>
        </Box>

        <Box
          sx={{ display: "flex", justifyContent: "center", padding: "10px" }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              flexWrap: "wrap",
              width: {
                lg: "90%",
                md: "90%",
                sm: "100%",
                xs: "100%",
              },
              gap: {
                sm: "20px",
              },
            }}
          >
            <Grid item xs={12} sm={3.5} md={3.5} lg={3.5} xl={2.5}>
              <KeyFeatureHeader
                sx={{
                  textAlign: {
                    lg: "left",
                    md: "left",
                    sm: "left",
                    xs: "center",
                  },
                  fontSize: {
                    lg: "35px",
                    md: "25px",
                    sm: "20px",
                    xs: "15px",
                  },
                }}
              >
                {" "}
                Access Control
              </KeyFeatureHeader>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                  rowGap: "40px",
                  marginTop: "25px",
                }}
              >
                <Box
                  sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <WebhookIcon sx={{ color: "white", fontSize: "30px" }} />
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "20px",
                        md: "14px",
                        sm: "12px",
                        xs: "12px",
                      },
                      fontWeight: "500",
                      backgroundImage:
                        "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    Webhook Verification
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <WifiIcon sx={{ color: "white", fontSize: "30px" }} />
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "20px",
                        md: "14px",
                        sm: "12px",
                        xs: "12px",
                      },
                      fontWeight: "500",
                      backgroundImage:
                        "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    IP Restriction
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <LockIcon sx={{ color: "white", fontSize: "30px" }} />
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "20px",
                        md: "14px",
                        sm: "12px",
                        xs: "12px",
                      },
                      fontWeight: "500",
                      backgroundImage:
                        "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    Basic Authentication
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4.5} md={4} lg={4} xl={2.5}>
              {" "}
              <KeyFeatureHeader
                sx={{
                  textAlign: {
                    lg: "left",
                    md: "left",
                    sm: "left",
                    xs: "center",
                  },
                  fontSize: {
                    lg: "35px",
                    md: "25px",
                    sm: "20px",
                    xs: "15px",
                  },
                }}
              >
                {" "}
                Encryption
              </KeyFeatureHeader>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // gap:'20px',
                  rowGap: "20px",
                  marginTop: "25px",
                }}
              >
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <WorkspacePremiumIcon
                    sx={{ color: "white", fontSize: "30px" }}
                  />
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "20px",
                        md: "14px",
                        sm: "12px",
                        xs: "12px",
                      },
                      fontWeight: "500",
                      backgroundImage:
                        "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    Automatic HTTPS/TLS Certificates
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    marginTop: "20px",
                  }}
                >
                  <HttpsIcon sx={{ color: "white", fontSize: "30px" }} />
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "20px",
                        md: "14px",
                        sm: "12px",
                        xs: "12px",
                      },
                      fontWeight: "500",
                      backgroundImage:
                        "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    Best Practices Encryption
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "10px", marginTop: "20px" }}>
                  <VpnKeyIcon sx={{ color: "white", fontSize: "30px" }} />
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "20px",
                        md: "14px",
                        sm: "12px",
                        xs: "12px",
                      },
                      fontWeight: "500",
                      backgroundImage:
                        "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    Zero-Knowledge Encryption (e2ee)
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={3} md={3} lg={3} xl={2}>
              {" "}
              <KeyFeatureHeader
                sx={{
                  textAlign: {
                    lg: "left",
                    md: "left",
                    sm: "left",
                    xs: "center",
                  },
                  fontSize: {
                    lg: "35px",
                    md: "25px",
                    sm: "20px",
                    xs: "15px",
                  },
                }}
              >
                {" "}
                Observability
              </KeyFeatureHeader>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "15px",
                  marginTop: "25px",
                }}
              >
                {" "}
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <TrafficIcon sx={{ color: "white", fontSize: "30px" }} />
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "20px",
                        md: "14px",
                        sm: "12px",
                        xs: "12px",
                      },
                      fontWeight: "500",
                      backgroundImage:
                        "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    Traffic Logging
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* <Hidden >
      <Box
        sx={{ display: "flex", justifyContent: "center", padding: "10px", }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            flexWrap: "wrap",
            width: {
              lg: "90%",
              md: "80%",
              sm: "100%",
              xs: "100%",
            },
            gap: {
              sm: "40px",
            },
          }}
        >
          <Grid item xs={12} sm={3} md={3} lg={3} >
            <KeyFeatureHeader
              sx={{
                textAlign: {
                  lg: "left",
                  md: "left",
                  sm: "left",
                  xs: "center",
                },
                fontSize: {
                  lg: "35px",
                  md: "25px",
                  sm: "20px",
                  xs: "15px",
                },
              }}
            >
              {" "}
              Access Control
            </KeyFeatureHeader>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: 'center',
                  rowGap: "15px",
                  marginTop: "15px",

                }}
              >
                <Box
                  sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <WebhookIcon sx={{ color: "white", fontSize: "30px" }} />
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "20px",
                        md: "14px",
                        sm: "12px",
                        xs: "12px",
                      },
                      fontWeight: "500",
                      backgroundImage:
                        "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",

                    }}
                  >
                    Webhook Verification
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <WifiIcon sx={{ color: "white", fontSize: "30px" }} />
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "20px",
                        md: "14px",
                        sm: "12px",
                        xs: "12px",
                      },
                      fontWeight: "500",
                      backgroundImage:
                        "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    IP Restriction
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <LockIcon sx={{ color: "white", fontSize: "30px" }} />
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "20px",
                        md: "14px",
                        sm: "12px",
                        xs: "12px",
                      },
                      fontWeight: "500",
                      backgroundImage:
                        "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    Basic Authentication
                  </Typography>
                </Box>
              </Box>
            </Box>

          </Grid>

          <Grid item xs={12} sm={3} md={3} lg={3}>
            {" "}
            <KeyFeatureHeader
              sx={{
                textAlign: {
                  lg: "left",
                  md: "left",
                  sm: "left",
                  xs: "center",
                },
                fontSize: {
                  lg: "35px",
                  md: "25px",
                  sm: "20px",
                  xs: "15px",
                },
              }}
            >
              {" "}
              Encryption
            </KeyFeatureHeader>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "15px",
                  marginTop: "15px",
                }}
              >
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <WorkspacePremiumIcon
                    sx={{ color: "white", fontSize: "30px" }}
                  />
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "20px",
                        md: "14px",
                        sm: "12px",
                        xs: "12px",
                      },
                      fontWeight: "500",
                      backgroundImage:
                        "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      textAlign: 'center'
                    }}
                  >
                    Automatic HTTPS/TLS Certificates
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <HttpsIcon sx={{ color: "white", fontSize: "30px" }} />
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "20px",
                        md: "14px",
                        sm: "12px",
                        xs: "12px",
                      },
                      fontWeight: "500",
                      backgroundImage:
                        "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    Best Practices Encryption
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <VpnKeyIcon sx={{ color: "white", fontSize: "30px" }} />
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "20px",
                        md: "14px",
                        sm: "12px",
                        xs: "12px",
                      },
                      fontWeight: "500",
                      backgroundImage:
                        "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    Zero-Knowledge Encryption (e2ee)
                  </Typography>
                </Box>
              </Box></Box>

          </Grid>

          <Grid item xs={12} sm={3} md={3} lg={3}>
            {" "}
            <KeyFeatureHeader
              sx={{
                textAlign: {
                  lg: "left",
                  md: "left",
                  sm: "left",
                  xs: "center",
                },
                fontSize: {
                  lg: "35px",
                  md: "25px",
                  sm: "20px",
                  xs: "15px",
                },
              }}
            >
              {" "}
              Observability
            </KeyFeatureHeader>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "15px",
                  marginTop: "15px",
                }}
              >
                {" "}
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <TrafficIcon sx={{ color: "white", fontSize: "30px" }} />
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "20px",
                        md: "14px",
                        sm: "12px",
                        xs: "12px",
                      },
                      fontWeight: "500",
                      backgroundImage:
                        "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    Traffic Logging
                  </Typography>
                </Box>
              </Box>
            </Box>

          </Grid>
        </Grid>
      </Box></Hidden> */}
      </Box>
    </div>
  );
};

export default KeyFeatures;
