import React, { useEffect } from 'react'
import {
  Autocomplete,
  Divider,
  Grid,
  IconButton,
  Pagination,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material'
import { ContentCopy } from '@mui/icons-material'
import {
  GridContainer,
  GridContainerBox,
  StatusCompleted,
  StatusLive,
  TableBox,
  TableCells,
  TableContainerStack,
  TablePaginationBox,
  TableRowBox,
} from './indexStyled'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import styled from '@emotion/styled'
import { IconBase } from 'react-icons'
import CopyToClipboard from 'react-copy-to-clipboard'
import './table.css'
import AssessmentIcon from '@mui/icons-material/Assessment'
import { Box } from '@mui/system'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import {
  ConferenceAction,
  fetchCompleteData,
  fetchConferenceTableData,
} from 'src/slices/Conference/ConferenceTable'
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined'
import * as XLSX from 'xlsx'
import Loader from 'src/components/Loader/Loader'

// const tableData = [
//   {
//     appId: 'b401b148-e31b-4e31w-b3b6-01e488ff0ea5',
//     roomName: 'Apple',
//     roomId: '1234567890',
//     participantName: 'Ranjan Nayak',
//     causeCode: '200',
//     reason: 'string',
//     cost: '43.76',
//     costCode: 'string',
//     sessionId: '739336f',
//     startTime: new Date('2022-07-22T16:34:01+05:30'),
//     endTime: new Date('2022-07-22T17:13:34+05:30'),
//     minutes: 127,
//     status: 'Live',
//   },
//   {
//     appId: 'b401b148-e31b-4ee31-b3b6-01e488ff0ea5',
//     roomName: 'PineApple',
//     roomId: '1234567890',
//     participantName: 'Pavan Kumar',
//     causeCode: '400',
//     reason: 'string',
//     cost: '43.76',
//     costCode: 'string',
//     sessionId: '739336fa',
//     startTime: new Date('2022-07-22T16:34:01+05:30'),
//     endTime: new Date('2022-07-23T10:34:01+05:30'),
//     minutes: 127,
//     status: 'Live',
//   },
//   {
//     appId: 'b401b148-e31b-4e31r-b3b6-01e488ff0ea5',
//     roomName: 'Banana',
//     roomId: '1234567890',
//     participantName: 'Athul',
//     causeCode: '100',
//     reason: 'string',
//     cost: '73.76',
//     costCode: 'string',
//     sessionId: '739336f',
//     startTime: new Date('2022-07-22T16:34:01+05:30'),
//     endTime: new Date('2022-07-22T11:34:01+05:30'),
//     minutes: 127,
//     status: 'COMPLETED',
//   },
//   {
//     appId: 'b401b148-e31b-4te31-b3b6-01e488ff0ea5',
//     roomName: 'Orange',
//     roomId: '1234567890',
//     participantName: 'Prathik Pai',
//     causeCode: '600',
//     reason: 'string',
//     cost: '43.76',
//     costCode: 'string',
//     sessionId: '739336fa',
//     startTime: new Date('2022-07-22T16:34:01+05:30'),
//     endTime: new Date('2022-07-22T12:34:01+05:30'),
//     minutes: 127,
//     status: 'Live',
//   },
//   {
//     appId: 'b401b148-e31bc-4e31-b3b6-01e488ff0ea5',
//     roomName: 'Mango',
//     roomId: '1234567890',
//     participantName: 'Rahul B ',
//     causeCode: '800',
//     reason: 'string',
//     cost: '83.76',
//     costCode: 'string',
//     sessionId: '739336fa',
//     startTime: new Date('2022-07-22T16:34:01+05:30'),
//     endTime: new Date('2022-07-22T16:34:03+05:30'),
//     minutes: 127,
//     status: 'COMPLETED',
//   },
//   {
//     appId: 'b401b148-e331bc-4e331-b3b6-01e488ff0ea5',
//     roomName: 'Mango',
//     roomId: '1234567890',
//     participantName: 'Rahul B ',
//     causeCode: '800',
//     reason: 'string',
//     cost: '83.76',
//     costCode: 'string',
//     sessionId: '739336fa',
//     startTime: new Date('2022-07-22T16:34:01+05:30'),
//     endTime: new Date('2022-07-22T16:34:03+05:30'),
//     minutes: 127,
//     status: 'LIVE',
//   },
// ]
const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
})

const staticIds = [
  {
    appId: 'b7398a1e-b5ef-4dcb-9b15-3be3f39a0970',
    name: 'ABC',
  },
]

const ConferenceTable = () => {
  const dispatch = useDispatch()
  const cdrData = useSelector((state: any) => state.conference.conferenceData)
  const message = useSelector((state: any) => state.conference.message)
  const metaDetails = useSelector((state: any) => state.conference.meta)
  const appids = useSelector((state: any) => state.apiKeys.tableData)
  console.log('appids', appids)
  const selectedAppId = useSelector((state: any) => state.conference.appIdData)
  console.log('Selected App Id', selectedAppId.appId)
  const page = useSelector((state: any) => state.conference.page)
  const loading = useSelector((state: any) => state.conference.loading)
  console.log('Loading', loading)
  const FinalExcel = useSelector(
    (state: any) => state.conference.exportToExcel.excelData,
  )

  useEffect(() => {
    dispatch(
      fetchCompleteData(
        metaDetails.total_count ? metaDetails.total_count : 10000,
        selectedAppId.appId,
      ),
    )
  }, [metaDetails.total_count, dispatch, selectedAppId.appId])

  const idList = [...staticIds, ...appids]
  console.log('cdrData', metaDetails.page_count)

  console.log('Excel Data Final', FinalExcel)

  const ExportData = FinalExcel.map((data) => {
    return {
      APP_ID: data.appId,
      ROOM_NAME: data.roomName,
      ROOM_ID: data.roomId,
      PARTICIPANT_NAME: data.participantName,
      CAUSE_CODE: data.causeCode,
      COST: data.cost,
      SESSION_ID: data.sessionId,
      START_TIME: data.startTime,
      END_TIME: data.endTime,
      MINUTES: data.minutes,
      STATUS: data.status,
    }
  })

  const exportToExcelHandler = () => {
    console.log('Exporting Data to Excel', ExportData)
    const wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(ExportData)
    XLSX.utils.book_append_sheet(wb, ws, 'MySheet1')
    XLSX.writeFile(wb, 'MyExcel.xlsx')
  }

  return (
    <>
      {/* table container */}
      <TableContainerStack>
        {/* title and filteres */}
        <GridContainer container>
          {/* Title */}
          <Grid
            item
            xs={2}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '15px',
            }}
          >
            <Typography variant="h3">Conference</Typography>
            {metaDetails.total_count >= 1 ? (
              <IconButton color="primary" onClick={exportToExcelHandler}>
                <SimCardDownloadOutlinedIcon />
              </IconButton>
            ) : (
              <IconButton disabled>
                <SimCardDownloadOutlinedIcon />
              </IconButton>
            )}
          </Grid>
          {/* Title */}
          <Grid item xs={10}>
            <GridContainerBox>
              {/* select app id field */}
              <Grid item xs={3}>
                <Autocomplete
                  value={selectedAppId}
                  onChange={(e: any, newvalue) => {
                    if (newvalue.appIds === null) {
                      dispatch(
                        ConferenceAction.setMessage(`Please select app id`),
                      )
                    }
                    dispatch(ConferenceAction.setAppId(newvalue))
                    dispatch(fetchConferenceTableData(1, newvalue.appId))
                    dispatch(ConferenceAction.setPageNumber(1))
                  }}
                  id="combo-box-demo"
                  size="small"
                  // options={appids}
                  options={idList}
                  getOptionLabel={(option: any) => option.appId}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="App Id"
                      placeholder="Select an app id here.."
                    />
                  )}
                />
              </Grid>
              {/* select app id field */}
            </GridContainerBox>
          </Grid>
        </GridContainer>
        {/* title and filteres */}
        <Divider />
        <TableBox>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCells style={{ width: '15%', paddingLeft: '15px' }}>
                    ROOM
                  </TableCells>
                  <TableCells style={{ width: '10%' }}>SESSION ID</TableCells>
                  <TableCells style={{ width: '10%' }}>NAME</TableCells>
                  <TableCells style={{ width: '17%' }}>
                    START & END TIME
                  </TableCells>
                  <TableCells style={{ width: '12%' }}>DURATION</TableCells>
                  <TableCells style={{ width: '13%' }}>CAUSE CODE</TableCells>
                  <TableCells style={{ width: '10%' }}>COST</TableCells>
                  <TableCells align="center" style={{ width: '15%' }}>
                    STATUS
                  </TableCells>
                </TableRow>
              </TableHead>
              {!loading && metaDetails.total_count >= 1 && (
                <TableBody>
                  {cdrData.map((row, index) => {
                    return (
                      <TableRowBox key={Math.random()}>
                        <TableCells>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              gap: '0.5rem',
                            }}
                          >
                            <AssessmentIcon sx={{ fontSize: '25px' }} />
                            <Typography>
                              <div style={{ fontWeight: 'bold' }}>
                                {row.roomName}
                              </div>

                              <NoMaxWidthTooltip
                                title={
                                  <Typography
                                    style={{
                                      display: 'flex',
                                      gap: '0.5rem',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    {row.appId}
                                    <CopyToClipboard text={row.appId}>
                                      <IconBase>
                                        <ContentCopy />
                                      </IconBase>
                                    </CopyToClipboard>
                                  </Typography>
                                }
                              >
                                <div>{row.appId.slice(0, 8)}...</div>
                              </NoMaxWidthTooltip>
                            </Typography>
                          </Box>
                        </TableCells>
                        <TableCells>{row.sessionId}</TableCells>
                        <TableCells>{row.participantName}</TableCells>
                        <TableCell>
                          <Box
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              textTransform: 'uppercase',
                            }}
                          >
                            <div>
                              {moment(new Date(row.startTime)).format(
                                'DD MMM YYYY',
                              )}
                              &nbsp;||&nbsp;
                              {moment(new Date(row.startTime)).format(
                                'hh:mm a',
                              )}
                            </div>

                            <div>
                              {moment(new Date(row.endTime)).format(
                                'DD MMM YYYY',
                              )}
                              &nbsp;||&nbsp;
                              {moment(new Date(row.endTime)).format('hh:mm a')}
                            </div>
                          </Box>
                        </TableCell>
                        <TableCell className="tablecell_minutes">
                          {/* {moment(
                          moment(row.startTime).diff(moment(row.endTime)),
                        ).format('mm')} */}
                          {/* {Math.trunc(
                            ((new Date(row.endTime) as any) -
                              (new Date(row.endTime) as any)) /
                              (1000 * 60),
                          )} */}
                          {row.minutes}MINS
                        </TableCell>
                        <TableCell>
                          <Box
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <div style={{ fontWeight: 'bold' }}>
                              {row.causeCode}
                            </div>
                            <div style={{ textTransform: 'uppercase' }}>
                              IT IS &nbsp;{row.status}
                            </div>
                          </Box>
                        </TableCell>
                        <TableCells>${row.cost}</TableCells>
                        <TableCell
                          align="center"
                          style={{ textTransform: 'uppercase' }}
                        >
                          {row.status === 'completed' ? (
                            <StatusLive label={row.status} />
                          ) : (
                            <StatusCompleted label={row.status} />
                          )}
                        </TableCell>
                      </TableRowBox>
                    )
                  })}
                </TableBody>
              )}
              {loading && (
                <Box
                  style={{
                    width: '100%',
                    height: '450px',
                    position: 'absolute',
                    top: '60px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Loader />
                </Box>
              )}
              {!loading && metaDetails.total_count < 1 && (
                <Box
                  style={{
                    width: '100%',
                    height: '450px',
                    position: 'absolute',
                    top: '60px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography>{message}</Typography>
                  {/* {!loading ? <Typography>No data found</Typography> : ''} */}
                </Box>
              )}
            </Table>
          </TableContainer>
        </TableBox>

        <TablePaginationBox>
          <Pagination
            count={metaDetails.page_count}
            shape="rounded"
            color="primary"
            page={page}
            onChange={(event, value) => {
              dispatch(ConferenceAction.setPageNumber(value))
              dispatch(fetchConferenceTableData(value, selectedAppId.appId))
            }}
          />
        </TablePaginationBox>
      </TableContainerStack>
      {/* table container */}
    </>
  )
}
export default ConferenceTable
