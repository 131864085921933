import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
// import PortegoIcon from "src/assets/icons/PortegoIcon";
import { Typography } from "@mui/material";

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.colors.alpha.trueWhite[100]};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);

function Logo() {
  return (
      <LogoWrapper to="/">
        <Typography fontSize='30px' fontWeight='400' color='black'>
              Ingressify

              </Typography>
      </LogoWrapper>
  );
}

export default Logo;
