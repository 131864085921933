import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, Divider, Grid } from '@mui/material';
import { setMonthValue, setParticapantCount, setTabState, setTimeValue, showAudioData, showRecordingData, showSimulcastData, showStorageData, } from 'src/slices/pricing';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { ButtonPrimary } from 'src/components/CustomButtons';
import { AmountText, CustomInfoCard, HeadingTitle, PrettoSlider, SmallText, SubHeading, Title } from './pricingStyle';

export const Conferencing = () => {
    const dispatch = useDispatch();
    const { participantCount, timeValue, monthValue, isAudio, tabState, isRecording, isSimulcast, isStorage } = useSelector(
        (state: RootState) => state.pricing
    );
    const audioMinValue = +(participantCount * timeValue * monthValue * 0.0006).toFixed(2);
    const freeMinValue = +(participantCount * timeValue * monthValue * 0.0006).toFixed(2);
    const recordingValue = +(participantCount * timeValue * monthValue * 0.0006).toFixed(2);
    const storageValue = +(participantCount * timeValue * monthValue * 0.0006).toFixed(2);
    const simulcastValue = +(participantCount * timeValue * monthValue * 0.0006).toFixed(2);
    const totalValue = Number(audioMinValue + freeMinValue + recordingValue + storageValue + simulcastValue).toFixed(2);

    const handleParticipantChange = (event, newValue) => {
        dispatch(setParticapantCount(newValue));
    };

    const handleTimeChange = (event, newValue) => {
        dispatch(setTimeValue(newValue));
    };

    const handleMonthChange = (event, newValue) => {
        dispatch(setMonthValue(newValue));
    };
    const handleRecordingDataChange = () => {
        if (isRecording) {
            dispatch(showRecordingData(false));
            dispatch(showStorageData(false));
        } else {
            dispatch(showRecordingData(true));
        }
    };
    const handleStorageDataChange = () => {
        if (isStorage) {
            dispatch(showStorageData(false));
        } else dispatch(showStorageData(true));
    };

    const handleSimulcastDataChange = () => {
        if (isSimulcast) {
            dispatch(showSimulcastData(false));
        } else dispatch(showSimulcastData(true));
    };

    return (
        <>
            <Grid container sx={{ width: { xs: '95%', sm: '90%', md: '75%' }, margin: 'auto', display: 'flex', padding: '10px' }} columnGap={3} rowGap={2} >
                <Grid item xs={12} sm={6} md={5} lg={6} xl={6}>
                    {/* <Box sx={{ width: 500, backgroundColor: '#E6E6E6', textAlign: 'center' }}> */}
                    <Grid container columnGap={6} rowGap={2}>
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                            <Title>Conference mode</Title>
                            <Box
                                sx={{
                                    marginBottom: "1rem",
                                    height: "auto",
                                    // background: "#F1F5FD",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: 'space-between',
                                    textAlign: 'center',
                                }}
                            >
                                <div style={{ display: "flex", gap: '10px' }}>
                                    <Button
                                        id="audio-btn"
                                        sx={{
                                            border: isAudio ? '0.1px solid #3087EC' : '0.1px solid grey',
                                            borderRadius: "16px",
                                            background: isAudio ? "#3087EC" : "transparent",
                                            color: isAudio ? "white" : "#686868",
                                            "&:hover": {
                                                background: isAudio ? "#3087EC" : "none",
                                            },
                                        }}
                                        onClick={() => {
                                            dispatch(showAudioData(true));
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: {
                                                    xl: "10px",
                                                    lg: "10px",
                                                    md: "10px",
                                                    sm: "10px",
                                                },
                                            }}
                                        >
                                            Audio
                                        </Typography>
                                    </Button>
                                    <Button
                                        id="audio-video-btn"
                                        sx={{
                                            border: isAudio ? '0.1px solid grey' : '0.1px solid #3087EC',
                                            borderRadius: "16px",
                                            color: isAudio ? "#686868" : "white",
                                            // mr: "10px",
                                            background: isAudio ? "transperant" : "#3087EC",
                                            "&:hover": {
                                                background: isAudio ? "none" : "#3087EC",
                                            },
                                        }}
                                        onClick={() => {
                                            dispatch(showAudioData(false));
                                            dispatch(setTabState(1));
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: {
                                                    xl: "10px",
                                                    lg: "10px",
                                                    md: "10px",
                                                    sm: "10px",
                                                },
                                            }}
                                        >
                                            Audio & Video
                                        </Typography>
                                    </Button>
                                </div>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                            {!isAudio ? <Title>Aggregate Resolution</Title> : ""}
                            <Box
                                sx={{
                                    marginBottom: "1rem",
                                    height: "auto",
                                    // background: "#F1F5FD",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: 'space-between',
                                    textAlign: 'center',
                                }}
                            >
                                {!isAudio &&
                                    <div style={{ display: "flex", gap: '10px' }}>
                                        <Button
                                            id="sd-btn"
                                            sx={{
                                                border: (tabState === 2 || tabState === 3) ? '0.1px solid grey' : '0.1px solid #3087EC',
                                                borderRadius: "16px",
                                                background: (tabState === 2 || tabState === 3) ? "transparent" : "#3087EC",
                                                color: (tabState === 2 || tabState === 3) ? "#686868" : "white",
                                                "&:hover": {
                                                    background: (tabState === 2 || tabState === 3) ? "none" : "#3087EC",
                                                },
                                            }}
                                            onClick={() => {
                                                dispatch(setTabState(1));
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xl: "10px",
                                                        lg: "10px",
                                                        md: "10px",
                                                        sm: "10px",
                                                    },
                                                }}
                                            >
                                                SD
                                            </Typography>
                                        </Button>
                                        <Button
                                            id="hd-btn"
                                            sx={{
                                                border: (tabState === 1 || tabState === 3) ? '0.1px solid grey' : '0.1px solid #3087EC',
                                                borderRadius: "16px",
                                                color: (tabState === 1 || tabState === 3) ? "#686868" : "white",
                                                // mr: "10px",
                                                background: (tabState === 1 || tabState === 3) ? "transperant" : "#3087EC",
                                                "&:hover": {
                                                    background: (tabState === 1 || tabState === 3) ? "none" : "#3087EC",
                                                },
                                            }}
                                            onClick={() => {
                                                dispatch(setTabState(2));
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xl: "10px",
                                                        lg: "10px",
                                                        md: "10px",
                                                        sm: "10px",
                                                    },
                                                }}
                                            >
                                                HD
                                            </Typography>
                                        </Button>
                                        <Button
                                            id="full-hd-btn"
                                            sx={{
                                                border: (tabState === 1 || tabState === 2) ? '0.1px solid grey' : '0.1px solid #3087EC',
                                                borderRadius: "16px",
                                                color: (tabState === 1 || tabState === 2) ? "#686868" : "white",
                                                // mr: "10px",
                                                background: (tabState === 1 || tabState === 2) ? "transperant" : "#3087EC",
                                                "&:hover": {
                                                    background: (tabState === 1 || tabState === 2) ? "none" : "#3087EC",
                                                },
                                            }}
                                            onClick={() => {
                                                dispatch(setTabState(3));
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xl: "10px",
                                                        lg: "10px",
                                                        md: "10px",
                                                        sm: "10px",
                                                    },
                                                }}
                                            >
                                                Full HD
                                            </Typography>
                                        </Button>
                                    </div>}

                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container columnGap={6} rowGap={2}>
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                            <Title>Participant</Title>
                            <AmountText>{participantCount}</AmountText>
                            <PrettoSlider
                                value={participantCount}
                                onChange={handleParticipantChange}
                                size="small"
                                aria-label="slider"
                                min={1}
                                max={300}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                            <Title>Avg. meeting duration (minutes)</Title>
                            <AmountText>{timeValue}</AmountText>
                            <PrettoSlider
                                value={timeValue}
                                onChange={handleTimeChange}
                                size="small"
                                aria-label="slider"
                                min={1}
                                max={240}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                            <Title>Monthly Sessions: </Title>
                            <AmountText>{monthValue}</AmountText>
                            <PrettoSlider
                                value={monthValue}
                                onChange={handleMonthChange}
                                size="small"
                                aria-label="slider"
                                min={1}
                                max={5000}
                            />
                        </Grid>
                    </Grid>
                    <Grid container columnGap={6} rowGap={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Title>Additional services (Select the services)</Title>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box
                                sx={{
                                    marginBottom: "1rem",
                                    height: "auto",
                                    // background: "#F1F5FD",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: 'space-between',
                                    textAlign: 'center',
                                }}
                            >
                                <div style={{ display: "flex", gap: '10px' }}>
                                    <Button
                                        id="recording-btn"
                                        sx={{
                                            border: !isRecording ? '0.1px solid grey' : '0.1px solid #3087EC',
                                            borderRadius: "16px",
                                            background: !isRecording ? "transparent" : "#3087EC",
                                            color: !isRecording ? "#686868" : "white",
                                            "&:hover": {
                                                background: !isRecording ? "none" : "#3087EC",
                                            },
                                        }}
                                        onClick={handleRecordingDataChange}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: {
                                                    xl: "10px",
                                                    lg: "10px",
                                                    md: "10px",
                                                    sm: "10px",
                                                },
                                            }}
                                        >
                                            Recording
                                        </Typography>
                                    </Button>
                                    {isRecording ?
                                        <Button
                                            id="storage-btn"
                                            sx={{
                                                border: !isStorage ? '0.1px solid grey' : '0.1px solid #3087EC',
                                                borderRadius: "16px",
                                                color: !isStorage ? "#686868" : "white",
                                                // mr: "10px",
                                                background: !isStorage ? "transperant" : "#3087EC",
                                                "&:hover": {
                                                    background: !isStorage ? "none" : "#3087EC",
                                                },
                                            }}
                                            onClick={handleStorageDataChange}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xl: "10px",
                                                        lg: "10px",
                                                        md: "10px",
                                                        sm: "10px",
                                                    },
                                                }}
                                            >
                                                Storage
                                            </Typography>
                                        </Button>
                                        : <Button
                                            id="storage-btn"
                                            disabled
                                            sx={{
                                                border: '0.1px solid grey',
                                                borderRadius: "16px",
                                                color: "#686868",
                                                // mr: "10px",
                                                background: "transperant"
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xl: "10px",
                                                        lg: "10px",
                                                        md: "10px",
                                                        sm: "10px",
                                                    },
                                                }}
                                            >
                                                Storage
                                            </Typography>
                                        </Button>}
                                    <Button
                                        id="simulcast-btn"
                                        sx={{
                                            border: !isSimulcast ? '0.1px solid grey' : '0.1px solid #3087EC',
                                            borderRadius: "16px",
                                            color: !isSimulcast ? "#686868" : "white",
                                            // mr: "10px",
                                            background: !isSimulcast ? "transperant" : "#3087EC",
                                            "&:hover": {
                                                background: !isSimulcast ? "none" : "#3087EC",
                                            },
                                        }}
                                        onClick={handleSimulcastDataChange}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: {
                                                    xl: "10px",
                                                    lg: "10px",
                                                    md: "10px",
                                                    sm: "10px",
                                                },
                                            }}
                                        >
                                            Simulcast (RTMP out)
                                        </Typography>
                                    </Button>
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                    {/* </Box> */}
                </Grid>

                {/* Pricing card */}
                <Grid item xs={12} sm={6} md={5} lg={5.6} xl={5}>
                    <CustomInfoCard>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
                            <HeadingTitle gutterBottom>Estimated Monthly Invoice</HeadingTitle>
                        </Box>
                        <Divider sx={{marginBottom:'10px'}}/>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <SubHeading gutterBottom>
                                {isAudio ? "Audio minutes"
                                    : tabState === 1 ? "Video minutes (SD)"
                                        : tabState === 2 ? "Video minutes (HD)"
                                            : "Video minutes (Full HD)"
                                }
                            </SubHeading>
                            <SubHeading gutterBottom>${audioMinValue}</SubHeading>
                        </Box>
                        <SmallText gutterBottom >
                            ({participantCount}&nbsp;x&nbsp;{timeValue}&nbsp;x&nbsp;{monthValue}
                            &nbsp;=&nbsp;{(participantCount * timeValue * monthValue).toLocaleString()}&nbsp;mins)
                        </SmallText>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <SubHeading gutterBottom >
                                {isAudio ? 'Free audio minutes' : "Free audio & video minutes"}</SubHeading>
                            <SubHeading gutterBottom>${freeMinValue}</SubHeading>
                        </Box>
                        <SmallText gutterBottom >
                            (10,000&nbsp;mins)
                        </SmallText>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <SubHeading gutterBottom >Recording</SubHeading>
                            <SubHeading gutterBottom>${recordingValue}</SubHeading>
                        </Box>
                        <SmallText gutterBottom >
                            {!isRecording ? '(0 min)' : `(${timeValue} x ${monthValue} = ${(timeValue * monthValue).toLocaleString()} mins)`}
                        </SmallText><Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <SubHeading gutterBottom >Storage</SubHeading>
                            <SubHeading gutterBottom>${storageValue}</SubHeading>
                        </Box>
                        <SmallText gutterBottom >
                            {isStorage ? `(${timeValue} x ${monthValue} = ${(timeValue * monthValue).toLocaleString()} mins)` : '(0 min)'}
                        </SmallText>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <SubHeading gutterBottom >Simulcast (RTMP out)</SubHeading>
                            <SubHeading gutterBottom>${simulcastValue}</SubHeading>
                        </Box>
                        <SmallText gutterBottom >
                            {isSimulcast ? `(${timeValue} x ${monthValue} = ${(timeValue * monthValue).toLocaleString()} mins)` : '(0 min)'}
                        </SmallText>
                        <Divider sx={{marginBottom:'10px'}}/>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <SubHeading gutterBottom >Total amount</SubHeading>
                            <SubHeading gutterBottom>${totalValue}</SubHeading>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',width:'100%' }}>
                            <ButtonPrimary
                                id="get-started"
                                responsive={false}
                                margin="1.3rem 1.3rem 0 0"
                                fixedHeight="40px"
                            // onClick={() => navigate("/contact-us")}
                            >
                                Get Started
                            </ButtonPrimary>
                        </Box>
                    </CustomInfoCard>
                </Grid>
                {/* Pricing card */}
            </Grid>
        </>
    );
}


