import React from "react";
import Particle2 from "./Particle2";
import Native from "src/assets/Svg/Native";
import Online from "src/assets/Svg/Online";
import Inspect from "src/assets/Svg/Inspect";
import Request from "src/assets/Svg/Request";
import Http from "src/assets/Svg/Http";
import FileServer from "src/assets/Svg/FileServer";
import { Box, Hidden, Typography } from "@mui/material";

const developerData = [
  {
    icon: <Native />,
    heading: "Native Installation on any OS",
    content: "currently we support for Windows and Linux Download Ingressify",
  },
  {
    icon: <Online />,
    heading: "Online in One Line",
    content: "Put any service, device, or app on the internet with one command",
  },
  {
    icon: <Inspect />,
    heading: "Web Inspection Agent",
    content:
      "Inspect and troubleshoot HTTP requests in real-time via inspection UI and APIs",
  },
  {
    icon: <Request />,
    heading: "Request Replay",
    content:
      "Save hours reproducing HTTP requests and triggering webhook calls with a single click",
  },
  {
    icon: <Http />,
    heading: "HTTP, TCP, and TLS tunnels",
    content:
      "Web apps, web services, APIs, databases, SSH, RDP, or any TCP service",
  },
  {
    icon: <FileServer />,
    heading: "File Server",
    content:
      "Serve files over secure HTTP tunnels without standing up WebDAV, SFTP, and file servers",
  },
];

const DeveloperFriendly = () => {
  return (
    <div>
      {" "}
      <Hidden lgDown>
        <Particle2 />
      </Hidden>
      <Box
        sx={{
          marginTop: "50px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          transform: {
            xl: "translate(0, -950px)",
            lg: "translate(0, -950px)",
          },
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontSize: {
              lg: "40px",
              md: "30px",
              sm: "30px",
              xs: "20px",
            },
            fontWeight: "500",
            backgroundImage:
              "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Developer Friendly
        </Typography>

        <Typography
          sx={{
            textAlign: "center",
            fontSize: {
              lg: "16px",
              md: "14px",
              sm: "12px",
              xs: "12px",
            },
            color: "white",
            fontWeight: "500",
            marginTop: "30px",
          }}
        >
          Put localhost on the internet securely
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "40px",
            marginTop: "40px",
            width: {
              xl: "60%",
              lg: "80%",
              md: "80%",
              sm: "80%",
              xs: "80%",
            },
            "@media (max-width:2004px) and (min-width:1920px)": {
              width: "63%",
            },
          }}
        >
          {developerData.map((data, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "20px",
                background: "white",
                borderRadius: "10px",
                width: {
                  xl: "370px",
                  lg: "350px",
                  md: "300px",
                  sm: "250px",
                  xs: "250px",
                },
                height: {
                  xl: "320px",
                  lg: "320px",
                  md: "320px",
                  sm: "320px",
                  xs: "320px",
                },
              }}
            >
              <Box
                sx={{
                  marginTop: "25px",
                  display: "flex",
                  justifyContent: "center",
                  height: "98px",
                }}
              >
                {data.icon}
              </Box>
              <Typography
                sx={{
                  marginTop: "25px",
                  fontSize: {
                    lg: "20px",
                    md: "14px",
                    sm: "12px",
                    xs: "12px",
                  },
                  fontWeight: "600",
                  textAlign: {
                    xl: "left",
                    lg: "left",
                    md: "left",
                    sm: "left",
                    xs: "center",
                  },
                }}
              >
                {data.heading}
              </Typography>
              <Typography
                sx={{
                  marginTop: "25px",
                  fontSize: {
                    lg: "16px",
                    md: "14px",
                    sm: "12px",
                    xs: "12px",
                  },
                  fontWeight: "400",
                  textAlign: {
                    xl: "left",
                    lg: "left",
                    md: "left",
                    sm: "left",
                    xs: "center",
                  },
                }}
              >
                {data.content}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </div>
  );
};

export default DeveloperFriendly;