import React, { useState, useEffect } from 'react'
import { Grid, Typography, Box,useMediaQuery,useTheme } from '@mui/material';
import PaymentIcon from '@mui/icons-material/Payment';
import CancelIcon from '@mui/icons-material/Cancel';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import CancelPayment from './CancelPayment';
import Table from './Table'
import helper from 'src/services/helper';


import AddPayment from './AddPayment';
import { editOutstanding } from 'src/slices/billing';


import { useSelector,useDispatch } from 'react-redux';
import { RootState } from 'src/store';


export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function Home() {
    const { outstanding } = useSelector((state: RootState) => state.Billing)
    console.log("outstanding",outstanding);
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.up('sm'));
    const dispatch=useDispatch();

    const [cancelPaymentDailog,setCancelDailog] = useState(false)
    const[addPaymentDialog,setPaymentDailog]=useState(false)


    const paymentDataPass=(data)=>{
        setPaymentDailog(data)

    }


    const handleClickOpenCancelSubscriptionDailog = (data) => {
        setCancelDailog(data);
    };

    useEffect(()=>{
        dispatch(editOutstanding(""))
    },[dispatch])


    return (

        <>
            <Box sx={{ ml: 2, mr: 2 }}>
                <Typography variant='h3' sx={{mt:1}}>BILLING</Typography>
                <Paper elevation={1} square style={{ marginBottom: "16px", marginTop:"8px",backgroundColor:"#f7f8fb"}}>
                    <Grid container display="flex" justifyContent="space-between" >
                        <Grid xs={12} md={4} lg={4} sx={{ mt: 1, mb: 1,p:2 }} >
                            <Box sx={{ mt: 1 }} display="flex" style={{alignItems:mobile?"flex-start":"center",justifyContent:mobile?"":"center"}} >
                                <Typography sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>Your upcoming bill</Typography>
                            </Box>

                            <Box sx={{ mt: 3 }} display="flex" style={{alignItems:mobile?"flex-start":"center",justifyContent:mobile?"":"center"}}>
                                <Typography sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>Usage until today:{outstanding? outstanding[0]?.amount?.unit:"NA"}{outstanding && outstanding[0]?.amount?.value}</Typography>
                            </Box>
                        </Grid>


                        <Grid xs={12} md={5} lg={5}>
                            <Box display="flex" style={{alignItems:mobile?"flex-start":"center",justifyContent:mobile?"":"center",marginTop:mobile?74:"4px",marginLeft:mobile?"":"8px",marginRight:mobile?"":"8px"}}>
                                <Typography sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>Your bill will be sent on {outstanding? helper.dateFormate(outstanding[0]?.billingDate):": NA"}</Typography>
                            </Box>
                        </Grid>


                        <Grid  xs={12} md={3} lg={3} display="flex" flexDirection="column" alignItems="center" sx={{ border:"1px solid #bfc9d8",mt:mobile?"":"16px" }}>
                            <Box sx={{ mt: 2,pb:1 ,borderBottom:"1px solid #bfc9d8"}}>
                                <Button style={{ textTransform: 'uppercase', fontWeight: 'bold',backgroundColor:"#f7f8fb",color:"black" }} variant="contained"  onClick={()=>setPaymentDailog(true)} startIcon={<PaymentIcon color="success" />}>add payment method</Button>
                            </Box>
                        

                            <Box sx={{ mt: 1,mb:mobile?"":2 }}>
                                <Button sx={{ textTransform: 'uppercase', fontWeight: 'bold',backgroundColor:"#f7f8fb",color:"black" , }} onClick={() => setCancelDailog(true)} startIcon={<CancelIcon color="error" />}>
                                    Cancel Subscription
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
                <Table />

                

            </Box>

            {addPaymentDialog?<AddPayment paymentDataPass={paymentDataPass}/>:null}

            {cancelPaymentDailog ? <CancelPayment openDailog={handleClickOpenCancelSubscriptionDailog} /> : null}

        </>



    )
}

export default Home
