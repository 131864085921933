import { Box, Grid } from '@mui/material';
import React from 'react'
// import { useNavigate } from 'react-router-dom';
import { ButtonPrimary, ButtonSecondary } from 'src/components/CustomButtons';
import { audioVideoText } from 'src/constants/string';
import { ButtonBox, CenterContentBox, ExtraFeatureCard, ExtraFeatureGridContainer, FeatureCard, FeatureContent, FeatureGridContainerRow, FeatureGridContainerRowReverse, FeatureHeading, FeatureTitle, FeatureWrapper, HeadingSubTitle, HeadingTitle, HeadingWrapper } from './productStyle';
// {/* <Grid item xs={} sm={} md={} lg={} xl={}>

// </Grid> */}
const AudioVideo = () => {
  // const navigate = useNavigate();
  return (
    <Box sx={{padding:'10px'}}>
      <Grid container sx={{ width: { xs: '95%', sm: '90%', md: '75%' }, margin: 'auto', padding: '10px', mb:'50px'}} columnGap={4} rowGap={2}>
        <Grid item xs={12} sm={6} md={5} lg={4} xl={4}>
          <HeadingWrapper>
            <HeadingTitle>{audioVideoText.title} </HeadingTitle>
            <HeadingSubTitle>{audioVideoText.subTitle}</HeadingSubTitle>
            <ButtonBox>
              <ButtonPrimary
                id="try-now-its-free"
                responsive={false}
                fixedHeight="40px"
              // onClick={() => navigate("/contact-us")}
              >
                {audioVideoText.button1}
              </ButtonPrimary>
              <ButtonSecondary
                id="talk-to-us"
                responsive={false}
                fixedHeight="40px"
              // onClick={() => navigate("/pages/get-demo")}
              >
                {audioVideoText.button2}
              </ButtonSecondary>
            </ButtonBox>
          </HeadingWrapper>
        </Grid>
        <Grid item xs={12} sm={5} md={6.5} lg={7.5} xl={7.5} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <FeatureCard></FeatureCard>
        </Grid>
      </Grid>
      
      <CenterContentBox>
        <FeatureTitle>{audioVideoText.featureHead}</FeatureTitle>
      </CenterContentBox>
      
      <FeatureGridContainerRow container sx={{ width: { xs: '95%', sm: '90%', md: '75%' }}} columnGap={10} rowGap={2}>
        <Grid item xs={12} sm={5.1} md={5.2} lg={5.5} xl={5.5} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <FeatureCard></FeatureCard>  
        </Grid>
        <Grid item xs={12} sm={5.1} md={5.2} lg={5.5} xl={5.5} display={'flex'} justifyContent={'start'} alignItems={'center'}>
          <FeatureWrapper>
            <FeatureHeading>{audioVideoText.firstFeatureTitle} </FeatureHeading>
            <FeatureContent>{audioVideoText.firstFeatureContent}</FeatureContent>
          </FeatureWrapper>
        </Grid>
      </FeatureGridContainerRow>

      <FeatureGridContainerRowReverse container sx={{ width: { xs: '95%', sm: '90%', md: '75%' } }} columnGap={10} rowGap={2}>
        <Grid item xs={12} sm={5.1} md={5.2} lg={5.5} xl={5.5} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <FeatureCard></FeatureCard>  
        </Grid>
        <Grid item xs={12} sm={5.1} md={5.2} lg={5.5} xl={5.5} display={'flex'} justifyContent={'start'} alignItems={'center'}>
          <FeatureWrapper>
            <FeatureHeading>{audioVideoText.secondFeatureTitle} </FeatureHeading>
            <FeatureContent>{audioVideoText.secondFeatureContent}</FeatureContent>
          </FeatureWrapper>
        </Grid>
      </FeatureGridContainerRowReverse>

      <FeatureGridContainerRow container sx={{ width: { xs: '95%', sm: '90%', md: '75%' } }} columnGap={10} rowGap={2}>
        <Grid item xs={12} sm={5.1} md={5.2} lg={5.5} xl={5.5} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <FeatureCard></FeatureCard>  
        </Grid>
        <Grid item xs={12} sm={5.1} md={5.2} lg={5.5} xl={5.5} display={'flex'} justifyContent={'start'} alignItems={'center'}>
          <FeatureWrapper>
            <FeatureHeading>{audioVideoText.thirdFeatureTitle} </FeatureHeading>
            <FeatureContent>{audioVideoText.thirdFeatureContent}</FeatureContent>
          </FeatureWrapper>
        </Grid>
      </FeatureGridContainerRow>

      <FeatureGridContainerRowReverse container sx={{ width: { xs: '95%', sm: '90%', md: '75%' } }} columnGap={10} rowGap={2}>
        <Grid item xs={12} sm={5.1} md={5.2} lg={5.5} xl={5.5} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <FeatureCard></FeatureCard>  
        </Grid>
        <Grid item xs={12} sm={5.1} md={5.2} lg={5.5} xl={5.5} display={'flex'} justifyContent={'start'} alignItems={'center'}>
          <FeatureWrapper>
            <FeatureHeading>{audioVideoText.fourthFeatureTitle} </FeatureHeading>
            <FeatureContent>{audioVideoText.fourthFeatureContent}</FeatureContent>
          </FeatureWrapper>
        </Grid>
      </FeatureGridContainerRowReverse>

      <FeatureGridContainerRow container sx={{ width: { xs: '95%', sm: '90%', md: '75%' } }} columnGap={10} rowGap={2}>
        <Grid item xs={12} sm={5.1} md={5.2} lg={5.5} xl={5.5} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <FeatureCard></FeatureCard>  
        </Grid>
        <Grid item xs={12} sm={5.1} md={5.2} lg={5.5} xl={5.5} display={'flex'} justifyContent={'start'} alignItems={'center'}>
          <FeatureWrapper>
            <FeatureHeading>{audioVideoText.fifthFeatureTitle} </FeatureHeading>
            <FeatureContent>{audioVideoText.fifthFeatureContent}</FeatureContent>
          </FeatureWrapper>
        </Grid>
      </FeatureGridContainerRow>

      <FeatureGridContainerRowReverse container sx={{ width: { xs: '95%', sm: '90%', md: '75%' } }} columnGap={10} rowGap={2}>
        <Grid item xs={12} sm={5.1} md={5.2} lg={5.5} xl={5.5} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <FeatureCard></FeatureCard>  
        </Grid>
        <Grid item xs={12} sm={5.1} md={5.2} lg={5.5} xl={5.5} display={'flex'} justifyContent={'start'} alignItems={'center'}>
          <FeatureWrapper>
            <FeatureHeading>{audioVideoText.sixthFeatureTitle} </FeatureHeading>
            <FeatureContent>{audioVideoText.sixthFeatureContent}</FeatureContent>
          </FeatureWrapper>
        </Grid>
      </FeatureGridContainerRowReverse>

      <CenterContentBox>
        <FeatureTitle>{audioVideoText.extraFeatureHead}</FeatureTitle>
      </CenterContentBox>

      <ExtraFeatureGridContainer container sx={{ width: { xs: '95%', sm: '90%', md: '85%', lg: '75%' } }} columnGap={5} rowGap={2}>
        <Grid item xs={12} sm={5} md={2.5} lg={2.5} xl={2.5} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <ExtraFeatureCard></ExtraFeatureCard>  
        </Grid>
        <Grid item xs={12} sm={5} md={2.5} lg={2.5} xl={2.5} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <ExtraFeatureCard></ExtraFeatureCard>  
        </Grid>
        <Grid item xs={12} sm={5} md={2.5} lg={2.5} xl={2.5} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <ExtraFeatureCard></ExtraFeatureCard>  
        </Grid>
        <Grid item xs={12} sm={5} md={2.5} lg={2.5} xl={2.5} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <ExtraFeatureCard></ExtraFeatureCard>  
        </Grid>
      </ExtraFeatureGridContainer>
    </Box>
  )
}

export default AudioVideo;