import { useState } from "react";
import DeveloperProductivity from "src/assets/Svg/DeveloperProductivity";
import Zerotrust from "src/assets/Svg/Zerotrust";
import B2b from "src/assets/Svg/B2b";
import ReactCardFlip from "react-card-flip";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Box, Typography, Grid } from "@mui/material";

const Solutions = () => {
  const [flip, setFlip] = useState(false);
  const [flip1, setFlip1] = useState(false);
  const [flip2, setFlip2] = useState(false);
  return (
    <div>
      <Box
        sx={{
          marginTop: {
            xs: "50px",
            sm: "50px",
            md: "-550px",
            lg: "-700px",
            xl: "-700px",
          },
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontSize: {
              lg: "40px",
              md: "30px",
              sm: "30px",
              xs: "20px",
            },
            fontWeight: "500",
            backgroundImage:
              "linear-gradient(180deg, #60E1CB 0%, rgba(0, 194, 255, 0.65) 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Solutions we offer
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "40px",
              marginTop: "30px",
              // paddingLeft: {
              //   xs: "20px",
              // },
            }}
          >
            <Grid item>
              <ReactCardFlip isFlipped={flip} flipDirection="horizontal">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "20px",
                    background: "white",
                    borderRadius: "10px",
                    width: {
                      lg: "270px",
                      md: "250px",
                      sm: "250px",
                      xs: "250px",
                    },
                    height: {
                      lg: "400px",
                      md: "350px",
                      sm: "320px",
                      xs: "350px",
                    },
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    {/* <DeveloperModeIcon sx={{ fontSize: "50px" }} /> */}
                    <DeveloperProductivity />
                  </Box>
                  <Typography
                    sx={{
                      marginTop: "25px",
                      fontSize: {
                        lg: "20px",
                        md: "14px",
                        sm: "12px",
                        xs: "12px",
                      },
                      fontWeight: "600",
                      textAlign: {
                        xl: "left",
                        lg: "left",
                        md: "left",
                        sm: "left",
                        xs: "center",
                      },
                    }}
                  >
                    Developer Productivity
                  </Typography>
                  <Typography
                    sx={{
                      marginTop: "25px",
                      fontSize: {
                        lg: "16px",
                        md: "14px",
                        sm: "12px",
                        xs: "12px",
                      },
                      fontWeight: "400",
                      textAlign: {
                        xl: "left",
                        lg: "left",
                        md: "left",
                        sm: "left",
                        xs: "center",
                      },
                    }}
                  >
                    Manage developer access through your identity provider.
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: {
                        xl: "left",
                        lg: "left",
                        md: "left",
                        sm: "left",
                        xs: "center",
                      },
                      gap: "5px",
                      marginTop: {
                        lg: "30px",
                        md: "15px",
                        sm: "30px",
                        xs: "30px",
                      },
                      alignItems: "center",
                      color: "#7BC0E3",
                      cursor: "pointer",
                    }}
                    onClick={() => setFlip(!flip)}
                  >
                    <Typography>Read More</Typography>
                    <ArrowRightAltIcon />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "20px",
                    background: "white",
                    borderRadius: "10px",

                    width: {
                      lg: "270px",
                      md: "250px",
                      sm: "250px",
                      xs: "250px",
                    },
                    height: {
                      lg: "400px",
                      md: "350px",
                      sm: "320px",
                      xs: "350px",
                    },
                  }}
                >
                  <ul>
                    <li>
                      <Typography
                        sx={{
                          fontSize: {
                            lg: "16px",
                            md: "14px",
                            sm: "12px",
                            xs: "12px",
                          },
                          fontWeight: "500",
                        }}
                      >
                        Connect to Industrial IoT systems in remote locations
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        sx={{
                          fontSize: {
                            lg: "16px",
                            md: "14px",
                            sm: "12px",
                            xs: "12px",
                          },
                          fontWeight: "500",
                        }}
                      >
                        Connect to IoT systems anywhere
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        sx={{
                          fontSize: {
                            lg: "16px",
                            md: "14px",
                            sm: "12px",
                            xs: "12px",
                          },
                          fontWeight: "500",
                        }}
                      >
                        Increase IoT availability
                      </Typography>
                    </li>
                  </ul>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: {
                        xl: "left",
                        lg: "left",
                        md: "left",
                        sm: "left",
                        xs: "center",
                      },
                      gap: "5px",
                      marginTop: {
                        lg: "120px",
                        md: "120px",
                        sm: "110px",
                        xs: "120px",
                      },
                      alignItems: "center",
                      color: "#7BC0E3",
                      cursor: "pointer",
                    }}
                    onClick={() => setFlip(!flip)}
                  >
                    <Typography>Back</Typography>
                    <ArrowRightAltIcon />
                  </Box>
                </Box>
              </ReactCardFlip>
            </Grid>

            <Grid item>
              <ReactCardFlip isFlipped={flip1} flipDirection="horizontal">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "20px",
                    background: "white",
                    borderRadius: "10px",

                    width: {
                      lg: "270px",
                      md: "250px",
                      sm: "250px",
                      xs: "250px",
                    },
                    height: {
                      lg: "400px",
                      md: "350px",
                      sm: "320px",
                      xs: "350px",
                    },
                  }}
                >
                  {/* <Box sx={{ marginTop: "25px" }}>
                <LockIcon sx={{ fontSize: "50px" }} />
              </Box> */}
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    {/* <DeveloperModeIcon sx={{ fontSize: "50px" }} /> */}
                    <Zerotrust />
                  </Box>
                  <Typography
                    sx={{
                      marginTop: "25px",
                      fontSize: {
                        lg: "20px",
                        md: "14px",
                        sm: "12px",
                        xs: "12px",
                      },
                      fontWeight: "600",
                      textAlign: {
                        xl: "left",
                        lg: "left",
                        md: "left",
                        sm: "left",
                        xs: "center",
                      },
                    }}
                  >
                    Zero Trust Access
                  </Typography>
                  <Typography
                    sx={{
                      marginTop: "25px",
                      fontSize: {
                        lg: "16px",
                        md: "14px",
                        sm: "12px",
                        xs: "12px",
                      },
                      fontWeight: "400",
                      textAlign: {
                        xl: "left",
                        lg: "left",
                        md: "left",
                        sm: "left",
                        xs: "center",
                      },
                    }}
                  >
                    Keep track of developer activity and tunnel traffic with
                    ngrok’s events system.
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      marginTop: {
                        lg: "30px",
                        md: "15px",
                        sm: "15px",
                        xs: "30px",
                      },
                      alignItems: "center",
                      color: "#7BC0E3",
                      cursor: "pointer",
                      justifyContent: {
                        xl: "left",
                        lg: "left",
                        md: "left",
                        sm: "left",
                        xs: "center",
                      },
                    }}
                    onClick={() => setFlip1(!flip1)}
                  >
                    <Typography>Read More</Typography>
                    <ArrowRightAltIcon />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "20px",
                    background: "white",
                    borderRadius: "10px",

                    width: {
                      lg: "270px",
                      md: "250px",
                      sm: "250px",
                      xs: "250px",
                    },
                    height: {
                      lg: "400px",
                      md: "350px",
                      sm: "320px",
                      xs: "350px",
                    },
                  }}
                >
                  <ul>
                    <li>
                      <Typography
                        sx={{
                          fontSize: {
                            lg: "16px",
                            md: "14px",
                            sm: "12px",
                            xs: "12px",
                          },
                          fontWeight: "500",
                        }}
                      >
                        Connect to Industrial IoT systems in remote locations
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        sx={{
                          fontSize: {
                            lg: "16px",
                            md: "14px",
                            sm: "12px",
                            xs: "12px",
                          },
                          fontWeight: "500",
                        }}
                      >
                        Connect to IoT systems anywhere
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        sx={{
                          fontSize: {
                            lg: "16px",
                            md: "14px",
                            sm: "12px",
                            xs: "12px",
                          },
                          fontWeight: "500",
                        }}
                      >
                        Increase IoT availability
                      </Typography>
                    </li>
                  </ul>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      marginTop: {
                        lg: "120px",
                        md: "120px",
                        sm: "110px",
                        xs: "130px",
                      },
                      alignItems: "center",
                      color: "#7BC0E3",
                      cursor: "pointer",
                      justifyContent: {
                        xl: "left",
                        lg: "left",
                        md: "left",
                        sm: "left",
                        xs: "center",
                      },
                    }}
                    onClick={() => setFlip1(!flip1)}
                  >
                    <Typography>Back</Typography>
                    <ArrowRightAltIcon />
                  </Box>
                </Box>
              </ReactCardFlip>
            </Grid>

            <Grid item>
              <ReactCardFlip isFlipped={flip2} flipDirection="horizontal">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "20px",
                    background: "white",
                    borderRadius: "10px",
                    width: {
                      lg: "270px",
                      md: "250px",
                      sm: "250px",
                      xs: "250px",
                    },
                    height: {
                      lg: "400px",
                      md: "350px",
                      sm: "320px",
                      xs: "350px",
                    },
                  }}
                >
                  {/* <Box sx={{ marginTop: "25px" }}>
                <NetworkLockedIcon sx={{ fontSize: "50px" }} />
              </Box> */}
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    {/* <DeveloperModeIcon sx={{ fontSize: "50px" }} /> */}
                    <B2b />
                  </Box>
                  <Typography
                    sx={{
                      marginTop: "25px",
                      fontSize: {
                        lg: "20px",
                        md: "14px",
                        sm: "12px",
                        xs: "12px",
                      },
                      fontWeight: "600",
                      textAlign: {
                        xl: "left",
                        lg: "left",
                        md: "left",
                        sm: "left",
                        xs: "center",
                      },
                    }}
                  >
                    B2B Networks
                  </Typography>
                  <Typography
                    sx={{
                      marginTop: "25px",
                      fontSize: {
                        lg: "16px",
                        md: "14px",
                        sm: "12px",
                        xs: "12px",
                      },
                      fontWeight: "400",
                      textAlign: {
                        xl: "left",
                        lg: "left",
                        md: "left",
                        sm: "left",
                        xs: "center",
                      },
                    }}
                  >
                    Enforce usage of the company account instead of personal
                    ngrok accounts.
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      marginTop: {
                        lg: "30px",
                        md: "15px",
                        sm: "15px",
                        xs: "30px",
                      },
                      alignItems: "center",
                      color: "#7BC0E3",
                      cursor: "pointer",
                      justifyContent: {
                        xl: "left",
                        lg: "left",
                        md: "left",
                        sm: "left",
                        xs: "center",
                      },
                    }}
                    onClick={() => setFlip2(!flip2)}
                  >
                    <Typography>Read More</Typography>
                    <ArrowRightAltIcon />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "20px",
                    background: "white",
                    borderRadius: "10px",

                    width: {
                      lg: "270px",
                      md: "250px",
                      sm: "250px",
                      xs: "250px",
                    },
                    height: {
                      lg: "400px",
                      md: "350px",
                      sm: "320px",
                      xs: "350px",
                    },
                  }}
                >
                  <ul>
                    <li>
                      <Typography
                        sx={{
                          fontSize: {
                            lg: "16px",
                            md: "14px",
                            sm: "12px",
                            xs: "12px",
                          },
                          fontWeight: "500",
                        }}
                      >
                        Connect to Industrial IoT systems in remote locations
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        sx={{
                          fontSize: {
                            lg: "16px",
                            md: "14px",
                            sm: "12px",
                            xs: "12px",
                          },
                          fontWeight: "500",
                        }}
                      >
                        Connect to IoT systems anywhere
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        sx={{
                          fontSize: {
                            lg: "16px",
                            md: "14px",
                            sm: "12px",
                            xs: "12px",
                          },
                          fontWeight: "500",
                        }}
                      >
                        Increase IoT availability
                      </Typography>
                    </li>
                  </ul>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      marginTop: {
                        lg: "120px",
                        md: "120px",
                        sm: "110px",
                        xs: "130px",
                      },
                      alignItems: "center",
                      color: "#7BC0E3",
                      cursor: "pointer",
                      justifyContent: {
                        xl: "left",
                        lg: "left",
                        md: "left",
                        sm: "left",
                        xs: "center",
                      },
                    }}
                    onClick={() => setFlip2(!flip2)}
                  >
                    <Typography>Back</Typography>
                    <ArrowRightAltIcon />
                  </Box>
                </Box>
              </ReactCardFlip>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default Solutions;
