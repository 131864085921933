import { createSlice } from "@reduxjs/toolkit";
import portegoUseImage1 from "src/assets/portego-use-image1.png";
import portegoUseImage2 from "src/assets/portego-use-image2.png";
import portegoUseImage3 from "src/assets/portego-use-image3.png";
import portegoUseImage4 from "src/assets/portego-use-image4.png";

const planRecords = [{
    subscription_type: "Dev",
    active_users: 25,
    subscription_plan: "Free"
},
{
    subscription_type: "Basic",
    active_users: 300,
    subscription_plan: "$99.00/month"
},
{
    subscription_type: "Standard",
    active_users: 1500,
    subscription_plan: "$499.00/month"
},
{
    subscription_type: "Enterprise",
    active_users: 3000,
    subscription_plan: "$999.00/month"
}];

const portegoUseRecords = [
    {
        title: "Healthcare",
        information: "Many care providers are turning to telehealth as a way to safely see patients during the pandemic. Virtual urgent care visits at a large health system, NYU Langone Health, had a 683% increase.",
        image: portegoUseImage1,
        direction: "right"
    },
    {
        title: "Social",
        information: "Developers of consumer apps are adding video into their experience to interact with other users. This is frequently happening in social, gaming, and dating apps.",
        image: portegoUseImage2,
    },
    {
        title: "Education",
        information: "According to the World Economic Forum, 1.38 billion learners have been impacted by national closures worldwide. Many LMS platforms and schools are using video to enable remote learning.",
        image: portegoUseImage3,
        direction: "right"
    },
    {
        title: "Recruiting",
        information: "Many companies are embedding video into their recruiting platforms to reimagine candidate engagement.",
        image: portegoUseImage4,
    }
]

const documentDetails = [
    // {
    //     title: "Documentation",
    //     information: "Detailed informations, integration guides and code samples.",
    //     button: "View Doc"
    // },
    // {
    //     title: "Solution Overview",
    //     information: "Use cases, top features and pricing model.",
    //     button: "View Solution"
    // }
];

interface HomePlanRecord {
    subscription_type: string,
    active_users: number,
    subscription_plan: string
}

interface PortegoUseRecord {
    title: string,
    information: string,
    image?: string,
    direction?: string
}

interface homeDocumentDetail {
    title: string,
    information: string,
    button: string,
}

interface HomePlans {
    homePlanRecords: HomePlanRecord[],
    portegoUseRecords: PortegoUseRecord[],
    homeDocumentDetails: homeDocumentDetail[]
}

const initialState: HomePlans = {
    homePlanRecords: planRecords,
    portegoUseRecords: portegoUseRecords,
    homeDocumentDetails: documentDetails
};

const slice = createSlice({
    name: "HomePlanCards",
    initialState,

    reducers: {
        // Single Payload
        HomePlanListing(state, action) {
            state.homePlanRecords = action.payload
        },
        PortegoUseListing(state, action) {
            state.portegoUseRecords = action.payload
        },
        DocumentDetailCards(state, action) {
            state.homeDocumentDetails = action.payload
        },

    },
});

export const HomePlanCardAPI = () => async (dispatch) => {
    dispatch(slice.actions.HomePlanListing(planRecords));
}

export const PortegoUseCardAPI = () => async (dispatch) => {
    dispatch(slice.actions.PortegoUseListing(portegoUseRecords));
}

export const DocumentDetailsAPI = () => async (dispatch) => {
    dispatch(slice.actions.DocumentDetailCards(documentDetails));
}

export const reducer = slice.reducer;

export default slice;